import React from "react";
import {
  prettifyError,
  findPaperSize
} from "../reduxandotherstuff/commonfabric";
import AddPlanList from "./AddPlanList.js";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import * as constants from "../reduxandotherstuff/constants";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class AddPlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.ismounted = false;
  }

  componentDidMount() {
    this.ismounted = true;
  }

  componentWillUnmount() {
    this.ismounted = false;
  }

  render() {
    let fieldmatching = { label: "Name" };
    let pageSize = "";
    let pagewidthMm = this.props.pagewidth;
    if (pagewidthMm) {
      pagewidthMm = pagewidthMm * constants.pointtomm;
    } else {
      pagewidthMm = "";
    }
    let pageheightMm = this.props.pageheight;
    if (pageheightMm) {
      pageheightMm = pageheightMm * constants.pointtomm;
    } else {
      pageheightMm = "";
    }
    if (pagewidthMm && pageheightMm) {
      pageSize = findPaperSize([pagewidthMm, pageheightMm]);
    }
    let pageOrient = "";
    if (pagewidthMm && pageheightMm) {
      if (pagewidthMm > pageheightMm) {
        pageOrient = "Landscape";
      } else if (pagewidthMm < pageheightMm) {
        pageOrient = "Portrait";
      } else {
        pageOrient = "Square";
      }
    }
    let totalPageSize;
    if (pageSize) {
      totalPageSize = pageSize + " " + pageOrient;
    } else if (pagewidthMm && pageheightMm) {
      totalPageSize =
        Math.round(pagewidthMm).toString() +
        "mm × " +
        Math.round(pageheightMm).toString() +
        "mm (" +
        pageOrient +
        ")";
    }
    let resolWarning = "";
    let maxWidth = parseInt(localStorage.getItem("maxWidth"));
    let maxHeight = parseInt(localStorage.getItem("maxHeight"));
    if (pageSize) {
      let minDPIWidth =
        (constants.mmpinch * maxWidth) / constants.PAGESIZES[pageSize][1];
      let minDPIHeight =
        (constants.mmpinch * maxHeight) / constants.PAGESIZES[pageSize][0];
      let minDPI = Math.floor(Math.min(minDPIWidth, minDPIHeight));
      if (this.props.resolution > minDPI) {
        resolWarning = `Warning: the maximum resolution for working with ${pageSize} documents on this computer is ${minDPI} dpi.\n`;
      }
    }

    let pdfObj;
    if (!this.props.fileuploaded && !this.props.successLoad) {
      pdfObj = "";
    } else {
      pdfObj = (
        <>
          <div>
            {this.props.successLoad && (
              <div className="form-group row">
                <div className="col-1">
                  <button
                    className="btn btn-info"
                    onClick={this.props.handlePrev}
                    id="prevButton"
                  >
                    Previous
                  </button>
                </div>
                <div className="col-1">
                  <button
                    className="btn btn-info"
                    onClick={this.props.handleNext}
                    id="nextButton"
                  >
                    Next
                  </button>
                </div>
                <div className="col-2" key={this.props.showlegend}>
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="selectforinc"
                      name="selectforinc"
                      onChange={this.props.handleCheckChange}
                      checked={this.props.selectforinc}
                      disabled={this.props.uploadall}
                    />{" "}
                    Include?
                  </label>
                </div>

                <div className="col-4 col-form-label">
                  {"Paper size: "}
                  {totalPageSize}
                </div>
                <label className="col-1 col-form-label" htmlFor="pagenosel">
                  Page no:
                </label>
                <div className="col-2">
                  <input
                    className="form-control"
                    name="pagenosel"
                    id="pagenosel"
                    value={this.props.pagenosel}
                    min="1"
                    max={this.props.numPages}
                    type="number"
                    onChange={this.props.handlepagenoselChange}
                  />
                </div>
                <div className="col-1 col-form-label">
                  out of&nbsp;{this.props.numPages}
                </div>
              </div>
            )}
            {this.props.fileuploaded && (
              <Document
                className="PDFDocument"
                file={this.props.fileuploaded}
                onLoadSuccess={this.props.onDocumentLoadSuccess}
                onLoadError={this.props.onDocumentLoadError}
                id="pdfdocumentitem"
              >
                <Page
                  className="PDFPage"
                  pageNumber={this.props.pageNumber}
                  scale={1.0}
                  renderAnnotationLayer={false}
                  onRenderSuccess={this.props.onPageLoadSuccess}
                  onLoadProgress={this.props.onLoadProgress}
                  onLoadError={this.props.onPageLoadError}
                  onRenderError={this.props.onPageRenderError}
                  id="pdfpageitem"
                />
              </Document>
            )}
          </div>
        </>
      );
    }
    return (
      <div
        className="modal fade"
        id="AddModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" id="AddModalDoc" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="AddModalLabel">
                Add Plan
              </h4>
              <button
                type="button"
                id="clearthis"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.clearPostDialog}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              onSubmit={this.props.handleSubmit}
              encType="multipart/form-data"
            >
              <div className="modal-body">
                {this.props.submitted &&
                  this.props.postplaninfo.error &&
                  this.props.postplaninfo.error.message && (
                    <div className="alert alert-danger">
                      {prettifyError(
                        this.props.postplaninfo.error.message,
                        fieldmatching
                      )}
                    </div>
                  )}
                {this.props.submitted && this.props.postplaninfo.loading && (
                  <div className="alert alert-info">
                    Uploading file to server...
                  </div>
                )}
                {this.props.submitted &&
                  !this.props.imageschosen &&
                  !this.props.uploadall && (
                    <div className="alert alert-danger">
                      At least one page must be selected.
                    </div>
                  )}
                {this.props.errorMessage && !this.props.infoState && (
                  <div className="alert alert-danger">
                    {this.props.errorMessage}
                  </div>
                )}
                {this.props.errorMessage && this.props.infoState && (
                  <div className="alert alert-info">
                    {this.props.errorMessage}
                  </div>
                )}
                <div className="form-group row">
                  <label className="col-3 col-form-label" htmlFor="label">
                    Project Name
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      id="label"
                      name="label"
                      className="form-control"
                      value={this.props.label}
                      onChange={this.props.handleChange}
                    />
                  </div>
                </div>
                {this.props.submitted && !this.props.label && (
                  <div className="alert alert-danger">
                    A project name is required.
                  </div>
                )}
                <div className="form-group row">
                  <label className="col-3 col-form-label" htmlFor="client">
                    Client Name
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      id="client"
                      name="client"
                      className="form-control"
                      value={this.props.client}
                      onChange={this.props.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label" htmlFor="description">
                    Description
                  </label>
                  <div className="col-9">
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      rows="3"
                      onChange={this.props.handleChange}
                      value={this.props.description}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label" htmlFor="resolution">
                    Resolution (DPI)
                  </label>
                  <div className="col-9">
                    <input
                      type="number"
                      id="resolution"
                      name="resolution"
                      step="any"
                      className="form-control"
                      value={this.props.resolution}
                      onChange={this.props.handleChange}
                      min={0.0}
                    />
                  </div>
                </div>
                {resolWarning && (
                  <div className="alert alert-warning">{resolWarning}</div>
                )}

                {this.props.submitted &&
                  (!this.props.resolution || this.props.resolution <= 0) && (
                    <div className="alert alert-danger">
                      The resolution must be present and greater than zero.
                    </div>
                  )}
                <div className="form-group row">
                  <label className="col-3 col-form-label" htmlFor="planfile">
                    Add Plan
                  </label>
                  <div className="col-9">
                    <input
                      type="file"
                      multiple
                      className="form-control-file"
                      id="planfile"
                      name="planfile"
                      ref={this.props.fileInput}
                      onChange={this.props.handleChange}
                    />
                  </div>
                </div>
                {this.props.submitted && this.props.filenames.length === 0 && (
                  <div className="alert alert-danger">A file is required.</div>
                )}

                <div className="form-group row">
                  <label className="col-3 col-form-label" htmlFor="gennotes">
                    Show General Notes
                  </label>
                  <div className="col-1">
                    <input
                      type="checkbox"
                      className="form-check-input checkcorrect"
                      id="gennotes"
                      name="gennotes"
                      checked={this.props.gennotes}
                      onChange={this.props.handleCheckBoxChange}
                    />
                  </div>
                  <div className="col-8" />
                </div>

                <fieldset className="form-group">
                  <div className="row">
                    <legend className="col-form-label col-3">
                      Plan Status
                    </legend>
                    <div className="col-9">
                      <div className="form-check text-left">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="plan_status"
                          id="QT"
                          value="QT"
                          checked={this.props.plan_status === "QT"}
                          onChange={this.props.planStatusChange}
                        />
                        <label className="form-check-label" htmlFor="QT">
                          Quoted
                        </label>
                      </div>
                      <div className="form-check text-left">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="plan_status"
                          id="AC"
                          value="AC"
                          checked={this.props.plan_status === "AC"}
                          onChange={this.props.planStatusChange}
                        />
                        <label className="form-check-label" htmlFor="AC">
                          Active
                        </label>
                      </div>
                      <div className="form-check text-left">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="plan_status"
                          id="PA"
                          value="PA"
                          checked={this.props.plan_status === "PA"}
                          onChange={this.props.planStatusChange}
                        />
                        <label className="form-check-label" htmlFor="PA">
                          Archived
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="form-group">
                  <div className="row">
                    <legend className="col-form-label col-3">
                      Pages for upload
                    </legend>
                    <div className="col-9">
                      <div className="form-check text-left">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="allorselected"
                          id="uploadall"
                          value="uploadall"
                          checked={this.props.uploadall}
                          onChange={this.props.handleAllOrSomeChange}
                        />
                        <label className="form-check-label" htmlFor="uploadall">
                          Upload all pages
                        </label>
                      </div>
                      <div className="form-check text-left">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="allorselected"
                          id="uploadselected"
                          value="uploadselected"
                          checked={!this.props.uploadall}
                          onChange={this.props.handleAllOrSomeChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="uploadselected"
                        >
                          Upload selected pages
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <AddPlanList
                  filenames={this.props.filenames}
                  pagesizes={this.props.pagesizes}
                  pageqlties={this.props.pageqlties}
                  removeFileForAdd={this.props.removeFileForAdd}
                />
                {pdfObj}
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  OK
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.props.clearPostDialog}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPlanModal;
