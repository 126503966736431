import React, { Fragment } from "react";

class SimpleAlertModal extends React.Component {
  render() {
    return (
      <Fragment>
        <div
          className="modal fade"
          id={this.props.modalId}
          tabIndex="-1"
          role="dialog"
          aria-labelledby={this.props.modalLabelId}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id={this.props.modalLabelId}>
                  {this.props.modalTitle}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                {this.props.modalBody.split("\n").map((item, key) => {
                  return (
                    <Fragment key={key}>
                      {item}
                      <br />
                    </Fragment>
                  );
                })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="gen_invisible">
          <button
            data-toggle="modal"
            data-target={"#" + this.props.modalId}
            id={"click_" + this.props.modalId}
          />
        </p>
      </Fragment>
    );
  }
}

export default SimpleAlertModal;
