import React from "react";

const SideMenuItem = ({
  text,
  icon,
  enabling,
  hrefval,
  circlearound,
  datatarget,
}) => (
  <div className={circlearound ? "circlearound row" : "row"}>
    <h5 className="col-md-3 SideMenu-icon-cont">
      <i
        className={
          enabling
            ? "SideMenu-enab material-icons md-48"
            : "SideMenu-disab material-icons md-48"
        }
      >
        {datatarget && hrefval ? (
          <a data-toggle="modal" data-target={datatarget} href={hrefval}>
            {icon}
          </a>
        ) : hrefval ? (
          <a href={hrefval}>{icon}</a>
        ) : (
          <>{icon}</>
        )}
      </i>
    </h5>
    <h6
      className={
        enabling ? "SideMenu-enab col-md-9" : "SideMenu-disab col-md-9"
      }
    >
      {datatarget && hrefval ? (
        <a data-toggle="modal" data-target={datatarget} href={hrefval}>
          {text}
        </a>
      ) : hrefval ? (
        <a href={hrefval}>{text}</a>
      ) : (
        <>{text}</>
      )}
    </h6>
  </div>
);

export default SideMenuItem;
