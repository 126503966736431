import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userService } from "../reduxandotherstuff/httpreq";
import * as constants from "../reduxandotherstuff/constants";
import AddPlanModal from "./AddPlanModal.js";
import { getCurrentUserId } from "../reduxandotherstuff/commonfabric";
import { history } from "../reduxandotherstuff/constants";
var moment = require("moment");

class UserPlanItem extends React.Component {
  render() {
    let momenttime = moment(this.props.timestamp, moment.ISO_8601).format(
      "MMMM Do YYYY, h:mm:ss a"
    );
    let anchor = `/plans/${this.props.id}`;
    return (
      <tr className="plan-table-tr" data-id={this.props.id}>
        <th scope="row" className="project-name-col">
          <a href={anchor}>{this.props.label}</a>
        </th>
        <td>{this.props.client}</td>
        {this.props.show_status ? (
          <td>{constants.PLAN_STATUSES[this.props.plan_status]}</td>
        ) : (
          ""
        )}

        <td>{this.props.number_of_pages}</td>
        <td>{momenttime}</td>
      </tr>
    );
  }
}

UserPlanItem.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  client: PropTypes.string,
  plan_status: PropTypes.string,
  number_of_pages: PropTypes.number,
  timestamp: PropTypes.string
};

class UserPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "",
      description: "",
      client: "",
      gennotes: true,
      uploadall: true,
      submitted: false,
      fileuploaded: null,
      numPages: null,
      pageNumber: 1,
      pagenosel: null,
      successLoad: false,
      errorMessage: "",
      infoState: false,
      pagesforUpload: null,
      selectforinc: false,
      imageschosen: 0,
      pageLoading: false,
      resolution: constants.defaultResolution,
      image_type: constants.defaultEncoding,
      encoder_options: constants.defaultEncoderOpt,
      plan_status: "QT",
      pageheight: null,
      pagewidth: null,
      files: [],
      filenames: [],
      pagesizes: [],
      pageqlties: [],
      basedCurFilePointer: null,
      afterFileChange: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clearPostDialog = this.clearPostDialog.bind(this);
    this.handleAllOrSomeChange = this.handleAllOrSomeChange.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlepagenoselChange = this.handlepagenoselChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.planStatusChange = this.planStatusChange.bind(this);
    this.fileInput = React.createRef();
    this.removeFileForAdd = this.removeFileForAdd.bind(this);
  }

  removeFileForAdd(e) {
    let callingid = parseInt(e.target.id.slice(6));

    // If the number of files remaining is 1.

    if (this.state.files.length === 1) {
      this.setState({
        fileuploaded: null,
        numPages: null,
        pageNumber: 1,
        pagenosel: null,
        pagesforUpload: null,
        basedCurFilePointer: null,
        selectforinc: false,
        imageschosen: 0,
        successLoad: false
      });
    } else {
      // We are removing a dud document from the list of files.

      if (this.state.pageqlties[callingid] === false) {
        if (this.state.basedCurFilePointer > callingid) {
          this.setState({
            basedCurFilePointer: this.state.basedCurFilePointer - 1
          });
        }
      } else {
        // If the number of legitimate pages equal the number of pages of the
        // document being removed, then we know that there is only one PDF
        // in the file list.

        if (this.state.numPages === this.state.pagesizes[callingid]) {
          this.setState({
            fileuploaded: null,
            successLoad: false,
            numPages: null,
            pageNumber: 1,
            pagenosel: null,
            pagesforUpload: null,
            basedCurFilePointer: null,
            selectforinc: false,
            imageschosen: 0
          });
        } else {
          // In whatever circumstances, we are going to make changes to the
          // following variables: numPages, pagesforUpload, and imageschosen.
          // So let's calculate these figures.

          let sumPagesBefore = 0;
          for (let i = 0; i < callingid; i++) {
            sumPagesBefore = sumPagesBefore + this.state.pagesizes[i];
          }
          let pagesToRemove = this.state.pagesizes[callingid];
          let pagesToRemoveChecked = 0;
          for (
            let i = sumPagesBefore;
            i < sumPagesBefore + pagesToRemove;
            i++
          ) {
            if (this.state.pagesforUpload[i])
              pagesToRemoveChecked = pagesToRemoveChecked + 1;
          }
          let newPagesForUpload = this.state.pagesforUpload;
          newPagesForUpload.splice(sumPagesBefore, pagesToRemove);
          this.setState({
            numPages: this.state.numPages - pagesToRemove,
            pagesforUpload: newPagesForUpload,
            imageschosen: this.state.imageschosen - pagesToRemoveChecked
          });

          // We now check if the page we are removing is one that we aren't viewing.

          if (this.state.basedCurFilePointer === callingid) {
            // We are removing the page we are viewing, which is going to affect a
            // lot of stuff. So we need to show another PDF to the user. By default,
            // we show the pdf beforehand, if it exists.

            if (sumPagesBefore > 0) {
              let indexBefore = callingid - 1;
              while (this.state.pageqlties[indexBefore] === false) {
                indexBefore = indexBefore - 1;
              }
              this.setState({
                basedCurFilePointer: indexBefore,
                fileuploaded: this.state.files[indexBefore],
                pageNumber: this.state.pagesizes[indexBefore],
                pagenosel: sumPagesBefore,
                selectforinc: this.state.pagesforUpload[sumPagesBefore - 1]
              });
            }

            // Otherwise, we need to show the pdf ahead.
            else {
              let indexAfter = callingid + 1;
              while (this.state.pageqlties[indexAfter] === false) {
                indexAfter = indexAfter + 1;
              }
              this.setState({
                basedCurFilePointer: indexAfter - 1,
                fileuploaded: this.state.files[indexAfter],
                pageNumber: 1,
                pagenosel: sumPagesBefore + 1,
                selectforinc: this.state.pagesforUpload[sumPagesBefore]
              });
            }
          } else if (this.state.basedCurFilePointer >= callingid) {
            this.setState({
              basedCurFilePointer: this.state.basedCurFilePointer - 1,
              pagenosel: this.state.pagenosel - pagesToRemove
            });
          }
        }
      }
    }
    this.setState({
      files: this.state.files.filter((_, i) => i !== callingid),
      filenames: this.state.filenames.filter((_, i) => i !== callingid),
      pagesizes: this.state.pagesizes.filter((_, i) => i !== callingid),
      pageqlties: this.state.pageqlties.filter((_, i) => i !== callingid)
    });
  }

  handleCheckBoxChange(e) {
    this.setState({ gennotes: !this.state.gennotes });
  }

  clearPostDialog() {
    this.setState({
      description: "",
      label: "",
      client: "",
      submitted: "",
      uploadall: true,
      gennotes: true,
      resolution: constants.defaultResolution,
      image_type: constants.defaultEncoding,
      encoder_options: constants.defaultEncoderOpt,
      plan_status: "QT",
      fileuploaded: null,
      pageheight: null,
      pagewidth: null,
      errorMessage: "",
      files: [],
      filenames: [],
      pagesizes: [],
      pageqlties: [],
      basedCurFilePointer: null,
      numPages: null,
      pageNumber: 1,
      pagenosel: null,
      afterFileChange: false,
      imageschosen: 0
    });
    this.fileInput.current.value = "";
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const {
      label,
      client,
      description,
      gennotes,
      files,
      uploadall,
      resolution,
      image_type,
      encoder_options,
      plan_status
    } = this.state;
    this.forceUpdate();
    let that = this;
    if (
      !label ||
      files.length === 0 ||
      !resolution ||
      (!uploadall && !this.state.imageschosen > 0)
    ) {
    } else {
      let page_nos_for_inclusion = "";
      if (!uploadall) {
        for (let pageiter = 0; pageiter < that.state.numPages; pageiter++) {
          if (that.state.pagesforUpload[pageiter]) {
            page_nos_for_inclusion += (pageiter + 1).toString();
            page_nos_for_inclusion += ",";
          }
        }
      }
      let postdata = {
        label: label,
        description: description,
        client: client,
        "planfile[]": files,
        uploadall: uploadall,
        resolution: resolution,
        image_type: image_type,
        gennotes: gennotes,
        encoder_options: encoder_options,
        page_nos_for_inclusion: page_nos_for_inclusion,
        plan_status: plan_status
      };
      let p = this.props.dispatch(
        userService.postPlan(getCurrentUserId(), postdata)
      );
      p.then(value => {
        if (!value.payload) {
          document.getElementById("clearthis").click();
          history.push(`/plans/${value.id}`);
        }
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (name === "planfile") {
      this.setState({
        fileuploaded: this.fileInput.current.files[0],
        afterFileChange: true
      });
      this.setState({
        files: [...this.state.files, this.fileInput.current.files[0]],
        filenames: [
          ...this.state.filenames,
          this.fileInput.current.files[0].name
        ],
        pagesizes: [...this.state.pagesizes, 0],
        pageqlties: [...this.state.pageqlties, null]
      });
    } else {
      this.setState({ [name]: value });
    }
  }

  handleAllOrSomeChange(e) {
    this.setState({ uploadall: !this.state.uploadall });
  }

  planStatusChange(e) {
    const { value } = e.target;
    this.setState({ plan_status: value });
  }

  handlePrev(e) {
    e.preventDefault();
    if (this.state.numPages && this.state.pagenosel > 1) {
      let newpageno = this.state.pagenosel - 1;
      this.setState({
        selectforinc: this.state.pagesforUpload[newpageno - 1]
      });
      this.setState({ pageLoading: true });
      this.setState({ pagenosel: newpageno });
      if (this.state.pageNumber > 1) {
        this.setState({ pageNumber: this.state.pageNumber - 1 });
      } else {
        let lesserIndex = this.state.basedCurFilePointer - 1;
        while (this.state.pageqlties[lesserIndex] === false) {
          lesserIndex = lesserIndex - 1;
        }
        this.setState({
          basedCurFilePointer: lesserIndex,
          pageNumber: this.state.pagesizes[lesserIndex],
          fileuploaded: this.state.files[lesserIndex]
        });
      }
    }
    this.forceUpdate();
  }

  handleNext(e) {
    e.preventDefault();
    if (this.state.numPages && this.state.pagenosel < this.state.numPages) {
      let newpageno = this.state.pagenosel + 1;
      this.setState({ pagenosel: newpageno });
      this.setState({ pageLoading: true });
      this.setState({
        selectforinc: this.state.pagesforUpload[newpageno - 1]
      });
      if (
        this.state.pageNumber <
        this.state.pagesizes[this.state.basedCurFilePointer]
      ) {
        this.setState({ pageNumber: this.state.pageNumber + 1 });
      } else {
        let greaterIndex = this.state.basedCurFilePointer + 1;
        while (this.state.pageqlties[greaterIndex] === false) {
          greaterIndex = greaterIndex + 1;
        }
        this.setState({
          basedCurFilePointer: greaterIndex,
          pageNumber: 1,
          fileuploaded: this.state.files[greaterIndex]
        });
      }
    }
    this.forceUpdate();
  }

  handlepagenoselChange(e) {
    const { value } = e.target;
    let newpageno = parseInt(value, 10);
    if (isNaN(newpageno)) {
      this.setState({ pagenosel: "" });
    } else {
      this.setState({ pagenosel: newpageno });
    }
    if (
      newpageno !== this.state.pagenosel &&
      !isNaN(newpageno) &&
      newpageno > 0 &&
      newpageno <= this.state.numPages
    ) {
      this.setState({ pageLoading: true });
      this.setState({
        selectforinc: this.state.pagesforUpload[newpageno - 1]
      });
      let cumulCount = 0;
      for (let i = 0; i < this.state.files.length; i++) {
        if (this.state.pageqlties[i]) {
          cumulCount = cumulCount + this.state.pagesizes[i];
          if (cumulCount >= newpageno) {
            this.setState({
              basedCurFilePointer: i,
              pageNumber: newpageno - (cumulCount - this.state.pagesizes[i]),
              fileuploaded: this.state.files[i]
            });
            break;
          }
        }
      }
    }
    this.forceUpdate();
  }

  handleCheckChange(e) {
    let newstate = !this.state.selectforinc;
    let imageschosen = this.state.imageschosen;
    this.setState({ selectforinc: newstate });
    let thePagesForUpload = this.state.pagesforUpload;
    thePagesForUpload[this.state.pagenosel - 1] = newstate;
    this.setState({ pagesforUpload: thePagesForUpload });
    if (newstate) {
      this.setState({ imageschosen: imageschosen + 1 });
    } else {
      this.setState({ imageschosen: imageschosen - 1 });
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    if (this.state.afterFileChange) {
      this.setState({
        afterFileChange: false,
        selectforinc: false,
        pageNumber: 1,
        basedCurFilePointer: this.state.pageqlties.length - 1
      });
      if (this.state.basedCurFilePointer === null) {
        this.setState({
          numPages: numPages,
          pagenosel: 1,
          pagesforUpload: new Array(numPages).fill(false)
        });
      } else {
        this.setState({
          numPages: this.state.numPages + numPages,
          pagenosel: this.state.numPages + 1,
          pagesforUpload: [
            ...this.state.pagesforUpload,
            ...new Array(numPages).fill(false)
          ]
        });
      }

      this.setState(state => {
        const pageqlties = state.pageqlties;
        pageqlties[pageqlties.length - 1] = true;
        const pagesizes = state.pagesizes;
        pagesizes[pagesizes.length - 1] = numPages;
        return { pageqlties, pagesizes };
      });
    }
    this.setState({
      successLoad: true,
      errorMessage: "",
      pageLoading: false
    });
    this.forceUpdate();
  };

  onDocumentLoadError = error => {
    if (this.state.afterFileChange) {
      this.setState({ afterFileChange: false });
      this.setState(state => {
        const pageqlties = state.pageqlties;
        pageqlties[pageqlties.length - 1] = false;
        return { pageqlties };
      });
      if (this.state.basedCurFilePointer !== null) {
        this.setState({
          fileuploaded: this.state.files[this.state.basedCurFilePointer]
        });
      }
    } else {
      this.setState({
        successLoad: false,
        errorMessage: error.message,
        pageLoading: false
      });
    }
    this.forceUpdate();
  };

  onPageLoadSuccess = page => {
    // If the rotation is 90 or 270, then we need to swap width and height.

    if (page._pageInfo.rotate === 90 || page._pageInfo.rotate === 270) {
      this.setState({
        pageLoading: false,
        pageheight: page.width,
        pagewidth: page.height
      });
    } else {
      this.setState({
        pageLoading: false,
        pageheight: page.height,
        pagewidth: page.width
      });
    }
    this.forceUpdate();
  };

  onLoadProgress = ({ loaded, total }) => {
    if (!this.state.pageLoading) {
      this.setState({ pageLoading: true });
      this.forceUpdate();
    }
  };

  onPageLoadError = error => {
    this.setState({ pageLoading: false, pageheight: null, pagewidth: null });
    this.forceUpdate();
  };

  onPageRenderError = error => {
    this.setState({ pageLoading: false });
    this.forceUpdate();
  };

  render() {
    this.props.userPlanItems.sort(function(item1, item2) {
      if (item1.last_updated > item2.last_updated) {
        return -1;
      }
      if (item1.last_updated < item2.last_updated) {
        return 1;
      }
      return 0;
    });

    const userPlanItems = this.props.userPlanItems
      .filter(
        item =>
          item.plan_status === this.props.plan_status || !this.props.plan_status
      )
      .filter(
        item =>
          (item.label.includes(this.props.search) &&
            this.props.search !== "") ||
          this.props.search === ""
      )
      .map(item => (
        <UserPlanItem
          id={item.id}
          label={item.label}
          client={item.client}
          plan_status={item.plan_status}
          number_of_pages={item.number_of_pages}
          key={item.id}
          timestamp={item.last_updated}
          show_status={this.props.show_status}
        />
      ));
    return (
      <div>
        <table className="table table-striped table-hover plan-table">
          <thead className="plan-table-thead">
            <tr className="plan-table-tr">
              <th className="plan-table-th col">Project name</th>
              <th className="plan-table-th col">Client name</th>
              {this.props.show_status ? (
                <th className="plan-table-th col">Project status</th>
              ) : (
                ""
              )}
              <th className="plan-table-th col">Number of pages</th>
              <th className="plan-table-th col">Last updated</th>
            </tr>
          </thead>
          <tbody className="plan-table-tbody">{userPlanItems}</tbody>
        </table>
        <div id="canv-invisible" />
        <AddPlanModal
          clearPostDialog={this.clearPostDialog}
          handleSubmit={this.handleSubmit}
          submitted={this.state.submitted}
          postplaninfo={this.props.postplaninfo}
          label={this.state.label}
          client={this.state.client}
          gennotes={this.state.gennotes}
          resolution={this.state.resolution}
          handleChange={this.handleChange}
          description={this.state.description}
          fileInput={this.fileInput}
          fileuploaded={this.state.fileuploaded}
          uploadall={this.state.uploadall}
          handleAllOrSomeChange={this.handleAllOrSomeChange}
          numPages={this.state.numPages}
          pageNumber={this.state.pageNumber}
          pagenosel={this.state.pagenosel}
          successLoad={this.state.successLoad}
          errorMessage={this.state.errorMessage}
          pagesforUpload={this.state.pagesforUpload}
          selectforinc={this.state.selectforinc}
          handlePrev={this.handlePrev}
          handleNext={this.handleNext}
          handlepagenoselChange={this.handlepagenoselChange}
          handleCheckChange={this.handleCheckChange}
          handleCheckBoxChange={this.handleCheckBoxChange}
          onDocumentLoadSuccess={this.onDocumentLoadSuccess}
          onDocumentLoadError={this.onDocumentLoadError}
          onPageLoadSuccess={this.onPageLoadSuccess}
          imageschosen={this.state.imageschosen}
          pageLoading={this.state.pageLoading}
          onLoadProgress={this.onLoadProgress}
          onPageLoadError={this.onPageLoadError}
          onPageRenderError={this.onPageRenderError}
          infoState={this.state.infoState}
          plan_status={this.state.plan_status}
          planStatusChange={this.planStatusChange}
          pageheight={this.state.pageheight}
          pagewidth={this.state.pagewidth}
          filenames={this.state.filenames}
          pagesizes={this.state.pagesizes}
          pageqlties={this.state.pageqlties}
          removeFileForAdd={this.removeFileForAdd}
        />
      </div>
    );
  }
}

UserPlans.propTypes = {
  userPlanItems: PropTypes.arrayOf(PropTypes.object),
  plan_status: PropTypes.string
};

function mapStateToProps(state) {
  return {
    postplaninfo: state.postplaninfo
  };
}

export default connect(mapStateToProps)(UserPlans);
