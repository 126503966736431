import React from "react";
import NavBar from "./NavBar.js";
import UserPlans from "./UserPlans.js";
import BootFooter from "./BootFooter.js";
import SideMenu from "./SideMenu.js";
import SimpleAlertModal from "./SimpleAlertModal.js";
import { connect } from "react-redux";
import {
  getLoginActions,
  getUserIdActions,
} from "../reduxandotherstuff/actions";
import { Helmet } from "react-helmet";
import { userService } from "../reduxandotherstuff/httpreq";
import {
  getCurrentUserId,
  findMaxAxCanvSize,
  findCanvSize,
  recalibCanvasDialog,
} from "../reduxandotherstuff/commonfabric";
import YesNoModal from "./YesNoModal.js";

class PrivatePage extends React.Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.state = {
      search: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  logOut() {
    this.props.dispatch(getLoginActions.logout());
    this.props.dispatch(getUserIdActions.clearUserId());
  }

  componentDidMount() {
    let current = getCurrentUserId();
    if (current) {
      this.props.dispatch(userService.getPlans(getCurrentUserId()));
      this.props.dispatch(userService.getUser(getCurrentUserId()));
    }
    if (
      !localStorage.getItem("maxHeight") ||
      !localStorage.getItem("maxWidth")
    ) {
      let thennable = new Promise(function (resolve, reject) {
        resolve(findMaxAxCanvSize());
      });
      thennable.then(function (val) {
        localStorage.setItem("maxHeight", val[0]);
        localStorage.setItem("maxWidth", val[1]);
      });
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.deleteplaninfo.loading !== prevProps.deleteplaninfo.loading
    ) {
      this.props.dispatch(userService.getPlans(getCurrentUserId()));
    }
  };

  handleChange(event) {
    this.setState({ search: event.target.value });
  }

  render() {
    const propSupply = [
      { isActive: false, href: "/", navText: "Home" },
      { isActive: false, href: "/login", navText: "Login" },
      { isActive: true, href: "/plans", navText: "Plan Board" },
    ];
    if (
      this.props.getuserinfo &&
      this.props.getuserinfo.item &&
      this.props.getuserinfo.item.is_superuser
    ) {
      propSupply.push({
        isActive: false,
        href: "/userslist",
        navText: "Users",
      });
    }
    let maincontent;
    if (this.props.plansinfo.error) {
      let thisError = this.props.plansinfo.error.message.toString();
      maincontent = <div className="alert alert-danger">{thisError}</div>;
    } else if (this.props.plansinfo.loading) {
      maincontent = (
        <p>
          <em>Waiting to load all items.</em>
        </p>
      );
    } else if (this.props.deleteplaninfo.loading) {
      maincontent = (
        <p>
          <em>Loading!</em>
        </p>
      );
    } else {
      let qt_count = this.props.plansinfo.items.filter(function (x) {
        return x.plan_status === "QT";
      }).length;
      let ac_count = this.props.plansinfo.items.filter(function (x) {
        return x.plan_status === "AC";
      }).length;
      let ar_count = this.props.plansinfo.items.filter(function (x) {
        return x.plan_status === "PA";
      }).length;
      maincontent = (
        <>
          <div className="row">
            <div className="col-sm-4">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Quoted</h2>
                  <p className="card-text">
                    <a href="/plancat/qt" className="qtacpa">
                      {qt_count}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Active</h2>
                  <p className="card-text">
                    <a href="/plancat/ac" className="qtacpa">
                      {ac_count}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Archived</h2>
                  <p className="card-text">
                    <a href="/plancat/pa" className="qtacpa">
                      {ar_count}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p />
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <i className="SideMenu-enab material-icons md-48 searchbutton">
                  search
                </i>
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search plans..."
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={this.state.search}
              onChange={this.handleChange}
            />
          </div>
          <h2>Search Results</h2>
          <UserPlans
            userPlanItems={this.props.plansinfo.items}
            search={this.state.search}
            show_status={true}
          />
        </>
      );
    }

    let addElemMenuItems = [
      {
        datatarget: "#AddModal",
        navText: "Add Plan",
      },
    ];

    let viewElemItems = [
      {
        navText: "Local Canvas Size",
        datatarget: "#findCanvModal",
      },
      {
        navText: "Recalibrate Canvas Size",
        datatarget: "#recalibCanvasModal",
      },
    ];

    let sidemenu = [
      {
        icon: "add_circle_outline",
        text: "",
        enabling: true,
        circlearound: true,
        datatarget: "#AddModal",
        hrefval: ".",
      },
      {
        icon: "dashboard",
        text: "Plan Board",
        enabling: true,
        hrefval: "/plans",
      },
      {
        icon: "history",
        text: "Saved PDFs",
        enabling: false,
        hrefval: "/savedpdfs",
      },
      {
        icon: "list_alt",
        text: "Item Sheets",
        enabling: false,
        hrefval: "/savedscafsheets",
      },
      { icon: "mic", text: "Quoted", enabling: false, hrefval: "/plancat/qt" },
      { icon: "work", text: "Active", enabling: false, hrefval: "/plancat/ac" },
      {
        icon: "archive",
        text: "Archived",
        enabling: false,
        hrefval: "/plancat/pa",
      },
      null,
      {
        icon: "settings",
        text: "Settings",
        enabling: false,
        hrefval: "/myprofile",
      },
    ];

    if (
      this.props.getuserinfo &&
      this.props.getuserinfo.item &&
      this.props.getuserinfo.item.is_superuser
    ) {
      sidemenu.push({
        icon: "emoji_people",
        text: "Users",
        enabling: false,
        hrefval: "/userslist",
      });
    }

    return (
      <div>
        <Helmet>
          <title>Scafflow | Plan Board</title>
        </Helmet>
        <NavBar
          navbarItems={propSupply}
          addElemItems={addElemMenuItems}
          addElemItemLabel="Add"
          viewElemItems={viewElemItems}
          viewElemItemLabel="View"
        />
        <main className="container-fluid" role="main">
          <div className="row">
            <div className="col-md-2">
              <p>&nbsp;</p>
              <SideMenu entries={sidemenu} />
            </div>
            <div className="col-md-10">
              <h1>
                <a href="/plans">Plan Board</a>
              </h1>
              {maincontent}
            </div>
          </div>
        </main>
        <BootFooter />
        <SimpleAlertModal
          modalId="findCanvModal"
          modalLabelId="findCanvLabel"
          modalTitle="Find Canvas Size"
          modalBody={findCanvSize()}
        />
        <YesNoModal
          modalId="recalibCanvasModal"
          modalTitle="Recalibrate Canvas Size"
          modalLabelId="recalibCanvasLabel"
          question="Do you wish to recalibrate the size of the canvas? (This operation may take some time to perform.)"
          callonYes={recalibCanvasDialog}
        />
        <SimpleAlertModal
          modalId="calcCanvModal"
          modalLabelId="calcCanvLabel"
          modalTitle="Recalibrate Canvas Size"
          modalBody="Size recalibrated."
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    plansinfo: state.plansinfo,
    deleteplaninfo: state.deleteplaninfo,
    getuserinfo: state.getuserinfo,
  };
}

export default connect(mapStateToProps)(PrivatePage);
