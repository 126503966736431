import { fabric } from "fabric";
import React from "react";
import * as constants from "../reduxandotherstuff/constants";
import {
  loadBoundActions,
  loadLogoActions,
  genImgActions,
  genPDFActions,
} from "./actions";
import {
  Grid,
  invisible,
  indivselectcolour,
  indivselectfill,
} from "../reduxandotherstuff/scaffoldplan";
import * as jsPDF from "jspdf";
import canvasSize from "canvas-size";
import * as math from "mathjs";
import { userService } from "../reduxandotherstuff/httpreq";
import * as env from "../reduxandotherstuff/env";
import { v1 as uuidv1 } from "uuid";
let PNG = require("pngjs").PNG;

function debugconsole(inputstr) {
  if (env.debugMode) {
    console.log(inputstr);
  }
}

// The problem with the context menu commands - such as toggle
// left brace - is that there is no visible change to
// appearance until the user does something _else_, such as zooming.
// in. This function _tells_ the canvas to update the appearance.

function zoomInAndOut() {
  document.getElementById("zoomInBtn").click();
  document.getElementById("zoomOutBtn").click();
}

// For getting current user id. (Somehow garbled by redux)

function getCurrentUserId() {
  let userid = parseInt(localStorage.getItem("userid"));
  if (isNaN(userid)) {
    return null;
  }
  return userid;
}

// For opening modal Alerts.

function openModalAlert(modalName) {
  document.getElementById("click_" + modalName).click();
}

// Returns a number only if input represents an integer; returns NaN otherwise!
function realParseInt(input) {
  let inputInt = parseInt(input);
  let inputFloor = Math.floor(inputInt);
  if (inputInt !== inputFloor) {
    return NaN;
  }
  return inputInt;
}

// for adding white rectangles on the plan.

function addBoundaryRect(canv, left, top, width, height, fill = "white") {
  let theRect = new fabric.Rect({
    left: left,
    top: top,
    width: width,
    height: height,
    fill: fill,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockUniScaling: true,
    lockRotation: true,
    selectable: false,
  });
  canv.add(theRect);
  return theRect;
}

// For adding glimmer rectangles on the plan.

function addGlimmerRect(canv, left, top, width, height) {
  let theRect = new fabric.Rect({
    left: left,
    top: top,
    width: width,
    height: height,
    fill: "rgba(255,255,255,0)",
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockUniScaling: true,
    lockRotation: true,
    selectable: false,
  });
  canv.add(theRect);
  return theRect;
}

// For adding boundary lines around a plan.

function addBoundLine(canv, firstx, firsty, secondx, secondy) {
  var theLine = new fabric.Line([firstx, firsty, secondx, secondy], {
    fill: "black",
    stroke: "black",
    strokeWidth: constants.defaultBWidth,
    originX: "center",
    originY: "center",
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockUniScaling: true,
    lockRotation: true,
    selectable: false,
  });
  canv.add(theLine);
  return theLine;
}

// For adding text for the legends at the bottom of the plan.

function addLedgerHead(canv, fsize, text, xpos, ypos) {
  let theText = new fabric.Text(text, {
    top: ypos,
    left: xpos,
    fontSize: fsize,
    fontFamily: "sans-serif",
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockUniScaling: true,
    lockRotation: true,
    selectable: false,
  });
  canv.add(theText);
  return theText;
}

// For adding editable text boxes.

function addLedgerBody(canv, fsize, text, xpos, ypos, twidth) {
  let theText = new fabric.Textbox(text, {
    top: ypos,
    left: xpos,
    fontSize: fsize,
    fontFamily: "sans-serif",
    lockMovementX: true,
    lockMovementY: true,
    lockScalingY: true,
    lockUniScaling: true,
    lockRotation: true,
    editable: true,
    selectable: false,
    width: twidth,
  });
  canv.add(theText);

  // Sometimes textboxes are temporarily selected. So we immediately deselect.

  theText.on("selected", function () {
    let activeGroup = canv.getActiveObjects();
    activeGroup.removeWithUpdate(this);
  });

  return theText;
}

// For adding monospaced text.

function addNotesText(canv, text, xpos, ypos, fsize) {
  //  sizeFactor
  let theText = new fabric.Text(text, {
    top: ypos,
    left: xpos,
    fontSize: fsize, //defaultLFont * sizeFactor,
    fontFamily: "monospace",
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockUniScaling: true,
    lockRotation: true,
    selectable: false,
    textAlign: "left",
  });
  canv.add(theText);
  return theText;
}

// Looks at a string strin, and wraps it at maxcharsperline, with indenting

function wrapandbreak(strin, maxcharsperline, indent) {
  var breakseq = strin.split(/\r?\n/g);
  var retseq = [];
  for (var i = 0; i < breakseq.length; i++) {
    var strline = breakseq[i];
    while (strline.length > maxcharsperline) {
      var endlinemarker = maxcharsperline;
      while (strline[endlinemarker] !== " ") {
        endlinemarker--;
      }
      var slicebit = strline.slice(0, endlinemarker);
      retseq.push(slicebit);
      strline = " ".repeat(indent - 1) + strline.slice(endlinemarker);
    }
    retseq.push(strline);
  }
  return retseq.join("\n");
}

// Looks at a string strin, and looks for all strings of the form {expr}
// inside, then looks for a matching value in interpargs. Yes, you can
// probably do a better job if you assume all browsers run ES6 or better,
// but they don't (esp IE)

function interpolate(strin, interpargs) {
  var paramsPattern = /\{([^}]+)\}/g;
  var extractParams = strin.replace(paramsPattern, function (match, capture) {
    if (interpargs.hasOwnProperty(capture)) {
      return interpargs[capture];
    }
    return "";
  });
  return extractParams;
}

// Takes JSON that repreosents a building plan, and removes data that:
// (a) Is for the legend / boundary
// (b) Is for the background image.

function abbrevOutput(JSONObject) {
  delete JSONObject["backgroundImage"];
  var i = JSONObject.objects.length;
  let permissibleGroups = ["heightGroup", "storeGroup", "textboxGroup"];
  while (i--) {
    if (
      JSONObject.objects[i].type === "textbox" ||
      !JSONObject.objects[i].selectable ||
      (JSONObject.objects[i].type === "group" &&
        JSONObject.objects[i].objects.length === 0)
    ) {
      if (
        !permissibleGroups.includes(JSONObject.objects[i].type) ||
        (JSONObject.objects[i].subtype &&
          JSONObject.objects[i].subtype === "GRID")
      ) {
        JSONObject.objects.splice(i, 1);
      }
    }
  }
  return JSONObject;
}

/* thehref: DataURL form of a png file. */
function removeAlphaFromDataUrl(thehref) {
  let dataurlstring = "data:image/png;base64,";
  let buff = new Buffer(thehref.slice(22), "base64");
  let png = PNG.sync.read(buff);
  let options = { colorType: 2 };
  let buffer = PNG.sync.write(png, options);
  let output = buffer.toString("base64");
  let newhref = dataurlstring + output;
  return newhref;
}

/* For zooming in on a canvas. */

function zoomInThat(that) {
  let delta = 5;
  let zoom = that.canvas.getZoom();
  zoom = zoom + delta / 200;
  if (zoom > 20) {
    zoom = 20;
  }
  if (zoom < 0.01) {
    zoom = 0.01;
  }
  let scrollLeft = document.getElementById("left-container").scrollLeft;
  let scrollTop = document.getElementById("left-container").scrollTop;
  let middleX = that.localvars["containerWidth"] / 2 + scrollLeft;
  let middleY = that.localvars["containerHeight"] / 2 + scrollTop;
  that.canvas.zoomToPoint({ x: middleX, y: middleY }, zoom);
  that.setState({ zoomlevel: that.canvas.getZoom().toFixed(3) });
}

/* For zooming out of a canvas */

function zoomOutThat(that) {
  let delta = -5;
  let zoom = that.canvas.getZoom();
  zoom = zoom + delta / 200;
  if (zoom > 20) {
    zoom = 20;
  }
  if (zoom < 0.01) {
    zoom = 0.01;
  }
  let scrollLeft = document.getElementById("left-container").scrollLeft;
  let scrollTop = document.getElementById("left-container").scrollTop;
  let middleX = that.localvars["containerWidth"] / 2 + scrollLeft;
  let middleY = that.localvars["containerHeight"] / 2 + scrollTop;
  that.canvas.zoomToPoint({ x: middleX, y: middleY }, zoom);
  that.setState({ zoomlevel: that.canvas.getZoom().toFixed(3) });
}

/* For zooming in and out of a canvas using the mousewheel. */

function canvmousewheelThat(that, opt) {
  let delta = opt.e.deltaY;
  let zoom = that.canvas.getZoom();
  zoom = zoom + delta / 200;
  if (zoom > 20) {
    zoom = 20;
  }
  if (zoom < 0.01) {
    zoom = 0.01;
  }
  that.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
  opt.e.preventDefault();
  opt.e.stopPropagation();
  that.setState({ zoomlevel: that.canvas.getZoom().toFixed(3) });
}

/* This adds the boundary around the canvas (with all its objects.) */

function addFlexibleBoundaryThat(that, image) {
  let imageWidth = that.localvars["imageWidth"];
  let imageHeight = that.localvars["imageHeight"];
  let defaultEntryFont = Math.round(imageWidth / 75);
  that.setState({ legfontsize: defaultEntryFont });
  let defaultLFont = Math.round(imageWidth / 100);
  let defaultEdgeDist = Math.round(imageWidth / 50);
  that.setState({ bounedge: defaultEdgeDist });
  let defaultLedgeHeight = Math.round(imageWidth / 20);
  that.setState({ legheight: defaultLedgeHeight });
  let defaultLHOff = Math.round(imageWidth / 200);
  let defaultEHOff = Math.round(imageWidth / 90);
  that.localvars["defaultEHOff"] = defaultEHOff;
  that.localvars["defaultLHOff"] = defaultLHOff;
  var legendLineY = imageHeight - defaultEdgeDist - defaultLedgeHeight;
  var legendTextY = legendLineY + defaultLHOff;
  let thatcanvas = that.canvas;
  let thatboundObjs = that.boundObjs;
  let thatprops = that.props;
  let companylogo = "/images/emptylogo.png";
  if (image) {
    companylogo = userService.domainStart + image;
  }
  fabric.Image.fromURL(
    companylogo,
    function (myImg) {
      try {
        // We want to find the maximum sizes that the image scaled can appear.

        let maxBoundWidth = (imageWidth - defaultEdgeDist * 2) / 4;
        let maxBoundHeight =
          defaultLedgeHeight / constants.defaultLedgerHeightDivider;

        // Then we need to find the best way of scaling - the one that
        // consumes the most area, but doesn't exceed the maximums indicated above. We have two
        // options:

        that.localvars["logoHeight"] = myImg.height;
        that.localvars["logoWidth"] = myImg.width;

        let maxWidthRatio = maxBoundWidth / myImg.width;
        let maxHeightRatio = maxBoundHeight / myImg.height;

        // Now we need to choose which is the best fit.

        let desiredScale;

        if (maxWidthRatio > maxHeightRatio) {
          // Using maxWidthRatio as scale gives us a larger picture.
          if (myImg.height * maxWidthRatio > maxBoundHeight) {
            // But that makes the height too big
            desiredScale = maxHeightRatio;
          } else {
            desiredScale = maxWidthRatio;
          }
        } else {
          // Using maxHeightRatio as scale gives us a larger picture.
          if (myImg.width * maxHeightRatio > maxBoundWidth) {
            // But that makes the width too big
            desiredScale = maxWidthRatio;
          } else {
            desiredScale = maxHeightRatio;
          }
        }
        let desiredLeft =
          defaultEdgeDist + (maxBoundWidth - desiredScale * myImg.width) / 2;
        let desiredTop =
          imageHeight -
          defaultEdgeDist -
          defaultLedgeHeight / constants.defaultLedgerHeightDivider +
          (maxBoundHeight - desiredScale * myImg.height) / 2;
        let logoImage = myImg.set({
          left: desiredLeft,
          top: desiredTop,
          scaleX: desiredScale,
          scaleY: desiredScale,
          lockMovementX: false,
          lockMovementY: false,
          lockScalingX: false,
          lockScalingY: false,
          lockUniScaling: false,
          lockRotation: false,
          selectable: false,
        });
        thatcanvas.add(logoImage);
        thatboundObjs["logoImage"] = logoImage;
        thatprops.dispatch(loadLogoActions.loadLogoSuccess());
      } catch (exception) {
        thatprops.dispatch(loadLogoActions.loadLogoFailure());
      }
    },
    { crossOrigin: "anonymous" }
  );
  that.boundObjs["glimmerRect"] = addGlimmerRect(
    that.canvas,
    0,
    0,
    imageWidth,
    imageHeight
  );
  let ovbrightness = ((255 * that.state.ovbrightness) / 100).toString();
  let ovcover = (that.state.ovcover / 100).toString();
  let fillcolor =
    "rgba(" +
    ovbrightness +
    "," +
    ovbrightness +
    "," +
    ovbrightness +
    "," +
    ovcover +
    ")";
  that.boundObjs["glimmerRect"].set({ fill: fillcolor });
  that.boundObjs["legendRect"] = addBoundaryRect(
    that.canvas,
    defaultEdgeDist,
    legendLineY,
    imageWidth - defaultEdgeDist * 2,
    defaultLedgeHeight
  );
  that.boundObjs["topRect"] = addBoundaryRect(
    that.canvas,
    0,
    0,
    imageWidth,
    defaultEdgeDist
  );
  that.boundObjs["bottomRect"] = addBoundaryRect(
    that.canvas,
    0,
    imageHeight - defaultEdgeDist,
    imageWidth,
    defaultEdgeDist
  );
  that.boundObjs["leftRect"] = addBoundaryRect(
    that.canvas,
    0,
    defaultEdgeDist,
    defaultEdgeDist,
    imageHeight - defaultEdgeDist * 2
  );
  that.boundObjs["rightRect"] = addBoundaryRect(
    that.canvas,
    imageWidth - defaultEdgeDist,
    defaultEdgeDist,
    defaultEdgeDist,
    imageHeight - defaultEdgeDist * 2
  );
  that.boundObjs["topGrey"] = addBoundaryRect(
    that.canvas,
    -imageWidth,
    -imageHeight,
    3 * imageWidth,
    imageHeight - 2,
    "grey"
  );
  that.boundObjs["bottomGrey"] = addBoundaryRect(
    that.canvas,
    -imageWidth,
    imageHeight,
    3 * imageWidth,
    imageHeight,
    "grey"
  );
  that.boundObjs["leftGrey"] = addBoundaryRect(
    that.canvas,
    -imageWidth,
    -10,
    imageWidth - 2,
    imageHeight + 200,
    "grey"
  );
  that.boundObjs["rightGrey"] = addBoundaryRect(
    that.canvas,
    imageWidth,
    -10,
    imageWidth,
    imageHeight + 200,
    "grey"
  );

  that.boundObjs["topLine"] = addBoundLine(
    that.canvas,
    defaultEdgeDist,
    defaultEdgeDist,
    imageWidth - defaultEdgeDist,
    defaultEdgeDist
  );
  that.boundObjs["bottomLine"] = addBoundLine(
    that.canvas,
    defaultEdgeDist,
    imageHeight - defaultEdgeDist,
    imageWidth - defaultEdgeDist,
    imageHeight - defaultEdgeDist
  );
  that.boundObjs["leftLine"] = addBoundLine(
    that.canvas,
    defaultEdgeDist,
    defaultEdgeDist,
    defaultEdgeDist,
    imageHeight - defaultEdgeDist
  );
  that.boundObjs["rightLine"] = addBoundLine(
    that.canvas,
    imageWidth - defaultEdgeDist,
    defaultEdgeDist,
    imageWidth - defaultEdgeDist,
    imageHeight - defaultEdgeDist
  );
  that.boundObjs["legendLine"] = addBoundLine(
    that.canvas,
    defaultEdgeDist,
    legendLineY,
    imageWidth - defaultEdgeDist,
    legendLineY
  );
  that.boundObjs["leftLegendLine"] = addBoundLine(
    that.canvas,
    imageWidth / 4 - defaultEdgeDist / 4,
    legendLineY,
    imageWidth / 4 - defaultEdgeDist / 4,
    imageHeight - defaultEdgeDist
  );
  that.boundObjs["middleLegendLine"] = addBoundLine(
    that.canvas,
    imageWidth / 2 - defaultEdgeDist / 2,
    legendLineY,
    imageWidth / 2 - defaultEdgeDist / 2,
    imageHeight - defaultEdgeDist
  );
  that.boundObjs["rightLegendLine"] = addBoundLine(
    that.canvas,
    (3 * imageWidth) / 4 - (3 * defaultEdgeDist) / 4,
    legendLineY,
    (3 * imageWidth) / 4 - (3 * defaultEdgeDist) / 4,
    imageHeight - defaultEdgeDist
  );
  that.boundObjs["clientHead"] = addLedgerHead(
    that.canvas,
    defaultLFont,
    "CLIENT",
    imageWidth / 4 - defaultEdgeDist / 4 + defaultLHOff,
    legendTextY
  );
  that.boundObjs["projectHead"] = addLedgerHead(
    that.canvas,
    defaultLFont,
    "PROJECT",
    imageWidth / 2 - defaultEdgeDist / 2 + defaultLHOff,
    legendTextY
  );
  that.boundObjs["descHead"] = addLedgerHead(
    that.canvas,
    defaultLFont,
    "DESCRIPTION",
    (3 * imageWidth) / 4 - (3 * defaultEdgeDist) / 4 + defaultLHOff,
    legendTextY
  );
  let ledgerBodyWidth = imageWidth / constants.ledgerBodyDivideWidth;
  that.boundObjs["clientBody"] = addLedgerBody(
    that.canvas,
    defaultEntryFont,
    constants.BOUNDTEXT_PLACEHOLDER,
    imageWidth / 4 - defaultEdgeDist / 4 + defaultLHOff,
    legendTextY + defaultEHOff,
    ledgerBodyWidth
  );
  that.boundObjs["projectBody"] = addLedgerBody(
    that.canvas,
    defaultEntryFont,
    constants.BOUNDTEXT_PLACEHOLDER,
    imageWidth / 2 - defaultEdgeDist / 2 + defaultLHOff,
    legendTextY + defaultEHOff,
    ledgerBodyWidth
  );
  that.boundObjs["descBody"] = addLedgerBody(
    that.canvas,
    defaultEntryFont,
    constants.BOUNDTEXT_PLACEHOLDER,
    (3 * imageWidth) / 4 - (3 * defaultEdgeDist) / 4 + defaultLHOff,
    legendTextY + defaultEHOff,
    ledgerBodyWidth
  );
  that.props.dispatch(loadBoundActions.loadBoundSuccess());
}

function ovBrightnessCoverChangeThat(that, ovbrightness, ovcover) {
  that.setState({ ovbrightness: ovbrightness, ovcover: ovcover });
  let ovbrightnessStr = ((255 * ovbrightness) / 100).toString();
  let ovcoverStr = (ovcover / 100).toString();
  let fillcolor =
    "rgba(" +
    ovbrightnessStr +
    "," +
    ovbrightnessStr +
    "," +
    ovbrightnessStr +
    "," +
    ovcoverStr +
    ")";
  that.boundObjs["glimmerRect"].set({ fill: fillcolor });
  that.canvas.renderAll();
}

function setBounWidthThat(that, bounwidth) {
  that.boundObjs["topLine"].set({ strokeWidth: bounwidth });
  that.boundObjs["bottomLine"].set({ strokeWidth: bounwidth });
  that.boundObjs["leftLine"].set({ strokeWidth: bounwidth });
  that.boundObjs["rightLine"].set({ strokeWidth: bounwidth });
  that.boundObjs["legendLine"].set({ strokeWidth: bounwidth });
  that.boundObjs["leftLegendLine"].set({ strokeWidth: bounwidth });
  that.boundObjs["middleLegendLine"].set({ strokeWidth: bounwidth });
  that.boundObjs["rightLegendLine"].set({ strokeWidth: bounwidth });
  that.setState({ bounwidth: bounwidth });
}

function setBoundaryLegendPosThat(that, bounedge, legheight) {
  let imageWidth = that.localvars["imageWidth"];
  let imageHeight = that.localvars["imageHeight"];
  let defaultEHOff = that.localvars["defaultEHOff"];
  let defaultLHOff = that.localvars["defaultLHOff"];
  let defaultEdgeDist = Math.round(imageWidth / 50);
  let defaultLedgeHeight = Math.round(imageWidth / 20);

  let maxBoundWidth = (imageWidth - defaultEdgeDist * 2) / 4;
  let maxBoundHeight =
    defaultLedgeHeight / constants.defaultLedgerHeightDivider;

  let maxWidthRatio = maxBoundWidth / that.localvars["logoWidth"];
  let maxHeightRatio = maxBoundHeight / that.localvars["logoHeight"];

  // Now we need to choose which is the best fit.

  let desiredScale;

  if (maxWidthRatio > maxHeightRatio) {
    // Using maxWidthRatio as scale gives us a larger picture.
    if (that.localvars["logoHeight"] * maxWidthRatio > maxBoundHeight) {
      // But that makes the height too big
      desiredScale = maxHeightRatio;
    } else {
      desiredScale = maxWidthRatio;
    }
  } else {
    // Using maxHeightRatio as scale gives us a larger picture.
    if (that.localvars["logoWidth"] * maxHeightRatio > maxBoundWidth) {
      // But that makes the width too big
      desiredScale = maxWidthRatio;
    } else {
      desiredScale = maxHeightRatio;
    }
  }
  let desiredLeft =
    defaultEdgeDist +
    (maxBoundWidth - desiredScale * that.localvars["logoWidth"]) / 2;
  let desiredTop =
    imageHeight -
    defaultEdgeDist -
    defaultLedgeHeight / constants.defaultLedgerHeightDivider +
    (maxBoundHeight - desiredScale * that.localvars["logoHeight"]) / 2;
  that.boundObjs["logoImage"].set({
    left: desiredLeft,
    top: desiredTop,
    scaleX: desiredScale,
    scaleY: desiredScale,
  });

  that.boundObjs["topLine"].set({
    x1: bounedge,
    y1: bounedge,
    x2: imageWidth - bounedge,
    y2: bounedge,
  });
  that.boundObjs["bottomLine"].set({
    x1: bounedge,
    y1: imageHeight - bounedge,
    x2: imageWidth - bounedge,
    y2: imageHeight - bounedge,
  });
  that.boundObjs["leftLine"].set({
    x1: bounedge,
    y1: bounedge,
    x2: bounedge,
    y2: imageHeight - bounedge,
  });
  that.boundObjs["rightLine"].set({
    x1: imageWidth - bounedge,
    y1: bounedge,
    x2: imageWidth - bounedge,
    y2: imageHeight - bounedge,
  });
  that.boundObjs["legendLine"].set({
    x1: bounedge,
    y1: imageHeight - bounedge - legheight,
    x2: imageWidth - bounedge,
    y2: imageHeight - bounedge - legheight,
  });
  that.boundObjs["leftLegendLine"].set({
    x1: imageWidth / 4 - bounedge / 4,
    y1: imageHeight - bounedge - legheight,
    x2: imageWidth / 4 - bounedge / 4,
    y2: imageHeight - bounedge,
  });
  that.boundObjs["middleLegendLine"].set({
    x1: imageWidth / 2 - bounedge / 2,
    y1: imageHeight - bounedge - legheight,
    x2: imageWidth / 2 - bounedge / 2,
    y2: imageHeight - bounedge,
  });
  that.boundObjs["rightLegendLine"].set({
    x1: (3 * imageWidth) / 4 - (3 * bounedge) / 4,
    y1: imageHeight - bounedge - legheight,
    x2: (3 * imageWidth) / 4 - (3 * bounedge) / 4,
    y2: imageHeight - bounedge,
  });
  that.boundObjs["legendRect"].set({
    left: bounedge,
    top: imageHeight - bounedge - legheight,
    width: imageWidth - 2 * bounedge,
    height: legheight,
  });
  that.boundObjs["topRect"].set({
    left: 0,
    top: 0,
    width: imageWidth,
    height: bounedge,
  });
  that.boundObjs["bottomRect"].set({
    left: 0,
    top: imageHeight - bounedge,
    width: imageWidth,
    height: bounedge,
  });
  that.boundObjs["leftRect"].set({
    left: 0,
    top: bounedge,
    width: bounedge,
    height: imageHeight - bounedge * 2,
  });
  that.boundObjs["rightRect"].set({
    left: imageWidth - bounedge,
    top: bounedge,
    width: bounedge,
    height: imageHeight - bounedge * 2,
  });
  let legendLineY = imageHeight - bounedge - legheight;
  let legendTextY = legendLineY + defaultLHOff;
  that.boundObjs["clientHead"].set({
    top: legendTextY,
    left: imageWidth / 4 - bounedge / 4 + defaultLHOff,
  });
  that.boundObjs["projectHead"].set({
    top: legendTextY,
    left: imageWidth / 2 - bounedge / 2 + defaultLHOff,
  });
  that.boundObjs["descHead"].set({
    top: legendTextY,
    left: (3 * imageWidth) / 4 - (3 * bounedge) / 4 + defaultLHOff,
  });
  that.boundObjs["clientBody"].set({
    top: legendTextY + defaultEHOff,
    left: imageWidth / 4 - bounedge / 4 + defaultLHOff,
  });
  that.boundObjs["projectBody"].set({
    top: legendTextY + defaultEHOff,
    left: imageWidth / 2 - bounedge / 2 + defaultLHOff,
  });
  that.boundObjs["descBody"].set({
    top: legendTextY + defaultEHOff,
    left: (3 * imageWidth) / 4 - (3 * bounedge) / 4 + defaultLHOff,
  });
  that.setState({ bounedge: bounedge, legheight: legheight });
}

function setLegendSizeThat(that, legfontsize) {
  that.boundObjs["clientBody"].set({ fontSize: legfontsize });
  that.boundObjs["projectBody"].set({ fontSize: legfontsize });
  that.boundObjs["descBody"].set({ fontSize: legfontsize });
  that.setState({ legfontsize: legfontsize });
}

function setLegendChangeThat(that, showlegend) {
  let fillColor = "rgba(0,0,0,0)";
  let rectColor = "rgba(0,0,0,0)";
  let logoOpacity = 0;
  if (showlegend) {
    fillColor = "black";
    rectColor = "white";
    logoOpacity = 1;
  }
  that.boundObjs["topLine"].set({ stroke: fillColor });
  that.boundObjs["leftLine"].set({ stroke: fillColor });
  that.boundObjs["rightLine"].set({ stroke: fillColor });
  that.boundObjs["bottomLine"].set({ stroke: fillColor });
  that.boundObjs["legendLine"].set({ stroke: fillColor });
  that.boundObjs["leftLegendLine"].set({ stroke: fillColor });
  that.boundObjs["middleLegendLine"].set({ stroke: fillColor });
  that.boundObjs["rightLegendLine"].set({ stroke: fillColor });
  that.boundObjs["legendRect"].set({ fill: rectColor });
  that.boundObjs["leftRect"].set({ fill: rectColor });
  that.boundObjs["rightRect"].set({ fill: rectColor });
  that.boundObjs["topRect"].set({ fill: rectColor });
  that.boundObjs["bottomRect"].set({ fill: rectColor });
  that.boundObjs["clientHead"].setColor(fillColor);
  that.boundObjs["projectHead"].setColor(fillColor);
  that.boundObjs["descHead"].setColor(fillColor);
  that.boundObjs["clientBody"].setColor(fillColor);
  that.boundObjs["projectBody"].setColor(fillColor);
  that.boundObjs["descBody"].setColor(fillColor);
  that.boundObjs["logoImage"].opacity = logoOpacity;
  that.setState({ showlegend: showlegend });
}

function zoomlevelChangeThat(that, zoomlevel) {
  that.setState({ zoomlevel: zoomlevel });
  let scrollLeft = document.getElementById("left-container").scrollLeft;
  let scrollTop = document.getElementById("left-container").scrollTop;
  let middleX = that.localvars["containerWidth"] / 2 + scrollLeft;
  let middleY = that.localvars["containerHeight"] / 2 + scrollTop;
  that.canvas.zoomToPoint({ x: middleX, y: middleY }, zoomlevel);
}

function setGreyOuterBoundary(that, boundadjust) {
  let imageWidth = that.localvars["imageWidth"];
  let imageHeight = that.localvars["imageHeight"];
  that.boundObjs["topGrey"].set({
    top: boundadjust * -imageHeight,
    left: boundadjust * -imageWidth,
    width: 2 * boundadjust * imageWidth + imageWidth,
    height: boundadjust * imageHeight - 2,
  });
  that.boundObjs["bottomGrey"].set({
    top: imageHeight,
    left: boundadjust * -imageWidth,
    width: 2 * boundadjust * imageWidth + imageWidth,
    height: boundadjust * imageHeight,
  });
  that.boundObjs["leftGrey"].set({
    top: -100,
    left: boundadjust * -imageWidth,
    width: boundadjust * imageWidth - 2,
    height: imageHeight + 200,
  });
  that.boundObjs["rightGrey"].set({
    top: -100,
    left: imageWidth,
    width: boundadjust * imageWidth,
    height: imageHeight + 200,
  });
}

// Does this work? from https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript

function dataURLtoBlob(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function saveAsImageThat(that, e) {
  let namefile = prompt(
    "Choose the name of a file to save as a png image",
    "scaffolddiagram.png"
  );
  if (namefile === null) {
    e.preventDefault();
    return;
  }
  if (namefile.substring(namefile.length - 4) !== ".png") {
    e.preventDefault();
    openModalAlert("saveAsImageMustBePNGModal");
    return;
  }
  that.props.dispatch(genImgActions.genImgBegin());
  let viewport = that.canvas.viewportTransform;
  let imageWidth = that.localvars["imageWidth"];
  let imageHeight = that.localvars["imageHeight"];
  that.canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
  let thehref = that.canvas.toDataURL(
    "png",
    1,
    1,
    0,
    0,
    imageWidth,
    imageHeight
  );
  document.getElementById("downloadimg").href = thehref;
  document.getElementById("downloadimg").download = namefile;
  that.canvas.viewportTransform = viewport;
  that.canvas.renderAll();
  that.props.dispatch(genImgActions.genImgSuccess());
}

function saveAsPDFThat(that, e) {
  let namefile = prompt(
    "Choose the name of a file to save in pdf form",
    "scaffolddiagram.pdf"
  );
  if (namefile === null) {
    e.preventDefault();
    return;
  }
  if (namefile.substring(namefile.length - 4) !== ".pdf") {
    e.preventDefault();
    openModalAlert("saveAsPDFMustBePDFModal");
    return;
  }
  e.preventDefault();
  that.props.dispatch(genPDFActions.genPDFBegin());
  if (that.state.show_grid && that.boundObjs["grid"]) {
    Grid.setVisibleOrInvisible(that.canvas, that.boundObjs["grid"], false);
  }
  if (that.localvars["indivbayselect"]) {
    let localSelectRun = that.localvars["indivbayselect"];
    let selectionObject = localSelectRun.rect_selection;
    if (selectionObject) {
      selectionObject.set({ stroke: invisible, fill: invisible });
      that.canvas.renderAll();
    }
  }

  let viewport = that.canvas.viewportTransform;
  that.canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
  let imageWidth = that.localvars["imageWidth"];
  let imageHeight = that.localvars["imageHeight"];
  let pdfimage = that.canvas.toDataURL(
    "png",
    1,
    1,
    0,
    0,
    imageWidth,
    imageHeight
  );

  // It's easy to work out the image size, but what about the size of the PDF?
  // Fortunately, for gennotes pages, we can look up the page size from "that".

  let calcWidth = imageWidth / constants.dpmm;
  let calcHeight = imageHeight / constants.dpmm;
  let pagesize = that.props.planinfo.item.default_psize;
  let pageDimensions = constants.PAGESIZES[pagesize];
  let pdf;

  if (imageWidth > imageHeight) {
    calcWidth = pageDimensions[1];
    calcHeight = pageDimensions[0];
    pdf = new jsPDF("l", "mm", pagesize.toLowerCase(), true);
  } else {
    calcWidth = pageDimensions[0];
    calcHeight = pageDimensions[1];
    pdf = new jsPDF("p", "mm", pagesize.toLowerCase(), true);
  }
  pdf.addImage(pdfimage, "PNG", 0, 0, calcWidth, calcHeight, "", "FAST");
  pdf.save(namefile);
  //  document.getElementById("downloadpdf").download = namefile;
  that.canvas.viewportTransform = viewport;
  that.canvas.renderAll();
  if (that.state.show_grid && that.boundObjs["grid"]) {
    Grid.setVisibleOrInvisible(that.canvas, that.boundObjs["grid"], true);
  }

  if (that.localvars["indivbayselect"]) {
    let localSelectRun = that.localvars["indivbayselect"];
    let selectionObject = localSelectRun.rect_selection;
    if (selectionObject) {
      selectionObject.set({ stroke: indivselectcolour, fill: indivselectfill });
      that.canvas.renderAll();
    }
  }

  that.props.dispatch(genPDFActions.genPDFSuccess());
}

function prettifyError(message, keystocontrols) {
  let jsonmessage;
  let errorsold, errors;
  try {
    jsonmessage = JSON.parse(message);
  } catch (e) {
    return <p>{message}</p>;
  }
  if (typeof jsonmessage === "string") {
    return <p>{jsonmessage}</p>;
  }
  let prettyField = (fieldname) =>
    "The '" + fieldname + "' field needs a valid value.";
  errorsold = Object.keys(jsonmessage).map(function (key) {
    return [key, jsonmessage[key]];
  });
  let errorsnew = [];
  for (let i = 0; i < errorsold.length; i++) {
    if (errorsold[i][0] !== "extraprofile") {
      errorsnew.push(errorsold[i]);
    } else {
      let extraprofileerrors = Object.keys(errorsold[i][1]).map(function (key) {
        return [key, jsonmessage[key]];
      });
      for (let j = 0; j < extraprofileerrors.length; j++) {
        errorsnew.push(extraprofileerrors[j]);
      }
    }
  }

  errors = errorsnew.map((item, i) => (
    <li key={i}>
      {!keystocontrols.hasOwnProperty(item[0])
        ? item[1]
        : prettyField(keystocontrols[item[0]])}
    </li>
  ));
  return <ul>{errors}</ul>;
}

function prettifyErrorAlt(message, keystocontrols, anticiperrors) {
  let jsonmessage;
  let errorsold, errors;
  try {
    jsonmessage = JSON.parse(message);
  } catch (e) {
    return <p>{message}</p>;
  }

  if (typeof jsonmessage === "string") {
    return <p>{jsonmessage}</p>;
  }
  let prettyField = (fieldname, issue) =>
    "There are issues with the value provided for the " +
    fieldname +
    " field: " +
    (anticiperrors[issue] ? anticiperrors[issue] : "'" + issue + "'") +
    ".";
  errorsold = Object.keys(jsonmessage).map(function (key) {
    return [key, jsonmessage[key]];
  });

  let errorsnew = [];
  for (let i = 0; i < errorsold.length; i++) {
    if (errorsold[i][0] !== "extraprofile") {
      errorsnew.push(errorsold[i]);
    } else {
      let extraprofileerrors = Object.keys(errorsold[i][1]).map(function (key) {
        return [key, jsonmessage["extraprofile"][key]];
      });
      for (let j = 0; j < extraprofileerrors.length; j++) {
        errorsnew.push(extraprofileerrors[j]);
      }
    }
  }

  errors = errorsnew.map((item, i) => (
    <li key={i}>
      {!keystocontrols.hasOwnProperty(item[0])
        ? item[1]
        : prettyField(keystocontrols[item[0]], item[1])}
    </li>
  ));
  return <ul>{errors}</ul>;
}

// For getting data from FileReader ameable to promises.
// Based on ideas from here:
// https://blog.shovonhasan.com/using-promises-with-filereader/

const FileToArrayBuffer = (inputFile) => {
  const ourFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    ourFileReader.onerror = () => {
      ourFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    ourFileReader.onload = () => {
      resolve(ourFileReader.result);
    };
    ourFileReader.readAsArrayBuffer(inputFile);
  });
};

// What is the maximum size in pixels that a canvas on the local machine can
// represent an A4/A3/A2/etc page in landscape mode. Returns a sequence,
// where seq[0] is the height, and seq[1] is the width.

function findMaxAxCanvSize() {
  let startDimensions = [841, 1189]; // An A0 size in pixels.
  let newDimensions = [null, null];
  let sizeFactor = 1;
  let resultCheck = true;
  let iterator = 1;
  while (resultCheck) {
    newDimensions[0] = Math.floor(startDimensions[0] * sizeFactor);
    newDimensions[1] = Math.floor(startDimensions[1] * sizeFactor);
    resultCheck = canvasSize.test({
      height: newDimensions[0],
      width: newDimensions[1],
    });
    if (resultCheck) {
      sizeFactor = sizeFactor * 2;
    }
  }
  sizeFactor = sizeFactor / 2;
  let logSize = Math.log2(sizeFactor);
  iterator = 1;
  logSize = logSize + 1 / Math.pow(2, iterator);
  let storeDimensions = [null, null];
  while (1) {
    storeDimensions[0] = newDimensions[0];
    storeDimensions[1] = newDimensions[1];
    sizeFactor = Math.pow(2, logSize);
    newDimensions[0] = Math.floor(startDimensions[0] * sizeFactor);
    newDimensions[1] = Math.floor(startDimensions[1] * sizeFactor);
    if (
      storeDimensions[0] === newDimensions[0] &&
      storeDimensions[1] === newDimensions[1] &&
      resultCheck
    ) {
      break;
    }
    resultCheck = canvasSize.test({
      height: newDimensions[0],
      width: newDimensions[1],
    });
    iterator = iterator + 1;
    if (resultCheck) {
      logSize = logSize + 1 / Math.pow(2, iterator);
    } else {
      logSize = logSize - 1 / Math.pow(2, iterator);
    }
  }
  return storeDimensions;
}

/* Stuff copied over from Python. */

function matchtuple(tupleval, matchval, precision) {
  /* Check whether two tuples match to a given precision. I.e.:
        |tupleval - matchval| / |matchval| < precision,
        where the |.| is the absolute value. Returns true if this 
        property is satisfied, and false otherwise.
    */

  if (!tupleval || !matchval || tupleval.length !== matchval.length) {
    return false;
  }
  if (
    math.norm(math.subtract(tupleval, matchval)) / math.norm(matchval) <
    precision
  ) {
    return true;
  }
  return false;
}

function findPaperSize(paperDims) {
  if (!paperDims) {
    return null;
  }
  let paperSizeKeys = Object.keys(constants.PAGESIZES);
  for (let i = 0; i < paperSizeKeys.length; i++) {
    let paperSize = paperSizeKeys[i];
    let paperSizeDims = constants.PAGESIZES[paperSize];
    let paperSizeDimsAlt = [paperSizeDims[1], paperSizeDims[0]];
    if (matchtuple(paperDims, paperSizeDims, constants.PAPERSIZE_PREC)) {
      return paperSize;
    }
    if (matchtuple(paperDims, paperSizeDimsAlt, constants.PAPERSIZE_PREC)) {
      return paperSize;
    }
  }
  return null;
}

function findCanvSize() {
  let maxWidth = parseInt(localStorage.getItem("maxWidth"));
  let maxHeight = parseInt(localStorage.getItem("maxHeight"));
  let strMessage = `For this computer, the maximum canvas width is ${maxWidth} pixels and the maximum canvas height is ${maxHeight} pixels.\n`;
  for (let i = 0; i <= 5; i++) {
    let pageSize = `A${i}`;
    let minDPIWidth =
      (constants.mmpinch * maxWidth) / constants.PAGESIZES[pageSize][1];
    let minDPIHeight =
      (constants.mmpinch * maxHeight) / constants.PAGESIZES[pageSize][0];
    let minDPI = Math.floor(Math.min(minDPIWidth, minDPIHeight));
    strMessage += `The maximum resolution for working with ${pageSize} documents on this computer is ${minDPI} dpi.\n`;
  }
  return strMessage;
}

function recalibCanvasDialog() {
  let thennable = new Promise(function (resolve, reject) {
    resolve(findMaxAxCanvSize());
  });
  thennable.then(function (val) {
    localStorage.setItem("maxHeight", val[0]);
    localStorage.setItem("maxWidth", val[1]);
  });
  openModalAlert("calcCanvModal");
}

// Building scaffolding notes.

function buildScafNotes(genNotesInterpObj) {
  let scafNotes = interpolate(
    constants.scafNotesStartNewPart1To4,
    genNotesInterpObj
  );
  constants.gennotesNumericKeys.forEach(function (numkey) {
    if (!isNaN(parseFloat(genNotesInterpObj[numkey]))) {
      scafNotes =
        scafNotes +
        interpolate(
          constants.scafNotesStartNewNumParameters[numkey],
          genNotesInterpObj
        );
    }
  });
  scafNotes =
    scafNotes +
    interpolate(constants.scafNotesStartNewPart5To9, genNotesInterpObj);
  if (genNotesInterpObj.basedon) {
    scafNotes =
      scafNotes +
      interpolate(constants.scafNotesStartNewPart10, genNotesInterpObj);
  }
  return scafNotes;
}

// Checks if two arrays are equal. From:
// https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript

function arrayequal(array1, array2) {
  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  );
}

//https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
// Because JS has a broken "modulo" function.

function modnp(n, p) {
  return n - p * Math.floor(n / p);
}

// Another from Stack Overflow: getting color names and converting to Hex.
// https://stackoverflow.com/questions/1573053/javascript-function-to-convert-color-names-to-hex-codes

function getHexColor(colorStr) {
  var a = document.createElement("div");
  a.style.color = colorStr;
  var colors = window
    .getComputedStyle(document.body.appendChild(a))
    .color.match(/\d+/g)
    .map(function (a) {
      return parseInt(a, 10);
    });
  document.body.removeChild(a);
  return colors.length >= 3
    ? "#" +
        ((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2])
          .toString(16)
          .substr(1)
    : false;
}

// Find the midpoint of pointP{x: xi, y: yi} and pointQ{x: xj, y: xj}.

function findMidPoint(pointP, pointQ) {
  return { x: (pointP.x + pointQ.x) / 2, y: (pointP.y + pointQ.y) / 2 };
}

// Subtracts pointQ{x: xj, y: xj} from pointP{x: xi, y: yi}, and returns the result.

function subtractPoint(pointP, pointQ) {
  return { x: pointP.x - pointQ.x, y: pointP.y - pointQ.y };
}

// Assume pointSequence is a sequence of points pi{x: xi, y: yi}, for i from 0 to n-1. This function returns
// another sequence of midpoints mi, where m0 is the midpoint of p0 and p1, m1 is the midpoint of p1 and p2,
// and mn-1 is the midpoint of pn-1 and p0.

function getMidPoints(pointSequence) {
  if (pointSequence.length === 0) {
    return [];
  } else if (pointSequence.length === 1) {
    return pointSequence;
  } else {
    let returnSeq = [];
    for (let i = 0; i < pointSequence.length; i++) {
      returnSeq.push(
        findMidPoint(
          pointSequence[i],
          pointSequence[(i + 1) % pointSequence.length]
        )
      );
    }
    return returnSeq;
  }
}

// Assume pointSequenceP is a sequence of points pi{x: xi, y: yi}, for i from 0 to n-1, and
// pointSequenceQ is a sequence of points qj{x: xj, y: yj}, for j from 0 to m-1. This function
// finds the closest pi and qj to each other, and returns [i, j]

function findClosePoints(pointSequenceP, pointSequenceQ) {
  if (pointSequenceP.length === 0 || pointSequenceQ.length === 0) {
    return null;
  }
  let startIndexI = 0;
  let startIndexJ = 0;
  let startDistance = Math.sqrt(
    Math.pow(pointSequenceP[0].x - pointSequenceQ[0].x, 2) +
      Math.pow(pointSequenceP[0].y - pointSequenceQ[0].y, 2)
  );
  for (let i = 0; i < pointSequenceP.length; i++) {
    for (let j = 0; j < pointSequenceQ.length; j++) {
      let calcDistance = Math.sqrt(
        Math.pow(pointSequenceP[i].x - pointSequenceQ[j].x, 2) +
          Math.pow(pointSequenceP[i].y - pointSequenceQ[j].y, 2)
      );
      if (calcDistance < startDistance) {
        startDistance = calcDistance;
        startIndexI = i;
        startIndexJ = j;
      }
    }
  }
  return [startIndexI, startIndexJ];
}

// This is a bit like the function above, but compares one point to a sequence of sequences of points.
// It finds the index inside the sequence of sequences of points (or null if not found).

function findClosePointToSeq(point, pointSequenceOfSequence) {
  if (point === 0 || pointSequenceOfSequence.length === 0) {
    return null;
  }
  let pointstoCompare = [];
  for (let pointseq of pointSequenceOfSequence) {
    let closepointpair = findClosePoints([point], pointseq);
    pointstoCompare.push(pointseq[closepointpair[1]]);
  }
  let finalpair = findClosePoints([point], pointstoCompare);
  return finalpair[1];
}

// This is a good function for displacement. Let's say if you start with
// displacements xdisp and ydisp in the x and y directions, and then
// rotate it by an angle, and divide by a scale, what is the result
// going to be?

function rotatecoords(xdisp, ydisp, angle, scale) {
  let cosangle = Math.cos((angle * Math.PI) / 180);
  let sinangle = Math.sin((angle * Math.PI) / 180);
  let xresult = (xdisp * cosangle + ydisp * sinangle) / scale;
  let yresult = (ydisp * cosangle - xdisp * sinangle) / scale;
  return [xresult, yresult];
}

// When pasting or loading new tubes to a screen, you might have a
// clash between pasted/loaded tube ids and existing tube ids. The safest
// thing to do is to regenerate ids in the tubes (and the scaffold bays
// that refer to them.)

function regenerateTubeIds(objs) {
  let ourTubes = objs.filter(function (x) {
    return x.type === "storeGroup" && x.subtype === "TUBE";
  });
  let ourScaffoldGroups = objs.filter(function (x) {
    return x.type === "scaffoldGroup";
  });
  let newTubeIds = {};
  for (let tube of ourTubes) {
    let newId = uuidv1();
    newTubeIds[tube.labelid] = newId;
    tube.labelid = newId;
  }
  for (let scaffoldGroup of ourScaffoldGroups) {
    for (let i = 0; i < scaffoldGroup.tubeids.length; i++) {
      let tubeid = scaffoldGroup.tubeids[i];
      if (tubeid in newTubeIds) {
        scaffoldGroup.tubeids[i] = newTubeIds[tubeid];
      }
    }
  }
}

// When adding new tubes (e.g from the database) - this checks that all the stuff is connected. This
// assumes that the objects are now in the canvas.

function reInitTubes(canv) {
  let allTubes = canv.getObjects().filter(function (x) {
    return x.type === "storeGroup" && x.subtype === "TUBE";
  });

  let collectedTubeSet = {};
  let collectedTubeCount = {};
  for (let tube of allTubes) {
    let tubeid = tube.labelid;
    collectedTubeSet[tubeid] = tube;
    collectedTubeCount[tubeid] = 0;
  }

  let allScaffolds = canv.getObjects().filter(function (x) {
    return x.type === "scaffoldGroup";
  });

  for (let scaffoldbay of allScaffolds) {
    for (let i = 0; i < scaffoldbay.tubeids.length; i++) {
      let tubeid = scaffoldbay.tubeids[i];
      if (tubeid in collectedTubeSet) {
        scaffoldbay.tubearray[i] = collectedTubeSet[tubeid];
        collectedTubeCount[tubeid] = collectedTubeCount[tubeid] + 1;
      } else {
        scaffoldbay.tubearray[i] = null;
      }
    }

    // Now we remove the null ones going backwards

    for (let i = scaffoldbay.tubeids.length - 1; i <= 0; i++) {
      if (scaffoldbay.tubearray[i] === null) {
        scaffoldbay.tubearray.splice(i, 1);
        scaffoldbay.tubeids.splice(i, 1);
        scaffoldbay.tubecoords.splice(i, 1);
      }
    }
  }

  for (let tube of allTubes) {
    tube.noattachments = collectedTubeCount[tube.labelid];
  }
}

/* Finds the index of the first value in array1 where |val - value| < separation. */

function findIndexOfNear(array1, val, seperation = 0.01) {
  const isNearVal = (value) => Math.abs(value - val) < seperation;
  return array1.findIndex(isNearVal);
}

export {
  debugconsole,
  zoomInAndOut,
  getCurrentUserId,
  openModalAlert,
  addBoundaryRect,
  addGlimmerRect,
  addBoundLine,
  addLedgerHead,
  addLedgerBody,
  addNotesText,
  wrapandbreak,
  interpolate,
  abbrevOutput,
  removeAlphaFromDataUrl,
  zoomInThat,
  zoomOutThat,
  canvmousewheelThat,
  addFlexibleBoundaryThat,
  ovBrightnessCoverChangeThat,
  setBounWidthThat,
  setBoundaryLegendPosThat,
  setLegendSizeThat,
  setLegendChangeThat,
  zoomlevelChangeThat,
  dataURLtoBlob,
  saveAsImageThat,
  saveAsPDFThat,
  prettifyError,
  prettifyErrorAlt,
  realParseInt,
  FileToArrayBuffer,
  findMaxAxCanvSize,
  setGreyOuterBoundary,
  matchtuple,
  findPaperSize,
  findCanvSize,
  recalibCanvasDialog,
  buildScafNotes,
  arrayequal,
  modnp,
  getHexColor,
  findMidPoint,
  subtractPoint,
  getMidPoints,
  findClosePoints,
  findClosePointToSeq,
  rotatecoords,
  regenerateTubeIds,
  reInitTubes,
  findIndexOfNear
};
