import React from "react";
import PropTypes from "prop-types";

function AddPlanListItem(props) {
  return (
    <tr className={props.classstyle}>
      <th scope="row">{props.filename}</th>
      <td>{props.pagesize}</td>
      <td>{props.removebutton}</td>
    </tr>
  );
}

class AddPlanList extends React.Component {
  render() {
    let classstyles, removebuttons;

    classstyles = new Array(this.props.filenames.length).fill("");
    removebuttons = new Array(this.props.filenames.length).fill(
      <button type="button" className="btn btn-info">
        Remove
      </button>
    );
    for (let i = 0; i < this.props.filenames.length; i++) {
      if (this.props.pageqlties[i] === false) {
        classstyles[i] = "table-danger";
      }
      let removeid = "remove" + i.toString();
      removebuttons[i] = (
        <button
          type="button"
          className="btn btn-info"
          onClick={this.props.removeFileForAdd}
          id={removeid}
        >
          Remove
        </button>
      );
    }
    let numbers = Array.apply(
      undefined,
      Array(this.props.filenames.length)
    ).map(function(x, y) {
      return y + 1;
    });
    const addplanlistItems = numbers.map(number => (
      // Correct! Key should be specified inside the array.

      <AddPlanListItem
        key={number.toString()}
        classstyle={classstyles[number - 1]}
        filename={this.props.filenames[number - 1]}
        pagesize={
          this.props.pageqlties[number - 1]
            ? this.props.pagesizes[number - 1]
            : "-"
        }
        removebutton={removebuttons[number - 1]}
      />
    ));
    return (
      <table className="table table-sm">
        <caption className="text-center">Plans for addition</caption>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Number of Pages</th>
            <th scope="col">Remove?</th>
          </tr>
        </thead>
        <tbody>{addplanlistItems}</tbody>
      </table>
    );
  }
}

AddPlanList.propTypes = {
  filenames: PropTypes.array,
  pagesizes: PropTypes.array,
  pageqlties: PropTypes.array
};

export default AddPlanList;
