import * as constants from "./constants";
import { persistor } from "./store";
import { userService } from "./httpreq";

export const getPlansBegin = () => ({
  type: constants.GET_PLANS_BEGIN
});

export const getPlansSuccess = data => ({
  type: constants.GET_PLANS_SUCCESS,
  payload: data
});

export const getPlansFailure = error => ({
  type: constants.GET_PLANS_FAILURE,
  payload: error
});

export const clearPlans = () => ({
  type: constants.CLEAR_PLANS
});

export const getPlansActions = {
  getPlansBegin,
  getPlansSuccess,
  getPlansFailure,
  clearPlans
};

export const getUfilersBegin = () => ({
  type: constants.GET_UFILERS_BEGIN
});

export const getUfilersSuccess = data => ({
  type: constants.GET_UFILERS_SUCCESS,
  payload: data
});

export const getUfilersFailure = error => ({
  type: constants.GET_UFILERS_FAILURE,
  payload: error
});

export const clearUfilers = () => ({
  type: constants.CLEAR_UFILERS
});

export const getUfilersActions = {
  getUfilersBegin,
  getUfilersSuccess,
  getUfilersFailure,
  clearUfilers
};

export const getUscafitemsheetsBegin = () => ({
  type: constants.GET_USCAFITEMSHEETS_BEGIN
});

export const getUscafitemsheetsSuccess = data => ({
  type: constants.GET_USCAFITEMSHEETS_SUCCESS,
  payload: data
});

export const getUscafitemsheetsFailure = error => ({
  type: constants.GET_USCAFITEMSHEETS_FAILURE,
  payload: error
});

export const clearUscafitemsheets = () => ({
  type: constants.CLEAR_USCAFITEMSHEETS
});

export const getUscafitemsheetsActions = {
  getUscafitemsheetsBegin,
  getUscafitemsheetsSuccess,
  getUscafitemsheetsFailure,
  clearUscafitemsheets
};

export const dummyActions = {
  dummy
};

function dummy() {
  return { type: constants.DUMMY_INFO };
}

function login(username, password) {
  return dispatch => {
    dispatch(userService.getLogin(username, password)).then(obj => {
      if (obj.access) {
        dispatch(userService.getUserId()).then(obj => {
          constants.history.push("/plans");
        });
      }
    });
  };
}

function logout() {
  userService.logout();
  persistor.purge();
  constants.history.push("/login");
  return { type: constants.CLEAR_LOGIN };
}

export const getLoginBegin = () => ({
  type: constants.GET_LOGIN_BEGIN
});

export const getLoginSuccess = data => ({
  type: constants.GET_LOGIN_SUCCESS,
  payload: data
});

export const getLoginFailure = error => ({
  type: constants.GET_LOGIN_FAILURE,
  payload: error
});

export const getLoginActions = {
  getLoginBegin,
  getLoginSuccess,
  getLoginFailure,
  login,
  logout
};

export const getUserIdBegin = () => ({
  type: constants.GET_USERID_BEGIN
});

export const getUserIdSuccess = data => ({
  type: constants.GET_USERID_SUCCESS,
  payload: data
});

export const getUserIdFailure = error => ({
  type: constants.GET_USERID_FAILURE,
  payload: error
});

export const clearUserId = () => ({
  type: constants.CLEAR_USERID
});

export const getUserIdActions = {
  getUserIdBegin,
  getUserIdSuccess,
  getUserIdFailure,
  clearUserId
};

export const getUsersBegin = () => ({
  type: constants.GET_USERS_BEGIN
});

export const getUsersSuccess = data => ({
  type: constants.GET_USERS_SUCCESS,
  payload: data
});

export const getUsersFailure = error => ({
  type: constants.GET_USERS_FAILURE,
  payload: error
});

export const clearUsers = () => ({
  type: constants.CLEAR_USERS
});

export const getUsersActions = {
  getUsersBegin,
  getUsersSuccess,
  getUsersFailure,
  clearUsers
};

export const getPlanBegin = () => ({
  type: constants.GET_PLAN_BEGIN
});

export const getPlanSuccess = data => ({
  type: constants.GET_PLAN_SUCCESS,
  payload: data
});

export const getPlanFailure = error => ({
  type: constants.GET_PLAN_FAILURE,
  payload: error
});

export const getPlanActions = {
  getPlanBegin,
  getPlanSuccess,
  getPlanFailure
};

export const getPagesBegin = () => ({
  type: constants.GET_PAGES_BEGIN
});

export const getPagesSuccess = data => ({
  type: constants.GET_PAGES_SUCCESS,
  payload: data
});

export const getPagesFailure = error => ({
  type: constants.GET_PAGES_FAILURE,
  payload: error
});

export const clearPages = () => ({
  type: constants.CLEAR_PAGES
});

export const getPagesActions = {
  getPagesBegin,
  getPagesSuccess,
  getPagesFailure,
  clearPages
};

export const getPageBegin = () => ({
  type: constants.GET_PAGE_BEGIN
});

export const getPageSuccess = data => ({
  type: constants.GET_PAGE_SUCCESS,
  payload: data
});

export const getPageFailure = error => ({
  type: constants.GET_PAGE_FAILURE,
  payload: error
});

export const getPageActions = {
  getPageBegin,
  getPageSuccess,
  getPageFailure
};

export const postPlanBegin = () => ({
  type: constants.POST_PLAN_BEGIN
});

export const postPlanSuccess = data => ({
  type: constants.POST_PLAN_SUCCESS,
  payload: data
});

export const postPlanFailure = error => ({
  type: constants.POST_PLAN_FAILURE,
  payload: error
});

export const postPlanActions = {
  postPlanBegin,
  postPlanSuccess,
  postPlanFailure
};

export const putPlanBegin = () => ({
  type: constants.PUT_PLAN_BEGIN
});

export const putPlanSuccess = data => ({
  type: constants.PUT_PLAN_SUCCESS,
  payload: data
});

export const putPlanFailure = error => ({
  type: constants.PUT_PLAN_FAILURE,
  payload: error
});

export const putPlanActions = {
  putPlanBegin,
  putPlanSuccess,
  putPlanFailure
};

export const putPageBegin = () => ({
  type: constants.PUT_PAGE_BEGIN
});

export const putPageSuccess = data => ({
  type: constants.PUT_PAGE_SUCCESS,
  payload: data
});

export const putPageFailure = error => ({
  type: constants.PUT_PAGE_FAILURE,
  payload: error
});

export const putPageActions = {
  putPageBegin,
  putPageSuccess,
  putPageFailure
};

export const deletePlanBegin = () => ({
  type: constants.DELETE_PLAN_BEGIN
});

export const deletePlanSuccess = data => ({
  type: constants.DELETE_PLAN_SUCCESS,
  payload: data
});

export const deletePlanFailure = error => ({
  type: constants.DELETE_PLAN_FAILURE,
  payload: error
});

export const deletePlanActions = {
  deletePlanBegin,
  deletePlanSuccess,
  deletePlanFailure
};

export const loadImageBegin = () => ({
  type: constants.LOADIMAGE_BEGIN
});

export const loadImageSuccess = () => ({
  type: constants.LOADIMAGE_SUCCESS
});

export const loadImageFailure = error => ({
  type: constants.LOADIMAGE_FAILURE,
  payload: error
});

export const loadImageActions = {
  loadImageBegin,
  loadImageSuccess,
  loadImageFailure
};

export const loadLogoBegin = () => ({
  type: constants.LOADLOGO_BEGIN
});

export const loadLogoSuccess = () => ({
  type: constants.LOADLOGO_SUCCESS
});

export const loadLogoFailure = error => ({
  type: constants.LOADLOGO_FAILURE,
  payload: error
});

export const loadLogoActions = {
  loadLogoBegin,
  loadLogoSuccess,
  loadLogoFailure
};

export const loadBoundBegin = () => ({
  type: constants.LOADBOUND_BEGIN
});

export const loadBoundSuccess = () => ({
  type: constants.LOADBOUND_SUCCESS
});

export const loadBoundFailure = error => ({
  type: constants.LOADBOUND_FAILURE,
  payload: error
});

export const loadBoundActions = {
  loadBoundBegin,
  loadBoundSuccess,
  loadBoundFailure
};

export const getGenNotesBegin = () => ({
  type: constants.GET_GENNOTES_BEGIN
});

export const getGenNotesSuccess = data => ({
  type: constants.GET_GENNOTES_SUCCESS,
  payload: data
});

export const getGenNotesFailure = error => ({
  type: constants.GET_GENNOTES_FAILURE,
  payload: error
});

export const clearGenNotes = () => ({
  type: constants.CLEAR_GENNOTES
});

export const getGenNotesActions = {
  getGenNotesBegin,
  getGenNotesSuccess,
  getGenNotesFailure,
  clearGenNotes
};

export const getGenNoteBegin = () => ({
  type: constants.GET_GENNOTE_BEGIN
});

export const getGenNoteSuccess = data => ({
  type: constants.GET_GENNOTE_SUCCESS,
  payload: data
});

export const getGenNoteFailure = error => ({
  type: constants.GET_GENNOTE_FAILURE,
  payload: error
});

export const getGenNoteActions = {
  getGenNoteBegin,
  getGenNoteSuccess,
  getGenNoteFailure
};

export const postGenNoteBegin = () => ({
  type: constants.POST_GENNOTE_BEGIN
});

export const postGenNoteSuccess = data => ({
  type: constants.POST_GENNOTE_SUCCESS,
  payload: data
});

export const postGenNoteFailure = error => ({
  type: constants.POST_GENNOTE_FAILURE,
  payload: error
});

export const postGenNoteActions = {
  postGenNoteBegin,
  postGenNoteSuccess,
  postGenNoteFailure
};

export const putGenNoteBegin = () => ({
  type: constants.PUT_GENNOTE_BEGIN
});

export const putGenNoteSuccess = data => ({
  type: constants.PUT_GENNOTE_SUCCESS,
  payload: data
});

export const putGenNoteFailure = error => ({
  type: constants.PUT_GENNOTE_FAILURE,
  payload: error
});

export const putGenNoteActions = {
  putGenNoteBegin,
  putGenNoteSuccess,
  putGenNoteFailure
};

export const deleteGenNoteBegin = () => ({
  type: constants.DELETE_GENNOTE_BEGIN
});

export const deleteGenNoteSuccess = data => ({
  type: constants.DELETE_GENNOTE_SUCCESS,
  payload: data
});

export const deleteGenNoteFailure = error => ({
  type: constants.DELETE_GENNOTE_FAILURE,
  payload: error
});

export const deleteGenNoteActions = {
  deleteGenNoteBegin,
  deleteGenNoteSuccess,
  deleteGenNoteFailure
};

export const getImageDatasBegin = () => ({
  type: constants.GET_IMAGEDATAS_BEGIN
});

export const getImageDatasSuccess = data => ({
  type: constants.GET_IMAGEDATAS_SUCCESS,
  payload: data
});

export const getImageDatasFailure = error => ({
  type: constants.GET_IMAGEDATAS_FAILURE,
  payload: error
});

export const clearImageDatas = () => ({
  type: constants.CLEAR_IMAGEDATAS
});

export const getImageDatasActions = {
  getImageDatasBegin,
  getImageDatasSuccess,
  getImageDatasFailure,
  clearImageDatas
};

export const getImageDataBegin = () => ({
  type: constants.GET_IMAGEDATA_BEGIN
});

export const getImageDataSuccess = data => ({
  type: constants.GET_IMAGEDATA_SUCCESS,
  payload: data
});

export const getImageDataFailure = error => ({
  type: constants.GET_IMAGEDATA_FAILURE,
  payload: error
});

export const getImageDataActions = {
  getImageDataBegin,
  getImageDataSuccess,
  getImageDataFailure
};

export const postImageDataBegin = () => ({
  type: constants.POST_IMAGEDATA_BEGIN
});

export const postImageDataSuccess = data => ({
  type: constants.POST_IMAGEDATA_SUCCESS,
  payload: data
});

export const postImageDataFailure = error => ({
  type: constants.POST_IMAGEDATA_FAILURE,
  payload: error
});

export const postImageDataActions = {
  postImageDataBegin,
  postImageDataSuccess,
  postImageDataFailure
};

export const putImageDataBegin = () => ({
  type: constants.PUT_IMAGEDATA_BEGIN
});

export const putImageDataSuccess = data => ({
  type: constants.PUT_IMAGEDATA_SUCCESS,
  payload: data
});

export const putImageDataFailure = error => ({
  type: constants.PUT_IMAGEDATA_FAILURE,
  payload: error
});

export const putImageDataActions = {
  putImageDataBegin,
  putImageDataSuccess,
  putImageDataFailure
};

export const deleteImageDataBegin = () => ({
  type: constants.DELETE_IMAGEDATA_BEGIN
});

export const deleteImageDataSuccess = data => ({
  type: constants.DELETE_IMAGEDATA_SUCCESS,
  payload: data
});

export const deleteImageDataFailure = error => ({
  type: constants.DELETE_IMAGEDATA_FAILURE,
  payload: error
});

export const deleteImageDataActions = {
  deleteImageDataBegin,
  deleteImageDataSuccess,
  deleteImageDataFailure
};

export const getFileResultsBegin = () => ({
  type: constants.GET_FILERESULTS_BEGIN
});

export const getFileResultsSuccess = data => ({
  type: constants.GET_FILERESULTS_SUCCESS,
  payload: data
});

export const getFileResultsFailure = error => ({
  type: constants.GET_FILERESULTS_FAILURE,
  payload: error
});

export const clearFileResults = () => ({
  type: constants.CLEAR_FILERESULTS
});

export const getFileResultsActions = {
  getFileResultsBegin,
  getFileResultsSuccess,
  getFileResultsFailure,
  clearFileResults
};

export const getFileResultBegin = () => ({
  type: constants.GET_FILERESULT_BEGIN
});

export const getFileResultSuccess = data => ({
  type: constants.GET_FILERESULT_SUCCESS,
  payload: data
});

export const getFileResultFailure = error => ({
  type: constants.GET_FILERESULT_FAILURE,
  payload: error
});

export const getFileResultActions = {
  getFileResultBegin,
  getFileResultSuccess,
  getFileResultFailure
};

export const postFileResultBegin = () => ({
  type: constants.POST_FILERESULT_BEGIN
});

export const postFileResultSuccess = data => ({
  type: constants.POST_FILERESULT_SUCCESS,
  payload: data
});

export const postFileResultFailure = error => ({
  type: constants.POST_FILERESULT_FAILURE,
  payload: error
});

export const postFileResultActions = {
  postFileResultBegin,
  postFileResultSuccess,
  postFileResultFailure
};

export const putFileResultBegin = () => ({
  type: constants.PUT_FILERESULT_BEGIN
});

export const putFileResultSuccess = data => ({
  type: constants.PUT_FILERESULT_SUCCESS,
  payload: data
});

export const putFileResultFailure = error => ({
  type: constants.PUT_FILERESULT_FAILURE,
  payload: error
});

export const putFileResultActions = {
  putFileResultBegin,
  putFileResultSuccess,
  putFileResultFailure
};

export const deleteFileResultBegin = () => ({
  type: constants.DELETE_FILERESULT_BEGIN
});

export const deleteFileResultSuccess = data => ({
  type: constants.DELETE_FILERESULT_SUCCESS,
  payload: data
});

export const deleteFileResultFailure = error => ({
  type: constants.DELETE_FILERESULT_FAILURE,
  payload: error
});

export const deleteFileResultActions = {
  deleteFileResultBegin,
  deleteFileResultSuccess,
  deleteFileResultFailure
};

export const getScafItemSheetsBegin = () => ({
  type: constants.GET_SCAFITEMSHEETS_BEGIN
});

export const getScafItemSheetsSuccess = data => ({
  type: constants.GET_SCAFITEMSHEETS_SUCCESS,
  payload: data
});

export const getScafItemSheetsFailure = error => ({
  type: constants.GET_SCAFITEMSHEETS_FAILURE,
  payload: error
});

export const clearScafItemSheets = () => ({
  type: constants.CLEAR_SCAFITEMSHEETS
});

export const getScafItemSheetsActions = {
  getScafItemSheetsBegin,
  getScafItemSheetsSuccess,
  getScafItemSheetsFailure,
  clearScafItemSheets
};

export const getScafItemSheetBegin = () => ({
  type: constants.GET_SCAFITEMSHEET_BEGIN
});

export const getScafItemSheetSuccess = data => ({
  type: constants.GET_SCAFITEMSHEET_SUCCESS,
  payload: data
});

export const getScafItemSheetFailure = error => ({
  type: constants.GET_SCAFITEMSHEET_FAILURE,
  payload: error
});

export const getScafItemSheetActions = {
  getScafItemSheetBegin,
  getScafItemSheetSuccess,
  getScafItemSheetFailure
};

export const postScafItemSheetBegin = () => ({
  type: constants.POST_SCAFITEMSHEET_BEGIN
});

export const postScafItemSheetSuccess = data => ({
  type: constants.POST_SCAFITEMSHEET_SUCCESS,
  payload: data
});

export const postScafItemSheetFailure = error => ({
  type: constants.POST_SCAFITEMSHEET_FAILURE,
  payload: error
});

export const postScafItemSheetActions = {
  postScafItemSheetBegin,
  postScafItemSheetSuccess,
  postScafItemSheetFailure
};

export const putScafItemSheetBegin = () => ({
  type: constants.PUT_SCAFITEMSHEET_BEGIN
});

export const putScafItemSheetSuccess = data => ({
  type: constants.PUT_SCAFITEMSHEET_SUCCESS,
  payload: data
});

export const putScafItemSheetFailure = error => ({
  type: constants.PUT_SCAFITEMSHEET_FAILURE,
  payload: error
});

export const putScafItemSheetActions = {
  putScafItemSheetBegin,
  putScafItemSheetSuccess,
  putScafItemSheetFailure
};

export const deleteScafItemSheetBegin = () => ({
  type: constants.DELETE_SCAFITEMSHEET_BEGIN
});

export const deleteScafItemSheetSuccess = data => ({
  type: constants.DELETE_SCAFITEMSHEET_SUCCESS,
  payload: data
});

export const deleteScafItemSheetFailure = error => ({
  type: constants.DELETE_SCAFITEMSHEET_FAILURE,
  payload: error
});

export const deleteScafItemSheetActions = {
  deleteScafItemSheetBegin,
  deleteScafItemSheetSuccess,
  deleteScafItemSheetFailure
};

export const getUserBegin = () => ({
  type: constants.GET_USER_BEGIN
});

export const getUserSuccess = data => ({
  type: constants.GET_USER_SUCCESS,
  payload: data
});

export const getUserFailure = error => ({
  type: constants.GET_USER_FAILURE,
  payload: error
});

export const getUserActions = {
  getUserBegin,
  getUserSuccess,
  getUserFailure
};

export const postUserBegin = () => ({
  type: constants.POST_USER_BEGIN
});

export const postUserSuccess = data => ({
  type: constants.POST_USER_SUCCESS,
  payload: data
});

export const postUserFailure = error => ({
  type: constants.POST_USER_FAILURE,
  payload: error
});

export const postUserActions = {
  postUserBegin,
  postUserSuccess,
  postUserFailure
};

export const putUserBegin = () => ({
  type: constants.PUT_USER_BEGIN
});

export const putUserSuccess = data => ({
  type: constants.PUT_USER_SUCCESS,
  payload: data
});

export const putUserFailure = error => ({
  type: constants.PUT_USER_FAILURE,
  payload: error
});

export const putUserActions = {
  putUserBegin,
  putUserSuccess,
  putUserFailure
};

export const activateUserBegin = () => ({
  type: constants.ACTIVATE_USER_BEGIN
});

export const activateUserSuccess = data => ({
  type: constants.ACTIVATE_USER_SUCCESS,
  payload: data
});

export const activateUserFailure = error => ({
  type: constants.ACTIVATE_USER_FAILURE,
  payload: error
});

export const activateUserActions = {
  activateUserBegin,
  activateUserSuccess,
  activateUserFailure
};

export const resendUserBegin = () => ({
  type: constants.RESEND_USER_BEGIN
});

export const resendUserSuccess = data => ({
  type: constants.RESEND_USER_SUCCESS,
  payload: data
});

export const resendUserFailure = error => ({
  type: constants.RESEND_USER_FAILURE,
  payload: error
});

export const resendUserActions = {
  resendUserBegin,
  resendUserSuccess,
  resendUserFailure
};

export const genImgBegin = () => ({
  type: constants.GENIMG_BEGIN
});

export const genImgSuccess = () => ({
  type: constants.GENIMG_SUCCESS
});

export const genImgFailure = error => ({
  type: constants.GENIMG_FAILURE,
  payload: error
});

export const genImgActions = {
  genImgBegin,
  genImgSuccess,
  genImgFailure
};

export const genPDFBegin = () => ({
  type: constants.GENPDF_BEGIN
});

export const genPDFSuccess = () => ({
  type: constants.GENPDF_SUCCESS
});

export const genPDFFailure = error => ({
  type: constants.GENPDF_FAILURE,
  payload: error
});

export const genPDFActions = {
  genPDFBegin,
  genPDFSuccess,
  genPDFFailure
};

export const postPasswordBegin = () => ({
  type: constants.POST_PASSWORD_BEGIN
});

export const postPasswordSuccess = data => ({
  type: constants.POST_PASSWORD_SUCCESS,
  payload: data
});

export const postPasswordFailure = error => ({
  type: constants.POST_PASSWORD_FAILURE,
  payload: error
});

export const postPasswordActions = {
  postPasswordBegin,
  postPasswordSuccess,
  postPasswordFailure
};

export const resetPasswordBegin = () => ({
  type: constants.RESET_PASSWORD_BEGIN
});

export const resetPasswordSuccess = data => ({
  type: constants.RESET_PASSWORD_SUCCESS,
  payload: data
});

export const resetPasswordFailure = error => ({
  type: constants.RESET_PASSWORD_FAILURE,
  payload: error
});

export const resetPasswordActions = {
  resetPasswordBegin,
  resetPasswordSuccess,
  resetPasswordFailure
};

export const confirmPasswordBegin = () => ({
  type: constants.CONFIRM_PASSWORD_BEGIN
});

export const confirmPasswordSuccess = data => ({
  type: constants.CONFIRM_PASSWORD_SUCCESS,
  payload: data
});

export const confirmPasswordFailure = error => ({
  type: constants.CONFIRM_PASSWORD_FAILURE,
  payload: error
});

export const confirmPasswordActions = {
  confirmPasswordBegin,
  confirmPasswordSuccess,
  confirmPasswordFailure
};
