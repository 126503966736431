/*
 * The scaffoldplan.js file.
 * @author Peter Murphy, 2018-2020.
 * @copyright Rose Scaffolding, 2018-2020.
 */
/**
 * Indicates Scaffolding orientation (how scaffolds are drawn on the plot)
 * @enum {string}
 */

import { fabric } from "fabric";
import { v1 as uuidv1 } from "uuid";
import {
  zoomInAndOut,
  debugconsole,
  modnp,
  rotatecoords,
  findClosePoints,
  findClosePointToSeq,
} from "./commonfabric";

// Adapted from
//https://stackoverflow.com/questions/2752725/finding-whether-a-point-lies-inside-a-rectangle-or-not
// For fabric.js

function pointInRectangle(m, r) {
  var AB = vector(r.tl, r.bl);
  var AM = vector(r.tl, m);
  var BC = vector(r.bl, r.br);
  var BM = vector(r.bl, m);
  var dotABAM = dot(AB, AM);
  var dotABAB = dot(AB, AB);
  var dotBCBM = dot(BC, BM);
  var dotBCBC = dot(BC, BC);
  return (
    0 <= dotABAM && dotABAM <= dotABAB && 0 <= dotBCBM && dotBCBM <= dotBCBC
  );
}

function vector(p1, p2) {
  return {
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  };
}

function dot(u, v) {
  return u.x * v.x + u.y * v.y;
}

// Checks if two lines intersect. Adapted from:
// https://stackoverflow.com/questions/9043805/test-if-two-lines-intersect-javascript-function

// returns true iff the line from (a,b)->(c,d) intersects with (p,q)->(r,s)
function intersects(a, b, c, d, p, q, r, s) {
  var det, gamma, lambda;
  det = (c - a) * (s - q) - (r - p) * (d - b);
  if (det === 0) {
    return false;
  } else {
    lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
    gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
}

function intersectpoints(line1point1, line1point2, line2point1, line2point2) {
  return intersects(
    line1point1.x,
    line1point1.y,
    line1point2.x,
    line1point2.y,
    line2point1.x,
    line2point1.y,
    line2point2.x,
    line2point2.y
  );
}

function lineIntersectsRectangle(linepoint1, linepoint2, rectangle) {
  if (pointInRectangle(linepoint1, rectangle)) {
    debugconsole(["A", linepoint1, linepoint2, rectangle]);
    return true;
  }
  if (pointInRectangle(linepoint2, rectangle)) {
    debugconsole(["B", linepoint1, linepoint2, rectangle]);
    return true;
  }
  if (intersectpoints(linepoint1, linepoint2, rectangle.tl, rectangle.tr)) {
    debugconsole(["C", linepoint1, linepoint2, rectangle]);
    return true;
  }
  if (intersectpoints(linepoint1, linepoint2, rectangle.tr, rectangle.br)) {
    debugconsole(["D", linepoint1, linepoint2, rectangle]);
    return true;
  }
  if (intersectpoints(linepoint1, linepoint2, rectangle.br, rectangle.bl)) {
    debugconsole(["E", linepoint1, linepoint2, rectangle]);
    return true;
  }
  if (intersectpoints(linepoint1, linepoint2, rectangle.bl, rectangle.tl)) {
    debugconsole(["B", linepoint1, linepoint2, rectangle]);
    return true;
  }
  return false;
}

function altLineIntersectRectangle(linepoints, rectangle) {
  let linepoint1 = { x: linepoints[0], y: linepoints[1] };
  let linepoint2 = { x: linepoints[2], y: linepoints[3] };
  return lineIntersectsRectangle(linepoint1, linepoint2, rectangle);
}

function jsonifypairpoints(point1, point2) {
  return JSON.stringify([
    { x: point1.xpos, y: point1.ypos },
    { x: point2.xpos, y: point2.ypos },
  ]);
}

function transformAnglePoint(
  basepoint,
  xdisp,
  ydisp,
  angle,
  scale,
  transmatrix
) {
  let [displaceX, displaceY] = rotatecoords(xdisp, ydisp, angle, scale);
  let displacePoint = fabric.util.transformPoint(
    new fabric.Point(basepoint.x + displaceX, basepoint.y + displaceY),
    transmatrix
  );
  return displacePoint;
}

/* For orientation of scaffold bays. */

const scaffoldorient = {
  /** Bays at the top; hopups at the bottom. */
  TOP: "Top",
  /** Bays at the bottom; hopups at the top. */
  BOTTOM: "Bottom",
  /** Bays on the left; hopups on the right. */
  LEFT: "Left",
  /** Bays on the right; hopups on the left. */
  RIGHT: "Right",
};

/* For adding extra bays from a basis point. */

const extrabaydir = {
  /** Adding bay on top of previous one. */
  ADDTOP: "ADDTOP",
  /** Adding bay on bottom of previous one. */
  ADDBOTTOM: "ADDBOTTOM",
  /** Adding bay on left of previous one. */
  ADDLEFT: "ADDLEFT",
  /** Adding bay on right of previous one. */
  ADDRIGHT: "ADDRIGHT",
};

/* For labels for adding left and right corner boards. */

const addleftcornerboardlabel = {
  /** Bays at the top; hopups at the bottom. */
  Top: "Number of corner boards (←)",
  /** Bays at the bottom; hopups at the top. */
  Bottom: "Number of corner boards (→)",
  /** Bays on the left; hopups on the right. */
  Left: "Number of corner boards (↓)",
  /** Bays on the right; hopups on the left. */
  Right: "Number of corner boards (↑)",
};

const addrightcornerboardlabel = {
  /** Bays at the top; hopups at the bottom. */
  Top: "Number of corner boards (→)",
  /** Bays at the bottom; hopups at the top. */
  Bottom: "Number of corner boards (←)",
  /** Bays on the left; hopups on the right. */
  Left: "Number of corner boards (↑)",
  /** Bays on the right; hopups on the left. */
  Right: "Number of corner boards (↓)",
};

/**
 * Scaffolding ties (are they drawn at all or not?)
 * @enum {string}
 */
const scaffoldties = {
  /** Yes - on the left. */
  YL: "YL",
  /** Yes - on the right. */
  YR: "YR",
  /** No. */
  NO: "NO",
  /** Yes - on both. Purely for testing. */
  YB: "YB",
};

/**
 * Stair orientation - how do you draw stairs on a diagram.
 * @enum {string}
 */

const stairorient = {
  /** Clockwise - horizonal. */
  CH: "CH",
  /** Anticlockwise - horizontal */
  AH: "AH",
  /** Clockwise - vertical. */
  CV: "CV",
  /** Anticlockwise - vertical */
  AV: "AV",
};

/**
 * Height group subtypes.
 * @enum {string}
 */

const heightgroupsubtype = {
  /** Construction stair. */
  CS: "CS",
  /** Stretcher stair. */
  SS: "SS",
  /** Loading bay. */
  LB: "LB",
  /** Buttress bay. */
  BB: "BB",
  /** Ladder Bay */
  LA: "LA",
  /** Elevation Plan - Left*/
  EPL: "EPL",
  /** Elevation Plan - Right*/
  EPR: "EPR",
};

/**
 * Scaffold brands.
 * @enum {string}
 */

const scaffoldbrand = {
  /** Kwikstage. */
  KWIKSTAGE: "KWIKSTAGE",
  /** Atpac. */
  ATPAC: "ATPAC",
};

/* These constants are used for drawing objects on the diagram.  */

const defaultScale = 12.5;
const defaultBoardWidth = 240;
const defaultGap = 150;
const ledgerLineWidth = 3;
const elevLedgerLineWidth = 4;
const boardSeperatorWidth = 2;
const tieLineWidth = 2;
const tieEdgeRatio = 0.075;
const theFontSize = 20;
const indicFontSize = 16;
const indicHeightWidth = 487.6;
const tieWedgeWidth = 20;
const tieWedgeHeight = 10;
const tubeWidth = 90;
const tubeColour = "orange";
const faceBraceGapBScale = 182.85;
const faceBraceLineWidth = 3;
const maincolour = "black";
const boardlinecolour = "maroon";
const lapcolour = "grey";
const heightIndRadius = 60;
const heightindbgnd = "white";
const heightindborder = "purple";
const stoppingtransomcolour = "indigo";
const tiecolour = "red"; //'blue';
const buttressbground = "rgba(127, 127, 127, 0.5)";
const ladderbaybground = "rgba(0, 127, 127, 0.5)";
const facebracecolour = "lightgreen";
const invisible = "rgba(0, 0, 0, 0)";
const indivselectcolour = "cyan";
const indivselectfill = "rgba(255, 255, 0, 0.2)";
const defaultFontFamily = "sans-serif";
const ladderLineWidth = 4.5;
const ladderRungWidth = 200;

// Set the object so that there is no stretch that removes proportionality.

const retainProport = { mb: false, mt: false, mr: false, ml: false };

// Set the object so that there is no stretching that resizes. */

const noStretch = { tl: false, tr: false, bl: false, br: false };

// Set the object so all fabric.js controls are invisible.

const invisibleControls = {
  mb: false,
  mt: false,
  mr: false,
  ml: false,
  tl: false,
  tr: false,
  bl: false,
  br: false,
  mtr: false,
};

// For loading bays.

const loadingBaySize = 2438;
const boardDivide = 10;
const stretDivide = 5;
const mainTransomSize = 1270;
const numMidBoardsStairs = 7;

// For magnetism. Based on work from http://jsfiddle.net/gcollect/FD53A/

const edgedetection = 40;

// We need to be a certain distance in each group to link standard with standard
// and ledger with ledger. This depends with scale. The constant "works"

const magnetscale = 404;

// For magnetic detection, we say something small like 2.5 degrees in difference
// of angles is just enough to have magnetic detection turned on.

const maxanglemagdiff = 2.5;

// This is for better edge detection.

const betteredgedetection = 1500;

// Apparently the quickest way to find the index of the smallest item according to:
// https://devblogs.microsoft.com/oldnewthing/20140526-00/?p=903

function indexOfSmallest(a) {
  var lowest = 0;
  for (var i = 1; i < a.length; i++) {
    if (a[i] < a[lowest]) lowest = i;
  }
  return lowest;
}

function isPoleDetection(activeObject, targ, edgedetection) {
  let activematrix = activeObject.calcTransformMatrix();
  let leftpoleactive = fabric.util.transformPoint(
    new fabric.Point(activeObject.leftpole.left, activeObject.leftpole.top),
    activematrix
  );
  let rightpoleactive = fabric.util.transformPoint(
    new fabric.Point(activeObject.rightpole.left, activeObject.rightpole.top),
    activematrix
  );
  let targmatrix = targ.calcTransformMatrix();
  let leftpoletarg = fabric.util.transformPoint(
    new fabric.Point(targ.leftpole.left, targ.leftpole.top),
    targmatrix
  );
  let rightpoletarg = fabric.util.transformPoint(
    new fabric.Point(targ.rightpole.left, targ.rightpole.top),
    targmatrix
  );
  let vectorDistance;
  let distances = [
    leftpoleactive.distanceFrom(leftpoletarg),
    leftpoleactive.distanceFrom(rightpoletarg),
    rightpoleactive.distanceFrom(leftpoletarg),
    rightpoleactive.distanceFrom(rightpoletarg),
  ];
  let smallestInDistance = indexOfSmallest(distances);
  switch (smallestInDistance) {
    case 0:
      if (leftpoleactive.distanceFrom(leftpoletarg) < edgedetection) {
        vectorDistance = leftpoletarg.subtract(leftpoleactive);
      }
      break;
    case 1:
      if (leftpoleactive.distanceFrom(rightpoletarg) < edgedetection) {
        vectorDistance = rightpoletarg.subtract(leftpoleactive);
      }
      break;
    case 2:
      if (rightpoleactive.distanceFrom(leftpoletarg) < edgedetection) {
        vectorDistance = leftpoletarg.subtract(rightpoleactive);
      }
      break;
    case 3:
      if (rightpoleactive.distanceFrom(rightpoletarg) < edgedetection) {
        vectorDistance = rightpoletarg.subtract(rightpoleactive);
      }
      break;
    default:
      break;
  }
  if (vectorDistance) {
    activeObject.left = activeObject.left + vectorDistance.x;
    activeObject.top = activeObject.top + vectorDistance.y;
    return true;
  }
  return false;
}

/* This is a less invasive pole detection. It just finds the closest poles, if any. It does not move bays. */

function islessInvPoleDetection(bayRun1, bayRun2, edgedetection) {
  let matrixbayRun1 = bayRun1.calcTransformMatrix();
  let leftpolebayRun1 = fabric.util.transformPoint(
    new fabric.Point(bayRun1.leftpole.left, bayRun1.leftpole.top),
    matrixbayRun1
  );
  let rightpolebayRun1 = fabric.util.transformPoint(
    new fabric.Point(bayRun1.rightpole.left, bayRun1.rightpole.top),
    matrixbayRun1
  );

  let matrixbayRun2 = bayRun2.calcTransformMatrix();
  let leftpolebayRun2 = fabric.util.transformPoint(
    new fabric.Point(bayRun2.leftpole.left, bayRun2.leftpole.top),
    matrixbayRun2
  );
  let rightpolebayRun2 = fabric.util.transformPoint(
    new fabric.Point(bayRun2.rightpole.left, bayRun2.rightpole.top),
    matrixbayRun2
  );

  let distances = [
    leftpolebayRun1.distanceFrom(leftpolebayRun2),
    leftpolebayRun1.distanceFrom(rightpolebayRun2),
    rightpolebayRun1.distanceFrom(leftpolebayRun2),
    rightpolebayRun1.distanceFrom(rightpolebayRun2),
  ];
  let smallestDistanceIndex = indexOfSmallest(distances);
  let smallestDistance = distances[smallestDistanceIndex];
  if (smallestDistance <= edgedetection) {
    return smallestDistanceIndex;
  }
  return -1;
}

// Like isPoleDetection, but does the messier business of side pole detection (and moves them!).

function isSidePoleDetection(activeObject, targ, edgedetection) {
  // If there are no bay lengths that match between activeObject and targ, then we
  // immediately return.

  let activeLengths = new Set(activeObject.baylthcollect);
  let targLengths = new Set(targ.baylthcollect);
  let intersection = new Set();
  for (let elem of activeLengths) {
    if (targLengths.has(elem)) {
      intersection.add(elem);
    }
  }

  let iterator1 = intersection.values();
  let minVal = null;

  for (let entry of iterator1) {
    if (entry && (!minVal || entry > minVal)) {
      minVal = entry;
    }
  }

  if (!minVal) {
    return false;
  }

  let activematrix = activeObject.calcTransformMatrix();
  let activeSidePoleCoords = [];
  let activeSidePoleLengths = [];
  let activeSidePoleIndex = 0;
  for (let item of activeObject.hopupside_magnets) {
    if (item) {
      activeSidePoleCoords.push(
        fabric.util.transformPoint(
          new fabric.Point(item.left, item.top),
          activematrix
        )
      );
      activeSidePoleLengths.push(
        activeObject.baylthcollect[activeSidePoleIndex]
      );
    }
    activeSidePoleIndex += 1;
  }
  activeSidePoleIndex = 0;
  for (let item of activeObject.rearside_magnets) {
    if (item) {
      activeSidePoleCoords.push(
        fabric.util.transformPoint(
          new fabric.Point(item.left, item.top),
          activematrix
        )
      );
      activeSidePoleLengths.push(
        activeObject.baylthcollect[activeSidePoleIndex]
      );
    }
    activeSidePoleIndex += 1;
  }
  let targmatrix = targ.calcTransformMatrix();
  let targSidePoleCoords = [];
  let targSidePoleLengths = [];
  let targSidePoleIndex = 0;
  for (let item of targ.hopupside_magnets) {
    if (item) {
      targSidePoleCoords.push(
        fabric.util.transformPoint(
          new fabric.Point(item.left, item.top),
          targmatrix
        )
      );
      targSidePoleLengths.push(targ.baylthcollect[targSidePoleIndex]);
    }
    targSidePoleIndex += 1;
  }
  targSidePoleIndex = 0;
  for (let item of targ.rearside_magnets) {
    if (item) {
      targSidePoleCoords.push(
        fabric.util.transformPoint(
          new fabric.Point(item.left, item.top),
          targmatrix
        )
      );
      targSidePoleLengths.push(targ.baylthcollect[targSidePoleIndex]);
    }
    targSidePoleIndex += 1;
  }

  if (activeSidePoleCoords.length === 0 || targSidePoleCoords.length === 0) {
    return false;
  }

  let smallestActiveIndex = null;
  let smallestTargIndex = null;

  let smallestActiveStartIndex = activeSidePoleLengths.indexOf(minVal);
  let smallestTargStartIndex = targSidePoleLengths.indexOf(minVal);

  let smallestDistance = activeSidePoleCoords[
    smallestActiveStartIndex
  ].distanceFrom(targSidePoleCoords[smallestTargStartIndex]);
  for (let i = 0; i < activeSidePoleCoords.length; i++) {
    for (let j = 0; j < targSidePoleCoords.length; j++) {
      if (activeSidePoleLengths[i] === targSidePoleLengths[j]) {
        let calcDist = activeSidePoleCoords[i].distanceFrom(
          targSidePoleCoords[j]
        );
        if (calcDist <= smallestDistance) {
          smallestDistance = calcDist;
          smallestActiveIndex = i;
          smallestTargIndex = j;
        }
      }
    }
  }
  if (smallestDistance > edgedetection) {
    return false;
  }

  let vectorDistance = targSidePoleCoords[smallestTargIndex].subtract(
    activeSidePoleCoords[smallestActiveIndex]
  );
  if (vectorDistance) {
    activeObject.left = activeObject.left + vectorDistance.x;
    activeObject.top = activeObject.top + vectorDistance.y;
    return true;
  }
  return false;
}

// This non invasive side pole detection shows if targ can be accreted to activeObject
// or not. If possible, it returns an object with two attributes:
// (1) "side" (true if activeObj's hopup side matches targ's rear side; false if
// targ's hopup side matches activeObj's rear side;
// (2) "index" (which bay on activeObj, starting with 0, matches the first bay of
// targ).
// If impossible to accrete, this function returns null.

function isnoninvasiveSidePoleDetection(activeObject, targ, edgedetection) {
  // If the number of bays of targ exceed that of activeObj, we can quickly
  // reject any possibility of accretion.

  if (activeObject.baylthcollect.length < targ.baylthcollect.length) {
    return null;
  }

  // The next stage is to find the hopup and rear coordinates of activeObject
  // (and the lengths).

  let activematrix = activeObject.calcTransformMatrix();
  let activeSidePoleCoords = [];
  let activeSidePoleLengths = [];
  let activeSidePoleIndex = 0;
  for (let item of activeObject.hopupside_magnets) {
    if (item) {
      activeSidePoleCoords.push(
        fabric.util.transformPoint(
          new fabric.Point(item.left, item.top),
          activematrix
        )
      );
      activeSidePoleLengths.push(
        activeObject.baylthcollect[activeSidePoleIndex]
      );
    }
    activeSidePoleIndex += 1;
  }
  activeSidePoleIndex = 0;
  for (let item of activeObject.rearside_magnets) {
    if (item) {
      activeSidePoleCoords.push(
        fabric.util.transformPoint(
          new fabric.Point(item.left, item.top),
          activematrix
        )
      );
      activeSidePoleLengths.push(
        activeObject.baylthcollect[activeSidePoleIndex]
      );
    }
    activeSidePoleIndex += 1;
  }

  // Let us do the same for targ.

  let targmatrix = targ.calcTransformMatrix();
  let targSidePoleCoords = [];
  let targSidePoleLengths = [];
  let targSidePoleIndex = 0;
  for (let item of targ.hopupside_magnets) {
    if (item) {
      targSidePoleCoords.push(
        fabric.util.transformPoint(
          new fabric.Point(item.left, item.top),
          targmatrix
        )
      );
      targSidePoleLengths.push(targ.baylthcollect[targSidePoleIndex]);
    }
    targSidePoleIndex += 1;
  }
  targSidePoleIndex = 0;
  for (let item of targ.rearside_magnets) {
    if (item) {
      targSidePoleCoords.push(
        fabric.util.transformPoint(
          new fabric.Point(item.left, item.top),
          targmatrix
        )
      );
      targSidePoleLengths.push(targ.baylthcollect[targSidePoleIndex]);
    }
    targSidePoleIndex += 1;
  }

  if (activeSidePoleCoords.length === 0 || targSidePoleCoords.length === 0) {
    return null;
  }

  // We now check whether the first bay of targ is closest to anywhere in activeObject.

  let sideValFound = null;
  let indexFound = null;
  let firstTargBayHopupSideMag = targSidePoleCoords[0];
  let firstTargBayRearSideMag = targSidePoleCoords[targ.baylthcollect.length];

  // Now we check if the hopup side of targ intersect the rear size of activeObj.

  for (let i = 0; i < activeObject.baylthcollect.length; i++) {
    let distanceFound = activeSidePoleCoords[
      i + activeObject.baylthcollect.length
    ].distanceFrom(firstTargBayHopupSideMag);
    if (distanceFound < edgedetection) {
      sideValFound = false;
      indexFound = i;
      break;
    }
  }
  // Now we check if the rear side of targ intersect the hopup size of activeObj.

  for (let i = 0; i < activeObject.baylthcollect.length; i++) {
    let distanceFound = activeSidePoleCoords[i].distanceFrom(
      firstTargBayRearSideMag
    );
    if (distanceFound < edgedetection) {
      sideValFound = true;
      indexFound = i;
      break;
    }
  }

  // If no such connections exist, then we return.

  if (sideValFound === null) {
    return null;
  }

  // Now we check if the lengths and magnets match!
  else if (sideValFound) {
    // True : activeObj's hopup side matches targ's rear side
    for (let i = 0; i < targ.baylthcollect.length; i++) {
      let distanceFound = activeSidePoleCoords[indexFound + i].distanceFrom(
        targSidePoleCoords[targ.baylthcollect.length + i]
      );
      if (distanceFound > edgedetection) {
        return null;
      }
      if (
        targSidePoleLengths[targ.baylthcollect.length + i] !==
        activeSidePoleLengths[indexFound + i]
      ) {
        return null;
      }
    }
  } else {
    // sideValFound is false: activeObj rear side matches targ's hopup side.
    for (let i = 0; i < targ.baylthcollect.length; i++) {
      let distanceFound = activeSidePoleCoords[
        activeObject.baylthcollect.length + indexFound + i
      ].distanceFrom(targSidePoleCoords[i]);
      if (distanceFound > edgedetection) {
        return null;
      }
      if (
        targSidePoleLengths[i] !==
        activeSidePoleLengths[
          indexFound + i + activeObject.baylthcollect.length
        ]
      ) {
        return null;
      }
    }
  }

  return { side: sideValFound, index: indexFound };
}

// The following methods are used in collision detection between scaffold runs.
// If they are close enough, they can "stick together".

function isEdgeDetectATRTTL(activeObject, targ, edgedetection) {
  let aTRtTLx = activeObject.oCoords.tr.x - targ.oCoords.tl.x;
  let aTRtTLy = activeObject.oCoords.tr.y - targ.oCoords.tl.y;
  if (Math.sqrt(aTRtTLx * aTRtTLx + aTRtTLy * aTRtTLy) < edgedetection)
    return true;
  return false;
}

function isEdgeDetectATLTTR(activeObject, targ, edgedetection) {
  let aTLtTRx = activeObject.oCoords.tl.x - targ.oCoords.tr.x;
  let aTLtTRy = activeObject.oCoords.tl.y - targ.oCoords.tr.y;
  if (Math.sqrt(aTLtTRx * aTLtTRx + aTLtTRy * aTLtTRy) < edgedetection)
    return true;
  return false;
}

function isEdgeDetectABRTTR(activeObject, targ, edgedetection) {
  let aBRtTRx = activeObject.oCoords.br.x - targ.oCoords.tr.x;
  let aBRtTRy = activeObject.oCoords.br.y - targ.oCoords.tr.y;
  if (Math.sqrt(aBRtTRx * aBRtTRx + aBRtTRy * aBRtTRy) < edgedetection)
    return true;
  return false;
}

function isEdgeDetectATRTBR(activeObject, targ, edgedetection) {
  let aTRtBRx = activeObject.oCoords.tr.x - targ.oCoords.br.x;
  let aTRtBRy = activeObject.oCoords.tr.y - targ.oCoords.br.y;
  if (Math.sqrt(aTRtBRx * aTRtBRx + aTRtBRy * aTRtBRy) < edgedetection)
    return true;
  return false;
}

// We are going to make our own subclassed group for containing Scaffolding
// Bay Runs. This not only allows the object to be serialised and deserialised;
// it also allows things such as face braces and ties to be edited after
// deserialisation.

// This code is adapted from here.
// https://stackoverflow.com/questions/24384804/fabric-js-subclassing-fabric-group-error-cannot-read-property-async-of-und

fabric.ScaffoldGroup = fabric.util.createClass(fabric.Group, {
  type: "scaffoldGroup",

  initialize: function (objects, options) {
    options || (options = {});
    let bayaddorientinit = [];
    if (options && options.baylength - 1) {
      bayaddorientinit = Array(options.baylength - 1).fill(
        extrabaydir.ADDRIGHT
      );
    }
    let extraboards_tops = null;
    if (options.extraboards_tops) {
      extraboards_tops = options.extraboards_tops;
    } else if (options.baylength) {
      extraboards_tops = Array(options.baylength).fill([]);
    }
    let extraboards_bottoms = null;
    if (options.extraboards_bottoms) {
      extraboards_bottoms = options.extraboards_bottoms;
    } else if (options.baylength) {
      extraboards_bottoms = Array(options.baylength).fill([]);
    }
    let hopup_overrides = null;
    if (options.hopup_overrides) {
      hopup_overrides = options.hopup_overrides;
    } else if (options.baylength) {
      hopup_overrides = Array(options.baylength).fill(0);
    }
    let leftcorner_overrides = null;
    if (options.leftcorner_overrides) {
      leftcorner_overrides = options.leftcorner_overrides;
    } else if (options.baylength) {
      leftcorner_overrides = Array(options.baylength).fill(0);
    }
    let rightcorner_overrides = null;
    if (options.rightcorner_overrides) {
      rightcorner_overrides = options.rightcorner_overrides;
    } else if (options.baylength) {
      rightcorner_overrides = Array(options.baylength).fill(0);
    }

    let rearcorner_overrides = null;
    if (options.rearcorner_overrides) {
      rearcorner_overrides = options.rearcorner_overrides;
    } else if (options.baylength) {
      rearcorner_overrides = Array(options.baylength).fill(0);
    }

    // Has internal handrail has three possible states: true, false, and null.
    // If not present, we have it default to true.

    if (typeof options.has_internal_handrail === "undefined") {
      this.set("has_internal_handrail", true);
    } else {
      this.set("has_internal_handrail", options.has_internal_handrail);
    }

    this.callSuper("initialize", objects, options);
    this.set("selectable", options.selectable || true);
    this.set("ismagnetic", options.ismagnetic || false);
    this.set("issidemagnetic", options.issidemagnetic || false);
    this.set("leftbracestore", options.leftbracestore || null);
    this.set("rightbracestore", options.rightbracestore || null);
    this.set("baylength", options.baylength || 0); // Note: number of bays, rather than length of individual bays!
    this.set(
      "bayaddorientations",
      options.bayaddorientations || bayaddorientinit
    );
    this.set("bayinvlength", options.bayinvlength || 0); // Note: length of individual bays!
    this.set("baylthcollect", options.baylthcollect || []); // Collection of lengths of indiv bays.
    this.set("bayorient", options.bayorient || ""); // Note: original orientation - top, left, bottom, right.
    this.set("bayxpos", options.bayxpos || 0); // Note: original x position.
    this.set("bayypos", options.bayypos || 0); // Note: original y position.
    this.set("baywidth", options.baywidth || 0); // Note: width of bays
    this.set("bayhopup", options.bayhopup || 0); // Note: size of hopups.
    this.set("bayleftcorner", options.bayleftcorner || 0); // Note: size of left corner boards.
    this.set("bayrightcorner", options.bayrightcorner || 0); // Note: size of right corner boards.
    this.set("bayrearcorner", options.bayrearcorner || 0); // Note: size of rear corner boards.
    this.set("baygap", options.baygap || 0); // Note: size of gap.
    this.set("bayboardwidth", options.bayboardwidth || 0); // Note: width of boards in hopups and bays.
    this.set("facebracestore", options.facebracestore || []);
    this.set("facebraceshow", options.facebraceshow || 0);
    this.set("lefttielines", options.lefttielines || []);
    this.set("righttielines", options.righttielines || []);
    this.set("lefttietriangles", options.lefttietriangles || []);
    this.set("righttietriangles", options.righttietriangles || []);
    this.set("lefttieindex", options.lefttieindex || 0);
    this.set("righttieindex", options.righttieindex || 0);
    this.set("tiestate", options.tiestate || []);
    this.set("magneticpoles", options.magneticpoles || false);
    this.set("leftpole", options.leftpole || null);
    this.set("rightpole", options.rightpole || null);
    this.set("scaffoldheight", options.scaffoldheight || null);
    this.set("showheight", options.showheight || false);
    this.set("show_internal_handrail", options.show_internal_handrail);
    this.set("no_extra_stars", options.no_extra_stars || 0);
    this.set("circle_heightind", options.circle_heightind || null);
    this.set("rect_heightind", options.rect_heightind || null);
    this.set("rect_heightind_array", options.rect_heightind_array || []);
    this.set("line_heighthoz", options.line_heighthoz || null);
    this.set("line_heightvrt", options.line_heightvrt || null);
    this.set("text_height", options.text_height || null);
    this.set("text_height_alt", options.text_height_alt || null);
    this.set("text_height_alt_array", options.text_height_alt_array || []);
    this.set(
      "text_internal_handrail_alt_array",
      options.text_internal_handrail_alt_array || []
    );
    this.set(
      "line_internal_handrail_alt_array",
      options.line_internal_handrail_alt_array || []
    );
    this.set(
      "rect_internal_handrail_array",
      options.rect_internal_handrail_array || []
    );
    this.set("text_no_extra_stars", options.text_no_extra_stars || null);
    this.set(
      "text_has_internal_handrail",
      options.text_has_internal_handrail || null
    );
    this.set("has_mesh", options.has_mesh || true);
    this.set("text_mesh", options.text_mesh || null);
    this.set("hopup_overrides", hopup_overrides);
    this.set("leftcorner_overrides", leftcorner_overrides);
    this.set("rightcorner_overrides", rightcorner_overrides);
    this.set("rearcorner_overrides", rearcorner_overrides);
    this.set("extraboards_tops", extraboards_tops);
    this.set("extraboards_bottoms", extraboards_bottoms);
    this.set("rect_selection", options.rect_selection || null);
    this.set("rect_selection_index", null); // Never save to/from DB
    this.set("hopupside_magnets", options.hopupside_magnets || []);
    this.set("rearside_magnets", options.rearside_magnets || []);
    this.set("tubeids", options.tubeids || []);
    this.set("tubecoords", options.tubecoords || []);
    this.set("tubearray", options.tubearray || []);
    this.set("scaffoldtype", options.scaffoldtype || scaffoldbrand.KWIKSTAGE);
  },

  toObject: function () {
    let selectionObject = this.rect_selection;
    if (selectionObject) {
      this.remove(selectionObject);
    }
    let finalObject = fabric.util.object.extend(this.callSuper("toObject"), {
      selectable: this.get("selectable"),
      ismagnetic: this.get("ismagnetic"),
      issidemagnetic: this.get("issidemagnetic"),
      leftbracestore: this.get("leftbracestore"),
      rightbracestore: this.get("rightbracestore"),
      baylength: this.get("baylength"),
      bayaddorientations: this.get("bayaddorientations"),
      bayinvlength: this.get("bayinvlength"),
      baylthcollect: this.get("baylthcollect"),
      bayorient: this.get("bayorient"),
      bayxpos: this.get("bayxpos"),
      bayypos: this.get("bayypos"),
      baywidth: this.get("baywidth"),
      bayhopup: this.get("bayhopup"),
      bayleftcorner: this.get("bayleftcorner"),
      bayrightcorner: this.get("bayrightcorner"),
      bayrearcorner: this.get("bayrearcorner"),
      baygap: this.get("baygap"),
      bayboardwidth: this.get("bayboardwidth"),
      facebracestore: this.get("facebracestore"),
      facebraceshow: this.get("facebraceshow"),
      lefttielines: this.get("lefttielines"),
      righttielines: this.get("righttielines"),
      lefttietriangles: this.get("lefttietriangles"),
      righttietriangles: this.get("righttietriangles"),
      lefttieindex: this.get("lefttieindex"),
      righttieindex: this.get("righttieindex"),
      tiestate: this.get("tiestate"),
      magneticpoles: this.get("magneticpoles"),
      leftpole: this.get("leftpole"),
      rightpole: this.get("rightpole"),
      scaffoldheight: this.get("scaffoldheight"),
      showheight: this.get("showheight"),
      show_internal_handrail: this.get("show_internal_handrail"),
      has_internal_handrail: this.get("has_internal_handrail"),
      no_extra_stars: this.get("no_extra_stars"),
      circle_heightind: this.get("circle_heightind"),
      rect_heightind: this.get("rect_heightind"),
      rect_heightind_array: this.get("rect_heightind_array"),
      line_heighthoz: this.get("line_heighthoz"),
      line_heightvrt: this.get("line_heightvrt"),
      text_height: this.get("text_height"),
      text_height_alt: this.get("text_height_alt"),
      text_height_alt_array: this.get("text_height_alt_array"),
      text_internal_handrail_alt_array: this.get(
        "text_internal_handrail_alt_array"
      ),
      line_internal_handrail_alt_array: this.get(
        "line_internal_handrail_alt_array"
      ),
      text_no_extra_stars: this.get("text_no_extra_stars"),
      text_has_internal_handrail: this.get("text_has_internal_handrail"),
      has_mesh: this.get("has_mesh"),
      text_mesh: this.get("text_mesh"),
      hopup_overrides: this.get("hopup_overrides"),
      leftcorner_overrides: this.get("leftcorner_overrides"),
      rightcorner_overrides: this.get("rightcorner_overrides"),
      rearcorner_overrides: this.get("rearcorner_overrides"),
      extraboards_tops: this.get("extraboards_tops"),
      extraboards_bottoms: this.get("extraboards_bottoms"),
      hopupside_magnets: this.get("hopupside_magnets"),
      rearside_magnets: this.get("rearside_magnets"),
      tubeids: this.get("tubeids"),
      tubecoords: this.get("tubecoords"),
      tubearray: [], //this.get("tubearray"), We don't serialize tubes per-se.
      scaffoldtype: this.get("scaffoldtype"),
    });
    if (selectionObject) {
      this.add(selectionObject);
    }
    return finalObject;
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },

  /* Bays can be in many different orientations; this gets the "corrected"
   angle based on its orientation.
  */

  getCorrectedAngle() {
    let correctedangle = modnp(this.angle, 360);
    if (this.bayorient === "Left") {
      correctedangle = (correctedangle + 270) % 360;
    } else if (this.bayorient === "Right") {
      correctedangle = (correctedangle + 90) % 360;
    } else if (this.bayorient === "Bottom") {
      correctedangle = (correctedangle + 180) % 360;
    }
    return correctedangle;
  },

  /* And sometimes one wants it when the angle of the bay is factored in. */

  getUncorrectedAngle() {
    if (this.bayorient === "Left") {
      return 90;
    } else if (this.bayorient === "Right") {
      return 270;
    } else if (this.bayorient === "Bottom") {
      return 180;
    } else {
      return 0;
    }
  },

  getrelativePositionFromPole(point, trueifleftpole) {
    if (trueifleftpole) {
      return {
        x: point.x - this.leftpole.left,
        y: point.y - this.leftpole.top,
      };
    } else {
      return {
        x: point.x - this.rightpole.left,
        y: point.y - this.rightpole.top,
      };
    }
  },

  translaterelativePositionToPole(point, trueifleftpole) {
    if (trueifleftpole) {
      return {
        x: point.x + this.leftpole.left,
        y: point.y + this.leftpole.top,
      };
    } else {
      return {
        x: point.x + this.rightpole.left,
        y: point.y + this.rightpole.top,
      };
    }
  },

  findBoundRect(buildsc) {
    let thiscalctrans = this.calcTransformMatrix();
    let returnmap = {};
    let totalbaylength = this.baylthcollect.reduce((a, b) => a + b, 0);
    let maxextraboardstop = 0;
    for (let i = 0; i < this.extraboards_tops.length; i++) {
      let topdistance = this.extraboards_tops[i].reduce((a, b) => a + b, 0);
      if (this.rearcorner_overrides[i] > this.bayrearcorner) {
        topdistance += this.rearcorner_overrides[i] - this.bayrearcorner;
      }
      maxextraboardstop = Math.max(maxextraboardstop, topdistance);
    }
    let maxextraboardsbottom = 0;
    for (let i = 0; i < this.extraboards_bottoms.length; i++) {
      let bottomdistance = this.extraboards_bottoms[i].reduce(
        (a, b) => a + b,
        0
      );
      if (this.hopup_overrides[i] > this.bayhopup) {
        bottomdistance += this.hopup_overrides[i] - this.bayhopup;
      }
      maxextraboardsbottom = Math.max(maxextraboardsbottom, bottomdistance);
    }

    let desiredwidth =
      totalbaylength + (this.bayleftcorner + this.bayrightcorner);
    let desiredheight =
      this.bayrearcorner +
      this.bayhopup +
      this.baywidth +
      maxextraboardstop +
      maxextraboardsbottom;

    let leftpointcentre = new fabric.Point(
      this.leftpole.left,
      this.leftpole.top
    );

    returnmap["tl"] = transformAnglePoint(
      leftpointcentre,
      -this.bayleftcorner,
      -(this.baywidth * 0.5 + this.bayrearcorner + maxextraboardstop),
      this.getUncorrectedAngle(),
      buildsc,
      thiscalctrans
    );
    returnmap["tr"] = transformAnglePoint(
      leftpointcentre,
      desiredwidth - this.bayleftcorner,
      -(this.baywidth * 0.5 + this.bayrearcorner + maxextraboardstop),
      this.getUncorrectedAngle(),
      buildsc,
      thiscalctrans
    );

    returnmap["bl"] = transformAnglePoint(
      leftpointcentre,
      -this.bayleftcorner,
      desiredheight -
        (this.baywidth * 0.5 + this.bayrearcorner + maxextraboardstop),
      this.getUncorrectedAngle(),
      buildsc,
      thiscalctrans
    );
    returnmap["br"] = transformAnglePoint(
      leftpointcentre,
      desiredwidth - this.bayleftcorner,
      desiredheight -
        (this.baywidth * 0.5 + this.bayrearcorner + maxextraboardstop),
      this.getUncorrectedAngle(),
      buildsc,
      thiscalctrans
    );
    return returnmap;
  },

  betterfindBoundRect(buildsc) {
    let thiscalctrans = this.calcTransformMatrix();
    let returnmap = {};
    let totalbaylength = this.baylthcollect.reduce((a, b) => a + b, 0);

    let leftpointcentre = new fabric.Point(
      this.leftpole.left,
      this.leftpole.top
    );

    returnmap["tl"] = transformAnglePoint(
      leftpointcentre,
      -this.bayleftcorner,
      -(this.baywidth * 0.5 + this.bayrearcorner),
      this.getUncorrectedAngle(),
      buildsc,
      thiscalctrans
    );

    returnmap["tr"] = transformAnglePoint(
      leftpointcentre,
      totalbaylength + this.bayrightcorner,
      -(this.baywidth * 0.5 + this.bayrearcorner),
      this.getUncorrectedAngle(),
      buildsc,
      thiscalctrans
    );

    returnmap["bl"] = transformAnglePoint(
      leftpointcentre,
      -this.bayleftcorner,
      this.baywidth * 0.5 + this.bayhopup,
      this.getUncorrectedAngle(),
      buildsc,
      thiscalctrans
    );

    returnmap["br"] = transformAnglePoint(
      leftpointcentre,
      totalbaylength + this.bayrightcorner,
      this.baywidth * 0.5 + this.bayhopup,
      this.getUncorrectedAngle(),
      buildsc,
      thiscalctrans
    );

    let maxextraboardstop = 0;
    let maxextraboardstops = [];
    for (let i = 0; i < this.extraboards_tops.length; i++) {
      let topdistance = this.extraboards_tops[i].reduce((a, b) => a + b, 0);
      if (this.rearcorner_overrides[i] > this.bayrearcorner) {
        topdistance += this.rearcorner_overrides[i] - this.bayrearcorner;
      }
      maxextraboardstops.push(topdistance);
      maxextraboardstop = Math.max(maxextraboardstop, topdistance);
    }
    let maxextraboardsbottom = 0;
    let maxextraboardsbottoms = [];
    for (let i = 0; i < this.extraboards_bottoms.length; i++) {
      let bottomdistance = this.extraboards_bottoms[i].reduce(
        (a, b) => a + b,
        0
      );
      if (this.hopup_overrides[i] > this.bayhopup) {
        bottomdistance += this.hopup_overrides[i] - this.bayhopup;
      }
      maxextraboardsbottoms.push(bottomdistance);
      maxextraboardsbottom = Math.max(maxextraboardsbottom, bottomdistance);
    }
    let extrarectanglewidths = [];
    for (let i = 0; i < this.baylthcollect.length; i++) {
      extrarectanglewidths.push(this.baylthcollect[i]);
    }
    extrarectanglewidths[0] += this.bayleftcorner;
    extrarectanglewidths[this.baylthcollect.length - 1] += this.bayrightcorner;

    let extraleft = 0;
    let extraarraystop = [];
    let extraarraysbottom = [];
    for (let i = 0; i < extrarectanglewidths.length; i++) {
      if (maxextraboardstops[i]) {
        let topmap = {};

        topmap["tl"] = transformAnglePoint(
          leftpointcentre,
          -this.bayleftcorner + extraleft,
          -(this.baywidth * 0.5 + this.bayrearcorner) - maxextraboardstops[i],
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        );

        topmap["tr"] = transformAnglePoint(
          leftpointcentre,
          -this.bayleftcorner + extraleft + extrarectanglewidths[i],
          -(this.baywidth * 0.5 + this.bayrearcorner) - maxextraboardstops[i],
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        );

        topmap["bl"] = transformAnglePoint(
          leftpointcentre,
          -this.bayleftcorner + extraleft,
          -(this.baywidth * 0.5 + this.bayrearcorner),
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        );

        topmap["br"] = transformAnglePoint(
          leftpointcentre,
          -this.bayleftcorner + extraleft + extrarectanglewidths[i],
          -(this.baywidth * 0.5 + this.bayrearcorner),
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        );
        extraarraystop.push(topmap);
      }
      if (maxextraboardsbottoms[i]) {
        let bottommap = {};

        bottommap["tl"] = transformAnglePoint(
          leftpointcentre,
          -this.bayleftcorner + extraleft,
          this.baywidth * 0.5 + this.bayhopup,
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        );

        bottommap["tr"] = transformAnglePoint(
          leftpointcentre,
          -this.bayleftcorner + extraleft + extrarectanglewidths[i],
          this.baywidth * 0.5 + this.bayhopup,
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        );

        bottommap["bl"] = transformAnglePoint(
          leftpointcentre,
          -this.bayleftcorner + extraleft,
          this.baywidth * 0.5 + this.bayhopup + maxextraboardsbottoms[i],
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        );

        bottommap["br"] = transformAnglePoint(
          leftpointcentre,
          -this.bayleftcorner + extraleft + extrarectanglewidths[i],
          this.baywidth * 0.5 + this.bayhopup + maxextraboardsbottoms[i],
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        );
        extraarraysbottom.push(bottommap);
      }
      extraleft += extrarectanglewidths[i];
    }

    return [returnmap, ...extraarraystop, ...extraarraysbottom];
  },

  betterFindPointInRect(point, buildsc) {
    if (!point) {
      return false;
    }
    let theseRectangles = this.betterfindBoundRect(buildsc);
    for (let item of theseRectangles) {
      if (pointInRectangle(point, item)) {
        return true;
      }
    }
    return false;
  },

  betterfindBoundStand(buildsc) {
    let thiscalctrans = this.calcTransformMatrix();
    let returnseq = [];

    let leftpointcentre = new fabric.Point(
      this.leftpole.left,
      this.leftpole.top
    );

    let rightpointcentre = new fabric.Point(
      this.rightpole.left,
      this.rightpole.top
    );

    let rectanglewidths = [];
    let sumvaluestart = 0;
    let nobays = this.baylthcollect.length;
    for (let item of this.baylthcollect) {
      sumvaluestart += item;
      rectanglewidths.push(sumvaluestart);
    }

    returnseq.push(
      transformAnglePoint(
        leftpointcentre,
        0,
        -(0.5 * this.baywidth),
        this.getUncorrectedAngle(),
        buildsc,
        thiscalctrans
      )
    );

    let maxextraboardstops = [];
    for (let i = 0; i < nobays; i++) {
      let topdistance = this.extraboards_tops[i].reduce((a, b) => a + b, 0);
      maxextraboardstops.push(topdistance);
    }
    let maxextraboardsbottoms = [];
    for (let i = 0; i < nobays; i++) {
      let bottompdistance = this.extraboards_bottoms[i].reduce(
        (a, b) => a + b,
        0
      );
      maxextraboardsbottoms.push(bottompdistance);
    }

    let initialrises = [];
    let initalrisesum = 0;
    for (let i = 0; i < this.extraboards_tops[0].length; i++) {
      initalrisesum -= this.extraboards_tops[0][i];
      initialrises.push(initalrisesum);
    }

    for (let i = 0; i < initialrises.length; i++) {
      returnseq.push(
        transformAnglePoint(
          leftpointcentre,
          0,
          -(0.5 * this.baywidth) + initialrises[i],
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        )
      );
    }

    for (let i = 0; i < nobays - 1; i++) {
      returnseq.push(
        transformAnglePoint(
          leftpointcentre,
          rectanglewidths[i],
          -(0.5 * this.baywidth) - maxextraboardstops[i],
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        )
      );

      if (maxextraboardstops[i] < maxextraboardstops[i + 1]) {
        let basetopx = 0;
        for (let j = 0; j < this.extraboards_tops[i + 1].length; j++) {
          basetopx = basetopx + this.extraboards_tops[i + 1][j];
          if (basetopx > maxextraboardstops[i]) {
            returnseq.push(
              transformAnglePoint(
                leftpointcentre,
                rectanglewidths[i],
                -(0.5 * this.baywidth) - basetopx,
                this.getUncorrectedAngle(),
                buildsc,
                thiscalctrans
              )
            );
          }
        }
      } else if (maxextraboardstops[i] > maxextraboardstops[i + 1]) {
        let basetopx = 0;
        let basereverse = [];
        for (let j = 0; j < this.extraboards_tops[i].length; j++) {
          basetopx = basetopx + this.extraboards_tops[i][j];
          basereverse.push(basetopx);
        }
        basereverse.reverse();
        for (let item of basereverse) {
          if (
            item > maxextraboardstops[i + 1] &&
            item !== maxextraboardstops[i]
          ) {
            returnseq.push(
              transformAnglePoint(
                leftpointcentre,
                rectanglewidths[i],
                -(0.5 * this.baywidth) - item,
                this.getUncorrectedAngle(),
                buildsc,
                thiscalctrans
              )
            );
          }
        }
        returnseq.push(
          transformAnglePoint(
            leftpointcentre,
            rectanglewidths[i],
            -(0.5 * this.baywidth) - maxextraboardstops[i + 1],
            this.getUncorrectedAngle(),
            buildsc,
            thiscalctrans
          )
        );
      }
    }

    let finalrises = [];
    let finalrisesum = -(0.5 * this.baywidth);
    for (let i = 0; i < this.extraboards_tops[nobays - 1].length; i++) {
      finalrisesum -= this.extraboards_tops[nobays - 1][i];
      finalrises.push(finalrisesum);
    }
    for (let item of finalrises.reverse()) {
      returnseq.push(
        transformAnglePoint(
          rightpointcentre,
          0,
          item,
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        )
      );
    }
    returnseq.push(
      transformAnglePoint(
        rightpointcentre,
        0,
        -(0.5 * this.baywidth),
        this.getUncorrectedAngle(),
        buildsc,
        thiscalctrans
      )
    );

    returnseq.push(
      transformAnglePoint(
        rightpointcentre,
        0,
        0.5 * this.baywidth,
        this.getUncorrectedAngle(),
        buildsc,
        thiscalctrans
      )
    );

    let finalfalls = [];
    let finalfallsum = 0.5 * this.baywidth;
    for (let i = 0; i < this.extraboards_bottoms[nobays - 1].length; i++) {
      finalfallsum += this.extraboards_bottoms[nobays - 1][i];
      finalfalls.push(finalfallsum);
    }

    for (let item of finalfalls) {
      returnseq.push(
        transformAnglePoint(
          rightpointcentre,
          0,
          item,
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        )
      );
    }
    for (let i = nobays - 2; i >= 0; i--) {
      returnseq.push(
        transformAnglePoint(
          leftpointcentre,
          rectanglewidths[i],
          0.5 * this.baywidth + maxextraboardsbottoms[i + 1],
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        )
      );

      if (maxextraboardsbottoms[i] > maxextraboardsbottoms[i + 1]) {
        let basetopx = 0;
        for (let j = 0; j < this.extraboards_bottoms[i].length; j++) {
          basetopx = basetopx + this.extraboards_bottoms[i][j];
          if (basetopx > maxextraboardsbottoms[i + 1]) {
            returnseq.push(
              transformAnglePoint(
                leftpointcentre,
                rectanglewidths[i],
                0.5 * this.baywidth + basetopx,
                this.getUncorrectedAngle(),
                buildsc,
                thiscalctrans
              )
            );
          }
        }
      } else if (maxextraboardsbottoms[i + 1] > maxextraboardsbottoms[i]) {
        let basetopx = 0;
        let basereverse = [];
        for (let j = 0; j < this.extraboards_bottoms[i + 1].length; j++) {
          basetopx = basetopx + this.extraboards_bottoms[i + 1][j];
          basereverse.push(basetopx);
        }
        basereverse.reverse();
        for (let item of basereverse) {
          if (
            item > maxextraboardsbottoms[i] &&
            item !== maxextraboardsbottoms[i + 1]
          ) {
            returnseq.push(
              transformAnglePoint(
                leftpointcentre,
                rectanglewidths[i],
                0.5 * this.baywidth + item,
                this.getUncorrectedAngle(),
                buildsc,
                thiscalctrans
              )
            );
          }
        }

        returnseq.push(
          transformAnglePoint(
            leftpointcentre,
            rectanglewidths[i],
            0.5 * this.baywidth + maxextraboardsbottoms[i],
            this.getUncorrectedAngle(),
            buildsc,
            thiscalctrans
          )
        );
      }
    }

    let firstfalls = [];
    let firstfallsum = 0.5 * this.baywidth;
    for (let i = 0; i < this.extraboards_bottoms[0].length; i++) {
      firstfallsum += this.extraboards_bottoms[0][i];
      firstfalls.push(firstfallsum);
    }

    for (let item of firstfalls.reverse()) {
      returnseq.push(
        transformAnglePoint(
          leftpointcentre,
          0,
          item,
          this.getUncorrectedAngle(),
          buildsc,
          thiscalctrans
        )
      );
    }

    returnseq.push(
      transformAnglePoint(
        leftpointcentre,
        0,
        0.5 * this.baywidth,
        this.getUncorrectedAngle(),
        buildsc,
        thiscalctrans
      )
    );

    return returnseq;
  },

  betterfindBoundStandLocal(buildsc) {
    let items = this.betterfindBoundStand(buildsc);
    let matrix = this.calcTransformMatrix();
    let mInverse = fabric.util.invertTransform(matrix);
    let returnItems = [];
    for (let item of items) {
      returnItems.push(fabric.util.transformPoint(item, mInverse));
    }
    return returnItems;
  },

  toggleMagnetic() {
    this.ismagnetic = !this.ismagnetic;
  },

  toggleSideMagnetic() {
    this.issidemagnetic = !this.issidemagnetic;
  },

  toggleLeftBrace: function (canvas) {
    if (this.leftbracestore.braceval) {
      this.leftbracestore.set({ stroke: invisible });
    } else {
      this.leftbracestore.set({ stroke: facebracecolour });
    }
    this.leftbracestore.braceval = !this.leftbracestore.braceval;
    canvas.renderAll();
    zoomInAndOut();
  },

  toggleRightBrace: function (canvas) {
    if (this.rightbracestore.braceval) {
      this.rightbracestore.set({ stroke: invisible });
    } else {
      this.rightbracestore.set({ stroke: facebracecolour });
    }
    this.rightbracestore.braceval = !this.rightbracestore.braceval;
    canvas.renderAll();
    zoomInAndOut();
  },

  /** Toggles individual side braces.
   * @param {number} index The side brace to toggle on and off.
   */

  toggleIndBrace(index) {
    if (this.facebracestore[index].braceval) {
      this.facebracestore[index].set({ stroke: invisible });
    } else {
      this.facebracestore[index].set({ stroke: facebracecolour });
    }
    this.facebracestore[index].braceval = !this.facebracestore[index].braceval;
  },

  /** Sets individual brace.
   * @param {number} index The brace to toggle on and off.
   * @param {boolean} setval True to set on, false to see off.
   */

  setIndBrace(index, setval) {
    if (setval) {
      this.facebracestore[index].set({ stroke: facebracecolour });
      this.facebracestore[index].braceval = true;
    } else {
      this.facebracestore[index].set({ stroke: invisible });
      this.facebracestore[index].braceval = false;
    }
  },

  toggleSideBrace(canvas) {
    for (let i = 0; i < this.baylength; i++) {
      if (i % 3 === this.facebraceshow) {
        this.toggleIndBrace(i);
      }
    }
    canvas.renderAll();
    zoomInAndOut();
  },

  /**
   * Whether to turn on the next set of side bracing on (and this set off).
   */

  rotateSideBrace(canvas) {
    for (let i = 0; i < this.baylength; i++) {
      if (i % 3 === this.facebraceshow) {
        this.toggleIndBrace(i);
      }
    }
    this.facebraceshow = (this.facebraceshow + 1) % 3;
    for (let i = 0; i < this.baylength; i++) {
      if (i % 3 === this.facebraceshow) {
        this.toggleIndBrace(i);
      }
    }
    canvas.renderAll();
    zoomInAndOut();
  },

  /* Adjust location of bay so that the leftpole (or the rightpole) is set to a certain coordinate */

  setPolePos(polepoint, isleft) {
    let currentTransMatrix = this.calcTransformMatrix();
    let abspoint;
    if (isleft) {
      abspoint = fabric.util.transformPoint(
        new fabric.Point(this.leftpole.left, this.leftpole.top),
        currentTransMatrix
      );
    } else {
      abspoint = fabric.util.transformPoint(
        new fabric.Point(this.rightpole.left, this.rightpole.top),
        currentTransMatrix
      );
    }
    let abspointxdiff = polepoint.x - abspoint.x;
    let abspointydiff = polepoint.y - abspoint.y;
    this.set({
      top: this.top + abspointydiff,
      left: this.left + abspointxdiff,
    });
    this.setCoords();
  },

  /* Used for setting objects to a colour. */

  setObjColour(obj, colour) {
    obj.set({ fill: colour });
    obj.set({ stroke: colour });
  },

  /** Toggles individual left tie.
   * @param {number} index The left tie to toggle on and off.
   */

  toggleIndLeftTie(index) {
    switch (this.tiestate[index]) {
      case scaffoldties.NO:
      case scaffoldties.YR:
        this.setObjColour(this.lefttielines[index], tiecolour);
        this.setObjColour(this.lefttietriangles[index], tiecolour);
        break;
      default:
        this.setObjColour(this.lefttielines[index], invisible);
        this.setObjColour(this.lefttietriangles[index], invisible);
        break;
    }
    switch (this.tiestate[index]) {
      case scaffoldties.NO:
        this.tiestate[index] = scaffoldties.YL;
        break;
      case scaffoldties.YR:
        this.tiestate[index] = scaffoldties.YB;
        break;
      case scaffoldties.YB:
        this.tiestate[index] = scaffoldties.YR;
        break;
      default:
        //case scaffoldties.YL:
        this.tiestate[index] = scaffoldties.NO;
        break;
    }
  },

  /** Sets individual left tie.
   * @param {number} index The left tie to toggle on and off.
   * @param {boolean} setval True to set on, false to see off.
   */

  setIndLeftTie(index, setval) {
    if (setval) {
      this.setObjColour(this.lefttielines[index], tiecolour);
      this.setObjColour(this.lefttietriangles[index], tiecolour);
      if (this.tiestate[index] === scaffoldties.NO) {
        this.tiestate[index] = scaffoldties.YL;
      }
      if (this.tiestate[index] === scaffoldties.YR) {
        this.tiestate[index] = scaffoldties.YB;
      }
    } else {
      this.setObjColour(this.lefttielines[index], invisible);
      this.setObjColour(this.lefttietriangles[index], invisible);
      if (this.tiestate[index] === scaffoldties.YB) {
        this.tiestate[index] = scaffoldties.YR;
      }
      if (this.tiestate[index] === scaffoldties.YL) {
        this.tiestate[index] = scaffoldties.NO;
      }
    }
  },

  /** Toggles individual right tie.
   * @param {number} index The right to toggle on and off.
   */

  toggleIndRightTie(index) {
    switch (this.tiestate[index]) {
      case scaffoldties.NO:
      case scaffoldties.YL:
        this.setObjColour(this.righttielines[index], tiecolour);
        this.setObjColour(this.righttietriangles[index], tiecolour);
        break;
      default:
        this.setObjColour(this.righttielines[index], invisible);
        this.setObjColour(this.righttietriangles[index], invisible);
        break;
    }
    switch (this.tiestate[index]) {
      case scaffoldties.NO:
        this.tiestate[index] = scaffoldties.YR;
        break;
      case scaffoldties.YR:
        this.tiestate[index] = scaffoldties.NO;
        break;
      case scaffoldties.YB:
        this.tiestate[index] = scaffoldties.YL;
        break;
      default:
        //case scaffoldties.YL:
        this.tiestate[index] = scaffoldties.YB;
        break;
    }
  },

  /**
   * Whether to toggle left ties. By default, one in two left ties are on.
   */

  toggleLeftTies(canvas) {
    for (let i = 0; i < this.baylength; i++) {
      if (i % 2 === this.lefttieindex) {
        if (this.tiestate[i] === "NO" || this.tiestate[i] === "YR") {
          this.setIndLeftTie(i, true);
        } else {
          this.setIndLeftTie(i, false);
        }
      }
    }
    canvas.renderAll();
    zoomInAndOut();
  },

  /**
   * Whether to toggle right ties. By default, one in two left ties are on.
   */

  toggleRightTies(canvas) {
    for (let i = 0; i < this.baylength; i++) {
      if (i % 2 === this.righttieindex) {
        this.toggleIndRightTie(i);
      }
    }
    canvas.renderAll();
    zoomInAndOut();
  },

  /**
   * Whether to rotate left ties.
   */

  rotateLeftTies(canvas) {
    let originalState = this.tiestate[this.lefttieindex];
    let turnon = originalState === "YL" || originalState === "YB";
    this.lefttieindex = (this.lefttieindex + 1) % 2;
    for (let i = 0; i < this.baylength; i++) {
      if (i % 2 === this.lefttieindex && turnon) {
        this.setIndLeftTie(i, true);
      } else {
        this.setIndLeftTie(i, false);
      }
    }
    canvas.renderAll();
    zoomInAndOut();
  },

  /**
   * Whether to rotate right ties.
   */

  rotateRightTies(canvas) {
    this.righttieindex = (this.righttieindex + 1) % 2;
    for (let i = 0; i < this.baylength; i++) {
      this.toggleIndRightTie(i);
    }
    canvas.renderAll();
    zoomInAndOut();
  },

  setExtraStars(canvas, no_extra_stars) {
    if (this.text_no_extra_stars) {
      this.no_extra_stars = no_extra_stars;
      this.text_no_extra_stars.set("text", no_extra_stars.toString());
      canvas.renderAll();
      zoomInAndOut();
    }
  },

  /**
   * Code called to automatically move scaffold bays on "magnetism".
   * Returns true if current bay moved via magnetism, false otherwise.
   */

  moveMagnetic(canvas, buildsc) {
    if (!this.ismagnetic && !this.issidemagnetic) {
      return false;
    }

    // This handles side magnetism - comes back later. issidemagnetic

    this.setCoords();
    let that = this;
    canvas.forEachObject(function (targ) {
      let activeObject = canvas.getActiveObject();
      if (targ === activeObject) {
        return false;
      }

      // We also ignore collisions with non-bays.

      if (targ.type !== "scaffoldGroup") {
        return false;
      }

      // And ignore collisions with bays that are not magnetic.

      if (!targ.ismagnetic && !targ.issidemagnetic) {
        return false;
      }

      // We have the broad based "proximity" magnetism contrasted with
      // the "magnetic poles" magnetism. Let them ignore each other.

      if (that.magneticpoles !== targ.magneticpoles) {
        return false;
      }

      // There's no magnetism between bays of different angles .

      if (
        Math.abs((that.getCorrectedAngle() - targ.getCorrectedAngle()) % 360) >
        maxanglemagdiff
      ) {
        return false;
      }

      let scalingfactor = magnetscale / buildsc;

      // For "proximity" magnetism.

      if (
        !that.magneticpoles &&
        !targ.isContainedWithinObject(that) &&
        !targ.intersectsWithObject(that) &&
        !that.isContainedWithinObject(targ && that.baywidth === targ.baywidth)
      ) {
        if (isEdgeDetectATRTTL(activeObject, targ, edgedetection)) {
          activeObject.left = targ.left - activeObject.width + scalingfactor;
          return true;
        }
        if (isEdgeDetectATLTTR(activeObject, targ, edgedetection)) {
          activeObject.left = targ.left + targ.width - scalingfactor;
          return true;
        }
        if (isEdgeDetectABRTTR(activeObject, targ, edgedetection)) {
          activeObject.top = targ.top - activeObject.height + scalingfactor;
          return true;
        }
        if (isEdgeDetectATRTBR(activeObject, targ, edgedetection)) {
          activeObject.top = targ.top + targ.height - scalingfactor;
          return true;
        }
      } else {
        activeObject.setCoords();
        targ.setCoords();
        if (
          activeObject.ismagnetic &&
          targ.ismagnetic &&
          that.baywidth === targ.baywidth &&
          isPoleDetection(activeObject, targ, betteredgedetection / buildsc)
        ) {
          return true;
        }
        if (
          activeObject.issidemagnetic &&
          targ.issidemagnetic &&
          isSidePoleDetection(activeObject, targ, betteredgedetection / buildsc)
        ) {
          return true;
        }
      }
      return false;
    });
  },
});

fabric.ScaffoldGroup.fromObject = function (object, callback) {
  fabric.util.enlivenObjects(object.objects, function (enlivenedObjects) {
    delete object.objects;
    if (callback) {
      var baylength = object.baylength;
      var heightCircle, heightHozLine, heightVertLine;
      var heightTextHeight,
        heightTextNoStars,
        heightTextInternalHandrail,
        heightTextMesh;
      var heightRect, heightTextAlt;
      var leftStoppingTransom, rightStoppingTransom;
      var sideBraceArray = new Array(baylength).fill(null);
      var leftTieLineArray = new Array(baylength).fill(null);
      var rightTieLineArray = new Array(baylength).fill(null);
      var leftTieTriangleArray = new Array(baylength).fill(null);
      var rightTieTriangleArray = new Array(baylength).fill(null);
      var textHeightAltArray = new Array(baylength).fill(null);
      var heightRectArray = new Array(baylength).fill(null);
      var textIntHandRailAltArray = new Array(baylength).fill(null);
      var lineIntHandRailAltArray = new Array(baylength).fill(null);
      var hopupMagnetArray = new Array(baylength).fill(null);
      var rearMagnetArray = new Array(baylength).fill(null);

      var newScaffoldGroup = new fabric.ScaffoldGroup(enlivenedObjects, object);
      let desiredIndex;
      for (let i = 0; i < enlivenedObjects.length; i++) {
        if (enlivenedObjects[i].type === "braceLine") {
          if (enlivenedObjects[i].bracetype === "L") {
            var theLeftBraceStore = enlivenedObjects[i];
          } else if (enlivenedObjects[i].bracetype === "R") {
            var theRightBraceStore = enlivenedObjects[i];
          } else {
            desiredIndex = enlivenedObjects[i].braceindex;
            sideBraceArray[desiredIndex] = enlivenedObjects[i];
          }
        }
        if (enlivenedObjects[i].type === "tieLine") {
          desiredIndex = enlivenedObjects[i].tieindex;
          if (enlivenedObjects[i].tietype === "L") {
            leftTieLineArray[desiredIndex] = enlivenedObjects[i];
          } else if (enlivenedObjects[i].tietype === "R") {
            rightTieLineArray[desiredIndex] = enlivenedObjects[i];
          }
        }
        if (enlivenedObjects[i].type === "tieTriangle") {
          desiredIndex = enlivenedObjects[i].tieindex;
          if (enlivenedObjects[i].tietype === "L") {
            leftTieTriangleArray[desiredIndex] = enlivenedObjects[i];
          } else if (enlivenedObjects[i].tietype === "R") {
            rightTieTriangleArray[desiredIndex] = enlivenedObjects[i];
          }
        }
        if (enlivenedObjects[i].type === "heightCircle") {
          heightCircle = enlivenedObjects[i];
        }
        if (enlivenedObjects[i].type === "heightRect") {
          if (enlivenedObjects[i].heightindex === null) {
            heightRect = enlivenedObjects[i];
          } else {
            heightRectArray[enlivenedObjects[i].heightindex] =
              enlivenedObjects[i];
          }
        }
        if (enlivenedObjects[i].type === "heightOutlineLine") {
          if (enlivenedObjects[i].heightoutlinetype === "H") {
            heightHozLine = enlivenedObjects[i];
          }
          if (enlivenedObjects[i].heightoutlinetype === "V") {
            heightVertLine = enlivenedObjects[i];
          }
        }
        if (enlivenedObjects[i].type === "heightIndicText") {
          if (enlivenedObjects[i].heightindictype === "H") {
            heightTextHeight = enlivenedObjects[i];
          } else if (enlivenedObjects[i].heightindictype === "S") {
            heightTextNoStars = enlivenedObjects[i];
          } else if (enlivenedObjects[i].heightindictype === "I") {
            heightTextInternalHandrail = enlivenedObjects[i];
          } else if (enlivenedObjects[i].heightindictype === "M") {
            heightTextMesh = enlivenedObjects[i];
          } else if (enlivenedObjects[i].heightindictype === "A") {
            heightTextAlt = enlivenedObjects[i];
          } else if (enlivenedObjects[i].heightindictype === "X") {
            textHeightAltArray[enlivenedObjects[i].heightindex] =
              enlivenedObjects[i];
          } else if (enlivenedObjects[i].heightindictype === "Q") {
            textIntHandRailAltArray[enlivenedObjects[i].heightindex] =
              enlivenedObjects[i];
          }
        }
        if (enlivenedObjects[i].type === "stoppingTransomLine") {
          if (enlivenedObjects[i].stoppingtransomtype === "L") {
            leftStoppingTransom = enlivenedObjects[i];
          }
          if (enlivenedObjects[i].stoppingtransomtype === "R") {
            rightStoppingTransom = enlivenedObjects[i];
          }
          if (enlivenedObjects[i].stoppingtransomtype === "I") {
            lineIntHandRailAltArray[enlivenedObjects[i].internalhandrailindex] =
              enlivenedObjects[i];
          }
        }

        if (enlivenedObjects[i].type === "magneticCircle") {
          if (enlivenedObjects[i].magnetictype === "H") {
            hopupMagnetArray[enlivenedObjects[i].magneticindex] =
              enlivenedObjects[i];
          }
          if (enlivenedObjects[i].magnetictype === "R") {
            rearMagnetArray[enlivenedObjects[i].magneticindex] =
              enlivenedObjects[i];
          }
        }
      }
      callback(newScaffoldGroup);
      newScaffoldGroup.leftbracestore = theLeftBraceStore;
      newScaffoldGroup.rightbracestore = theRightBraceStore;
      newScaffoldGroup.facebracestore = new Array(baylength).fill(null);
      newScaffoldGroup.lefttielines = new Array(baylength).fill(null);
      newScaffoldGroup.righttielines = new Array(baylength).fill(null);
      newScaffoldGroup.lefttietriangles = new Array(baylength).fill(null);
      newScaffoldGroup.righttietriangles = new Array(baylength).fill(null);
      newScaffoldGroup.rect_heightind_array = new Array(baylength).fill(null);
      newScaffoldGroup.circle_heightind = heightCircle;
      newScaffoldGroup.rect_heightind = heightRect;
      newScaffoldGroup.line_heighthoz = heightHozLine;
      newScaffoldGroup.line_heightvrt = heightVertLine;
      newScaffoldGroup.text_height = heightTextHeight;
      newScaffoldGroup.text_no_extra_stars = heightTextNoStars;
      newScaffoldGroup.text_has_internal_handrail = heightTextInternalHandrail;
      newScaffoldGroup.text_mesh = heightTextMesh;
      newScaffoldGroup.line_leftstoppingtransom = leftStoppingTransom;
      newScaffoldGroup.line_rightstoppingtransom = rightStoppingTransom;
      newScaffoldGroup.text_height_alt = heightTextAlt;
      newScaffoldGroup.text_height_alt_array = new Array(baylength).fill(null);
      newScaffoldGroup.text_internal_handrail_alt_array = new Array(
        baylength
      ).fill(null);
      newScaffoldGroup.line_internal_handrail_alt_array = new Array(
        baylength
      ).fill(null);
      newScaffoldGroup.hopupside_magnets = new Array(baylength).fill(null);
      newScaffoldGroup.rearside_magnets = new Array(baylength).fill(null);

      for (let i = 0; i < baylength; i++) {
        newScaffoldGroup.facebracestore[i] = sideBraceArray[i];
        newScaffoldGroup.lefttielines[i] = leftTieLineArray[i];
        newScaffoldGroup.righttielines[i] = rightTieLineArray[i];
        newScaffoldGroup.lefttietriangles[i] = leftTieTriangleArray[i];
        newScaffoldGroup.righttietriangles[i] = rightTieTriangleArray[i];
        newScaffoldGroup.text_height_alt_array[i] = textHeightAltArray[i];
        newScaffoldGroup.text_internal_handrail_alt_array[i] =
          textIntHandRailAltArray[i];
        newScaffoldGroup.line_internal_handrail_alt_array[i] =
          lineIntHandRailAltArray[i];
        newScaffoldGroup.rect_heightind_array[i] = heightRectArray[i];
        newScaffoldGroup.hopupside_magnets[i] = hopupMagnetArray[i];
        newScaffoldGroup.rearside_magnets[i] = rearMagnetArray[i];
      }

      // Always remember to keep no stretch on reloading
      newScaffoldGroup.setControlsVisibility(retainProport);
      newScaffoldGroup.setControlsVisibility(noStretch);
    }
  });
};

fabric.ScaffoldGroup.async = true;

// We are also going to make a subclassed group for other objects (like buttress
// bays and stretcher stairs) where the user can specify scaffolding height,
// without the full complexity of a scaffolding group.
// This information needs to be serialised and deserialised as well.

fabric.HeightGroup = fabric.util.createClass(fabric.Group, {
  type: "heightGroup",

  initialize: function (objects, options) {
    options || (options = {});
    this.callSuper("initialize", objects, options);
    this.set("subtype", options.subtype || "");
    this.set("scaffoldheight", options.scaffoldheight || null);
    this.set("showheight", options.showheight || false);
    this.set("objlength", options.objlength || null);
    this.set("objwidth", options.objwidth || false);
    this.set("circle_heightind", options.circle_heightind || null);
    this.set("text_height", options.text_height || null);
    this.set("text_height_alt", options.text_height_alt || null);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      subtype: this.get("subtype"),
      scaffoldheight: this.get("scaffoldheight"),
      showheight: this.get("showheight"),
      objlength: this.get("objlength"),
      objwidth: this.get("objwidth"),
      circle_heightind: this.get("circle_heightind"),
      rect_heightind: this.get("rect_heightind"),
      text_height: this.get("text_height"),
      text_height_alt: this.get("text_height_alt"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },

  hideShowLabels: function (labelsubtype) {
    let labelcount = this.getObjects().filter(function (x) {
      return x.type === "labelText" && x.labeltype.search(labelsubtype) === 0;
    });
    if (labelcount.length) {
      let fillcolour = labelcount[0].fill;
      for (let item of labelcount) {
        if (fillcolour === "black") {
          item.set({ fill: invisible });
        } else {
          item.set({ fill: "black" });
        }
      }
    }
  },
});

fabric.HeightGroup.fromObject = function (object, callback) {
  fabric.util.enlivenObjects(object.objects, function (enlivenedObjects) {
    delete object.objects;
    if (callback) {
      var heightCircle;
      var heightRect;
      var heightTextHeight;
      var heightTextAlt;
      var newHeightGroup = new fabric.HeightGroup(enlivenedObjects, object);
      for (let i = 0; i < enlivenedObjects.length; i++) {
        if (enlivenedObjects[i].type === "heightCircle") {
          heightCircle = enlivenedObjects[i];
        }
        if (enlivenedObjects[i].type === "heightRect") {
          heightRect = enlivenedObjects[i];
        }
        if (enlivenedObjects[i].type === "heightIndicText") {
          if (enlivenedObjects[i].heightindictype === "H") {
            heightTextHeight = enlivenedObjects[i];
          }
          if (enlivenedObjects[i].heightindictype === "A") {
            heightTextAlt = enlivenedObjects[i];
          }
        }
      }
      callback(newHeightGroup);
      newHeightGroup.circle_heightind = heightCircle;
      newHeightGroup.rect_heightind = heightRect;
      newHeightGroup.text_height = heightTextHeight;
      newHeightGroup.text_height_alt = heightTextAlt;
      newHeightGroup.setControlsVisibility(retainProport);
      newHeightGroup.setControlsVisibility(noStretch);
    }
  });
};

fabric.HeightGroup.async = true;

// The store group is for objects (Laps and Ladder Beams)
// where we need to record what they are, but otherwise
// don't need to keep much information for them.

fabric.StoreGroup = fabric.util.createClass(fabric.Group, {
  type: "storeGroup",

  initialize: function (objects, options) {
    options || (options = {});
    this.callSuper("initialize", objects, options);
    this.set("subtype", options.subtype || null);
    this.set("objlength", options.objlength || null);
    this.set("labelid", options.labelid || null);
    this.set("noattachments", options.noattachments || 0);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      subtype: this.get("subtype"),
      objlength: this.get("objlength"),
      labelid: this.get("labelid"),
      noattachments: this.get("noattachments"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.StoreGroup.fromObject = function (object, callback) {
  fabric.util.enlivenObjects(object.objects, function (enlivenedObjects) {
    delete object.objects;
    if (callback) {
      var newStoreGroup = new fabric.StoreGroup(enlivenedObjects, object);
      callback(newStoreGroup);
      newStoreGroup.setControlsVisibility(retainProport);
      if (newStoreGroup.subtype === "LADDER") {
        newStoreGroup.setControlsVisibility(noStretch);
      }
    }
  });
};

fabric.StoreGroup.async = true;

// The text box group is basically for editable text boxes.

fabric.TextboxGroup = fabric.util.createClass(fabric.Group, {
  type: "textboxGroup",

  initialize: function (objects, options) {
    options || (options = {});
    this.callSuper("initialize", objects, options);
    this.set("textheightreal", options.textheightreal || null);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      textheightreal: this.get("textheightreal"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.TextboxGroup.fromObject = function (object, callback) {
  fabric.util.enlivenObjects(object.objects, function (enlivenedObjects) {
    delete object.objects;
    if (callback) {
      var newTextboxGroup = new fabric.TextboxGroup(enlivenedObjects, object);
      callback(newTextboxGroup);
      newTextboxGroup.setControlsVisibility(retainProport);
    }
  });
};

fabric.TextboxGroup.async = true;

// We also need to create a class for serializing/desearializing brace lines.

fabric.BraceLine = fabric.util.createClass(fabric.Line, {
  type: "braceLine",

  initialize: function (points, options) {
    options || (options = {});
    this.callSuper("initialize", points, options);
    this.set("bracetype", options.bracetype || null);
    this.set("braceindex", options.braceindex || 0);
    this.set("braceval", options.braceval || false);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      bracetype: this.get("bracetype"),
      braceindex: this.get("braceindex"),
      braceval: this.get("braceval"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.BraceLine.fromObject = function (object, callback) {
  callback &&
    callback(
      new fabric.BraceLine([object.x1, object.y1, object.x2, object.y2], object)
    );
};

// Because of Fabric.JS's utterly way of handling groups inside groups, we seem
// to need to handle a class for containing tie lines.

fabric.TieLine = fabric.util.createClass(fabric.Line, {
  type: "tieLine",

  initialize: function (points, options) {
    options || (options = {});
    this.callSuper("initialize", points, options);
    this.set("tietype", options.tietype || null);
    this.set("tieindex", options.tieindex || 0);
    this.set("tieval", options.tieval || false);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      tietype: this.get("tietype"),
      tieindex: this.get("tieindex"),
      tieval: this.get("tieval"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.TieLine.fromObject = function (object, callback) {
  callback &&
    callback(
      new fabric.TieLine([object.x1, object.y1, object.x2, object.y2], object)
    );
};

// And tie triangles.

fabric.TieTriangle = fabric.util.createClass(fabric.Triangle, {
  type: "tieTriangle",

  initialize: function (options) {
    options || (options = {});
    this.callSuper("initialize", options);
    this.set("tietype", options.tietype || null);
    this.set("tieindex", options.tieindex || 0);
    this.set("tieval", options.tieval || false);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      tietype: this.get("tietype"),
      tieindex: this.get("tieindex"),
      tieval: this.get("tieval"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.TieTriangle.fromObject = function (object, callback) {
  callback && callback(new fabric.TieTriangle(object));
};

// We now have a particular class for height displaying circles.

fabric.HeightCircle = fabric.util.createClass(fabric.Circle, {
  type: "heightCircle",

  initialize: function (options) {
    options || (options = {});
    this.callSuper("initialize", options);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {});
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.HeightCircle.fromObject = function (object, callback) {
  callback && callback(new fabric.HeightCircle(object));
};

// Special adaptations of circles to be magnetic side poles.

fabric.MagneticCircle = fabric.util.createClass(fabric.Circle, {
  type: "magneticCircle",

  initialize: function (options) {
    options || (options = {});
    this.callSuper("initialize", options);
    var magnetictoReturn;
    if (options.magneticindex >= 0) {
      magnetictoReturn = options.magneticindex;
    } else {
      magnetictoReturn = null;
    }
    this.set("magneticindex", magnetictoReturn);
    var magneticTypeToReturn = null;
    if (
      !(
        typeof options.magnetictype === "undefined" ||
        options.magnetictype === null
      )
    ) {
      magneticTypeToReturn = options.magnetictype;
    }
    this.set("magnetictype", magneticTypeToReturn);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      magnetictype: this.get("magnetictype"),
      magneticindex: this.get("magneticindex"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.MagneticCircle.fromObject = function (object, callback) {
  callback && callback(new fabric.MagneticCircle(object));
};

// An an alternative one for height displaying rectangle.

fabric.HeightRect = fabric.util.createClass(fabric.Rect, {
  type: "heightRect",

  initialize: function (options) {
    options || (options = {});
    this.callSuper("initialize", options);
    var heightindextoReturn;
    if (options.heightindex >= 0) {
      heightindextoReturn = options.heightindex;
    } else {
      heightindextoReturn = null;
    }
    this.set("heightindex", heightindextoReturn);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      heightindex: this.get("heightindex"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.HeightRect.fromObject = function (object, callback) {
  callback && callback(new fabric.HeightRect(object));
};

// And one for the lines in the circles that are used for outlining text.

fabric.HeightOutlineLine = fabric.util.createClass(fabric.Line, {
  type: "heightOutlineLine",

  initialize: function (points, options) {
    options || (options = {});
    this.callSuper("initialize", points, options);
    this.set("heightoutlinetype", options.heightoutlinetype || null);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      heightoutlinetype: this.get("heightoutlinetype"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.HeightOutlineLine.fromObject = function (object, callback) {
  callback &&
    callback(
      new fabric.HeightOutlineLine(
        [object.x1, object.y1, object.x2, object.y2],
        object
      )
    );
};

// And one for stopping transoms.

fabric.StoppingTransomLine = fabric.util.createClass(fabric.Line, {
  type: "stoppingTransomLine",

  initialize: function (points, options) {
    options || (options = {});
    this.callSuper("initialize", points, options);
    this.set("stoppingtransomtype", options.stoppingtransomtype || null);
    if (
      typeof options.internalhandrailindex === "undefined" ||
      options.internalhandrailindex === null
    ) {
      this.set("internalhandrailindex", null);
    } else {
      this.set("internalhandrailindex", options.internalhandrailindex);
    }
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      stoppingtransomtype: this.get("stoppingtransomtype"),
      internalhandrailindex: this.get("internalhandrailindex"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.StoppingTransomLine.fromObject = function (object, callback) {
  callback &&
    callback(
      new fabric.StoppingTransomLine(
        [object.x1, object.y1, object.x2, object.y2],
        object
      )
    );
};

// And one for indicator text.

fabric.HeightIndicText = fabric.util.createClass(fabric.Text, {
  type: "heightIndicText",

  initialize: function (text, options) {
    options || (options = {});
    this.callSuper("initialize", text, options);
    this.set("heightindictype", options.heightindictype || null);
    var heightindextoReturn;
    if (options.heightindex >= 0) {
      heightindextoReturn = options.heightindex;
    } else {
      heightindextoReturn = null;
    }
    this.set("heightindex", heightindextoReturn);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      heightindictype: this.get("heightindictype"),
      heightindex: this.get("heightindex"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.HeightIndicText.fromObject = function (object, callback) {
  callback && callback(new fabric.HeightIndicText(object.text, object));
};

/* We will create a class for all the text labels in elevation plans. */

fabric.LabelText = fabric.util.createClass(fabric.Text, {
  type: "labelText",

  initialize: function (text, options) {
    options || (options = {});
    this.callSuper("initialize", text, options);
    this.set("labeltype", options.labeltype || null);
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      labeltype: this.get("labeltype"),
    });
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

fabric.LabelText.fromObject = function (object, callback) {
  callback && callback(new fabric.LabelText(object.text, object));
};

/**
 * For handling the geometry of plotting points on the screen. This can get
 * confusing real fast, so lets explain why this class was written.
 *
 * When one graphs points to a screen (or a HTML canvas), one uses two
 * coordinates - x and y. By convention, increasing x goes to the right of the
 * screen, and increasing y goes towards the bottom of the screen.
 *
 * A scaffolding bay plan is drawn in two dimensions - one dimension for the
 * ledgers, and one for the transoms. However, they can be drawn in different
 * orientations: Top, Left, Bottom and Right. So where you draw components on
 * the screen depends on the orientation.
 *
 * The convention (here) is to start at the LC (the leftmost corner), where
 * the boundary of the building meets a point colinear with the "left" transom
 * (left if you are looking outwards from the building). Then the two dimensions
 * are "along" (to the right), and "outwards" from the building.
 *
 * To cut a long story short, this class is for translating from "along",
 * "outwards", and angle: to x and y.
 */

class PointTrans {
  /**
   * @param {number} xpos The x coordinate for the point (in px)
   * @param {number} ypos The y coordinate for the point (in px)
   */
  constructor(xpos, ypos) {
    this.xpos = xpos;
    this.ypos = ypos;
  }

  stringify() {
    return JSON.stringify({
      x: this.xpos,
      y: this.ypos,
    });
  }

  /**
   * Returns the midpoint between two points.
   * @param {PointTrans} point1 The first point.
   * @param {PointTrans} point2 The second point.
   * @return {PointTrans} The midpoint
   */

  static midpoint(point1, point2) {
    return new PointTrans(
      (point1.xpos + point2.xpos) / 2,
      (point1.ypos + point2.ypos) / 2
    );
  }

  /**
   * Translates a point to another point, given the relative geometry and the
   * orientation.
   * @param {number} along The position rightwards along the bay (in px)
   * @param {number} outwards The position outwards across the bay (in px)
   * @param {scaffoldorient} orient The orientation of the bay
   * @return {PointTrans} a new point, translated appropriately from this
   */

  translate(along, outwards, orient) {
    switch (orient) {
      case scaffoldorient.TOP:
        return new PointTrans(this.xpos + along, this.ypos - outwards);
      case scaffoldorient.BOTTOM:
        return new PointTrans(this.xpos - along, this.ypos + outwards);
      case scaffoldorient.LEFT:
        return new PointTrans(this.xpos - outwards, this.ypos - along);
      default:
        // case scaffoldorient.RIGHT:
        return new PointTrans(this.xpos + outwards, this.ypos + along);
    }
  }
}

/**
 * The base class for scaffolding objects. Basically written because the
 * same methods were being used in multiple classes. Written to clean up
 * the code for future extension.
 */

class ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos The x-position coordinate for drawing on canvas.
   * @param {number} ypos The y-position coordinate for drawing on canvas.
   */

  constructor(canv, scale, xpos, ypos) {
    this.canv = canv;
    this.scale = scale;
    this.xpos = xpos;
    this.ypos = ypos;

    // This is where we keep the canvas objects.

    this.group = new fabric.Group([]);
    this.canv.add(this.group);
  }

  /**
   * Draws a pole point on the canvas.
   * @param {PointTrans} theCoord the coordinates for the pole point
   * @return {fabric.Circle} a Fabric.Js object representing the pole point.
   */

  drawpolepoint(theCoord) {
    let ourStandard = new fabric.Circle({
      left: theCoord.xpos,
      top: theCoord.ypos,
      strokeWidth: (ledgerLineWidth * defaultScale) / this.scale,
      radius: (ledgerLineWidth * defaultScale) / this.scale,
      fill: "white",
      stroke: "white",
      originX: "center",
      originY: "center",
    });
    this.group.addWithUpdate(ourStandard);
    return ourStandard;
  }

  /**
   * Draws a side magnet point on the canvas.
   * @param {PointTrans} theCoord the coordinates for the side magnet point
   * @param {string} magnetictype the type of side magnet ("H" for hopup, "R" for rear side)
   * @param {number} magneticindex the index of the bay the side magnet is attached to.
   * @return {fabric.MagneticCircle} a Fabric.Js object representing the side magnet point.
   */

  drawsidemagnet(theCoord, magnetictype, magneticindex) {
    let ourStandard = new fabric.MagneticCircle({
      left: theCoord.xpos,
      top: theCoord.ypos,
      strokeWidth: (ledgerLineWidth * defaultScale) / this.scale,
      radius: (ledgerLineWidth * defaultScale) / this.scale,
      fill: invisible,
      stroke: invisible,
      originX: "center",
      originY: "center",
      magnetictype: magnetictype,
      magneticindex: magneticindex,
    });
    this.group.addWithUpdate(ourStandard);
    return ourStandard;
  }

  /**
   * Draws a standard (as a small circle) on the canvas.
   * @param {PointTrans} theCoord the coordinates for the standard
   * @return {fabric.Circle} a Fabric.Js object representing the standard.
   */

  drawstandard(theCoord) {
    let ourStandard = new fabric.Circle({
      left: theCoord.xpos,
      top: theCoord.ypos,
      strokeWidth: (ledgerLineWidth * defaultScale) / this.scale,
      radius: (2 * ledgerLineWidth * defaultScale) / this.scale,
      fill: "white",
      stroke: maincolour,
      originX: "center",
      originY: "center",
    });
    this.group.addWithUpdate(ourStandard);
    return ourStandard;
  }

  /**
   * Draws a standard (as a small circle) on the canvas once, only when no
   * other standard has been drawn at the same spot.
   * @param {PointTrans} theCoord the coordinates for the standard
   * @param {Set} jsonset a set of JSON strings representing earlier standards.
   * @return {?fabric.Circle} a Fabric.Js object representing the standard (or null if already constructed).
   */

  drawstandardonce(theCoord, jsonset) {
    let fabCircle = null;
    let standardRep = theCoord.stringify();
    if (!jsonset.has(standardRep)) {
      fabCircle = this.drawstandard(theCoord);
      jsonset.add(standardRep);
    }
    return fabCircle;
  }

  /**
   * Draws a line representing a ledger or transom on the canvas.
   * @param {PointTrans} first the coordinates for the start of the line
   * @param {PointTrans} second the coordinates for the end of the line
   * @return {fabric.Line} a Fabric.Js object representing the line.
   */

  drawledgerortransom(first, second) {
    let ourLedgerOrTransom = new fabric.Line(
      [first.xpos, first.ypos, second.xpos, second.ypos],
      {
        fill: maincolour,
        stroke: maincolour,
        strokeWidth: (ledgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourLedgerOrTransom);
    return ourLedgerOrTransom;
  }

  /**
   * Draws a line representing a ledger or transom on the canvas, only when.
   * no other line has been drawn between the same two points
   * @param {PointTrans} first the coordinates for the start of the line
   * @param {PointTrans} second the coordinates for the end of the line
   * @param {Set} jsonset a set of JSON strings representing earlier ledgers and transoms.
   * @return {?fabric.Line} a Fabric.Js object representing the line (or null if already constructed).
   */

  drawledgerortransomonce(first, second, jsonset) {
    let fabLine = null;
    let ledgerortransomRep = jsonifypairpoints(first, second);
    if (!jsonset.has(ledgerortransomRep)) {
      fabLine = this.drawledgerortransom(first, second);
      jsonset.add(ledgerortransomRep);
    }
    return fabLine;
  }

  drawboardline(first, second) {
    let ourBoardLine = new fabric.Line(
      [first.xpos, first.ypos, second.xpos, second.ypos],
      {
        fill: boardlinecolour,
        stroke: boardlinecolour,
        strokeWidth: (boardSeperatorWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourBoardLine);
    return ourBoardLine;
  }

  /**
   * Draws text on a bay on the canvas. This text is the length of the bay.
   * @param {text} text the text to write on the screen.
   * @param {number} xCoord the x-coordinate for the text
   * @param {number} yCoord the y-coordinate for the text
   * @param {number} theAngle the angle (in degrees for the text)
   * @param {text} fillcolour the colour to fill in text (default: black)
   * @return {fabric.Text} a Fabric.Js object representing the text.
   */

  drawtext(text, xCoord, yCoord, theAngle, fillcolour = "black") {
    let ourText = new fabric.Text(text, {
      top: yCoord,
      left: xCoord,
      fontSize: (theFontSize * defaultScale) / this.scale,
      fontFamily: defaultFontFamily,
      angle: theAngle,
      originX: "center",
      originY: "center",
      fill: fillcolour,
    });
    this.group.addWithUpdate(ourText);
    return ourText;
  }

  /**
   * Draws height indicator text on a bay on the canvas.
   * @param {text} text the text to write on the screen.
   * @param {number} xCoord the x-coordinate for the text
   * @param {number} yCoord the y-coordinate for the text
   * @param {number} theAngle the angle (in degrees for the text)
   * @param {text} fillcolour the colour to fill in text (default: black)
   * @param {text} heightindictype the indicator type.
   * @return {fabric.HeightIndicText} a Fabric.Js object representing the text.
   */

  drawheightindictext(
    text,
    xCoord,
    yCoord,
    theAngle,
    fillcolour = "black",
    heightindictype = "",
    heightindex = null
  ) {
    let ourText = new fabric.HeightIndicText(text, {
      top: yCoord,
      left: xCoord,
      fontSize: (indicFontSize * defaultScale) / this.scale,
      fontFamily: defaultFontFamily,
      angle: theAngle,
      originX: "center",
      originY: "center",
      fill: fillcolour,
      heightindictype: heightindictype,
      heightindex: heightindex,
    });
    this.group.addWithUpdate(ourText);
    return ourText;
  }

  /**
   * Draws a rectangle on a bay (as background to the bay length text).
   * @param {fabric.Group} group the group to add the rectangle to.
   * @param {number} XCoord the x coordinate
   * @param {number} YCoord the y coordinate
   * @param {number} widthArg the width of the rectangle
   * @param {number} heightArg the height of the rectangle.
   * @param {string} sidecolour the colour to render for the sides.
   * @param {string} insidecolour the colour inside the rectangle.
   * @param {number} extra extra information to add to the rectangle.
   * @return {fabric.Rect} a Fabric.Js object representing the rectangle.
   */

  drawrect(
    group,
    XCoord,
    YCoord,
    widthArg,
    heightArg,
    sidecolour,
    insidecolour,
    extra = 0
  ) {
    let ourRect = new fabric.Rect({
      left: XCoord,
      top: YCoord,
      originX: "center",
      originY: "center",
      width: widthArg,
      height: heightArg,
      stroke: sidecolour,
      strokeWidth: (boardSeperatorWidth * defaultScale) / this.scale,
      fill: insidecolour,
      transparentCorners: false,
      theExtra: extra,
    });
    group.addWithUpdate(ourRect);
    return ourRect;
  }

  /**
   * Creates a height indicator circle.
   * @param {PointTrans} theCoord The coordinates of the circle centre
   * @param {number|null} scaffoldheight The height of the scaffold in metres (or null for not set)
   * @param {boolean} showheight Whether to show the indicator circle
   * @return {array} The components for the indicator circle.
   */

  drawheightindcircle(theCoord, scaffoldheight = null, showheight = false) {
    let defaultcirclestroke = invisible;
    let defaultcirclefill = invisible;
    let defaulttextfill = invisible;
    if (showheight) {
      defaulttextfill = "black";
      defaultcirclestroke = heightindborder;
      defaultcirclefill = heightindbgnd;
    }

    let ourHeightInd = new fabric.HeightCircle({
      left: theCoord.xpos,
      top: theCoord.ypos,
      strokeWidth: (ledgerLineWidth * defaultScale) / this.scale,
      radius: (heightIndRadius * defaultScale) / this.scale,
      fill: defaultcirclefill,
      stroke: defaultcirclestroke,
      originX: "center",
      originY: "center",
    });
    this.group.addWithUpdate(ourHeightInd);
    this.group.circle_heightind = ourHeightInd;
    let heightDisplay = "-";
    if (scaffoldheight !== null) {
      heightDisplay = scaffoldheight.toString() + "m";
    }
    this.group.text_height = this.drawheightindictext(
      heightDisplay,
      theCoord.xpos,
      theCoord.ypos,
      0,
      defaulttextfill,
      "H"
    );
    return [ourHeightInd, this.group.text_height];
  }

  /**
   * Creates a height indicator rectangle.
   * @param {PointTrans} theCoord The coordinates of the rectangle centre
   * @param {number|null} scaffoldheight The height of the scaffold in metres (or null for not set)
   * @param {boolean} showheight Whether to show the indicator rectangle
   * @param {number} angle The angle to show the text (default 0).
   * @return {array} The components for the indicator rectangle.
   */

  drawheightindrect(
    theCoord,
    scaffoldheight = null,
    showheight = false,
    angle = 0,
    indicator = "A",
    heightindex = null
  ) {
    let defaultrectstroke = invisible;
    let defaultrectfill = invisible;
    let defaulttextfill = invisible;
    if (showheight) {
      defaulttextfill = "black";
      defaultrectstroke = invisible;
      defaultrectfill = invisible;
    }
    let ourHeightInd = new fabric.HeightRect({
      left: theCoord.xpos,
      top: theCoord.ypos,
      width: indicHeightWidth / this.scale,
      height: indicHeightWidth / this.scale,
      strokeWidth: (boardSeperatorWidth * defaultScale) / this.scale,
      fill: defaultrectfill,
      stroke: defaultrectstroke,
      transparentCorners: false,
      originX: "center",
      originY: "center",
      heightindex: heightindex,
    });
    this.group.addWithUpdate(ourHeightInd);
    let heightDisplay = "-";
    if (scaffoldheight !== null) {
      heightDisplay = scaffoldheight.toString() + "m";
    }
    let text_height_alt = this.drawheightindictext(
      heightDisplay,
      theCoord.xpos,
      theCoord.ypos,
      angle,
      defaulttextfill,
      indicator,
      heightindex
    );
    if (indicator === "A") {
      this.group.rect_heightind = ourHeightInd;
      this.group.text_height_alt = text_height_alt;
    } else {
      this.group.rect_heightind_array[heightindex] = ourHeightInd;
      this.group.text_height_alt_array[heightindex] = text_height_alt;
    }
    return [ourHeightInd, text_height_alt];
  }
}

/**
 * For orientable scaffolding objects - can be facing top, left, right or
 * bottom.
 */

class OrientScaffoldingBase extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {scaffoldorient} orient The orientation of the object
   * @param {number} xpos The x-position coordinate for drawing on canvas.
   * @param {number} ypos The y-position coordinate for drawing on canvas.
   */

  constructor(canv, scale, orient, xpos, ypos) {
    super(canv, scale, xpos, ypos);
    this.orient = orient;
  }

  /**
   * A helper function: maps orientation into degrees for printing text
   * @return {number} the angle desired for text in degrees.
   */

  getAngle() {
    switch (this.orient) {
      case scaffoldorient.TOP:
        return 180;
      case scaffoldorient.BOTTOM:
        return 0;
      case scaffoldorient.LEFT:
        return 90;
      default:
        // case scaffoldorient.RIGHT:
        return 270;
    }
  }
}

/**
 * For stair orientable scaffolding objects - can be facing horizontal or
 * vertical, and clockwise or anti-clockwise. Now not relevant.
 */

class StairScaffoldingBase extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {stairorient} orient The orientation of the object
   * @param {number} xpos The x-position coordinate for drawing on canvas.
   * @param {number} ypos The y-position coordinate for drawing on canvas.
   */

  constructor(canv, scale, orient, xpos, ypos) {
    super(canv, scale, xpos, ypos);
    this.orient = orient;
    this.temporient = this.mapOrient(orient);
  }

  /**
   * A helper maps stair orientation into regular orientation.
   * @param {stairorient} orient the stair orientation
   * @return {scaffoldorient} a compatible scaffolding orientation.
   */

  mapOrient(orient) {
    switch (orient) {
      case stairorient.CH:
        return scaffoldorient.TOP;
      case stairorient.AH:
        return scaffoldorient.BOTTOM;
      case stairorient.CV:
        return scaffoldorient.LEFT;
      default:
        // case stairorient.AV:
        return scaffoldorient.RIGHT;
    }
  }

  /**
   * A helper function: maps orientation into degrees for printing text
   * @return {number} the angle desired for text in degrees.
   */

  getAngle() {
    switch (this.temporient) {
      case scaffoldorient.TOP:
        return 180;
      case scaffoldorient.BOTTOM:
        return 0;
      case scaffoldorient.LEFT:
        return 90;
      default:
        // case scaffoldorient.RIGHT:
        return 270;
    }
  }
}

/* For drawing lap board arrows on a diagram. */

class Lap extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos The x-position coordinate on the left.
   * @param {number} ypos The y-position coordinate on the top.
   * @param {number} length The length of the arrow.
   * @param {number} width The width of the arrowhead.
   * @param {number} mainlength The length of centre (exl. arrowhead).
   * @param {number} mainwidth The width of cente (excl. arrorhead).
   * @param {number} angle The angle for drawing the lap.
   */
  constructor(
    canv,
    scale,
    xpos,
    ypos,
    length,
    width,
    mainlength,
    mainwidth,
    angle
  ) {
    super(canv, scale, xpos, ypos);
    this.length = length / scale;
    this.width = width / scale;
    this.mainlength = mainlength / scale;
    this.mainwidth = mainwidth / scale;
    this.angle = angle;

    let existingGroup = this.group;
    this.canv.remove(existingGroup);
    this.group = new fabric.StoreGroup([], {
      objlength: 0,
      subtype: "LAP",
      labelid: null,
      noattachments: 0,
    });
    this.canv.add(this.group);

    // You can stretch, as long as proportionality is kept.

    this.group.setControlsVisibility(retainProport);
    let shapeWidth = (ledgerLineWidth * defaultScale) / this.scale;
    let centreWidth = (this.width - this.mainwidth) / 2;
    let centreLength = (this.length - this.mainlength) / 2;

    this.group.addWithUpdate(
      new fabric.Polygon(
        [
          {
            x: 0,
            y: this.width / 2,
          },
          {
            x: centreLength,
            y: 0,
          },
          {
            x: centreLength,
            y: centreWidth,
          },
          {
            x: this.length - centreLength,
            y: centreWidth,
          },
          {
            x: this.length - centreLength,
            y: 0,
          },
          {
            x: this.length,
            y: this.width / 2,
          },
          {
            x: this.length - centreLength,
            y: this.width,
          },
          {
            x: this.length - centreLength,
            y: this.width - centreWidth,
          },
          {
            x: centreLength,
            y: this.width - centreWidth,
          },
          {
            x: centreLength,
            y: this.width,
          },
        ],
        {
          left: xpos,
          top: ypos,
          stroke: lapcolour,
          strokeWidth: shapeWidth,
          fill: invisible,
          angle: angle,
          originX: "center",
          originY: "center",
        }
      )
    );
    let ourText = new fabric.Text("LAP", {
      left: xpos,
      top: ypos,
      fontSize: this.mainwidth,
      fontFamily: defaultFontFamily,
      angle: angle,
      originX: "center",
      originY: "center",
      fill: lapcolour,
    });
    this.group.addWithUpdate(ourText);
    this.canv.renderAll();
  }
}

/**
 * A class for individual bays in a scaffold bay row. Just for containing,
 * not drawing.
 */

class BayRowItem {
  /**
   * @param {number} length The length of the bay (and its ledger) in mm.
   * @param {scaffoldties} ties What ties there are on the bay, if any.
   * @param {boolean} buttress Whether there is a buttress for the bay.
   * @param {boolean} facebrace Whether there is face bracing for the bay.
   * @param {extrabaydir} baydir The direction to add the ith bay from the i-1th.
   * @param {number} hopup_override The hopup width in mm (for this bay alone)
   * @param {number} leftcorner_override The width of the left corner boards in mm (for this bay alone).
   * @param {number} rightcorner_override The width of the right corner boards in mm (for this bay alone).
   * @param {number} rearcorner_override The width of the rear corner boards in mm (for this bay alone).
   * @param {array<number>} extraboards_top The width of the extra boards above (if any).
   * @param {array<number>} extraboards_bottom The width of the extra boards below (if any).
   * Note: the first extrabaydir element is ignored in practice.
   */

  constructor(
    length,
    ties,
    buttress,
    facebrace = false,
    baydir = extrabaydir.ADDRIGHT,
    hopup_override = 0,
    leftcorner_override = 0,
    rightcorner_override = 0,
    rearcorner_override = 0,
    extraboards_top = [],
    extraboards_bottom = []
  ) {
    this.length = length;
    this.ties = ties;
    this.buttress = buttress;
    this.facebrace = facebrace;
    this.baydir = baydir;
    this.hopup_override = hopup_override;
    this.leftcorner_override = leftcorner_override;
    this.rightcorner_override = rightcorner_override;
    this.rearcorner_override = rearcorner_override;
    this.extraboards_top = extraboards_top;
    this.extraboards_bottom = extraboards_bottom;
  }

  /**
   * Alternative constructor. Takes a comma seperated string of values, and
   * produces a BayRowItem item in return.
   * @param {str} csv The comma seperated list of values.
   * @return {BayRowItem} the returned object.
   */

  static fromString(csv) {
    let csvArray = csv.split(",");
    let ourBayRowItem = new BayRowItem(
      parseInt(csvArray[0]),
      csvArray[1],
      csvArray[2],
      csvArray[3] === "true"
    );
    return ourBayRowItem;
  }
}

/**
 * The main class for drawing scaffolding bays - and runs (scaffolding bay rows)
 * on a HTML5 canvas. Scaffolding bays come in many shapes and sizes, but they
 * are generally defined by:
 *
 * * The length of the ledgers (in mm)
 * * The length of the transoms (in mm)
 * * The total width of the hopups (in mm)
 *
 * However, drawing this on a canvas is a little different, because the units
 * there are in pixels, not mm. So we use scale (measured in mm/px) to indicate
 * the proportions of drawing an object with a certain length on the screen.
 * Basically, you take the length, divide by the scale, and get the output
 * resolution in pixels. For example, when the scale is 10, a 2438 mm ledger
 * would be mapped as a 243.8 (~244) px line on the canvas.
 *
 * The drawing of scaffolding bays are done relative to the leftmost corner (LC)
 * where the boundary of the building meets a point colinear with the left
 * transom. There is a gap then between the building boundary and the nearest
 * hopup and/or ledger. The default gap is 150mm, but this can be changed.
 *
 * Note: the convention for "x" and "y" is the same as for PointTrans. When
 * one graphs points to a screen (or a HTML canvas), one uses two coordinates -
 * x and y. Increasing x means going to the right of the screen, and increasing
 * y means going towards the bottom of the screen.
 */

class BayRow extends OrientScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {scaffoldorient} orient The orientation of the bay
   * @param {number} xpos The x-position coordinate for the LC on canvas.
   * @param {number} ypos The y-position coordinate for the LC on canvas.
   * @param {BayRowItem[]} bayitems The bay row items.
   * @param {number} width The width of the bays (length of transoms) in mm.
   * @param {number} hopup The hopup width in mm. This excludes the hopup gap.
   * @param {number} leftcorner The width of the left corner boards in mm.
   * @param {number} rightcorner The width of the right corner boards in mm.
   * @param {number} rearcorner The width of the rear corner boards in mm.
   * @param {scaffoldbrand} scaffoldtype The type of the scaffold (Kwikstage for default)
   * @param {boolean} magneticpoles True only if there is "new style" magnetic poles.
   * @param {?number} scaffoldheight The height of the scaffold (nullable by default)
   * @param {boolean} showheight Whether to display height and other item information.
   * @param {boolean} has_internal_handrail Is there an internal handrail
   * @param {boolean} show_internal_handrail Whether to show internal handrail information.
   * @param {boolean} has_mesh Is there mesh
   * @param {number} no_extra_stars: Number of extra stars.
   * @param {number} angle: Extra angle to rotate the object (if needed)
   * @param {number} gap The gap between the hopups / ledger and the building.
   * @param {number} boardwidth The width of boards (in mm).
   * @param {boolean} leftbracing True only if there is left bracing.
   * @param {boolean} rightbracing True only if there is right bracing.
   */

  constructor(
    canv,
    scale,
    orient,
    xpos,
    ypos,
    bayitems,
    width,
    hopup,
    leftcorner = 0,
    rightcorner = 0,
    rearcorner = 0,
    scaffoldtype = scaffoldbrand.KWIKSTAGE,
    magneticpoles = true,
    scaffoldheight = null,
    showheight = false,
    has_internal_handrail = true,
    show_internal_handrail = false,
    has_mesh = true,
    no_extra_stars = 0,
    angle = 0,
    gap = defaultGap,
    boardwidth = defaultBoardWidth,
    leftbracing = false,
    rightbracing = false,
    leftstoppingtransom = false,
    rightstoppingtransom = false
  ) {
    super(canv, scale, orient, xpos, ypos);

    // Make this a scaffold group rather than a group. Make sure to remove
    // existing group from canvas, as we want a ScaffoldGroup for its awesome
    // powers of serialization and deserialization.
    let existingGroup = this.group;
    this.canv.remove(existingGroup);

    let bayinvlength = null;
    if (bayitems.length > 0) {
      bayinvlength = bayitems[0].length;
    }

    let bayaddorientations = [];

    for (let i = 1; i < bayitems.length; i++) {
      bayaddorientations.push(bayitems[i].baydir);
    }

    let baylthcollect = [];
    for (let i = 0; i < bayitems.length; i++) {
      baylthcollect.push(bayitems[i].length);
    }

    let bayhopup = hopup;

    let hopup_overrides = [];
    let leftcorner_overrides = [];
    let rightcorner_overrides = [];
    let rearcorner_overrides = [];
    let extraboards_tops = [];
    let extraboards_bottoms = [];

    for (let i = 0; i < bayitems.length; i++) {
      hopup_overrides.push(bayitems[i].hopup_override);
      leftcorner_overrides.push(bayitems[i].leftcorner_override);
      rightcorner_overrides.push(bayitems[i].rightcorner_override);
      rearcorner_overrides.push(bayitems[i].rearcorner_override);
      extraboards_tops.push(bayitems[i].extraboards_top);
      extraboards_bottoms.push(bayitems[i].extraboards_bottom);
    }

    this.group = new fabric.ScaffoldGroup([], {
      bayaddorientations: bayaddorientations,
      baylength: bayitems.length,
      bayinvlength: bayinvlength,
      baylthcollect: baylthcollect,
      bayorient: orient,
      bayxpos: xpos,
      bayypos: ypos,
      baywidth: width,
      bayhopup: bayhopup,
      bayleftcorner: leftcorner,
      bayrightcorner: rightcorner,
      bayrearcorner: rearcorner,
      scaffoldtype: scaffoldtype,
      baygap: gap,
      bayboardwidth: boardwidth,
      magneticpoles: magneticpoles,
      hopup_overrides: hopup_overrides,
      leftcorner_overrides: leftcorner_overrides,
      rightcorner_overrides: rightcorner_overrides,
      rearcorner_overrides: rearcorner_overrides,
      extraboards_tops: extraboards_tops,
      extraboards_bottoms: extraboards_bottoms,
    });
    this.canv.add(this.group);
    this.bayitems = bayitems;
    this.width = width;
    this.leftcorner = leftcorner;
    this.rightcorner = rightcorner;
    this.gap = gap;
    this.boardwidth = boardwidth;
    this.leftbracing = leftbracing;
    this.rightbracing = rightbracing;
    this.leftstoppingtransom = leftstoppingtransom;
    this.rightstoppingtransom = rightstoppingtransom;
    this.scaffoldtype = scaffoldtype;

    // This is where we keep the canvas objects.

    this.baygroupleft = [];
    this.baygroupright = [];

    // Now we start calculate the coordinates for the components.

    let ledgerScaled;
    let transomScaled = width / scale;
    let gapScaled = gap / scale;
    let leftCornerScaled = leftcorner / scale;
    let rightCornerScaled = rightcorner / scale;
    let numLeftCorBoards = Math.floor(leftcorner / boardwidth);
    let leftCornerDivideScaled = leftcorner / (numLeftCorBoards * scale);
    let numRightCorBoards = Math.floor(rightcorner / boardwidth);
    let rightCornerDivideScaled = rightcorner / (numRightCorBoards * scale);

    let rearCornerDivideScaled = 0;
    let rearSize = 0;

    let bayNum = bayitems.length;

    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.

    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);

    // The components we want to represent are stored in arrays

    let actualTopStandards = [];
    let actualBottomStandards = [];

    // This is for extra standards we want to draw above.

    let extraTopStandardsAbove = [];
    let extraBottomStandardsAbove = [];

    // This is for extra standards we want to draw below.

    let extraTopStandardsBelow = [];
    let extraBottomStandardsBelow = [];

    let standardsToPrint = new Set([]);
    let ledgersandtransomsToPrint = new Set([]);

    let rearTransomLedgerPoints = [];

    // We also need to store coordinates for face bracings (if present.)

    let lFaceBraces = [];
    let rFaceBraces = [];

    // We may have separate hopup ends - left and right. These may not be
    // aligned with each other.

    let lHopupEnds = [];
    let rHopupEnds = [];

    // Ditto with rear corner boards - left and right. These may not be
    // aligned with each other.

    let lRearEnds = [];
    let rRearEnds = [];

    // And the boards in each hopup!

    let lBoardHopupCoords = [];
    let rBoardHopupCoords = [];
    let lRearBoardCoords = [];
    let rRearBoardCoords = [];

    // Now we need to calculate the tie positions.

    let tlTies = [];
    let blTies = [];
    let trTies = [];
    let brTies = [];

    // This is for the has internal handrail indicators.

    let lIndic = [];
    let rIndic = [];

    // Make bays "front/back magnetic" by default, but turn off side magnetism.

    this.group.ismagnetic = true;
    this.group.issidemagnetic = false;

    // We need to find the maximum hopup to use as a basis.

    let maxhopup = hopup;

    let tghScaled = (width + maxhopup + gap) / scale;
    let ghScaled = (gap + maxhopup) / scale;
    let baseCorner = new PointTrans(xpos, ypos);

    // Now let us plot the standards.

    let numHopupBoards;
    let hopupDivideScaled;
    let faceBraceGapScaled = faceBraceGapBScale / scale;

    let leftPolePoint, rightPolePoint;

    if (bayNum > 0) {
      leftPolePoint = baseCorner.translate(
        0,
        (tghScaled + ghScaled) / 2,
        this.orient
      );
      this.group.leftpole = this.drawpolepoint(leftPolePoint);
      if (rearcorner > 0) {
        rearTransomLedgerPoints.push(
          baseCorner.translate(0, tghScaled + rearSize, this.orient)
        );
      }
    }

    // let numBayBoards = Math.floor(width / boardwidth);
    // let bayDivideScaled = width / (numBayBoards * scale);
    let boardsAboveLeft = [];
    let boardsAboveRight = [];
    let boardsBelowLeft = [];
    let boardsBelowRight = [];

    /*

    for (let i = 1; i < numBayBoards; i++) {
      for (let j = 0; j < bayNum; j++) {
        lBoardBayCoords.push(
          actualBottomStandards[2 * j].translate(
            0,
            i * bayDivideScaled,
            this.orient
          )
        );
        rBoardBayCoords.push(
          actualBottomStandards[2 * j + 1].translate(
            0,
            i * bayDivideScaled,
            this.orient
          )
        );
      }
    }

*/
    let sumAboves = [];
    let sumBelows = [];
    let leftExtraLedTramLeft = [];
    let leftExtraLedTramRight = [];
    let rightExtraLedTramLeft = [];
    let rightExtraLedTramRight = [];

    for (let i = 0; i < bayNum; i++) {
      let item = bayitems[i];
      ledgerScaled = item.length / scale;
      numHopupBoards = Math.floor(hopup / boardwidth);
      hopupDivideScaled = hopup / (numHopupBoards * scale);

      if (i === 0) {
        actualTopStandards.push(
          baseCorner.translate(0, tghScaled, this.orient)
        );
        actualBottomStandards.push(
          baseCorner.translate(0, ghScaled, this.orient)
        );
        actualTopStandards.push(
          baseCorner.translate(item.length / scale, tghScaled, this.orient)
        );
        actualBottomStandards.push(
          baseCorner.translate(item.length / scale, ghScaled, this.orient)
        );

        // Add code for adding extra bays above and below.

        let sumAbove = 0;
        let sumBelow = 0;

        extraTopStandardsAbove.push([]);
        extraBottomStandardsAbove.push([]);

        for (let j = 0; j < extraboards_tops[0].length; j++) {
          let extraboards_top_val = extraboards_tops[0][j] / scale;
          let numBayBoardsTop = Math.floor(extraboards_tops[0][j] / boardwidth);
          let bayDivideScaledTop =
            extraboards_tops[0][j] / (numBayBoardsTop * scale);

          extraTopStandardsAbove[0].push(
            actualTopStandards[0].translate(
              0,
              extraboards_top_val + sumAbove,
              this.orient
            )
          );
          extraTopStandardsAbove[0].push(
            actualTopStandards[1].translate(
              0,
              extraboards_top_val + sumAbove,
              this.orient
            )
          );
          let BaseBottomAboveLeft = extraTopStandardsAbove[0][2 * j].translate(
            0,
            -extraboards_top_val,
            this.orient
          );

          let BaseBottomAboveRight = extraTopStandardsAbove[0][
            2 * j + 1
          ].translate(0, -extraboards_top_val, this.orient);

          extraBottomStandardsAbove[0].push(BaseBottomAboveLeft);
          extraBottomStandardsAbove[0].push(BaseBottomAboveRight);

          if (rightCornerScaled && bayNum === 1) {
            rightExtraLedTramRight.push(BaseBottomAboveRight);
            rightExtraLedTramLeft.push(
              BaseBottomAboveRight.translate(rightCornerScaled, 0, this.orient)
            );
          }

          if (leftCornerScaled) {
            leftExtraLedTramRight.push(BaseBottomAboveLeft);
            leftExtraLedTramLeft.push(
              BaseBottomAboveLeft.translate(-leftCornerScaled, 0, this.orient)
            );
          }

          for (let k = 1; k < numBayBoardsTop; k++) {
            boardsAboveLeft.push(
              BaseBottomAboveLeft.translate(
                0,
                k * bayDivideScaledTop,
                this.orient
              )
            );
            boardsAboveRight.push(
              BaseBottomAboveRight.translate(
                0,
                k * bayDivideScaledTop,
                this.orient
              )
            );
          }

          sumAbove = sumAbove + extraboards_top_val;
        }
        sumAboves.push(sumAbove);
        extraTopStandardsBelow.push([]);
        extraBottomStandardsBelow.push([]);

        for (let j = 0; j < extraboards_bottoms[0].length; j++) {
          let extraboards_bottom_val = extraboards_bottoms[0][j] / scale;
          let numBayBoardsBottom = Math.floor(
            extraboards_bottoms[0][j] / boardwidth
          );
          let bayDivideScaledBottom =
            extraboards_bottoms[0][j] / (numBayBoardsBottom * scale);

          extraBottomStandardsBelow[0].push(
            actualBottomStandards[0].translate(
              0,
              -extraboards_bottom_val - sumBelow,
              this.orient
            )
          );
          extraBottomStandardsBelow[0].push(
            actualBottomStandards[1].translate(
              0,
              -extraboards_bottom_val - sumBelow,
              this.orient
            )
          );

          let BaseTopBelowLeft = extraBottomStandardsBelow[0][2 * j].translate(
            0,
            extraboards_bottom_val,
            this.orient
          );

          let BaseTopBelowRight = extraBottomStandardsBelow[0][
            2 * j + 1
          ].translate(0, extraboards_bottom_val, this.orient);

          if (leftCornerScaled) {
            leftExtraLedTramRight.push(BaseTopBelowLeft);
            leftExtraLedTramLeft.push(
              BaseTopBelowLeft.translate(-leftCornerScaled, 0, this.orient)
            );
          }

          extraTopStandardsBelow[0].push(BaseTopBelowLeft);
          extraTopStandardsBelow[0].push(BaseTopBelowRight);

          if (rightCornerScaled && bayNum === 1) {
            rightExtraLedTramRight.push(BaseTopBelowRight);
            rightExtraLedTramLeft.push(
              BaseTopBelowRight.translate(rightCornerScaled, 0, this.orient)
            );
          }

          for (let k = 1; k < numBayBoardsBottom; k++) {
            boardsBelowLeft.push(
              BaseTopBelowLeft.translate(
                0,
                -k * bayDivideScaledBottom,
                this.orient
              )
            );
            boardsBelowRight.push(
              BaseTopBelowRight.translate(
                0,
                -k * bayDivideScaledBottom,
                this.orient
              )
            );
          }

          sumBelow = sumBelow + extraboards_bottom_val;
        }
        sumBelows.push(sumBelow);
      } else {
        let lastTop = actualTopStandards[actualTopStandards.length - 1];
        let lastBottom =
          actualBottomStandards[actualBottomStandards.length - 1];
        extraTopStandardsAbove.push([]);
        extraBottomStandardsAbove.push([]);
        extraTopStandardsBelow.push([]);
        extraBottomStandardsBelow.push([]);

        actualTopStandards.push(lastTop);
        actualBottomStandards.push(lastBottom);
        let thisTop = lastTop.translate(item.length / scale, 0, this.orient);
        actualTopStandards.push(thisTop);
        let thisBottom = lastBottom.translate(
          item.length / scale,
          0,
          this.orient
        );
        actualBottomStandards.push(thisBottom);
        let sumAbove = 0;
        let sumBelow = 0;

        for (let j = 0; j < extraboards_tops[i].length; j++) {
          let extraboards_top_val = extraboards_tops[i][j] / scale;
          let numBayBoardsTop = Math.floor(extraboards_tops[i][j] / boardwidth);
          let bayDivideScaledTop =
            extraboards_tops[i][j] / (numBayBoardsTop * scale);
          extraTopStandardsAbove[i].push(
            lastTop.translate(0, extraboards_top_val + sumAbove, this.orient)
          );
          extraTopStandardsAbove[i].push(
            thisTop.translate(0, extraboards_top_val + sumAbove, this.orient)
          );

          let BaseBottomAboveLeft = extraTopStandardsAbove[i][2 * j].translate(
            0,
            -extraboards_top_val,
            this.orient
          );

          let BaseBottomAboveRight = extraTopStandardsAbove[i][
            2 * j + 1
          ].translate(0, -extraboards_top_val, this.orient);

          extraBottomStandardsAbove[i].push(BaseBottomAboveLeft);
          extraBottomStandardsAbove[i].push(BaseBottomAboveRight);

          if (rightCornerScaled && i === bayNum - 1) {
            rightExtraLedTramRight.push(BaseBottomAboveRight);
            rightExtraLedTramLeft.push(
              BaseBottomAboveRight.translate(rightCornerScaled, 0, this.orient)
            );
          }

          for (let k = 1; k < numBayBoardsTop; k++) {
            boardsAboveLeft.push(
              BaseBottomAboveLeft.translate(
                0,
                k * bayDivideScaledTop,
                this.orient
              )
            );
            boardsAboveRight.push(
              BaseBottomAboveRight.translate(
                0,
                k * bayDivideScaledTop,
                this.orient
              )
            );
          }
          sumAbove = sumAbove + extraboards_top_val;
        }
        sumAboves.push(sumAbove);
        for (let j = 0; j < extraboards_bottoms[i].length; j++) {
          let extraboards_bottom_val = extraboards_bottoms[i][j] / scale;
          let numBayBoardsBottom = Math.floor(
            extraboards_bottoms[i][j] / boardwidth
          );
          let bayDivideScaledBottom =
            extraboards_bottoms[i][j] / (numBayBoardsBottom * scale);
          extraBottomStandardsBelow[i].push(
            lastBottom.translate(
              0,
              -extraboards_bottom_val - sumBelow,
              this.orient
            )
          );
          extraBottomStandardsBelow[i].push(
            thisBottom.translate(
              0,
              -extraboards_bottom_val - sumBelow,
              this.orient
            )
          );

          let BaseTopBelowLeft = extraBottomStandardsBelow[i][2 * j].translate(
            0,
            extraboards_bottom_val,
            this.orient
          );

          let BaseTopBelowRight = extraBottomStandardsBelow[i][
            2 * j + 1
          ].translate(0, extraboards_bottom_val, this.orient);

          extraTopStandardsBelow[i].push(BaseTopBelowLeft);
          extraTopStandardsBelow[i].push(BaseTopBelowRight);

          if (rightCornerScaled && i === bayNum - 1) {
            rightExtraLedTramRight.push(BaseTopBelowRight);
            rightExtraLedTramLeft.push(
              BaseTopBelowRight.translate(rightCornerScaled, 0, this.orient)
            );
          }

          for (let k = 1; k < numBayBoardsBottom; k++) {
            boardsBelowLeft.push(
              BaseTopBelowLeft.translate(
                0,
                -k * bayDivideScaledBottom,
                this.orient
              )
            );
            boardsBelowRight.push(
              BaseTopBelowRight.translate(
                0,
                -k * bayDivideScaledBottom,
                this.orient
              )
            );
          }
          sumBelow = sumBelow + extraboards_bottom_val;
        }
        sumBelows.push(sumBelow);
      }

      if (rearcorner > 0 || rearcorner_overrides[i]) {
        let numRearBoards = Math.floor(
          Math.max(rearcorner, rearcorner_overrides[i]) / boardwidth
        );
        rearCornerDivideScaled =
          Math.max(rearcorner, rearcorner_overrides[i]) /
          (numRearBoards * scale);
        rearSize = numRearBoards * rearCornerDivideScaled;
        rearTransomLedgerPoints.push(
          actualTopStandards[2 * i + 1].translate(
            0,
            rearSize + sumAboves[i],
            this.orient
          )
        );

        lRearEnds.push(
          actualTopStandards[2 * i].translate(
            0,
            rearSize + sumAboves[i],
            this.orient
          )
        );
        rRearEnds.push(
          actualTopStandards[2 * i + 1].translate(
            0,
            rearSize + sumAboves[i],
            this.orient
          )
        );

        lRearBoardCoords.push([]);
        rRearBoardCoords.push([]);
        for (let j = 1; j < numRearBoards; j++) {
          lRearBoardCoords[i].push(
            actualTopStandards[2 * i].translate(
              0,
              j * rearCornerDivideScaled + sumAboves[i],
              this.orient
            )
          );
          rRearBoardCoords[i].push(
            actualTopStandards[2 * i + 1].translate(
              0,
              j * rearCornerDivideScaled + sumAboves[i],
              this.orient
            )
          );
        }
      } else {
        lRearBoardCoords.push(null);
        rRearBoardCoords.push(null);
        lRearEnds.push(null);
        rRearEnds.push(null);
      }

      if (hopup > 0 || hopup_overrides[i]) {
        let numHopupBoards = Math.floor(
          Math.max(hopup, hopup_overrides[i]) / boardwidth
        );
        hopupDivideScaled =
          Math.max(hopup, hopup_overrides[i]) / (numHopupBoards * scale);
        let hopupSize = numHopupBoards * hopupDivideScaled;

        lHopupEnds.push(
          actualBottomStandards[2 * i].translate(
            0,
            -hopupSize - sumBelows[i],
            this.orient
          )
        );
        rHopupEnds.push(
          actualBottomStandards[2 * i + 1].translate(
            0,
            -hopupSize - sumBelows[i],
            this.orient
          )
        );
        lBoardHopupCoords.push([]);
        rBoardHopupCoords.push([]);
        for (let j = 1; j < numHopupBoards; j++) {
          lBoardHopupCoords[i].push(
            actualBottomStandards[2 * i].translate(
              0,
              -hopupSize + j * hopupDivideScaled - sumBelows[i],
              this.orient
            )
          );
          rBoardHopupCoords[i].push(
            actualBottomStandards[2 * i + 1].translate(
              0,
              -hopupSize + j * hopupDivideScaled - sumBelows[i],
              this.orient
            )
          );
        }
      } else {
        lHopupEnds.push(null);
        rHopupEnds.push(null);
        lBoardHopupCoords.push(null);
        rBoardHopupCoords.push(null);
      }

      lFaceBraces.push(
        actualTopStandards[2 * i].translate(
          0,
          faceBraceGapScaled + sumAboves[i] + rearSize,
          this.orient
        )
      );
      rFaceBraces.push(
        actualTopStandards[2 * i + 1].translate(
          0,
          faceBraceGapScaled + sumAboves[i] + rearSize,
          this.orient
        )
      );
      let hopupSize;
      if (hopup > 0 || hopup_overrides[i]) {
        let numHopupBoards = Math.floor(
          Math.max(hopup, hopup_overrides[i]) / boardwidth
        );
        hopupDivideScaled =
          Math.max(hopup, hopup_overrides[i]) / (numHopupBoards * scale);
        hopupSize = numHopupBoards * hopupDivideScaled;
      } else {
        hopupSize = 0;
      }
      let tieDisplacement = 0;
      if (extraboards_bottoms[i].length) {
        tieDisplacement =
          sumBelows[i] -
          extraboards_bottoms[i][extraboards_bottoms[i].length - 1] / scale +
          width / scale;
      }
      tlTies.push(
        actualTopStandards[2 * i].translate(
          ledgerScaled * tieEdgeRatio,
          -tieDisplacement,
          this.orient
        )
      );
      blTies.push(
        actualBottomStandards[2 * i].translate(
          ledgerScaled * tieEdgeRatio,
          -hopupSize - gapScaled - sumBelows[i],
          this.orient
        )
      );
      trTies.push(
        actualTopStandards[2 * i + 1].translate(
          -ledgerScaled * tieEdgeRatio,
          -tieDisplacement,
          this.orient
        )
      );
      brTies.push(
        actualBottomStandards[2 * i + 1].translate(
          -ledgerScaled * tieEdgeRatio,
          -hopupSize - gapScaled,
          this.orient
        )
      );

      lIndic.push(
        actualBottomStandards[2 * i].translate(
          ledgerScaled * tieEdgeRatio,
          -hopupSize - gapScaled - sumBelows[i],
          this.orient
        )
      );
      rIndic.push(
        actualBottomStandards[2 * i + 1].translate(
          -ledgerScaled * tieEdgeRatio,
          -hopupSize - gapScaled - sumBelows[i],
          this.orient
        )
      );
    }

    rightPolePoint = actualBottomStandards[2 * bayNum - 1].translate(
      0,
      (tghScaled - ghScaled) / 2,
      this.orient
    );

    this.group.rightpole = this.drawpolepoint(rightPolePoint);
    // This is for the left corner boards (if any)

    let tLeftCorner;
    let bLeftCorner;
    let wLeftCorner;
    let rearLeftCorner;

    // And for the left bracing.

    let tLeftBracing;
    let bLeftBracing;

    if (leftcorner > 0) {
      tLeftCorner = baseCorner.translate(
        -leftCornerScaled,
        tghScaled + sumAboves[0],
        this.orient
      );
      let numRearBoards = Math.floor(
        Math.max(rearcorner, rearcorner_overrides[0]) / boardwidth
      );
      let rearCornerDivideScaled =
        Math.max(rearcorner, rearcorner_overrides[0]) / (numRearBoards * scale);
      rearSize = numRearBoards * rearCornerDivideScaled;
      if (Math.max(rearcorner, rearcorner_overrides[0]) > 0) {
        rearLeftCorner = baseCorner.translate(
          -leftCornerScaled,
          tghScaled + rearSize + sumAboves[0],
          this.orient
        );
      }
      bLeftCorner = baseCorner.translate(
        -leftCornerScaled,
        ghScaled - sumBelows[0],
        this.orient
      );
      tLeftBracing = tLeftCorner.translate(
        -faceBraceGapScaled,
        sumAboves[0],
        this.orient
      );
      bLeftBracing = bLeftCorner.translate(
        -faceBraceGapScaled,
        -sumBelows[0],
        this.orient
      );
      if (bayNum > 0 && Math.max(hopup, hopup_overrides[0]) > 0) {
        let numHopupBoards = Math.floor(
          Math.max(hopup, hopup_overrides[0]) / boardwidth
        );
        hopupDivideScaled =
          Math.max(hopup, hopup_overrides[0]) / (numHopupBoards * scale);
        let hopupSize = numHopupBoards * hopupDivideScaled;
        wLeftCorner = baseCorner.translate(
          -leftCornerScaled,
          ghScaled - hopupSize - sumBelows[0],
          this.orient
        );
      }
    } else {
      tLeftBracing = baseCorner.translate(
        -faceBraceGapScaled,
        tghScaled + sumAboves[0],
        this.orient
      );
      bLeftBracing = baseCorner.translate(
        -faceBraceGapScaled,
        ghScaled - sumBelows[0],
        this.orient
      );
    }

    // This is for the right corner boards (if any)

    let tRightCorner;
    let bRightCorner;
    let wRightCorner;
    let rearRightCorner;

    // And for the right bracing.

    let tRightBracing;
    let bRightBracing;

    if (rightcorner > 0) {
      let numRearBoards = Math.floor(
        Math.max(rearcorner, rearcorner_overrides[bayNum - 1]) / boardwidth
      );
      let rearCornerDivideScaled =
        Math.max(rearcorner, rearcorner_overrides[bayNum - 1]) /
        (numRearBoards * scale);
      rearSize = numRearBoards * rearCornerDivideScaled;

      tRightCorner = actualTopStandards[2 * bayNum - 1].translate(
        rightCornerScaled,
        sumAboves[bayNum - 1],
        this.orient
      );

      if (numRearBoards > 0) {
        rearRightCorner = actualTopStandards[2 * bayNum - 1].translate(
          rightCornerScaled,
          rearSize + sumAboves[bayNum - 1],
          this.orient
        );
      }

      bRightCorner = actualBottomStandards[2 * bayNum - 1].translate(
        rightCornerScaled,
        -sumBelows[bayNum - 1],
        this.orient
      );

      tRightBracing = tRightCorner.translate(
        +faceBraceGapScaled,
        sumAboves[bayNum - 1],
        this.orient
      );
      bRightBracing = bRightCorner.translate(
        +faceBraceGapScaled,
        -sumBelows[bayNum - 1],
        this.orient
      );
      if (bayNum > 0 && Math.max(hopup, hopup_overrides[bayNum - 1]) > 0) {
        let numHopupBoards = Math.floor(
          Math.max(hopup, hopup_overrides[bayNum - 1]) / boardwidth
        );
        hopupDivideScaled =
          Math.max(hopup, hopup_overrides[bayNum - 1]) /
          (numHopupBoards * scale);
        let hopupSize = numHopupBoards * hopupDivideScaled;
        wRightCorner = actualBottomStandards[2 * bayNum - 1].translate(
          rightCornerScaled,
          -hopupSize - sumBelows[bayNum - 1],
          this.orient
        );
      }
    } else {
      tRightBracing = actualTopStandards[2 * bayNum - 1].translate(
        +faceBraceGapScaled,
        sumAboves[bayNum - 1],
        this.orient
      );
      bRightBracing = actualBottomStandards[2 * bayNum - 1].translate(
        +faceBraceGapScaled,
        -sumBelows[bayNum - 1],
        this.orient
      );
    }

    // Now we need to add arrays to contain the start and end coordinates for
    // the left and right corner boards lines.

    let tLeftCornerBoards = [];
    let bLeftCornerBoards = [];
    let tRightCornerBoards = [];
    let bRightCornerBoards = [];

    if (bayNum > 0) {
      for (let i = 1; i < numLeftCorBoards; i++) {
        tLeftCornerBoards.push(
          baseCorner.translate(
            0 - i * leftCornerDivideScaled,
            tghScaled + sumAboves[0],
            this.orient
          )
        );
        bLeftCornerBoards.push(
          baseCorner.translate(
            0 - i * leftCornerDivideScaled,
            ghScaled - sumBelows[0],
            this.orient
          )
        );
      }

      for (let i = 1; i < numRightCorBoards; i++) {
        tRightCornerBoards.push(
          actualTopStandards[2 * bayNum - 1].translate(
            i * rightCornerDivideScaled,
            sumAboves[bayNum - 1],
            this.orient
          )
        );
        bRightCornerBoards.push(
          actualBottomStandards[2 * bayNum - 1].translate(
            i * rightCornerDivideScaled,
            -sumBelows[bayNum - 1],
            this.orient
          )
        );
      }
    }

    // Now we need to add arrays to contain the start and end coordinates for
    // boards lines (in the bays and the hopups).

    // Working out how to divide up the bays and hopups into boards.

    let numBayBoards = Math.floor(width / boardwidth);
    let bayDivideScaled = width / (numBayBoards * scale);
    let lBoardBayCoords = [];
    let rBoardBayCoords = [];

    for (let i = 1; i < numBayBoards; i++) {
      for (let j = 0; j < bayNum; j++) {
        lBoardBayCoords.push(
          actualBottomStandards[2 * j].translate(
            0,
            i * bayDivideScaled,
            this.orient
          )
        );
        rBoardBayCoords.push(
          actualBottomStandards[2 * j + 1].translate(
            0,
            i * bayDivideScaled,
            this.orient
          )
        );
      }
    }

    // Now it is time to plot it!

    // Drawing the ties

    for (let i = 0; i < bayNum; i++) {
      let tiestate = bayitems[i].ties;
      this.group.tiestate.push(tiestate);
      switch (tiestate) {
        case "YL":
        case "YB":
          this.group.lefttielines.push(
            this.drawtieline(blTies[i], tlTies[i], "L", i, true)
          );
          this.group.lefttietriangles.push(
            this.drawtietriangle(blTies[i], tlTies[i], "L", i, true)
          );
          break;
        default:
          this.group.lefttielines.push(
            this.drawtieline(blTies[i], tlTies[i], "L", i, false)
          );
          this.group.lefttietriangles.push(
            this.drawtietriangle(blTies[i], tlTies[i], "L", i, false)
          );
          break;
      }

      switch (tiestate) {
        case "YR":
        case "YB":
          this.group.righttielines.push(
            this.drawtieline(brTies[i], trTies[i], "R", i, true)
          );
          this.group.righttietriangles.push(
            this.drawtietriangle(brTies[i], trTies[i], "R", i, true)
          );
          break;
        default:
          this.group.righttielines.push(
            this.drawtieline(brTies[i], trTies[i], "R", i, false)
          );
          this.group.righttietriangles.push(
            this.drawtietriangle(brTies[i], trTies[i], "R", i, false)
          );
          break;
      }
    }

    for (let i = 0; i < numLeftCorBoards - 1; i++) {
      this.drawboardline(tLeftCornerBoards[i], bLeftCornerBoards[i]);
    }

    for (let i = 0; i < numRightCorBoards - 1; i++) {
      this.drawboardline(tRightCornerBoards[i], bRightCornerBoards[i]);
    }

    // We copy and store the left and right face braces.

    if (this.rightbracing) {
      this.group.rightbracestore = this.drawfacebrace(
        tRightBracing,
        bRightBracing,
        "R",
        null,
        true
      );
    } else {
      this.group.rightbracestore = this.drawfacebrace(
        tRightBracing,
        bRightBracing,
        "R",
        null,
        false
      );
    }

    if (this.leftbracing) {
      this.group.leftbracestore = this.drawfacebrace(
        tLeftBracing,
        bLeftBracing,
        "L",
        null,
        true
      );
    } else {
      this.group.leftbracestore = this.drawfacebrace(
        tLeftBracing,
        bLeftBracing,
        "L",
        null,
        false
      );
    }

    for (let i = 0; i < bayNum; i++) {
      if (lBoardHopupCoords[i] !== null) {
        let hopupCoordsLen = lBoardHopupCoords[i].length;
        for (let j = 0; j < hopupCoordsLen; j++) {
          this.drawboardline(lBoardHopupCoords[i][j], rBoardHopupCoords[i][j]);
        }
      }
    }

    for (let i = 0; i < bayNum; i++) {
      if (lRearBoardCoords[i] !== null) {
        let rearCoordsLen = lRearBoardCoords[i].length;
        for (let j = 0; j < rearCoordsLen; j++) {
          this.drawboardline(lRearBoardCoords[i][j], rRearBoardCoords[i][j]);
        }
      }
    }

    for (let i = 0; i < lBoardBayCoords.length; i++) {
      this.drawboardline(lBoardBayCoords[i], rBoardBayCoords[i]);
    }

    for (let i = 0; i < boardsAboveLeft.length; i++) {
      this.drawboardline(boardsAboveLeft[i], boardsAboveRight[i]);
    }

    for (let i = 0; i < boardsBelowLeft.length; i++) {
      this.drawboardline(boardsBelowLeft[i], boardsBelowRight[i]);
    }

    for (let i = 0; i < bayNum; i++) {
      let sideBrace;
      if (bayitems[i].facebrace) {
        sideBrace = this.drawfacebrace(
          lFaceBraces[i],
          rFaceBraces[i],
          "S",
          i,
          true
        );
      } else {
        sideBrace = this.drawfacebrace(
          lFaceBraces[i],
          rFaceBraces[i],
          "S",
          i,
          false
        );
      }
      this.group.facebracestore.push(sideBrace);

      if (lHopupEnds[i] !== null && rHopupEnds[i] !== null) {
        this.drawledgerortransomonce(
          lHopupEnds[i],
          rHopupEnds[i],
          ledgersandtransomsToPrint
        );
        this.drawledgerortransomonce(
          lHopupEnds[i],
          actualBottomStandards[2 * i],
          ledgersandtransomsToPrint
        );
        this.drawledgerortransomonce(
          actualBottomStandards[2 * i + 1],
          rHopupEnds[i],
          ledgersandtransomsToPrint
        );
      }

      this.drawledgerortransomonce(
        actualTopStandards[2 * i],
        actualTopStandards[2 * i + 1],
        ledgersandtransomsToPrint
      );
      this.drawledgerortransomonce(
        actualBottomStandards[2 * i],
        actualBottomStandards[2 * i + 1],
        ledgersandtransomsToPrint
      );
      this.drawledgerortransomonce(
        actualTopStandards[2 * i],
        actualBottomStandards[2 * i],
        ledgersandtransomsToPrint
      );
      this.drawledgerortransomonce(
        actualTopStandards[2 * i + 1],
        actualBottomStandards[2 * i + 1],
        ledgersandtransomsToPrint
      );

      for (let j = 0; j < extraTopStandardsAbove[i].length / 2; j++) {
        this.drawledgerortransomonce(
          extraTopStandardsAbove[i][2 * j],
          extraTopStandardsAbove[i][2 * j + 1],
          ledgersandtransomsToPrint
        );
        this.drawledgerortransomonce(
          extraBottomStandardsAbove[i][2 * j],
          extraBottomStandardsAbove[i][2 * j + 1],
          ledgersandtransomsToPrint
        );
        this.drawledgerortransomonce(
          extraTopStandardsAbove[i][2 * j],
          extraBottomStandardsAbove[i][2 * j],
          ledgersandtransomsToPrint
        );
        this.drawledgerortransomonce(
          extraTopStandardsAbove[i][2 * j + 1],
          extraBottomStandardsAbove[i][2 * j + 1],
          ledgersandtransomsToPrint
        );
      }

      for (let j = 0; j < extraTopStandardsBelow[i].length / 2; j++) {
        this.drawledgerortransomonce(
          extraTopStandardsBelow[i][2 * j],
          extraTopStandardsBelow[i][2 * j + 1],
          ledgersandtransomsToPrint
        );
        this.drawledgerortransomonce(
          extraBottomStandardsBelow[i][2 * j],
          extraBottomStandardsBelow[i][2 * j + 1],
          ledgersandtransomsToPrint
        );

        this.drawledgerortransomonce(
          extraTopStandardsBelow[i][2 * j],
          extraBottomStandardsBelow[i][2 * j],
          ledgersandtransomsToPrint
        );
        this.drawledgerortransomonce(
          extraTopStandardsBelow[i][2 * j + 1],
          extraBottomStandardsBelow[i][2 * j + 1],
          ledgersandtransomsToPrint
        );
      }

      if (rearcorner > 0 || rearcorner_overrides[i]) {
        if (lRearEnds[i] !== null && rRearEnds[i] !== null) {
          this.drawledgerortransomonce(
            lRearEnds[i],
            rRearEnds[i],
            ledgersandtransomsToPrint
          );
          this.drawledgerortransomonce(
            lRearEnds[i],
            actualTopStandards[2 * i],
            ledgersandtransomsToPrint
          );
          this.drawledgerortransomonce(
            actualTopStandards[2 * i + 1],
            rRearEnds[i],
            ledgersandtransomsToPrint
          );
        }
      }
    }

    if (bayNum > 0) {
      if (leftcorner > 0 && Math.max(rearcorner, rearcorner_overrides[0]) > 0) {
        this.drawledgerortransomonce(
          rearLeftCorner,
          tLeftCorner,
          ledgersandtransomsToPrint
        );
        this.drawledgerortransomonce(
          rearLeftCorner,
          lRearEnds[0],
          ledgersandtransomsToPrint
        );
      }
      if (
        rightcorner > 0 &&
        Math.max(rearcorner, rearcorner_overrides[bayNum - 1]) > 0
      ) {
        this.drawledgerortransomonce(
          rearRightCorner,
          tRightCorner,
          ledgersandtransomsToPrint
        );
        this.drawledgerortransomonce(
          rearRightCorner,
          rRearEnds[bayNum - 1],
          ledgersandtransomsToPrint
        );
      }

      for (let i = 0; i < leftExtraLedTramLeft.length; i++) {
        this.drawledgerortransomonce(
          leftExtraLedTramRight[i],
          leftExtraLedTramLeft[i],
          ledgersandtransomsToPrint
        );
      }

      for (let i = 0; i < rightExtraLedTramLeft.length; i++) {
        this.drawledgerortransomonce(
          rightExtraLedTramRight[i],
          rightExtraLedTramLeft[i],
          ledgersandtransomsToPrint
        );
      }

      if (leftcorner > 0) {
        this.drawledgerortransomonce(
          actualTopStandards[0].translate(0, sumAboves[0], this.orient),
          tLeftCorner,
          ledgersandtransomsToPrint
        );

        this.drawledgerortransomonce(
          actualBottomStandards[0].translate(0, -sumBelows[0], this.orient),
          bLeftCorner,
          ledgersandtransomsToPrint
        );
        this.drawledgerortransomonce(
          bLeftCorner,
          tLeftCorner,
          ledgersandtransomsToPrint
        );
        if (Math.max(hopup, hopup_overrides[0]) > 0) {
          this.drawledgerortransomonce(
            bLeftCorner,
            wLeftCorner,
            ledgersandtransomsToPrint
          );
          this.drawledgerortransomonce(
            lHopupEnds[0],
            wLeftCorner,
            ledgersandtransomsToPrint
          );
        }
      }
      if (rightcorner > 0) {
        this.drawledgerortransomonce(
          actualTopStandards[2 * bayNum - 1].translate(
            0,
            sumAboves[bayNum - 1],
            this.orient
          ),
          tRightCorner,
          ledgersandtransomsToPrint
        );
        this.drawledgerortransomonce(
          actualBottomStandards[2 * bayNum - 1].translate(
            0,
            -sumBelows[bayNum - 1],
            this.orient
          ),
          bRightCorner,
          ledgersandtransomsToPrint
        );

        this.drawledgerortransomonce(
          tRightCorner,
          bRightCorner,
          ledgersandtransomsToPrint
        );
        if (Math.max(hopup, hopup_overrides[bayNum - 1]) > 0) {
          this.drawledgerortransomonce(
            bRightCorner,
            wRightCorner,
            ledgersandtransomsToPrint
          );
          this.drawledgerortransomonce(
            rHopupEnds[bayNum - 1],
            wRightCorner,
            ledgersandtransomsToPrint
          );
        }
      }
    }

    for (let i = 0; i < actualTopStandards.length; i++) {
      this.drawstandardonce(actualTopStandards[i], standardsToPrint);
    }
    for (let i = 0; i < actualBottomStandards.length; i++) {
      this.drawstandardonce(actualBottomStandards[i], standardsToPrint);
    }

    for (let i = 0; i < extraTopStandardsAbove.length; i++) {
      for (let j = 0; j < extraTopStandardsAbove[i].length; j++) {
        this.drawstandardonce(extraTopStandardsAbove[i][j], standardsToPrint);
      }
    }

    for (let i = 0; i < extraTopStandardsBelow.length; i++) {
      for (let j = 0; j < extraTopStandardsBelow[i].length; j++) {
        this.drawstandardonce(extraTopStandardsBelow[i][j], standardsToPrint);
      }
    }

    for (let i = 0; i < extraBottomStandardsAbove.length; i++) {
      for (let j = 0; j < extraBottomStandardsAbove[i].length; j++) {
        this.drawstandardonce(
          extraBottomStandardsAbove[i][j],
          standardsToPrint
        );
      }
    }

    for (let i = 0; i < extraBottomStandardsBelow.length; i++) {
      for (let j = 0; j < extraBottomStandardsBelow[i].length; j++) {
        this.drawstandardonce(
          extraBottomStandardsBelow[i][j],
          standardsToPrint
        );
      }
    }

    for (let i = 0; i < bayNum; i++) {
      let middleRight =
        actualTopStandards[2 * i].xpos + actualBottomStandards[2 * i + 1].xpos;
      let middleDown =
        actualTopStandards[2 * i].ypos + actualBottomStandards[2 * i + 1].ypos;
      ledgerScaled = bayitems[i].length / scale;

      switch (this.orient) {
        case scaffoldorient.TOP:
          this.drawrect(
            this.group,
            middleRight / 2,
            middleDown / 2,
            ledgerScaled / 2,
            transomScaled / 2,
            boardlinecolour,
            "white"
          );
          break;
        case scaffoldorient.BOTTOM:
          this.drawrect(
            this.group,
            middleRight / 2,
            middleDown / 2,
            ledgerScaled / 2,
            transomScaled / 2,
            boardlinecolour,
            "white"
          );
          break;
        case scaffoldorient.LEFT:
          this.drawrect(
            this.group,
            middleRight / 2,
            middleDown / 2,
            transomScaled / 2,
            ledgerScaled / 2,
            boardlinecolour,
            "white"
          );
          break;
        default:
          //case scaffoldorient.RIGHT:
          this.drawrect(
            this.group,
            middleRight / 2,
            middleDown / 2,
            transomScaled / 2,
            ledgerScaled / 2,
            boardlinecolour,
            "white"
          );
          break;
      }

      this.drawtext(
        bayitems[i].length.toString(),
        middleRight / 2,
        middleDown / 2,
        this.getAngle()
      );

      for (let j = 0; j < extraTopStandardsAbove[i].length / 2; j++) {
        let middleRight =
          extraTopStandardsAbove[i][2 * j].xpos +
          extraBottomStandardsAbove[i][2 * j + 1].xpos;
        let middleDown =
          extraTopStandardsAbove[i][2 * j].ypos +
          extraBottomStandardsAbove[i][2 * j + 1].ypos;
        let ledgerScaled = bayitems[i].length / scale;
        let transomScaled =
          extraTopStandardsAbove[i][2 * j].ypos -
          extraBottomStandardsAbove[i][2 * j + 1].ypos;

        switch (this.orient) {
          case scaffoldorient.TOP:
            this.drawrect(
              this.group,
              middleRight / 2,
              middleDown / 2,
              ledgerScaled / 2,
              transomScaled / 2,
              boardlinecolour,
              "white"
            );
            break;
          case scaffoldorient.BOTTOM:
            this.drawrect(
              this.group,
              middleRight / 2,
              middleDown / 2,
              ledgerScaled / 2,
              transomScaled / 2,
              boardlinecolour,
              "white"
            );
            break;
          case scaffoldorient.LEFT:
            transomScaled = Math.abs(
              extraTopStandardsAbove[i][2 * j].xpos -
                extraBottomStandardsAbove[i][2 * j + 1].xpos
            );
            this.drawrect(
              this.group,
              middleRight / 2,
              middleDown / 2,
              transomScaled / 2,
              ledgerScaled / 2,
              boardlinecolour,
              "white"
            );
            break;
          default:
            //case scaffoldorient.RIGHT:
            transomScaled = Math.abs(
              extraTopStandardsAbove[i][2 * j].xpos -
                extraBottomStandardsAbove[i][2 * j + 1].xpos
            );
            this.drawrect(
              this.group,
              middleRight / 2,
              middleDown / 2,
              transomScaled / 2,
              ledgerScaled / 2,
              boardlinecolour,
              "white"
            );
            break;
        }

        this.drawtext(
          bayitems[i].length.toString(),
          middleRight / 2,
          middleDown / 2,
          this.getAngle()
        );
      }

      for (let j = 0; j < extraTopStandardsBelow[i].length / 2; j++) {
        let middleRight =
          extraTopStandardsBelow[i][2 * j].xpos +
          extraBottomStandardsBelow[i][2 * j + 1].xpos;
        let middleDown =
          extraTopStandardsBelow[i][2 * j].ypos +
          extraBottomStandardsBelow[i][2 * j + 1].ypos;
        let ledgerScaled = bayitems[i].length / scale;
        let transomScaled =
          extraBottomStandardsBelow[i][2 * j + 1].ypos -
          extraTopStandardsBelow[i][2 * j].ypos;
        switch (this.orient) {
          case scaffoldorient.TOP:
            this.drawrect(
              this.group,
              middleRight / 2,
              middleDown / 2,
              ledgerScaled / 2,
              transomScaled / 2,
              boardlinecolour,
              "white"
            );
            break;
          case scaffoldorient.BOTTOM:
            this.drawrect(
              this.group,
              middleRight / 2,
              middleDown / 2,
              ledgerScaled / 2,
              transomScaled / 2,
              boardlinecolour,
              "white"
            );
            break;
          case scaffoldorient.LEFT:
            transomScaled = Math.abs(
              extraBottomStandardsBelow[i][2 * j + 1].xpos -
                extraTopStandardsBelow[i][2 * j].xpos
            );
            this.drawrect(
              this.group,
              middleRight / 2,
              middleDown / 2,
              transomScaled / 2,
              ledgerScaled / 2,
              boardlinecolour,
              "white"
            );
            break;
          default:
            //case scaffoldorient.RIGHT:
            transomScaled = Math.abs(
              extraBottomStandardsBelow[i][2 * j + 1].xpos -
                extraTopStandardsBelow[i][2 * j].xpos
            );

            this.drawrect(
              this.group,
              middleRight / 2,
              middleDown / 2,
              transomScaled / 2,
              ledgerScaled / 2,
              boardlinecolour,
              "white"
            );
            break;
        }

        this.drawtext(
          bayitems[i].length.toString(),
          middleRight / 2,
          middleDown / 2,
          this.getAngle()
        );
      }
    }

    if (bayNum > 0) {
      for (let i = 0; i < bayNum; i++) {
        let drawHeightIndicPoint;
        switch (this.orient) {
          case scaffoldorient.TOP:
            drawHeightIndicPoint = new PointTrans(
              (lFaceBraces[i].xpos + rFaceBraces[i].xpos) / 2,
              (lFaceBraces[i].ypos + rFaceBraces[i].ypos) / 2 - gapScaled
            );
            break;
          case scaffoldorient.BOTTOM:
            drawHeightIndicPoint = new PointTrans(
              (lFaceBraces[i].xpos + rFaceBraces[i].xpos) / 2,
              (lFaceBraces[i].ypos + rFaceBraces[i].ypos) / 2 + gapScaled
            );
            break;
          case scaffoldorient.LEFT:
            drawHeightIndicPoint = new PointTrans(
              (lFaceBraces[i].xpos + rFaceBraces[i].xpos) / 2 - gapScaled,
              (lFaceBraces[i].ypos + rFaceBraces[i].ypos) / 2
            );
            break;
          case scaffoldorient.RIGHT:
            drawHeightIndicPoint = new PointTrans(
              (lFaceBraces[i].xpos + rFaceBraces[i].xpos) / 2 + gapScaled,
              (lFaceBraces[i].ypos + rFaceBraces[i].ypos) / 2
            );
            break;
          default:
            break;
        }
        this.drawheightindrect(
          drawHeightIndicPoint,
          scaffoldheight,
          showheight,
          this.getAngle(),
          "X",
          i
        );
      }
    }
    this.group.scaffoldheight = scaffoldheight;
    this.group.has_internal_handrail = has_internal_handrail;
    this.group.showheight = showheight;
    this.group.show_internal_handrail = show_internal_handrail;
    this.group.no_extra_stars = no_extra_stars;
    this.group.has_mesh = has_mesh;
    let ltStopTransom = actualTopStandards[0].translate(
      0,
      sumAboves[0],
      this.orient
    );
    let lbStopTransom = actualBottomStandards[0].translate(
      0,
      -sumBelows[0],
      this.orient
    );
    let rtStopTransom = actualTopStandards[2 * bayNum - 1].translate(
      0,
      sumAboves[bayNum - 1],
      this.orient
    );
    let rbStopTransom = actualBottomStandards[2 * bayNum - 1].translate(
      0,
      -sumBelows[bayNum - 1],
      this.orient
    );

    if (this.leftstoppingtransom) {
      this.group.line_leftstoppingtransom = this.drawstoppingtransom(
        ltStopTransom,
        lbStopTransom,
        "L"
      );
    } else {
      this.group.line_leftstoppingtransom = this.drawstoppingtransom(
        ltStopTransom,
        lbStopTransom,
        "L",
        null,
        invisible,
        invisible
      );
    }
    if (this.rightstoppingtransom) {
      this.group.line_rightstoppingtransom = this.drawstoppingtransom(
        rtStopTransom,
        rbStopTransom,
        "R"
      );
    } else {
      this.group.line_rightstoppingtransom = this.drawstoppingtransom(
        rtStopTransom,
        rbStopTransom,
        "R",
        null,
        invisible,
        invisible
      );
    }

    // Now it is time to do the internal handrails. Because of the loops involved,
    // it is also easy to do side magnetism.

    let defaultinternalhandrailcolour = invisible;
    if (show_internal_handrail && has_internal_handrail !== null) {
      defaultinternalhandrailcolour = stoppingtransomcolour;
    }

    let defaultmakedashed = false;
    if (show_internal_handrail && has_internal_handrail === false) {
      defaultmakedashed = true;
    }

    for (let i = 0; i < bayNum; i++) {
      if (extraBottomStandardsBelow[i].length === 0) {
        this.group.line_internal_handrail_alt_array[
          i
        ] = this.drawstoppingtransom(
          actualBottomStandards[2 * i],
          actualBottomStandards[2 * i + 1],
          "I",
          i,
          defaultinternalhandrailcolour,
          defaultinternalhandrailcolour,
          defaultmakedashed
        );
        this.group.hopupside_magnets[i] = this.drawsidemagnet(
          PointTrans.midpoint(
            actualBottomStandards[2 * i],
            actualBottomStandards[2 * i + 1]
          ),
          "H",
          i
        );
      } else {
        this.group.line_internal_handrail_alt_array[
          i
        ] = this.drawstoppingtransom(
          extraBottomStandardsBelow[i][extraBottomStandardsBelow[i].length - 2],
          extraBottomStandardsBelow[i][extraBottomStandardsBelow[i].length - 1],
          "I",
          i,
          defaultinternalhandrailcolour,
          defaultinternalhandrailcolour,
          defaultmakedashed
        );
        this.group.hopupside_magnets[i] = this.drawsidemagnet(
          PointTrans.midpoint(
            extraBottomStandardsBelow[i][
              extraBottomStandardsBelow[i].length - 2
            ],
            extraBottomStandardsBelow[i][
              extraBottomStandardsBelow[i].length - 1
            ]
          ),
          "H",
          i
        );
      }

      if (extraTopStandardsAbove[i].length === 0) {
        this.group.rearside_magnets[i] = this.drawsidemagnet(
          PointTrans.midpoint(
            actualTopStandards[2 * i],
            actualTopStandards[2 * i + 1]
          ),
          "R",
          i
        );
      } else {
        this.group.rearside_magnets[i] = this.drawsidemagnet(
          PointTrans.midpoint(
            extraTopStandardsAbove[i][extraTopStandardsAbove[i].length - 2],
            extraTopStandardsAbove[i][extraTopStandardsAbove[i].length - 1]
          ),
          "R",
          i
        );
      }
    }

    this.group.rotate(angle);
    // Sometimes you need to tell the canvas to re-render.

    this.canv.renderAll();
  }

  /**
   * An alternative factory for setting up BayRows at a given angle at a given position.
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos The x-position coordinate for the LC on canvas.
   * @param {number} ypos The y-position coordinate for the LC on canvas.
   * @param {BayRowItem[]} bayitems The bay row items.
   * @param {number} width The width of the bays (length of transoms) in mm.
   * @param {number} hopup The desired hopup.
   * @param {number} leftcorner The width of the left corner boards in mm.
   * @param {number} rightcorner The width of the right corner boards in mm.
   * @param {number} rearcorner The width of the rear corner boards in mm.
   * @param {scaffoldbrand} scaffoldtype The type of the scaffold (Kwikstage for default)
   * @param {number} angle: Extra angle to rotate the object.
   * @param {number} frontgap The gap between the hopups and the building.
   * @param {number} sidegap The gap between the left corner and the building.
   * @param {boolean} leftbracing True only if there is left bracing.
   * @param {boolean} rightbracing True only if there is right bracing.
   * @param {boolean} leftstoppingtransom True only if there is a left stopping transom.
   * @param {boolean} rightstoppingtransom True only if there is a left stopping transom.
   */

  static bayrowaltfactory(
    canv, // 1
    scale, // 2
    xpos, // 3
    ypos, // 4
    bayitems, // 5
    width, // 6
    hopup, // 7
    leftcorner, // 8
    rightcorner, // 9
    rearcorner, // 10
    scaffoldtype, // 11
    angle = 0,
    frontgap = defaultGap,
    sidegap = defaultGap,
    leftbracing = false,
    rightbracing = false,
    leftstoppingtransom = false,
    rightstoppingtransom = false
  ) {
    let displacementx = 0;
    let displacementy = 0;
    if (bayitems.length > 0) {
      var ourBayRow = new BayRow(
        canv,
        scale,
        scaffoldorient.TOP,
        xpos,
        ypos,
        bayitems,
        width, // number of boards.
        hopup, // Hopup width.
        leftcorner, // Left corner boards.
        rightcorner, // Right corner boards.
        rearcorner, // Rear corners generally zero.
        scaffoldtype, // Brand of scaffold (Kwikstage by default)
        true, // New style magnetism!
        null, // Scaffold height
        true, // Show height
        true, // Has internal handrails
        false, // Show internal handrails
        true, // Has mesh
        0, // Number of extra stars
        angle // Angle
      );

      // For automatically added scaf bays, we prefer all types of magnetism off!

      ourBayRow.group.ismagnetic = false;
      ourBayRow.group.issidemagnetic = false;

      // Add side bracings.

      if (leftbracing) {
        ourBayRow.group.toggleLeftBrace(canv);
      }
      if (rightbracing) {
        ourBayRow.group.toggleRightBrace(canv);
      }

      // Ditto with stopping transoms

      if (leftstoppingtransom) {
        ourBayRow.group.line_leftstoppingtransom.setColor(
          stoppingtransomcolour
        );
        ourBayRow.group.line_leftstoppingtransom.set(
          "stroke",
          stoppingtransomcolour
        );
      }
      if (rightstoppingtransom) {
        ourBayRow.group.line_rightstoppingtransom.setColor(
          stoppingtransomcolour
        );
        ourBayRow.group.line_rightstoppingtransom.set(
          "stroke",
          stoppingtransomcolour
        );
      }

      // Works for now.

      let thiscalctrans = ourBayRow.group.calcTransformMatrix();
      let leftpoleactive = fabric.util.transformPoint(
        new fabric.Point(
          ourBayRow.group.leftpole.left,
          ourBayRow.group.leftpole.top
        ),
        thiscalctrans
      );
      let cosangle = Math.cos((angle * Math.PI) / 180);
      let sinangle = Math.sin((angle * Math.PI) / 180);
      displacementx =
        xpos -
        leftpoleactive.x +
        ((sidegap + leftcorner) / scale) * cosangle +
        ((frontgap + hopup + ourBayRow.group.baywidth * 0.5) / scale) *
          sinangle;
      displacementy =
        ypos -
        leftpoleactive.y +
        ((sidegap + leftcorner) / scale) * sinangle -
        ((frontgap + hopup + ourBayRow.group.baywidth * 0.5) / scale) *
          cosangle;
      ourBayRow.group.set({
        top: ourBayRow.group.top + displacementy,
        left: ourBayRow.group.left + displacementx,
      });
      ourBayRow.group.setCoords();
      return ourBayRow;
    } else {
      return null;
    }
  }

  /**
   * Finds the "best" fabric.ScaffoldGroup that contains a point.
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {fabric.Point} point The point for
   * @return {?fabric.ScaffoldGroup} a Fabric.Js ScaffoldGroup that contains the point (or null if not found).
   */

  static findBayForPoint(canv, scale, point) {
    let ourScaffolds = canv.getObjects().filter(function (x) {
      return (
        x.type === "scaffoldGroup" &&
        pointInRectangle(point, x.findBoundRect(scale))
      );
    });

    if (ourScaffolds.length === 0) {
      return null;
    } else if (ourScaffolds.length === 1) {
      return ourScaffolds[0];
    }

    // Past this case, there may be two or more fabric.ScaffoldGroup objects covering a point
    // We need to be better at elimination.

    let ourFilteredScaffolds = ourScaffolds.filter(function (x) {
      return x.betterFindPointInRect(point, scale);
    });

    let seqsOfPointsToCompare = [];

    if (ourFilteredScaffolds.length === 1) {
      return ourFilteredScaffolds[0];
    } else if (ourFilteredScaffolds.length > 1) {
      for (let item of ourFilteredScaffolds) {
        seqsOfPointsToCompare.push(item.betterfindBoundStand(scale));
      }
      let seqsIndex = findClosePointToSeq(point, seqsOfPointsToCompare);
      return ourFilteredScaffolds[seqsIndex];
    } else {
      for (let item of ourScaffolds) {
        seqsOfPointsToCompare.push(item.betterfindBoundStand(scale));
      }
      let seqsIndex = findClosePointToSeq(point, seqsOfPointsToCompare);
      return ourScaffolds[seqsIndex];
    }
  }

  /**
   * Creates a internal handrail indicator.
   * @param {PointTrans} theCoord The coordinates of the centre of the indicator.
   * @param {string|null} has_internal_handrail The value for the indicator.
   * @param {boolean} show_internal_handrail Whether to show the indicator value
   * @param {number} angle The angle to show the text (default 0).
   * @param {number|null} indicator_index The index for the indicator
   * @return {array} The components for the indicator.
   */

  draw_internal_handrail_ind(
    theCoord,
    has_internal_handrail = null,
    show_internal_handrail = false,
    angle = 0,
    indicator_index = null
  ) {
    let defaulttextfill = invisible;
    if (show_internal_handrail) {
      defaulttextfill = "black";
    }
    let indicatorDisplay = has_internal_handrail
      ? "Y"
      : has_internal_handrail === false
      ? "N"
      : "";
    let text_internal_handrail_alt = this.drawheightindictext(
      indicatorDisplay,
      theCoord.xpos,
      theCoord.ypos,
      angle,
      defaulttextfill,
      "Q",
      indicator_index
    );
    if (indicator_index !== null) {
      this.group.text_internal_handrail_alt_array[
        indicator_index
      ] = text_internal_handrail_alt;
    }
    return [text_internal_handrail_alt];
  }

  /**
   * Draws a line representing a stoppingtransom on the canvas.
   * @param {PointTrans} first the coordinates for the start of the line
   * @param {PointTrans} second the coordinates for the end of the line
   * @param {string} stoppingtransomtype "L" for left, "R" for right, "I" for internal handrails
   * @param {?number} internalhandrailindex An index used for internal handrails (null otherwise)
   * @param {string} fillcolour the colour to fill the line
   * @param {string} strokecolour the colour to stroke the line
   * @param {boolean} makedashed whether to make the line dashed or not.
   * @return {fabric.StoppingTransomLine} a Fabric.Js object representing the line.
   */

  drawstoppingtransom(
    first,
    second,
    stoppingtransomtype,
    internalhandrailindex = null,
    fillcolour = stoppingtransomcolour,
    strokecolour = stoppingtransomcolour,
    makedashed = false
  ) {
    let strokeDashArray = null;
    if (makedashed) {
      strokeDashArray = [
        (6 * defaultScale) / this.scale,
        (6 * defaultScale) / this.scale,
      ];
    }
    let ourStoppingTransom = new fabric.StoppingTransomLine(
      [first.xpos, first.ypos, second.xpos, second.ypos],
      {
        fill: fillcolour,
        stroke: strokecolour,
        strokeWidth: (ledgerLineWidth * defaultScale * 2) / this.scale,
        strokeDashArray: strokeDashArray,
        originX: "center",
        originY: "center",
        stoppingtransomtype: stoppingtransomtype,
        internalhandrailindex: internalhandrailindex,
      }
    );
    this.group.addWithUpdate(ourStoppingTransom);
    return ourStoppingTransom;
  }

  /**
   * Creates a height indicator circle.
   * @param {PointTrans} theCoord The coordinates of the circle centre
   * @param {number|null} scaffoldheight The height of the scaffold in metres (or null for not set)
   * @param {boolean} showheight Whether to show the indicator circle
   * @param {boolean} has_internal_handrail Whether the scaffold has an internal handrail
   * @param {boolean} has_mesh Whether the scaffold has mesh or not.
   * @param {number} no_extra_stars The number of extra stars
   * @return {array} The components for the indicator circle.
   */

  drawheightindcircle(
    theCoord,
    scaffoldheight = null,
    showheight = false,
    has_internal_handrail = true,
    has_mesh = true,
    no_extra_stars = 0
  ) {
    let defaultcirclestroke = invisible;
    let defaultcirclefill = invisible;
    let defaulttextfill = invisible;
    if (showheight) {
      defaulttextfill = "black";
      defaultcirclestroke = heightindborder;
      defaultcirclefill = heightindbgnd;
    }

    let ourHeightInd = new fabric.HeightCircle({
      left: theCoord.xpos,
      top: theCoord.ypos,
      strokeWidth: (ledgerLineWidth * defaultScale) / this.scale,
      radius: (heightIndRadius * defaultScale) / this.scale,
      fill: defaultcirclefill,
      stroke: defaultcirclestroke,
      originX: "center",
      originY: "center",
    });
    this.group.addWithUpdate(ourHeightInd);
    this.group.circle_heightind = ourHeightInd;
    let ourHeightHozLine = new fabric.HeightOutlineLine(
      [
        theCoord.xpos - (heightIndRadius * defaultScale) / this.scale,
        theCoord.ypos,
        theCoord.xpos + (heightIndRadius * defaultScale) / this.scale,
        theCoord.ypos,
      ],
      {
        fill: defaultcirclestroke,
        stroke: defaultcirclestroke,
        strokeWidth: (ledgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
        heightoutlinetype: "H",
      }
    );
    this.group.addWithUpdate(ourHeightHozLine);
    this.group.line_heighthoz = ourHeightHozLine;
    let ourHeightVertLine = new fabric.HeightOutlineLine(
      [
        theCoord.xpos,
        theCoord.ypos - (heightIndRadius * defaultScale) / this.scale,
        theCoord.xpos,
        theCoord.ypos + (heightIndRadius * defaultScale) / this.scale,
      ],
      {
        fill: defaultcirclestroke,
        stroke: defaultcirclestroke,
        strokeWidth: (ledgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
        heightoutlinetype: "V",
      }
    );
    this.group.line_heightvrt = ourHeightVertLine;
    this.group.addWithUpdate(ourHeightVertLine);
    let heightDisplay = "-";
    if (scaffoldheight !== null) {
      heightDisplay = scaffoldheight.toString() + "m";
    }
    this.group.text_height = this.drawheightindictext(
      heightDisplay,
      theCoord.xpos - (0.4 * heightIndRadius * defaultScale) / this.scale,
      theCoord.ypos - (0.4 * heightIndRadius * defaultScale) / this.scale,
      0,
      defaulttextfill,
      "H"
    );
    this.group.text_no_extra_stars = this.drawheightindictext(
      no_extra_stars.toString(),
      theCoord.xpos + (0.4 * heightIndRadius * defaultScale) / this.scale,
      theCoord.ypos - (0.4 * heightIndRadius * defaultScale) / this.scale,
      0,
      defaulttextfill,
      "S"
    );
    this.group.text_has_internal_handrail = this.drawheightindictext(
      has_internal_handrail ? "Y" : has_internal_handrail === false ? "N" : "",
      theCoord.xpos - (0.4 * heightIndRadius * defaultScale) / this.scale,
      theCoord.ypos + (0.4 * heightIndRadius * defaultScale) / this.scale,
      0,
      defaulttextfill,
      "I"
    );
    this.group.text_mesh = this.drawheightindictext(
      has_mesh ? "Y" : "N",
      theCoord.xpos + (0.4 * heightIndRadius * defaultScale) / this.scale,
      theCoord.ypos + (0.4 * heightIndRadius * defaultScale) / this.scale,
      0,
      defaulttextfill,
      "M"
    );
    return [
      ourHeightInd,
      ourHeightHozLine,
      ourHeightVertLine,
      this.group.text_height,
      this.group.text_no_extra_stars,
      this.group.text_has_internal_handrail,
      this.group.text_mesh,
    ];
  }

  /**
   * Draws a dashed line representing a face brace.
   * @param {PointTrans} first the coordinates for the start of the line
   * @param {PointTrans} second the coordinates for the end of the line
   * @param {string} thetype the type of brace - "L", "R", "S"
   * @param {number|null} theindex the index for the brace (or null for L|R)
   * @param {boolean} ispresent true if present; false otherwise
   * @return {fabric.BraceLine} a Fabric.Js object representing the line.
   */

  drawfacebrace(first, second, thetype, theindex, ispresent) {
    let thiscolour = invisible;
    if (ispresent) {
      thiscolour = facebracecolour;
    }
    let ourFaceBrace = new fabric.BraceLine(
      [first.xpos, first.ypos, second.xpos, second.ypos],
      {
        strokeWidth: (faceBraceLineWidth * defaultScale) / this.scale,
        strokeDashArray: [
          (6 * defaultScale) / this.scale,
          (6 * defaultScale) / this.scale,
        ],
        stroke: thiscolour,
        originX: "center",
        originY: "center",
        bracetype: thetype,
        braceindex: theindex,
        braceval: ispresent,
      }
    );
    this.group.addWithUpdate(ourFaceBrace);
    return ourFaceBrace;
  }

  /**
   * Draws a line representing the linear part of a tie symbol.
   * @param {PointTrans} wall the coordinates for the wall part of the line
   * @param {PointTrans} other the coordinates for the other end of the line
   * @param {string} thetype the type of tie - "L" or "R"
   * @param {number} theindex the index for the tie
   * @param {boolean} ispresent true if present; false otherwise
   * @return {fabric.TieLine} a Fabric.Js object representing the line.
   */

  drawtieline(wall, other, thetype, theindex, ispresent) {
    let incolour;
    if (ispresent) {
      incolour = tiecolour;
    } else {
      incolour = invisible;
    }
    let ourTieLine = new fabric.TieLine(
      [wall.xpos, wall.ypos, other.xpos, other.ypos],
      {
        fill: incolour,
        stroke: incolour,
        strokeWidth: (tieLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
        tietype: thetype,
        tieindex: theindex,
        tieval: ispresent,
      }
    );
    this.group.addWithUpdate(ourTieLine);
    return ourTieLine;
  }

  /**
   * Draws a wedge representing the triangular part of a tie symbol.
   * @param {PointTrans} wall the coordinates for the wall part of the line
   * @param {PointTrans} other the coordinates for the other end of the line
   * @param {string} thetype the type of tie - "L" or "R"
   * @param {number} theindex the index for the tie
   * @param {boolean} ispresent true if present; false otherwise
   * @return {fabric.TieTriangle} a Fabric.Js object representing the wedge.
   */

  drawtietriangle(wall, other, thetype, theindex, ispresent) {
    let incolour;
    if (ispresent) {
      incolour = tiecolour;
    } else {
      incolour = invisible;
    }
    // Construct the coordinates for the wedge part of the tie.

    let tieWedgeX;
    let tieWedgeY;

    switch (this.orient) {
      case scaffoldorient.TOP:
        tieWedgeX = wall.xpos;
        tieWedgeY = wall.ypos + tieWedgeHeight / 2;
        break;
      case scaffoldorient.BOTTOM:
        tieWedgeX = wall.xpos;
        tieWedgeY = wall.ypos - tieWedgeHeight / 2;
        break;
      case scaffoldorient.LEFT:
        tieWedgeX = wall.xpos + tieWedgeHeight / 2;
        tieWedgeY = wall.ypos;
        break;
      default:
        // case scaffoldorient.RIGHT:
        tieWedgeX = wall.xpos - tieWedgeHeight / 2;
        tieWedgeY = wall.ypos;
        break;
    }

    let ourTieTriangle = new fabric.TieTriangle({
      width: (tieWedgeWidth * defaultScale) / this.scale,
      height: (tieWedgeHeight * defaultScale) / this.scale,
      left: tieWedgeX,
      top: tieWedgeY,
      centredRotation: false,
      angle: this.getAngle() - 180,
      fill: incolour,
      originX: "center",
      originY: "center",
      tietype: thetype,
      tieindex: theindex,
      tieval: ispresent,
    });
    this.group.addWithUpdate(ourTieTriangle);
    return ourTieTriangle;
  }
}

/**
 * A class for drawing loading bays. We assume that the loading bays are
 * 2438 by 2438.
 */

class LoadingBay extends OrientScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {scaffoldorient} orient The orientation of the bay
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   * @param {?number} scaffoldheight The height of the scaffold (nullable by default)
   * @param {boolean} showheight Whether to display height and other item information.
   */
  constructor(
    canv,
    scale,
    orient,
    xpos,
    ypos,
    scaffoldheight = null,
    showheight = false
  ) {
    super(canv, scale, orient, xpos, ypos);

    let existingGroup = this.group;
    this.canv.remove(existingGroup);
    this.group = new fabric.HeightGroup([], {
      subtype: heightgroupsubtype.LB,
      scaffoldheight: scaffoldheight,
      showheight: showheight,
      objlength: null,
      objwidth: null,
    });
    this.canv.add(this.group);

    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.

    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);

    // Now we start calculate the coordinates of the corners of the bay.

    let sideScaled = loadingBaySize / scale;
    let boardDivideScaled = sideScaled / boardDivide;

    // Now we need to work out the coordinates for the standards.

    let blCoord = new PointTrans(xpos, ypos);
    let tlCoord = blCoord.translate(0, sideScaled, this.orient);
    let trCoord = blCoord.translate(sideScaled, sideScaled, this.orient);
    let brCoord = blCoord.translate(sideScaled, 0, this.orient);

    let boardLeft = [];
    let boardRight = [];
    for (let i = 1; i < boardDivide; i++) {
      boardLeft.push(blCoord.translate(0, i * boardDivideScaled, this.orient));
      boardRight.push(brCoord.translate(0, i * boardDivideScaled, this.orient));
    }
    for (let i = 0; i < boardDivide - 1; i++) {
      this.drawboardline(boardLeft[i], boardRight[i]);
    }

    this.drawledgerortransom(blCoord, tlCoord);
    this.drawledgerortransom(tlCoord, trCoord);
    this.drawledgerortransom(trCoord, brCoord);
    this.drawledgerortransom(brCoord, blCoord);
    this.drawledgerortransom(tlCoord, brCoord);
    this.drawledgerortransom(trCoord, blCoord);
    this.drawstandard(tlCoord);
    this.drawstandard(blCoord);
    this.drawstandard(trCoord);
    this.drawstandard(brCoord);

    switch (this.orient) {
      case scaffoldorient.TOP:
        this.drawrect(
          this.group,
          (tlCoord.xpos + brCoord.xpos) / 2,
          (tlCoord.ypos + brCoord.ypos) / 2,
          sideScaled / 2,
          sideScaled / 2,
          boardlinecolour,
          "white"
        );
        break;
      case scaffoldorient.BOTTOM:
        this.drawrect(
          this.group,
          (tlCoord.xpos + brCoord.xpos) / 2,
          (tlCoord.ypos + brCoord.ypos) / 2,
          sideScaled / 2,
          sideScaled / 2,
          boardlinecolour,
          "white"
        );
        break;
      case scaffoldorient.LEFT:
        this.drawrect(
          this.group,
          (tlCoord.xpos + brCoord.xpos) / 2,
          (tlCoord.ypos + brCoord.ypos) / 2,
          sideScaled / 2,
          sideScaled / 2,
          boardlinecolour,
          "white"
        );
        break;
      default:
        // case scaffoldorient.RIGHT:
        this.drawrect(
          this.group,
          (tlCoord.xpos + brCoord.xpos) / 2,
          (tlCoord.ypos + brCoord.ypos) / 2,
          sideScaled / 2,
          sideScaled / 2,
          boardlinecolour,
          "white"
        );
        break;
    }

    this.drawtext(
      loadingBaySize.toString(),
      (tlCoord.xpos + brCoord.xpos) / 2,
      (trCoord.ypos + blCoord.ypos) / 2,
      this.getAngle()
    );

    // Sometimes you need to tell the canvas to re-render.

    this.drawheightindrect(
      new PointTrans(
        (tlCoord.xpos + trCoord.xpos) / 2,
        (tlCoord.ypos + trCoord.ypos) / 2 - defaultGap / scale
      ),
      scaffoldheight,
      showheight
    );

    this.group.scaffoldheight = scaffoldheight;
    this.group.showheight = showheight;
    this.canv.renderAll();
  }
}

/* For drawing stretcher stairs. */

class StretcherStair extends OrientScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {scaffoldorient} orient The orientation of the stairs
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   * @param {?number} scaffoldheight The height of the scaffold (nullable by default)
   * @param {boolean} showheight Whether to display height and other item information.
   */
  constructor(
    canv,
    scale,
    orient,
    xpos,
    ypos,
    scaffoldheight = null,
    showheight = false
  ) {
    super(canv, scale, orient, xpos, ypos);

    let existingGroup = this.group;
    this.canv.remove(existingGroup);
    this.group = new fabric.HeightGroup([], {
      subtype: heightgroupsubtype.SS,
      scaffoldheight: scaffoldheight,
      showheight: showheight,
      objlength: null,
      objwidth: null,
    });
    this.canv.add(this.group);

    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.

    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);

    // Now we start calculate the coordinates of the corners of the bay.

    let sideScaled = loadingBaySize / scale;
    let transomScaled = mainTransomSize / scale;
    let boardDivideScaled = sideScaled / boardDivide;
    let transDivideScaled = transomScaled / stretDivide;

    // Now we need to work out the coordinates for the standards.

    let blCoord = new PointTrans(xpos, ypos);
    let mlCoord = blCoord.translate(0, transomScaled, this.orient);
    let tlCoord = blCoord.translate(0, 2 * transomScaled, this.orient);
    let bcentlCoord = blCoord.translate(transomScaled, 0, this.orient);
    let mcentlCoord = mlCoord.translate(transomScaled, 0, this.orient);
    let tcentlCoord = tlCoord.translate(transomScaled, 0, this.orient);
    let bcentrCoord = bcentlCoord.translate(sideScaled, 0, this.orient);
    let mcentrCoord = mcentlCoord.translate(sideScaled, 0, this.orient);
    let tcentrCoord = tcentlCoord.translate(sideScaled, 0, this.orient);
    let brCoord = bcentrCoord.translate(transomScaled, 0, this.orient);
    let mrCoord = mcentrCoord.translate(transomScaled, 0, this.orient);
    let trCoord = tcentrCoord.translate(transomScaled, 0, this.orient);

    let boardTop = [];
    let boardBottom = [];

    for (let i = 1; i < stretDivide; i++) {
      boardTop.push(tlCoord.translate(i * transDivideScaled, 0, this.orient));
      boardBottom.push(
        blCoord.translate(i * transDivideScaled, 0, this.orient)
      );
    }

    for (let i = 1; i < boardDivide; i++) {
      boardTop.push(
        tcentlCoord.translate(i * boardDivideScaled, 0, this.orient)
      );
      boardBottom.push(
        bcentlCoord.translate(i * boardDivideScaled, 0, this.orient)
      );
    }

    for (let i = 1; i < stretDivide; i++) {
      boardTop.push(
        tcentrCoord.translate(i * boardDivideScaled, 0, this.orient)
      );
      boardBottom.push(
        bcentrCoord.translate(i * boardDivideScaled, 0, this.orient)
      );
    }

    for (let i = 0; i < boardTop.length; i++) {
      this.drawboardline(boardTop[i], boardBottom[i]);
    }

    this.drawledgerortransom(blCoord, mlCoord);
    this.drawledgerortransom(mlCoord, tlCoord);
    this.drawledgerortransom(bcentlCoord, mcentlCoord);
    this.drawledgerortransom(mcentlCoord, tcentlCoord);
    this.drawledgerortransom(bcentrCoord, mcentrCoord);
    this.drawledgerortransom(mcentrCoord, tcentrCoord);
    this.drawledgerortransom(brCoord, mrCoord);
    this.drawledgerortransom(mrCoord, trCoord);
    this.drawledgerortransom(mlCoord, mrCoord);
    this.drawledgerortransom(tlCoord, trCoord);
    this.drawledgerortransom(brCoord, blCoord);
    this.drawstandard(tlCoord);
    this.drawstandard(blCoord);
    this.drawstandard(trCoord);
    this.drawstandard(brCoord);
    this.drawstandard(mlCoord);
    this.drawstandard(bcentlCoord);
    this.drawstandard(mcentlCoord);
    this.drawstandard(tcentlCoord);
    this.drawstandard(bcentrCoord);
    this.drawstandard(mcentrCoord);
    this.drawstandard(tcentrCoord);
    this.drawstandard(mrCoord);

    // This is where we draw the rectangles and write the text.

    switch (this.orient) {
      case scaffoldorient.TOP:
      case scaffoldorient.BOTTOM:
        this.drawrect(
          this.group,
          (tlCoord.xpos + mrCoord.xpos) / 2,
          (trCoord.ypos + mlCoord.ypos) / 2,
          sideScaled * 0.7,
          transomScaled * 0.3,
          boardlinecolour,
          "white"
        );
        this.drawrect(
          this.group,
          (mlCoord.xpos + brCoord.xpos) / 2,
          (mrCoord.ypos + blCoord.ypos) / 2,
          sideScaled * 0.7,
          transomScaled * 0.3,
          boardlinecolour,
          "white"
        );
        break;
      default:
        this.drawrect(
          this.group,
          (tlCoord.xpos + mrCoord.xpos) / 2,
          (trCoord.ypos + mlCoord.ypos) / 2,
          transomScaled * 0.3,
          sideScaled * 0.7,
          boardlinecolour,
          "white"
        );
        this.drawrect(
          this.group,
          (mlCoord.xpos + brCoord.xpos) / 2,
          (mrCoord.ypos + blCoord.ypos) / 2,
          transomScaled * 0.3,
          sideScaled * 0.7,
          boardlinecolour,
          "white"
        );
    }

    this.drawtext(
      "STAIR",
      (tlCoord.xpos + mrCoord.xpos) / 2,
      (trCoord.ypos + mlCoord.ypos) / 2,
      this.getAngle()
    );
    this.drawtext(
      "STRETCHER",
      (mlCoord.xpos + brCoord.xpos) / 2,
      (mrCoord.ypos + blCoord.ypos) / 2,
      this.getAngle()
    );

    // Sometimes you need to tell the canvas to re-render.

    this.drawheightindrect(
      new PointTrans(
        (tlCoord.xpos + trCoord.xpos) / 2,
        (tlCoord.ypos + trCoord.ypos) / 2 - defaultGap / scale
      ),
      scaffoldheight,
      showheight
    );

    this.group.scaffoldheight = scaffoldheight;
    this.group.showheight = showheight;
    this.canv.renderAll();
  }
}

/* For drawing construction stairs. */

class ConstStair extends OrientScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {scaffoldorient} orient The orientation of the stairs
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   * @param {?number} scaffoldheight The height of the scaffold (nullable by default)
   * @param {boolean} showheight Whether to display height and other item information.
   */
  constructor(
    canv,
    scale,
    orient,
    xpos,
    ypos,
    scaffoldheight = null,
    showheight = false
  ) {
    super(canv, scale, orient, xpos, ypos);

    let existingGroup = this.group;
    this.canv.remove(existingGroup);
    this.group = new fabric.HeightGroup([], {
      subtype: heightgroupsubtype.CS,
      scaffoldheight: scaffoldheight,
      showheight: showheight,
      objlength: null,
      objwidth: null,
    });
    this.canv.add(this.group);

    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.

    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);

    // Now we start calculate the coordinates of the corners of the bay.

    let sideScaled = loadingBaySize / scale;
    let transomScaled = mainTransomSize / scale;
    let boardDivideScaled = sideScaled / boardDivide;

    // Now we need to work out the coordinates for the standards.

    let blCoord = new PointTrans(xpos, ypos);
    let tlCoord = blCoord.translate(0, transomScaled, this.orient);
    let trCoord = blCoord.translate(sideScaled, transomScaled, this.orient);
    let brCoord = blCoord.translate(sideScaled, 0, this.orient);
    let mlCoord = blCoord.translate(0, transomScaled / 2, this.orient);
    let mrCoord = brCoord.translate(0, transomScaled / 2, this.orient);
    let mtCoord = tlCoord.translate(sideScaled / 2, 0, this.orient);
    let mbCoord = blCoord.translate(sideScaled / 2, 0, this.orient);

    let boardTop = [];
    boardTop.push(mtCoord);
    let boardBottom = [];
    boardBottom.push(mbCoord);
    let numboardsEitherSide = Math.floor(numMidBoardsStairs / 2);
    for (let i = 1; i <= numboardsEitherSide; i++) {
      boardTop.push(mtCoord.translate(i * boardDivideScaled, 0, this.orient));
      boardBottom.push(
        mbCoord.translate(i * boardDivideScaled, 0, this.orient)
      );
    }
    for (let i = 1; i <= numboardsEitherSide; i++) {
      boardTop.push(mtCoord.translate(-i * boardDivideScaled, 0, this.orient));
      boardBottom.push(
        mbCoord.translate(-i * boardDivideScaled, 0, this.orient)
      );
    }

    for (let i = 0; i < numMidBoardsStairs; i++) {
      this.drawboardline(boardTop[i], boardBottom[i]);
    }

    this.drawledgerortransom(blCoord, tlCoord);
    this.drawledgerortransom(mlCoord, mrCoord);
    this.drawledgerortransom(tlCoord, trCoord);
    this.drawledgerortransom(trCoord, brCoord);
    this.drawledgerortransom(brCoord, blCoord);
    this.drawstandard(tlCoord);
    this.drawstandard(blCoord);
    this.drawstandard(trCoord);
    this.drawstandard(brCoord);

    // This is where we draw the rectangles and write the text.

    switch (this.orient) {
      case scaffoldorient.TOP:
      case scaffoldorient.BOTTOM:
        this.drawrect(
          this.group,
          (tlCoord.xpos + mrCoord.xpos) / 2,
          (trCoord.ypos + mlCoord.ypos) / 2,
          sideScaled * 0.5,
          transomScaled * 0.3,
          boardlinecolour,
          "white"
        );
        this.drawrect(
          this.group,
          (mlCoord.xpos + brCoord.xpos) / 2,
          (mrCoord.ypos + blCoord.ypos) / 2,
          sideScaled * 0.5,
          transomScaled * 0.3,
          boardlinecolour,
          "white"
        );
        break;
      default:
        this.drawrect(
          this.group,
          (tlCoord.xpos + mrCoord.xpos) / 2,
          (trCoord.ypos + mlCoord.ypos) / 2,
          transomScaled * 0.3,
          sideScaled * 0.5,
          boardlinecolour,
          "white"
        );
        this.drawrect(
          this.group,
          (mlCoord.xpos + brCoord.xpos) / 2,
          (mrCoord.ypos + blCoord.ypos) / 2,
          transomScaled * 0.3,
          sideScaled * 0.5,
          boardlinecolour,
          "white"
        );
    }

    this.drawtext(
      "STAIR",
      (tlCoord.xpos + mrCoord.xpos) / 2,
      (trCoord.ypos + mlCoord.ypos) / 2,
      this.getAngle()
    );
    this.drawtext(
      "CONST.",
      (mlCoord.xpos + brCoord.xpos) / 2,
      (mrCoord.ypos + blCoord.ypos) / 2,
      this.getAngle()
    );

    this.drawheightindrect(
      new PointTrans(
        (tlCoord.xpos + trCoord.xpos) / 2,
        (tlCoord.ypos + trCoord.ypos) / 2 - defaultGap / scale
      ),
      scaffoldheight,
      showheight
    );

    this.group.scaffoldheight = scaffoldheight;
    this.group.showheight = showheight;

    // Sometimes you need to tell the canvas to re-render.

    this.canv.renderAll();
  }
}

/* For drawing buttress bays. */

class ButtBay extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} length The length of the buttress bay
   * @param {number} width The width of the buttress bay
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   * @param {?number} scaffoldheight The height of the scaffold (nullable by default)
   * @param {boolean} showheight Whether to display height and other item information.
   */
  constructor(
    canv,
    scale,
    length,
    width,
    xpos,
    ypos,
    scaffoldheight = null,
    showheight = false
  ) {
    super(canv, scale, xpos, ypos);
    this.length = length;
    this.width = width;

    let existingGroup = this.group;
    this.canv.remove(existingGroup);
    this.group = new fabric.HeightGroup([], {
      subtype: heightgroupsubtype.BB,
      scaffoldheight: scaffoldheight,
      showheight: showheight,
      objlength: this.length,
      objwidth: this.width,
    });
    this.canv.add(this.group);

    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.

    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);

    // Now we start calculate the coordinates of the corners of the bay.

    let lengthScaled = length / scale;
    let widthScaled = width / scale;

    // Now we need to work out the coordinates for the standards.

    let blCoord = new PointTrans(
      xpos - widthScaled / 2,
      ypos + lengthScaled / 2
    );
    let tlCoord = blCoord.translate(0, lengthScaled, scaffoldorient.TOP);
    let trCoord = blCoord.translate(
      widthScaled,
      lengthScaled,
      scaffoldorient.TOP
    );
    let brCoord = blCoord.translate(widthScaled, 0, scaffoldorient.TOP);
    this.drawrect(
      this.group,
      xpos,
      ypos,
      widthScaled,
      lengthScaled,
      boardlinecolour,
      buttressbground
    );
    this.drawledgerortransom(blCoord, tlCoord);
    this.drawledgerortransom(tlCoord, trCoord);
    this.drawledgerortransom(trCoord, brCoord);
    this.drawledgerortransom(brCoord, blCoord);
    this.drawstandard(tlCoord);
    this.drawstandard(blCoord);
    this.drawstandard(trCoord);
    this.drawstandard(brCoord);
    this.drawheightindrect(
      new PointTrans(
        (tlCoord.xpos + trCoord.xpos) / 2,
        (tlCoord.ypos + trCoord.ypos) / 2 - defaultGap / scale
      ),
      scaffoldheight,
      showheight
    );
    this.group.scaffoldheight = scaffoldheight;
    this.group.showheight = showheight;
  }
}

/* For drawing ladder bays. */

class LadderBay extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} length The length of the ladder bay
   * @param {number} width The width of the ladder bay
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   * @param {?number} scaffoldheight The height of the scaffold (nullable by default)
   * @param {boolean} showheight Whether to display height and other item information.
   */
  constructor(
    canv,
    scale,
    length,
    width,
    xpos,
    ypos,
    scaffoldheight = null,
    showheight = false
  ) {
    super(canv, scale, xpos, ypos);
    this.length = length;
    this.width = width;

    let existingGroup = this.group;
    this.canv.remove(existingGroup);
    this.group = new fabric.HeightGroup([], {
      subtype: heightgroupsubtype.LA,
      scaffoldheight: scaffoldheight,
      showheight: showheight,
      objlength: this.length,
      objwidth: this.width,
    });
    this.canv.add(this.group);

    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.

    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);

    // Now we start calculate the coordinates of the corners of the bay.

    let lengthScaled = length / scale;
    let widthScaled = width / scale;

    // Now we need to work out the coordinates for the standards.

    let blCoord = new PointTrans(
      xpos - widthScaled / 2,
      ypos + lengthScaled / 2
    );
    let tlCoord = blCoord.translate(0, lengthScaled, scaffoldorient.TOP);
    let trCoord = blCoord.translate(
      widthScaled,
      lengthScaled,
      scaffoldorient.TOP
    );
    let brCoord = blCoord.translate(widthScaled, 0, scaffoldorient.TOP);
    this.drawrect(
      this.group,
      xpos,
      ypos,
      widthScaled,
      lengthScaled,
      boardlinecolour,
      "white"
    );
    this.drawgridline(tlCoord, brCoord);
    this.drawgridline(trCoord, blCoord);
    let topPoint = new PointTrans(
      (tlCoord.xpos + trCoord.xpos) / 2,
      (tlCoord.ypos + trCoord.ypos) / 2
    );
    let bottomPoint = new PointTrans(
      (blCoord.xpos + brCoord.xpos) / 2,
      (blCoord.ypos + brCoord.ypos) / 2
    );
    let leftPoint = new PointTrans(
      (tlCoord.xpos + blCoord.xpos) / 2,
      (tlCoord.ypos + blCoord.ypos) / 2
    );
    let rightPoint = new PointTrans(
      (trCoord.xpos + brCoord.xpos) / 2,
      (trCoord.ypos + brCoord.ypos) / 2
    );
    this.drawgridline(topPoint, leftPoint);
    this.drawgridline(topPoint, rightPoint);
    this.drawgridline(bottomPoint, leftPoint);
    this.drawgridline(bottomPoint, rightPoint);

    let topcentreRightPoint = new PointTrans(
      (tlCoord.xpos + trCoord.xpos * 3) / 4,
      (tlCoord.ypos + trCoord.ypos * 3) / 4
    );

    let topcentreLeftPoint = new PointTrans(
      (tlCoord.xpos * 3 + trCoord.xpos) / 4,
      (tlCoord.ypos * 3 + trCoord.ypos) / 4
    );

    let leftcentreTopPoint = new PointTrans(
      (tlCoord.xpos * 3 + blCoord.xpos) / 4,
      (tlCoord.ypos * 3 + blCoord.ypos) / 4
    );

    let leftcentreBottomPoint = new PointTrans(
      (tlCoord.xpos + blCoord.xpos * 3) / 4,
      (tlCoord.ypos + blCoord.ypos * 3) / 4
    );

    let bottomcentreLeftPoint = new PointTrans(
      (blCoord.xpos * 3 + brCoord.xpos) / 4,
      (blCoord.ypos * 3 + brCoord.ypos) / 4
    );

    let bottomcentreRightPoint = new PointTrans(
      (blCoord.xpos + brCoord.xpos * 3) / 4,
      (blCoord.ypos + brCoord.ypos * 3) / 4
    );

    let rightcentreBottomPoint = new PointTrans(
      (trCoord.xpos + brCoord.xpos * 3) / 4,
      (trCoord.ypos + brCoord.ypos * 3) / 4
    );

    let rightcentreTopPoint = new PointTrans(
      (trCoord.xpos * 3 + brCoord.xpos) / 4,
      (trCoord.ypos * 3 + brCoord.ypos) / 4
    );

    this.drawgridline(topcentreLeftPoint, leftcentreTopPoint);
    this.drawgridline(bottomcentreLeftPoint, leftcentreBottomPoint);
    this.drawgridline(rightcentreTopPoint, topcentreRightPoint);
    this.drawgridline(bottomcentreRightPoint, rightcentreBottomPoint);

    this.drawgridline(topcentreLeftPoint, rightcentreBottomPoint);
    this.drawgridline(bottomcentreLeftPoint, rightcentreTopPoint);
    this.drawgridline(topcentreRightPoint, leftcentreBottomPoint);
    this.drawgridline(bottomcentreRightPoint, leftcentreTopPoint);

    let ourCentrePoint = new PointTrans(
      (tlCoord.xpos + brCoord.xpos) / 2,
      (tlCoord.ypos + brCoord.ypos) / 2
    );

    this.drawrect(
      this.group,
      ourCentrePoint.x,
      ourCentrePoint.y,
      Math.abs(tlCoord.xpos - brCoord.xpos) / 2,
      Math.abs(tlCoord.ypos - brCoord.ypos) / 2,
      "black",
      "white"
    );
    if (this.length === 2438 && this.width === 2438) {
      this.drawtext("LADDER\nACCESS", ourCentrePoint.x, ourCentrePoint.y, 0);
    } else if (this.length === 2438) {
      this.drawtext("L\nA", ourCentrePoint.x, ourCentrePoint.y, 0);
    } else {
      this.drawtext("LA", ourCentrePoint.x, ourCentrePoint.y, 0);
    }

    this.drawledgerortransom(blCoord, tlCoord);
    this.drawledgerortransom(tlCoord, trCoord);
    this.drawledgerortransom(trCoord, brCoord);
    this.drawledgerortransom(brCoord, blCoord);
    this.drawstandard(tlCoord);
    this.drawstandard(blCoord);
    this.drawstandard(trCoord);
    this.drawstandard(brCoord);
    this.drawheightindrect(
      new PointTrans(
        (tlCoord.xpos + trCoord.xpos) / 2,
        (tlCoord.ypos + trCoord.ypos) / 2 - defaultGap / scale
      ),
      scaffoldheight,
      showheight
    );
    this.group.scaffoldheight = scaffoldheight;
    this.group.showheight = showheight;
  }

  /**
   * Draws a grid line between two points.
   * @param {PointTrans} point1 the first point
   * @param {PointTrans} point2 the second point
   * @return {fabric.Line} a line between the two points.
   */

  drawgridline(point1, point2) {
    let ourGridLine = new fabric.Line(
      [point1.xpos, point1.ypos, point2.xpos, point2.ypos],
      {
        fill: ladderbaybground,
        stroke: ladderbaybground,
        strokeWidth: (ladderLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourGridLine);
    return ourGridLine;
  }
}

/* For drawing elevation plan ties */

class ElevPlanTie extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   * @param {number} scafheight The height of the plan (in metres).
   * @param {string} elevplantieorient The orientation of the tie (left or right).
   * @param {number} elevplantieboards The width of the boards (in mm).
   * @param {number} elevplantiehopups The width of the hopups (in mm).
   */
  constructor(
    canv,
    scale,
    xpos,
    ypos,
    elevplantieorient,
    elevplantieboards,
    elevplantiehopups
  ) {
    super(canv, scale, xpos, ypos);
    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);
    this.elevplantieorient = elevplantieorient;
    this.elevplantieboards = elevplantieboards;
    this.elevplantiehopups = elevplantiehopups;

    let desiredOrient = scaffoldorient.TOP;
    let boardScaled = elevplantieboards / scale;
    let hopupScaled = elevplantiehopups / scale;
    let gapScaled = defaultGap / scale;
    let baseCoord = new PointTrans(xpos, ypos);
    let otherCoord = baseCoord.translate(
      hopupScaled + gapScaled + boardScaled,
      0,
      desiredOrient
    );
    if (this.elevplantieorient === "Right") {
      this.drawtiesymbol(baseCoord, otherCoord);
    } else {
      this.drawtiesymbol(otherCoord, baseCoord);
    }
  }

  /**
   * Draws a symbol (line and triangle) representing a scaffold tie.
   * @param {PointTrans} wall the coordinates for the wall part of the line
   * @param {PointTrans} other the coordinates for the other end of the line
   * @return {array} an array representing the two created objects.
   */

  drawtiesymbol(wall, other) {
    let ourTieLine = new fabric.Line(
      [wall.xpos, wall.ypos, other.xpos, other.ypos],
      {
        fill: tiecolour,
        stroke: tiecolour,
        strokeWidth: (tieLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );

    // Construct the coordinates for the wedge part of the tie.

    let tieWedgeX;
    let tieWedgeY;
    var orientatation = scaffoldorient.LEFT;
    switch (orientatation) {
      case scaffoldorient.TOP:
        tieWedgeX = wall.xpos;
        tieWedgeY = wall.ypos + tieWedgeHeight / 2;
        break;
      case scaffoldorient.BOTTOM:
        tieWedgeX = wall.xpos;
        tieWedgeY = wall.ypos - tieWedgeHeight / 2;
        break;
      case scaffoldorient.LEFT:
        tieWedgeX = wall.xpos + tieWedgeHeight / 2;
        tieWedgeY = wall.ypos;
        break;
      default:
        // case scaffoldorient.RIGHT:
        tieWedgeX = wall.xpos - tieWedgeHeight / 2;
        tieWedgeY = wall.ypos;
        break;
    }
    let anglechoice;
    switch (this.elevplantieorient) {
      case "Left":
        anglechoice = -90;
        break;
      default:
        // case "right":
        anglechoice = 90;
        break;
    }
    let ourTieTriangle = new fabric.Triangle({
      width: (tieWedgeWidth * defaultScale) / this.scale,
      height: (tieWedgeHeight * defaultScale) / this.scale,
      left: tieWedgeX,
      top: tieWedgeY,
      centredRotation: false,
      angle: anglechoice,
      fill: tiecolour,
      originX: "center",
      originY: "center",
    });
    let theTieGroup = new fabric.Group([ourTieLine, ourTieTriangle]);
    this.canv.add(theTieGroup);
    return theTieGroup;
  }
}

/* For drawing elevation plans. */

class ElevPlan extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   * @param {number} scafheight The height of the plan (in metres).
   * @param {number} numstarsbayint Number additional stars above exterior bay.
   * @param {string} heightstint: A comma seperated list of int. standard heights (highest first),
   * @param {number} numstarsbayext Number additional stars above exterior bay.
   * @param {string} heightstext: A comma seperated list of ext. standard heights (highest first),
   * @param {number} boardwidth The width of the boards (in mm).
   * @param {number} hopupwidth The width of the hopups (in mm).
   * @param {number} buttlength The length of buttress (or 0 for none) in mm.
   * @param {string} addlabel a label to add to the sideplan.
   * @param {string} orient which side has the buttresses (left or right)
   * @param {boolean} addties whether to add ties to the plan (or not).
   * @param {boolean} addfacebrace whether to add facebrace to the plan.
   * @param {number} stairwidth The width of the stairs (in mm); 0 if no stairs.
   * @param {number} numstarsstrext Number of additional stars above ext. stairs
   * @param {string} heightststair A comma seperated list of stair standard heights (highest first),
   * @param {number} numstepstairs The number of steps per stairway.
   * @param {number} verthop The number of stars between hopups.
   */
  constructor(
    canv,
    scale,
    xpos,
    ypos,
    scafheight,
    numstarsbayint,
    heightstint,
    numstarsbayext,
    heightstext,
    boardwidth,
    hopupwidth,
    buttlength,
    addlabel,
    orient,
    addties,
    addfacebrace,
    stairwidth,
    numstarsstrext,
    heightststair,
    numstepstairs = 7,
    verthop = 4
  ) {
    super(canv, scale, xpos, ypos);
    let existingGroup = this.group;
    this.canv.remove(existingGroup);
    let subtype =
      orient === "right" ? heightgroupsubtype.EPR : heightgroupsubtype.EPL;
    this.group = new fabric.HeightGroup([], {
      subtype: subtype,
      scaffoldheight: scafheight,
      showheight: false,
      objlength: boardwidth,
      objwidth: hopupwidth,
    });
    this.canv.add(this.group);
    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.
    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);
    this.scafheight = scafheight;
    this.heightstint = heightstint;
    let heightsintParsed = ElevPlan.parseCSVNumList(heightstint);
    this.numstarsbayint = numstarsbayint;
    this.numstarsbayext = numstarsbayext;
    this.heightstext = heightstext;
    let heightstextParsed = ElevPlan.parseCSVNumList(heightstext);
    this.boardwidth = boardwidth;
    this.hopupwidth = hopupwidth;
    this.buttlength = buttlength;
    this.addlabel = addlabel;
    this.orient = orient;
    this.addties = addties;
    this.addfacebrace = addfacebrace;
    this.stairwidth = stairwidth;
    this.numstarsstrext = numstarsstrext;
    this.heightststair = heightststair;
    let heightststairParsed = ElevPlan.parseCSVNumList(heightststair);
    this.numstepstairs = numstepstairs;
    let defaultOrient = scaffoldorient.TOP;
    let boardwidthScaled = boardwidth / scale;
    let scafheightScaled = (scafheight * 1000) / scale;
    let buttressScaled = buttlength / scale;
    let hopupScaled = hopupwidth / scale;
    let metreScaled = 1000 / scale;
    let faceBraceGapScaled = faceBraceGapBScale / scale;
    let gapScaled = defaultGap / scale;
    let stairwidthScaled = stairwidth / scale;
    let numstairlev = Math.ceil(scafheight / 1.5);
    let numstairheight = numstairlev * 1.5;
    let numstairscaled = numstairheight * metreScaled;

    // We may need to heighten the height of the buttress bracing. This calculates
    // what it should be (in stars).

    let leftHeighten = 0;
    let rightHeighten = 0;

    // The number of stars above the bay exterior is generally set by the
    // scaffolding author - but if there are stairs next door, then this
    // needs to be set to something high enough for the stairs. The same
    // goes for the number of stars above stairs; this can be changed if
    // necessary for the buttress. Finally, there may be bracing above the
    // main bays.

    let noRightStars = Math.round(scafheight * 2) - 1;
    let bayModulus = noRightStars % 8;
    let bracingext = 0;
    if (bayModulus === 5) {
      bracingext = 2;
    }
    if (bayModulus === 6 || bayModulus === 1) {
      bracingext = 1;
    }

    let calcnumstarsstrext = numstarsstrext;
    if (stairwidth && buttlength) {
      calcnumstarsstrext = Math.max(numstarsstrext, rightHeighten);
    }

    let calcnumstarsbayext = Math.max(numstarsbayext, bracingext);
    if (buttlength && !stairwidth) {
      calcnumstarsbayext = Math.max(calcnumstarsbayext, rightHeighten);
    } else if (stairwidth) {
      calcnumstarsbayext = Math.max(
        calcnumstarsbayext,
        Math.round((numstairheight - scafheight) * 2)
      );
    }

    let multip = 1;
    if (orient === "right") {
      multip = -1;
    }

    // Now we need to work out the coordinates for the standards. By default,
    // we assume that buttresses are on the left and hopups on the right;
    // but the user may choose the opposite. So it is easier to use the
    // multiplier to calculate the right coordinates.

    let blCoord = new PointTrans(
      xpos - boardwidthScaled / 2,
      ypos + scafheightScaled / 2
    );
    let brCoord = blCoord.translate(
      multip * boardwidthScaled,
      0,
      defaultOrient
    );
    let tlCoord = blCoord.translate(0, scafheightScaled, defaultOrient);
    let trCoord = tlCoord.translate(
      multip * boardwidthScaled,
      0,
      defaultOrient
    );

    let bbCoord, tbCoord;
    let bsCoord, tsCoord;
    let bhalfsCoord, thalfsCoord;
    if (stairwidthScaled > 0) {
      bsCoord = blCoord.translate(multip * -stairwidthScaled, 0, defaultOrient);
      tsCoord = bsCoord.translate(0, numstairscaled, defaultOrient);
      bhalfsCoord = blCoord.translate(
        (multip * -stairwidthScaled) / 2,
        0,
        defaultOrient
      );
      thalfsCoord = bhalfsCoord.translate(0, numstairscaled, defaultOrient);
    }

    if (buttressScaled > 0) {
      if (stairwidthScaled > 0) {
        bbCoord = bsCoord.translate(multip * -buttressScaled, 0, defaultOrient);
      } else {
        bbCoord = blCoord.translate(multip * -buttressScaled, 0, defaultOrient);
      }
      tbCoord = bbCoord.translate(0, scafheightScaled, defaultOrient);
    }
    let sidelCoords = [];
    let siderCoords = [];
    let numLevels = scafheight;
    for (let i = scafheight; i > 0; i = i - 2) {
      sidelCoords.push(blCoord.translate(0, i * metreScaled, defaultOrient));
      siderCoords.push(brCoord.translate(0, i * metreScaled, defaultOrient));
    }

    let sidelHopupCoords = [];
    let siderHopupCoords = [];
    let indexcount;

    if (hopupScaled) {
      for (let i = scafheight; i >= 2; i = i - verthop / 2) {
        sidelHopupCoords.push(
          brCoord.translate(0, i * metreScaled, defaultOrient)
        );
        siderHopupCoords.push(
          brCoord.translate(
            multip * hopupScaled,
            i * metreScaled,
            defaultOrient
          )
        );
        indexcount = i;
      }
      if (indexcount !== 2) {
        sidelHopupCoords.push(
          brCoord.translate(0, 2 * metreScaled, defaultOrient)
        );
        siderHopupCoords.push(
          brCoord.translate(
            multip * hopupScaled,
            2 * metreScaled,
            defaultOrient
          )
        );
      }
    }

    let sidelStarCoords = [];
    let siderStarCoords = [];
    let sidebStarCoords = [];
    let sidesStarCoords = [];

    for (let i = 0; i < numLevels * 2 + numstarsbayint; i++) {
      siderStarCoords.push(
        brCoord.translate(0, ((i + 1) * metreScaled) / 2, defaultOrient)
      );
    }
    for (let i = 0; i < numLevels * 2 + calcnumstarsbayext; i++) {
      sidelStarCoords.push(
        blCoord.translate(0, ((i + 1) * metreScaled) / 2, defaultOrient)
      );
    }

    if (stairwidthScaled) {
      for (let i = 0; i < numstairheight * 2 + calcnumstarsstrext; i++) {
        sidesStarCoords.push(
          bsCoord.translate(0, ((i + 1) * metreScaled) / 2, defaultOrient)
        );
      }
    }
    if (buttressScaled) {
      for (let i = 0; i < scafheight * 2 + leftHeighten; i++) {
        sidebStarCoords.push(
          bbCoord.translate(0, ((i + 1) * metreScaled) / 2, defaultOrient)
        );
      }
    }

    let stairLeftCoords = [];
    let stairRightCoords = [];
    let stairRatio;

    let stepLeftCoords = [];
    let stepMidCoords = [];
    let stepRightCoords = [];
    let stepRatio;

    if (stairwidthScaled && numstairlev && numstepstairs) {
      stairRatio = 1.5 * metreScaled;
      stepRatio = stairRatio / numstepstairs;
      for (let i = 0; i < numstairlev; i++) {
        stairLeftCoords.push(
          bsCoord.translate(0, (i + 1) * stairRatio, defaultOrient)
        );
        stairRightCoords.push(
          blCoord.translate(0, (i + 1) * stairRatio, defaultOrient)
        );
      }

      for (let i = 0; i < numstepstairs * numstairlev; i++) {
        stepLeftCoords.push(
          bsCoord.translate(0, (i + 1) * stepRatio, defaultOrient)
        );
        stepMidCoords.push(
          bhalfsCoord.translate(0, (i + 1) * stepRatio, defaultOrient)
        );
        stepRightCoords.push(
          blCoord.translate(0, (i + 1) * stepRatio, defaultOrient)
        );
      }
    }

    let tfbCoord;
    let bfbCoord;
    if (addfacebrace) {
      if (buttressScaled) {
        tfbCoord = tbCoord.translate(
          -multip * faceBraceGapScaled,
          (leftHeighten * metreScaled) / 2 + metreScaled / 4,
          defaultOrient
        );
        bfbCoord = bbCoord.translate(
          -multip * faceBraceGapScaled,
          0,
          defaultOrient
        );
      } else if (stairwidthScaled) {
        tfbCoord = tsCoord.translate(
          -multip * faceBraceGapScaled,
          (calcnumstarsstrext * metreScaled) / 2 + metreScaled / 4,
          defaultOrient
        );
        bfbCoord = bsCoord.translate(
          -multip * faceBraceGapScaled,
          0,
          defaultOrient
        );
      } else {
        tfbCoord = tlCoord.translate(
          -multip * faceBraceGapScaled,
          (calcnumstarsbayext * metreScaled) / 2 + metreScaled / 4,
          defaultOrient
        );
        bfbCoord = blCoord.translate(
          -multip * faceBraceGapScaled,
          0,
          defaultOrient
        );
      }
    }
    if (addties) {
      let tiesbaseCoord = brCoord.translate(
        multip * (hopupScaled + gapScaled),
        0,
        defaultOrient
      );
      for (let i = scafheight; i > 0; i = i - 4) {
        if (i > 0) {
          this.drawtiesymbol(
            tiesbaseCoord.translate(
              0,
              i * metreScaled - metreScaled / 8,
              defaultOrient
            ),
            blCoord.translate(
              0,
              i * metreScaled - metreScaled / 8,
              defaultOrient
            )
          );
        }
      }
    }

    if (stairwidthScaled > 0) {
      this.drawstairline(bhalfsCoord, thalfsCoord);
      for (let i = 0; i < numstepstairs * numstairlev; i++) {
        let levelFound = i % (2 * numstepstairs);
        if (levelFound >= 0 && levelFound < numstepstairs) {
          this.drawstairline(stepLeftCoords[i], stepMidCoords[i]);
        }
        if (levelFound >= numstepstairs && levelFound < 2 * numstepstairs) {
          this.drawstairline(stepMidCoords[i], stepRightCoords[i]);
        }
        stepLeftCoords.push(
          bsCoord.translate(0, (i + 1) * stepRatio, defaultOrient)
        );
        stepMidCoords.push(
          bhalfsCoord.translate(0, (i + 1) * stepRatio, defaultOrient)
        );
        stepRightCoords.push(
          blCoord.translate(0, (i + 1) * stepRatio, defaultOrient)
        );
      }
    }

    this.drawltselev(
      tlCoord.translate(
        0,
        ((2 + calcnumstarsbayext * 2) * metreScaled) / 4,
        defaultOrient
      ),
      blCoord,
      true
    );
    this.drawltselev(
      trCoord.translate(
        0,
        (2 * metreScaled) / 4 + this.numstarsbayint * 0.5 * metreScaled,
        defaultOrient
      ),
      brCoord,
      true
    );
    this.drawaltltselev(trCoord, tlCoord);
    if (buttressScaled) {
      this.drawltselev(
        tbCoord.translate(
          0,
          (2 * metreScaled) / 4 + (leftHeighten * metreScaled) / 2,
          defaultOrient
        ),
        bbCoord,
        true
      );
    }
    if (stairwidthScaled) {
      this.drawltselev(
        tsCoord.translate(
          0,
          ((2 + calcnumstarsstrext * 2) * metreScaled) / 4,
          defaultOrient
        ),
        bsCoord,
        true
      );
    }

    for (let i = 0; i < sidelCoords.length; i++) {
      this.drawaltltselev(sidelCoords[i], siderCoords[i]);
    }

    if (this.numstarsbayext >= 4 && this.numstarsbayint >= 4) {
      this.drawaltltselev(
        tlCoord.translate(0, metreScaled * 2, defaultOrient),
        trCoord.translate(0, metreScaled * 2, defaultOrient)
      );
    }

    if (hopupScaled) {
      for (let i = 0; i < sidelHopupCoords.length; i++) {
        this.drawaltltselev(sidelHopupCoords[i], siderHopupCoords[i]);
      }
    }

    for (let i = 0; i < noRightStars - 2; i = i + 8) {
      this.drawaltltselev(sidelStarCoords[i], siderStarCoords[i + 3]);
    }
    for (let i = 4; i < noRightStars - 2; i = i + 8) {
      this.drawaltltselev(sidelStarCoords[i + 3], siderStarCoords[i]);
    }
    if (bayModulus === 5) {
      this.drawaltltselev(
        sidelStarCoords[noRightStars + 2],
        siderStarCoords[noRightStars - 1]
      );
    }
    if (bayModulus === 6 || bayModulus === 1) {
      this.drawaltltselev(
        sidelStarCoords[noRightStars + 1],
        siderStarCoords[noRightStars - 2]
      );
    }
    if (bayModulus === 2) {
      this.drawaltltselev(
        sidelStarCoords[noRightStars],
        siderStarCoords[noRightStars - 3]
      );
    }

    this.drawaltltselev(sidelStarCoords[0], siderStarCoords[0]);
    if (stairwidthScaled) {
      this.drawaltltselev(sidelStarCoords[0], sidesStarCoords[0]);
    }

    if (buttressScaled > 0) {
      this.drawaltltselev(sidelStarCoords[0], sidebStarCoords[0]);
      for (let i = Math.round(scafheight * 2) - 1; i > 0; i = i - 4) {
        if (stairwidthScaled) {
          this.drawaltltselev(sidesStarCoords[i], sidebStarCoords[i]);
        } else {
          this.drawaltltselev(sidelStarCoords[i], sidebStarCoords[i]);
        }
      }
      for (let i = 0; i < sidebStarCoords.length - 4; i = i + 10) {
        if (stairwidthScaled) {
          this.drawaltltselev(sidesStarCoords[i], sidebStarCoords[i + 4]);
        } else {
          this.drawaltltselev(sidelStarCoords[i], sidebStarCoords[i + 4]);
        }
      }

      // This is the tricky part - going from buttress to other higher, wall ()

      if (stairwidthScaled) {
        for (let i = 0; i < sidebStarCoords.length - 9; i = i + 10) {
          this.drawaltltselev(sidesStarCoords[i + 9], sidebStarCoords[i + 5]);
        }
      } else {
        for (let i = 0; i < sidebStarCoords.length - 9; i = i + 10) {
          this.drawaltltselev(sidelStarCoords[i + 9], sidebStarCoords[i + 5]);
        }
      }
    }

    if (stairwidthScaled && numstairlev) {
      for (let i = 0; i < numstairlev; i++) {
        this.drawaltltselev(stairLeftCoords[i], stairRightCoords[i]);
      }
    }

    if (hopupScaled) {
      for (let i = scafheight; i >= 2; i = i - verthop / 2) {
        this.drawhopup(brCoord.translate(0, i * metreScaled, defaultOrient));
        indexcount = i;
      }
      if (indexcount !== 2) {
        this.drawhopup(brCoord.translate(0, 2 * metreScaled, defaultOrient));
      }
    }

    if (addfacebrace) {
      this.drawfacebrace(tfbCoord, bfbCoord);
    }

    this.drawjack(blCoord);
    this.drawjack(brCoord);

    if (buttressScaled > 0) {
      this.drawjack(bbCoord);
    }

    if (stairwidthScaled > 0) {
      this.drawjack(bsCoord);
    }

    this.drawlabeltext(
      addlabel,
      "B",
      blCoord.xpos,
      blCoord.ypos + 200 / scale,
      0
    );
    for (let i = 0; i < siderStarCoords.length; i++) {
      this.drawstar(siderStarCoords[i]);
    }
    for (let i = 0; i < sidelStarCoords.length; i++) {
      this.drawstar(sidelStarCoords[i]);
    }

    if (buttressScaled) {
      for (let i = 0; i < sidebStarCoords.length; i++) {
        this.drawstar(sidebStarCoords[i]);
      }
    }
    if (stairwidthScaled) {
      for (let i = 0; i < sidesStarCoords.length; i++) {
        this.drawstar(sidesStarCoords[i]);
      }
    }

    /* We now draw the text for the numbers. But we need to check that the total sum doesn't
   exceed the height of the bay.
*/
    let heightSoFar, xposPlace;
    const textDisplace = (0.73 / 2) * metreScaled;
    let intHeight = this.scafheight + this.numstarsbayint * 0.5;
    heightsintParsed = ElevPlan.build_scaf_standard(intHeight, 3);
    heightSoFar = 0;
    if (this.orient === "left") {
      xposPlace = trCoord.xpos - textDisplace;
    } else {
      xposPlace = trCoord.xpos + textDisplace;
    }
    for (let i = 0; i < heightsintParsed.length; i++) {
      let standardLength = heightsintParsed[i];
      this.drawlabeltext(
        standardLength.toString() + "m",
        "I" + i.toString(),
        xposPlace,
        tlCoord.ypos +
          (heightSoFar - this.numstarsbayint * 0.5 + standardLength / 2) *
            metreScaled,
        0
      );
      heightSoFar = heightSoFar + standardLength;
    }

    /* Now for the exterior. */

    let extHeight = this.scafheight + this.numstarsbayext * 0.5;
    heightstextParsed = ElevPlan.build_scaf_standard(extHeight, 2);
    heightSoFar = 0;

    if (this.orient === "left") {
      xposPlace = tlCoord.xpos - textDisplace;
    } else {
      xposPlace = tlCoord.xpos + textDisplace;
    }
    for (let i = 0; i < heightstextParsed.length; i++) {
      let standardLength = heightstextParsed[i];
      this.drawlabeltext(
        standardLength.toString() + "m",
        "X" + i.toString(),
        xposPlace,
        tlCoord.ypos +
          (heightSoFar + standardLength / 2 - this.numstarsbayext * 0.5) *
            metreScaled,
        0
      );
      heightSoFar = heightSoFar + standardLength;
    }

    if (stairwidthScaled) {
      let stairHeight = this.scafheight + this.numstarsstrext * 0.5;
      heightststairParsed = ElevPlan.build_scaf_standard(stairHeight, 3);
      heightSoFar = 0;

      if (this.orient === "left") {
        xposPlace = bsCoord.xpos - textDisplace;
      } else {
        xposPlace = bsCoord.xpos + textDisplace;
      }
      for (let i = 0; i < heightststairParsed.length; i++) {
        let standardLength = heightststairParsed[i];
        this.drawlabeltext(
          standardLength.toString() + "m",
          "S" + i.toString(),
          xposPlace,
          tlCoord.ypos +
            (heightSoFar + standardLength / 2 - this.numstarsstrext * 0.5) *
              metreScaled,
          0
        );
        heightSoFar = heightSoFar + standardLength;
      }
    }
    this.canv.renderAll();
  }

  /**
   * Draws labels on an elevation plan. This text is the length of the bay.
   * @param {string} text the text to write on the screen.
   * @param {string} labeltype a type identified to identify the text.
   * @param {number} xCoord the x-coordinate for the text
   * @param {number} yCoord the y-coordinate for the text
   * @param {number} theAngle the angle (in degrees for the text)
   * @param {text} fillcolour the colour to fill in text (default: black)
   * @return {fabric.Text} a Fabric.Js object representing the text.
   */

  drawlabeltext(
    text,
    labeltype,
    xCoord,
    yCoord,
    theAngle,
    fillcolour = "black"
  ) {
    let ourLabelText = new fabric.LabelText(text, {
      top: yCoord,
      left: xCoord,
      fontSize: (theFontSize * defaultScale) / this.scale,
      fontFamily: defaultFontFamily,
      angle: theAngle,
      originX: "center",
      originY: "center",
      fill: fillcolour,
      labeltype: labeltype,
    });
    this.group.addWithUpdate(ourLabelText);
    return ourLabelText;
  }

  /**
   * Takes a height, and a base scaffold height, and returns an array of standard heights.
   * @param {number} height_m the height of the scaffold
   * @param {number} init_base_m The height of the base standard.
   * @return {Array<number>} an array of all standards.
   */

  static build_scaf_standard(height_m, init_base_m) {
    let standardArray = [init_base_m];
    let remainder = height_m - init_base_m;
    let no_threes = Math.floor(remainder / 3);
    let mod_three = remainder % 3;
    if (mod_three === 0.5) {
      for (let i = 0; i < no_threes - 1; i++) {
        standardArray.unshift(3);
      }
      standardArray.unshift(2.5);
      standardArray.unshift(1);
    } else {
      for (let i = 0; i < no_threes; i++) {
        standardArray.unshift(3);
      }
      if (mod_three !== 0) {
        standardArray.unshift(mod_three);
      }
    }
    return standardArray;
  }

  /**
   * Takes a string, and attempts to parse it as a CSV list of numbers.
   * @param {string} csvNumString the CSV list of numbers
   * @return {Array<number>} an array of all positive numbers in the string (empty if none are present).
   */

  static parseCSVNumList(csvNumString) {
    let csvSplitArray = csvNumString.split(/[ ,]+/);
    let csvReturnNumbers = [];
    for (let i = 0; i < csvSplitArray.length; i++) {
      let arrayNum = parseFloat(csvSplitArray[i]);
      if (!isNaN(arrayNum) && arrayNum > 0) {
        csvReturnNumbers.push(arrayNum);
      }
    }
    return csvReturnNumbers;
  }

  /**
   * Draws a scaffolding star at a given object on the canvas.
   * @param {PointTrans} thePoint the coordinates for point
   * @return {array} an array containing the components for the star.
   */

  drawstar(thePoint) {
    let ourRect = new fabric.Rect({
      left: thePoint.xpos,
      top: thePoint.ypos,
      originX: "center",
      originY: "center",
      width: (ledgerLineWidth * defaultScale) / this.scale,
      height: (ledgerLineWidth * defaultScale) / this.scale,
      stroke: "black",
      strokeWidth: ((boardSeperatorWidth / 2) * defaultScale) / this.scale,
      fill: "white",
      transparentCorners: false,
    });
    this.group.addWithUpdate(ourRect);
    let ourRect2 = new fabric.Rect({
      left: thePoint.xpos - (ledgerLineWidth * defaultScale) / this.scale,
      top: thePoint.ypos + (ledgerLineWidth * defaultScale) / this.scale,
      originX: "center",
      originY: "center",
      width: (ledgerLineWidth * defaultScale) / this.scale,
      height: (ledgerLineWidth * defaultScale) / this.scale,
      stroke: "black",
      strokeWidth: ((boardSeperatorWidth / 2) * defaultScale) / this.scale,
      fill: "white",
      transparentCorners: false,
    });
    this.group.addWithUpdate(ourRect2);
    let ourRect3 = new fabric.Rect({
      left: thePoint.xpos + (ledgerLineWidth * defaultScale) / this.scale,
      top: thePoint.ypos + (ledgerLineWidth * defaultScale) / this.scale,
      originX: "center",
      originY: "center",
      width: (ledgerLineWidth * defaultScale) / this.scale,
      height: (ledgerLineWidth * defaultScale) / this.scale,
      stroke: "black",
      strokeWidth: ((boardSeperatorWidth / 2) * defaultScale) / this.scale,
      fill: "white",
      transparentCorners: false,
    });
    this.group.addWithUpdate(ourRect3);
    return [ourRect, ourRect2, ourRect3];
  }

  /**
   * Draws a scaffolding jack at a given object on the canvas.
   * @param {PointTrans} thePoint the coordinates for point
   * @return {array} an array containing the components for the jack.
   */

  drawjack(thePoint) {
    return new Jack(this.canv, this.scale, thePoint.xpos, thePoint.ypos);
  }

  /**
   * Draws symbols for a hopup.
   * @param {PointTrans} thePoint the coordinates for point
   * @param {number} hopupwidth The width of the hopups (in mm).
   * @return {array} an array containing the components for the hopup.
   */

  drawhopup(thePoint) {
    let multip = 1;
    if (this.orient === "right") {
      multip = -1;
    }

    let hopupScaled = this.hopupwidth / this.scale;
    let metreScaled = 1000 / this.scale;
    let hopupEnd = thePoint.translate(
      (multip * hopupScaled * 2) / 3,
      0,
      scaffoldorient.TOP
    );
    let secondEnd = hopupEnd.translate(
      (-multip * hopupScaled) / 3,
      -metreScaled / 3,
      scaffoldorient.TOP
    );
    let finalBit = thePoint.translate(0, -metreScaled / 3, scaffoldorient.TOP);

    let vertBot = secondEnd.translate(
      (-multip * hopupScaled) / 6,
      0,
      scaffoldorient.TOP
    );
    let vertTop = vertBot.translate(0, metreScaled / 3, scaffoldorient.TOP);

    // This is the lines used for the drawing hopups.

    let ourFirstLine, ourSecondLine, ourThirdLine;

    if (this.hopupwidth === 250) {
      return []; // No cantilever for one board hopups.
    } else {
      ourFirstLine = this.drawhopupelevview(hopupEnd, secondEnd);
      ourSecondLine = this.drawhopupelevview(finalBit, secondEnd);
      ourThirdLine = this.drawhopupelevview(vertBot, vertTop);
      return [ourFirstLine, ourSecondLine, ourThirdLine];
    }
  }

  /**
   * Draws a dashed line representing a face brace.
   * @param {PointTrans} first the coordinates for the start of the line
   * @param {PointTrans} second the coordinates for the end of the line
   * @return {fabric.BraceLine} a Fabric.Js object representing the line.
   */

  drawfacebrace(first, second) {
    let ourFaceBrace = new fabric.BraceLine(
      [first.xpos, first.ypos, second.xpos, second.ypos],
      {
        strokeWidth: (faceBraceLineWidth * defaultScale) / this.scale,
        strokeDashArray: [
          (6 * defaultScale) / this.scale,
          (6 * defaultScale) / this.scale,
        ],
        stroke: facebracecolour,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourFaceBrace);
    return ourFaceBrace;
  }

  /**
   * Draws a line representing part of a hopup in elev view. on the canvas.
   * @param {PointTrans} first the coordinates for the start of the line
   * @param {PointTrans} second the coordinates for the end of the line
   * @return {fabric.Line} a Fabric.Js object representing the line.
   */

  drawhopupelevview(first, second) {
    let ourHopupElev = new fabric.Line(
      [first.xpos, first.ypos, second.xpos, second.ypos],
      {
        fill: maincolour,
        stroke: maincolour,
        strokeWidth: ((ledgerLineWidth / 2) * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourHopupElev);
    return ourHopupElev;
  }

  /**
   * Draws a symbol (line and triangle) representing a scaffold tie.
   * @param {PointTrans} wall the coordinates for the wall part of the line
   * @param {PointTrans} other the coordinates for the other end of the line
   * @return {array} an array representing the two created objects.
   */

  drawtiesymbol(wall, other) {
    let ourTieLine = new fabric.Line(
      [wall.xpos, wall.ypos, other.xpos, other.ypos],
      {
        fill: tiecolour,
        stroke: tiecolour,
        strokeWidth: (tieLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );

    // Construct the coordinates for the wedge part of the tie.

    let tieWedgeX;
    let tieWedgeY;
    var orientatation = scaffoldorient.LEFT;
    switch (orientatation) {
      case scaffoldorient.TOP:
        tieWedgeX = wall.xpos;
        tieWedgeY = wall.ypos + tieWedgeHeight / 2;
        break;
      case scaffoldorient.BOTTOM:
        tieWedgeX = wall.xpos;
        tieWedgeY = wall.ypos - tieWedgeHeight / 2;
        break;
      case scaffoldorient.LEFT:
        tieWedgeX = wall.xpos + tieWedgeHeight / 2;
        tieWedgeY = wall.ypos;
        break;
      default:
        // case scaffoldorient.RIGHT:
        tieWedgeX = wall.xpos - tieWedgeHeight / 2;
        tieWedgeY = wall.ypos;
        break;
    }
    let anglechoice;
    switch (this.orient) {
      case "left":
        anglechoice = -90;
        break;
      default:
        // case "right":
        anglechoice = 90;
        break;
    }
    let ourTieTriangle = new fabric.Triangle({
      width: (tieWedgeWidth * defaultScale) / this.scale,
      height: (tieWedgeHeight * defaultScale) / this.scale,
      left: tieWedgeX,
      top: tieWedgeY,
      centredRotation: false,
      angle: anglechoice,
      fill: tiecolour,
      originX: "center",
      originY: "center",
    });
    let theTieGroup = new fabric.Group([ourTieLine, ourTieTriangle]);
    this.canv.add(theTieGroup);
    return theTieGroup;
  }

  /**
   * Draws a stairwell line.
   * @param {PointTrans} first the coordinates for the first part
   * @param {PointTrans} second the coordinates for the second part
   * @return {fabric.Line} a line object for the sair.
   */

  drawstairline(first, second) {
    let ourStairLine = new fabric.Line(
      [first.xpos, first.ypos, second.xpos, second.ypos],
      {
        fill: tiecolour,
        stroke: tiecolour,
        strokeWidth: (tieLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourStairLine);
    return ourStairLine;
  }

  /**
   * Draws a line representing a ledger, transom or standard on the canvas
   * - for elevation plans.
   * @param {PointTrans} first the coordinates for the start of the line
   * @param {PointTrans} second the coordinates for the end of the line
   * @param {boolean} isVertical true iff represents vertical standard
   * @return {fabric.Line} a Fabric.Js object representing the line.
   *
   * Note 1: trick to get the line like a pipe: draw a white line of thinner
   * width over a black like of thicker width; and two black circles at the
   * end.
   * Note 2: if isVertical is true, attempt to terminate part of line nearest
   * first with a spigot.
   */

  drawltselev(first, second, isVertical = false) {
    let testColour = maincolour;
    let testCircleCoord;
    let spigotEndCoord;
    if (isVertical) {
      testCircleCoord = trimlinebypiece(second, first, 100 / this.scale);
      spigotEndCoord = trimlinebypiece(second, first, 200 / this.scale);
    } else {
      spigotEndCoord = first;
    }
    let ourLTS = new fabric.Line(
      [spigotEndCoord.xpos, spigotEndCoord.ypos, second.xpos, second.ypos],
      {
        fill: testColour,
        stroke: testColour,
        strokeWidth: (elevLedgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourLTS);
    let ourWhiteOverlay = new fabric.Line(
      [spigotEndCoord.xpos, spigotEndCoord.ypos, second.xpos, second.ypos],
      {
        fill: "white",
        stroke: "white",
        strokeWidth: (0.5 * elevLedgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourWhiteOverlay);
    if (isVertical) {
      let ourSpigotLine = new fabric.Line(
        [spigotEndCoord.xpos, spigotEndCoord.ypos, first.xpos, first.ypos],
        {
          fill: testColour,
          stroke: testColour,
          strokeWidth: (0.5 * elevLedgerLineWidth * defaultScale) / this.scale,
          originX: "center",
          originY: "center",
        }
      );
      this.group.addWithUpdate(ourSpigotLine);
      let ourSpigotOverlay = new fabric.Line(
        [spigotEndCoord.xpos, spigotEndCoord.ypos, first.xpos, first.ypos],
        {
          fill: "white",
          stroke: "white",
          strokeWidth: (0.25 * elevLedgerLineWidth * defaultScale) / this.scale,
          originX: "center",
          originY: "center",
        }
      );
      this.group.addWithUpdate(ourSpigotOverlay);
    }
    let ourfirstCircle;
    if (isVertical) {
      ourfirstCircle = new fabric.Circle({
        fill: maincolour,
        stroke: maincolour,
        radius: (0.125 * elevLedgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
        top: first.ypos,
        left: first.xpos,
      });
    } else {
      ourfirstCircle = new fabric.Circle({
        fill: maincolour,
        stroke: maincolour,
        radius: (0.25 * elevLedgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
        top: first.ypos,
        left: first.xpos,
      });
    }
    this.group.addWithUpdate(ourfirstCircle);
    let oursecondCircle = new fabric.Circle({
      fill: maincolour,
      stroke: maincolour,
      radius: (0.25 * elevLedgerLineWidth * defaultScale) / this.scale,
      originX: "center",
      originY: "center",
      top: second.ypos,
      left: second.xpos,
    });
    this.group.addWithUpdate(oursecondCircle);
    if (isVertical) {
      let ourTestCircle = new fabric.Circle({
        fill: maincolour,
        stroke: maincolour,
        radius: (0.125 * elevLedgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
        top: testCircleCoord.ypos,
        left: testCircleCoord.xpos,
      });
      this.group.addWithUpdate(ourTestCircle);
    }
    return [ourLTS, ourWhiteOverlay, ourfirstCircle, oursecondCircle];
  }

  /**
   * Draws a line representing a ledger or brace for elevation plans -
   * but create them as independent groups.
   * @param {PointTrans} first the coordinates for the start of the line
   * @param {PointTrans} second the coordinates for the end of the line
   * @return {fabric.Line} a Fabric.Js object representing the line.
   *
   */

  drawaltltselev(first, second) {
    new ElevPlanLedgerBrace(
      this.canv,
      this.scale,
      (first.xpos + second.xpos) / 2,
      (first.ypos + second.ypos) / 2,
      Math.sqrt(
        Math.pow(first.xpos - second.xpos, 2) +
          Math.pow(first.ypos - second.ypos, 2)
      ) * this.scale,
      (180 * Math.atan2(first.ypos - second.ypos, first.xpos - second.xpos)) /
        Math.PI
    );
  }
}

// Let a        c <- n  b
// Where a, b are PointTrans dots, n is distance to trim to b; Returns c

function trimlinebypiece(a, b, n) {
  let xposdist = b.xpos - a.xpos;
  let yposdist = b.ypos - a.ypos;
  let linelength = Math.sqrt(Math.pow(xposdist, 2) + Math.pow(yposdist, 2));
  let fraction = n / linelength;
  let cxpos = a.xpos + (1 - fraction) * xposdist;
  let cypos = a.ypos + (1 - fraction) * yposdist;
  return new PointTrans(cxpos, cypos);
}

/* For drawing jacks. */

class Jack extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   */
  constructor(canv, scale, xpos, ypos) {
    super(canv, scale, xpos, ypos);
    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.
    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);
    let ourFirstLine = new fabric.Line(
      [xpos, ypos, xpos, ypos - 1000 / this.scale / 8],
      {
        fill: maincolour,
        stroke: maincolour,
        strokeWidth: (1.5 * ledgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourFirstLine);
    let ourSecondLine = new fabric.Line(
      [
        xpos - 1000 / this.scale / 8,
        ypos - 1000 / this.scale / 8,
        xpos + 1000 / this.scale / 8,
        ypos - 1000 / this.scale / 8,
      ],
      {
        fill: maincolour,
        stroke: maincolour,
        strokeWidth: (1.5 * ledgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourSecondLine);
    let ourThirdLine = new fabric.Line(
      [xpos - 1000 / this.scale / 8, ypos, xpos + 1000 / this.scale / 8, ypos],
      {
        fill: maincolour,
        stroke: maincolour,
        strokeWidth: (1.5 * ledgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourThirdLine);
    this.canv.renderAll();
  }
}

/* For drawing free standing ledgers or transoms. */

class LedgerTransom extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   * @param {number} ledgertransomlength The length of the ledger or transom.
   */
  constructor(canv, scale, xpos, ypos, ledgertransomlength) {
    super(canv, scale, xpos, ypos);
    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.
    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);
    let first = new PointTrans(
      xpos - (0.5 * ledgertransomlength) / scale,
      ypos
    );
    let second = new PointTrans(
      xpos + (0.5 * ledgertransomlength) / scale,
      ypos
    );
    this.drawledgerortransom(first, second);
    this.drawstandard(first);
    if (ledgertransomlength !== 0) {
      this.drawstandard(second);
    }
    this.canv.renderAll();
  }
}

/* For drawing jacks at oblique angles. Not really used. */

class ObliqueJack extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   */
  constructor(canv, scale, xpos, ypos) {
    super(canv, scale, xpos, ypos);
    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.
    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);
    let baseDraw = 1000;
    let baseDrawScaled = baseDraw / scale;
    let defaultOrient = scaffoldorient.TOP;

    let baseCoord = new PointTrans(xpos, ypos);
    let aboveCoord = baseCoord.translate(0, baseDrawScaled, defaultOrient);
    let baseLeftCoord = baseCoord.translate(
      -10 * baseDrawScaled,
      5 * baseDrawScaled,
      defaultOrient
    );
    let aboveLeftCoord = aboveCoord.translate(
      -10 * baseDrawScaled,
      5 * baseDrawScaled,
      defaultOrient
    );
    let baseRightCoord = baseCoord.translate(
      4 * baseDrawScaled,
      2 * baseDrawScaled,
      defaultOrient
    );
    let aboveRightCoord = aboveCoord.translate(
      4 * baseDrawScaled,
      2 * baseDrawScaled,
      defaultOrient
    );
    let farCoord = aboveRightCoord.translate(
      -10 * baseDrawScaled,
      5 * baseDrawScaled,
      defaultOrient
    );

    let basePlate = aboveCoord.translate(
      -2.9 * baseDrawScaled,
      1.74 * baseDrawScaled,
      defaultOrient
    );
    let basePlateRight = basePlate.translate(
      3.5 * baseDrawScaled,
      1.75 * baseDrawScaled,
      defaultOrient
    );
    let basePlateLeft = basePlate.translate(
      -3.5 * baseDrawScaled,
      1.75 * baseDrawScaled,
      defaultOrient
    );
    let basePlateBehind = basePlate.translate(
      0,
      3.5 * baseDrawScaled,
      defaultOrient
    );

    var endPoints = [
      { x: xpos, y: ypos },
      { x: xpos, y: ypos - baseDrawScaled },
      { x: xpos + 4 * baseDrawScaled, y: ypos - 3 * baseDrawScaled },
      { x: xpos + 4 * baseDrawScaled, y: ypos - 2 * baseDrawScaled },
    ];

    var endPolygon = new fabric.Polygon(endPoints, {
      fill: "grey",
    });
    this.group.add(endPolygon);
    var botEllipse = new fabric.Ellipse({
      left: basePlate.xpos,
      top: basePlate.ypos - 1.75 * baseDrawScaled,
      rx: (2 * baseDrawScaled) / 3,
      ry: baseDrawScaled / 3,
      fill: "white",
      stroke: "black",
      strokeWidth: (ledgerLineWidth * defaultScale) / this.scale,
      originX: "center",
      originY: "center",
    });
    this.group.add(botEllipse);
    var topEllipse = new fabric.Ellipse({
      left: basePlate.xpos,
      top: basePlate.ypos - 8.75 * baseDrawScaled,
      rx: baseDrawScaled,
      ry: baseDrawScaled / 2,
      fill: "white",
      stroke: "black",
      strokeWidth: (ledgerLineWidth * defaultScale) / this.scale,
      originX: "center",
      originY: "center",
    });
    this.group.add(topEllipse);

    this.drawledgerortransom(baseCoord, aboveCoord);
    this.drawledgerortransom(baseCoord, baseLeftCoord);
    this.drawledgerortransom(aboveLeftCoord, aboveCoord);
    this.drawledgerortransom(aboveLeftCoord, baseLeftCoord);
    this.drawledgerortransom(baseCoord, baseRightCoord);
    this.drawledgerortransom(aboveRightCoord, aboveCoord);
    this.drawledgerortransom(aboveRightCoord, baseRightCoord);
    this.drawledgerortransom(aboveRightCoord, farCoord);
    this.drawledgerortransom(aboveLeftCoord, farCoord);
    this.drawledgerortransom(basePlate, basePlateRight);
    this.drawledgerortransom(basePlate, basePlateLeft);
    this.drawledgerortransom(basePlateBehind, basePlateRight);
    this.drawledgerortransom(basePlateBehind, basePlateLeft);
    this.addWithUpdate(this.itemsToAdd);
    this.canv.renderAll();
  }
}

/* For drawing text boxes. */

class ScafText extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   * @param {string} text the text to display.
   * @param {number} size the size of the text to display (in pixels).
   * @param {boolean} hasbackground false if transparent; true if present.
   */
  constructor(canv, scale, xpos, ypos, text, size, hasbackground = false) {
    super(canv, scale, xpos, ypos);
    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.
    let existingGroup = this.group;
    this.canv.remove(existingGroup);
    this.group = new fabric.TextboxGroup([], {
      textheightreal: size * scale,
    });
    this.canv.add(this.group);
    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);
    this.text = text;
    let backgroundcol = hasbackground ? "white" : null;
    this.group.addWithUpdate(
      new fabric.Text(text, {
        top: ypos,
        left: xpos,
        fontSize: size,
        fontFamily: "sans-serif",
        lockScalingX: true,
        lockScalingY: true,
        lockUniScaling: true,
        backgroundColor: backgroundcol,
      })
    );
  }
}

/* For drawing tubes. */

class Tube extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos X-position coordinate for the centre.
   * @param {number} ypos Y-position coordinate for the centre.
   * @param {number} tubelen The length of the tube in mm.
   * @param {number} angle The angle of the tube.
   * @param {?string} labelid A label to attached to the tube (for reconnection)
   * @param {number} noattachments The number of attachments for the Tube
   */
  constructor(
    canv,
    scale,
    xpos,
    ypos,
    tubelen,
    angle = 0,
    labelid = null,
    noattachments = 0
  ) {
    super(canv, scale, xpos, ypos);
    this.tubelen = Math.floor(tubelen);
    this.angle = angle;
    let existingGroup = this.group;
    this.canv.remove(existingGroup);

    // Note - new Tubes are written using originX and originY of center;
    // It makes it easier to calculate coordinates.

    this.group = new fabric.StoreGroup([], {
      objlength: this.tubelen,
      subtype: "TUBE",
      labelid: labelid,
      originX: "center",
      originY: "center",
      noattachments: noattachments,
    });

    this.canv.add(this.group);

    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.

    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);

    // Now we start calculate the coordinates of the corners of the bay.

    let lengthScaled = tubelen / scale;

    // Let us add the line.

    let ourTubeLine = new fabric.Line(
      [xpos - lengthScaled / 2, ypos, xpos + lengthScaled / 2, ypos],
      {
        strokeWidth: tubeWidth / scale,
        stroke: tubeColour,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourTubeLine);
    let text = (this.tubelen / 1000).toString();
    this.group.addWithUpdate(
      new fabric.Text(text, {
        top: ypos,
        left: xpos,
        fontSize: (tubeWidth * 3) / scale,
        fontFamily: "sans-serif",
        originX: "center",
        originY: "center",
        backgroundColor: "white",
        stroke: tubeColour,
      })
    );
    this.group.rotate(angle);
    this.group.setCoords();
    this.canv.renderAll();
  }

  /**
   * Connects a Tube between two points.
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} buildsc The given scale for the canvas.
   * @param {point} point1 - The first point.
   * @param {point} point2 - The second point.
   * @return {Tube} the desired Tube.
   */

  static connectPoints(canv, buildsc, point1, point2, noattachments) {
    let tubemidpointx = (point1.x + point2.x) / 2;
    let tubemidpointy = (point1.y + point2.y) / 2;
    let tubediffx = point2.x - point1.x;
    let tubediffy = point2.y - point1.y;
    let tubeangle = (Math.atan2(tubediffy, tubediffx) * 180) / Math.PI;
    let tubedist =
      Math.sqrt(Math.pow(tubediffx, 2) + Math.pow(tubediffy, 2)) * buildsc;
    tubedist = Math.ceil(tubedist / 300) * 300; // Round up to the nearest 300mm length.
    let uniqueid = uuidv1(); // A new unique value
    return new Tube(
      canv,
      buildsc,
      tubemidpointx,
      tubemidpointy,
      tubedist,
      tubeangle,
      uniqueid,
      noattachments
    );
  }

  /**
   * Redraws a Tube between two points. This is meant to be as quick as possible, so
   * stuff that forces the correct coordinates to appear are deferred.
   * @param {number} buildsc The given scale for the canvas.
   * @param {fabric.StoreGroup} tubegroup The object to analyse.
   * @param {point} point1 - The first point.
   * @param {point} point2 - The second point.
   * @return {void}.
   * Note: side effect is to set originX and originY of tubes to "center", because it is easier to calculate with.
   * */

  static redrawTube(buildsc, tubegroup, point1, point2) {
    let tubemidpointx = (point1.x + point2.x) / 2;
    let tubemidpointy = (point1.y + point2.y) / 2;
    let tubediffx = point2.x - point1.x;
    let tubediffy = point2.y - point1.y;
    let tubeangle = (Math.atan2(tubediffy, tubediffx) * 180) / Math.PI;
    let tubedist =
      Math.sqrt(Math.pow(tubediffx, 2) + Math.pow(tubediffy, 2)) * buildsc;
    if (Math.abs(tubedist - Math.round(tubedist)) < 0.1) {
      // Small rounding errors are chiselled off.
      tubedist = Math.round(tubedist);
    }

    let tubedistround = Math.ceil(tubedist / 300) * 300; // Round up to the nearest 300mm length.
    let tubedistnorm = tubedistround / buildsc;
    let tubetext = (tubedistround / 1000).toString();
    let firstLine = tubegroup.getObjects()[0];
    let firstText = tubegroup.getObjects()[1];
    tubegroup.remove(firstLine);
    tubegroup.remove(firstText);
    tubegroup.set({ top: 0, left: 0, height: 0, width: 0, angle: 0 });
    let ourTubeLine = new fabric.Line(
      [
        tubemidpointx - tubedistnorm / 2,
        tubemidpointy,
        tubemidpointx + tubedistnorm / 2,
        tubemidpointy,
      ],
      {
        strokeWidth: tubeWidth / buildsc,
        stroke: tubeColour,
        originX: "center",
        originY: "center",
      }
    );
    tubegroup.addWithUpdate(ourTubeLine);
    tubegroup.addWithUpdate(
      new fabric.Text(tubetext, {
        top: tubemidpointy,
        left: tubemidpointx,
        fontSize: (tubeWidth * 3) / buildsc,
        fontFamily: "sans-serif",
        originX: "center",
        originY: "center",
        backgroundColor: "white",
        stroke: tubeColour,
      })
    );
    tubegroup.rotate(tubeangle);
    tubegroup.setCoords();
  }

  /**
   * Connects a Tube between two bays.
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} buildsc The given scale for the canvas.
   * @param {boolean} truetofix Whether to fix the tube to the bays (true) or connect (false)
   * @param {point} point1 - The first point.
   * @param {fabric.ScaffoldGroup} scafgroup1 - The first scaffold group.
   * @param {point} point2 - The second point.
   * @param {fabric.ScaffoldGroup} scafgroup2 - The second scaffold group.
   * @return {Tube} the desired Tube.
   */

  static connectTubes(
    canv,
    buildsc,
    truetofix,
    point1,
    scafgroup1,
    point2,
    scafgroup2
  ) {
    // You generally don't connect tubes between the same bay.

    if (scafgroup1 === scafgroup2) {
      return null;
    }
    let connectcount = truetofix ? 2 : 0;
    let thisTube = Tube.connectPoints(
      canv,
      buildsc,
      point1,
      point2,
      connectcount
    );
    if (truetofix) {
      let thisLabel = thisTube.group.labelid;
      let trans1 = scafgroup1.calcTransformMatrix();
      let transinv1 = fabric.util.invertTransform(trans1);
      let newpoint1 = fabric.util.transformPoint(point1, transinv1);
      let trans2 = scafgroup2.calcTransformMatrix();
      let transinv2 = fabric.util.invertTransform(trans2);
      let newpoint2 = fabric.util.transformPoint(point2, transinv2);
      scafgroup1.tubeids.push(thisLabel);
      scafgroup1.tubecoords.push(newpoint1);
      scafgroup1.tubearray.push(thisTube.group);
      scafgroup2.tubeids.push(thisLabel);
      scafgroup2.tubecoords.push(newpoint2);
      scafgroup2.tubearray.push(thisTube.group);
    }
    return thisTube;
  }

  /**
   * Finds the coordinate ends of the tube
   * @param {canvas} canv The canvas to draw upon.
   * @param {fabric.StoreGroup} tubegroup The object to analyse.
   * @return {?array[fabric.Point]} The coordinates of the end (or null for non-Tube objects).
   *
   * Note: this code handles both "OriginX: left; OriginY: top" and "OriginX: center; OriginY center"
   * cases. To make coding easier, we mode from the former to the latter, but we decided to handle
   * both (for now).
   */

  static findCoordEnds(tubegroup) {
    if (tubegroup.subtype !== "TUBE") {
      return null;
    }
    let coordEnds = [];
    let startingcoords = [tubegroup.left, tubegroup.top];
    if (tubegroup.originX === "left" && tubegroup.originY === "top") {
      let firstdisp = rotatecoords(
        0,
        tubegroup.height / 2,
        -tubegroup.angle,
        1
      );
      coordEnds.push({
        x: startingcoords[0] + firstdisp[0],
        y: startingcoords[1] + firstdisp[1],
      });
      let seconddisp = rotatecoords(tubegroup.width, 0, -tubegroup.angle, 1);
      coordEnds.push({
        x: coordEnds[0].x + seconddisp[0],
        y: coordEnds[0].y + seconddisp[1],
      });
    } else {
      let dispmain = rotatecoords(tubegroup.width / 2, 0, -tubegroup.angle, 1);
      coordEnds.push({
        x: startingcoords[0] - dispmain[0],
        y: startingcoords[1] - dispmain[1],
      });
      coordEnds.push({
        x: startingcoords[0] + dispmain[0],
        y: startingcoords[1] + dispmain[1],
      });
    }
    return coordEnds;
  }

  /**
   * Connects an existing tube to a bay. If connected to another bay, then extends length.
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} buildsc The given scale for the canvas.
   * @param {fabric.StoreGroup} tubegroup The object to connect.
   * @param {boolean} tubeendfirst True to connect the first end; false to connect second end.
   * @param {fabric.ScaffoldGroup} scafgroup - The scaffold group to connect.
   * @return {boolean} true if successfully connected; false otherwise.
   */

  static connectExistingTube(
    canv,
    buildsc,
    tubegroup,
    tubeendfirst,
    scafgroup
  ) {
    if (tubegroup.subtype !== "TUBE") {
      return false;
    }
    if (scafgroup.tubeids.indexOf(tubegroup.labelid) !== -1) {
      return false;
    }
    if (tubegroup.noattachments === 2) {
      return false;
    }
    let coordEnds = Tube.findCoordEnds(tubegroup);
    let coordEnd = tubeendfirst ? coordEnds[0] : coordEnds[1];
    let otherCoordEnd = tubeendfirst ? coordEnds[1] : coordEnds[0];
    let scafBoundStands = scafgroup.betterfindBoundStand(buildsc);
    let coordIndex = findClosePoints(scafBoundStands, [coordEnd]);
    if (coordIndex === null) {
      return false;
    }
    let desiredCoordEnd = scafBoundStands[coordIndex[0]];
    let transmatrix = scafgroup.calcTransformMatrix();
    let transinv = fabric.util.invertTransform(transmatrix);
    let desiredCoordEndLocal = fabric.util.transformPoint(
      desiredCoordEnd,
      transinv
    );
    if (tubegroup.noattachments === 1) {
      if (tubeendfirst) {
        Tube.redrawTube(buildsc, tubegroup, desiredCoordEnd, otherCoordEnd);
      } else {
        Tube.redrawTube(buildsc, tubegroup, otherCoordEnd, desiredCoordEnd);
      }
    } else {
      let displaceX = desiredCoordEnd.x - coordEnd.x;
      let displaceY = desiredCoordEnd.y - coordEnd.y;
      let newCoordEnd = {
        x: otherCoordEnd.x + displaceX,
        y: otherCoordEnd.y + displaceY,
      };
      if (tubeendfirst) {
        Tube.redrawTube(buildsc, tubegroup, desiredCoordEnd, newCoordEnd);
      } else {
        Tube.redrawTube(buildsc, tubegroup, newCoordEnd, desiredCoordEnd);
      }
    }
    tubegroup.setCoords();
    tubegroup.noattachments = tubegroup.noattachments + 1;
    scafgroup.tubeids.push(tubegroup.labelid);
    scafgroup.tubecoords.push(desiredCoordEndLocal);
    scafgroup.tubearray.push(tubegroup);
    canv.renderAll();
    return true;
  }

  /**
   * Removes a connection between a Tube and a Scaffold Bay.
   * @param {fabric.StoreGroup} tubegroup - The fabric group associated with the tube.
   * @param {fabric.ScaffoldGroup} scafgroup - The scaffold group.
   * @return {boolean} true if the connection is successfully removed; false otherwuse.
   */

  static removeTubeConnection(tubegroup, scafgroup) {
    let tubelabel = tubegroup.labelid;
    let labelindex = scafgroup.tubeids.indexOf(tubelabel);
    if (labelindex === -1) {
      return false;
    }
    scafgroup.tubeids.splice(labelindex, 1);
    scafgroup.tubecoords.splice(labelindex, 1);
    scafgroup.tubearray.splice(labelindex, 1);
    tubegroup.noattachments = tubegroup.noattachments - 1;
    return true;
  }

  /**
   * Frees all tubes from a Scaffold Bay.
   * @param {fabric.ScaffoldGroup} scafgroup - The scaffold group.
   * @return {boolean} true if there are connections to be removed; false otherwise.
   */

  static freeAllTubes(scafgroup) {
    if (scafgroup.tubearray.length === 0) {
      return false;
    }
    for (let tube of scafgroup.tubearray) {
      tube.noattachments = tube.noattachments - 1;
    }
    scafgroup.tubecoords = [];
    scafgroup.tubeids = [];
    scafgroup.tubearray = [];
    return true;
  }

  /* Finds all scaffold bays for a tube, and frees it from them. */

  static unFixTube(canv, tubegroup) {
    let scafGroupsWithTube = canv.getObjects().filter(function (x) {
      return (
        x.type === "scaffoldGroup" &&
        x.tubeids.indexOf(tubegroup.labelid) !== -1
      );
    });
    for (let scafGroup of scafGroupsWithTube) {
      Tube.removeTubeConnection(tubegroup, scafGroup);
    }
  }
}

/* For drawing elevation plan ledgers and braces. */

class ElevPlanLedgerBrace extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos X-position coordinate for the centre.
   * @param {number} ypos Y-position coordinate for the centre.
   * @param {number} ledgerbracelen The length of the ledger/brace in mm.
   * @param {number} angle The angle of the ledger/brace.
   */
  constructor(canv, scale, xpos, ypos, ledgerbracelen, angle = 0) {
    super(canv, scale, xpos, ypos);
    this.ledgerbracelen = ledgerbracelen;
    this.angle = angle;
    let existingGroup = this.group;
    this.canv.remove(existingGroup);
    this.group = new fabric.StoreGroup([], {
      objlength: this.ledgerbracelen,
      subtype: "LEDGERBRACE",
      originX: "center",
      originY: "center",
      labelid: null,
      noattachments: 0,
    });
    this.canv.add(this.group);

    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.

    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);

    // Now we start calculate the coordinates of the corners of the bay.

    let lengthScaled = ledgerbracelen / scale;
    let ourLTS = new fabric.Line(
      [xpos - lengthScaled / 2, ypos, xpos + lengthScaled / 2, ypos],
      {
        fill: maincolour,
        stroke: maincolour,
        strokeWidth: (elevLedgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourLTS);
    let ourWhiteOverlay = new fabric.Line(
      [xpos - lengthScaled / 2, ypos, xpos + lengthScaled / 2, ypos],
      {
        fill: "white",
        stroke: "white",
        strokeWidth: (0.5 * elevLedgerLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourWhiteOverlay);
    let ourfirstCircle = new fabric.Circle({
      fill: maincolour,
      stroke: maincolour,
      radius: (0.25 * elevLedgerLineWidth * defaultScale) / this.scale,
      originX: "center",
      originY: "center",
      top: ypos,
      left: xpos - lengthScaled / 2,
    });

    this.group.addWithUpdate(ourfirstCircle);
    let oursecondCircle = new fabric.Circle({
      fill: maincolour,
      stroke: maincolour,
      radius: (0.25 * elevLedgerLineWidth * defaultScale) / this.scale,
      originX: "center",
      originY: "center",
      top: ypos,
      left: xpos + lengthScaled / 2,
    });
    this.group.addWithUpdate(oursecondCircle);
    this.group.set({ angle: angle });
  }
}

/* For drawing ladders */

class Ladder extends OrientScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {scaffoldorient} orient The orientation of the ladder
   * @param {number} xpos X-position coordinate for the bottom left corner.
   * @param {number} ypos Y-position coordinate for the bottom left canvas.
   * @param {number} ladderlen The length of the ladder in mm.
   */

  constructor(canv, scale, orient, xpos, ypos, ladderlen) {
    super(canv, scale, orient, xpos, ypos);
    this.ladderlen = ladderlen;
    let existingGroup = this.group;
    this.canv.remove(existingGroup);
    this.group = new fabric.StoreGroup([], {
      objlength: this.ladderlen,
      subtype: "LADDER",
      labelid: null,
      noattachments: 0,
    });
    this.canv.add(this.group);

    // Set the object so that there is no stretch that removes proportionality.
    // Set the object so that there is no stretching that resizes.

    this.group.setControlsVisibility(retainProport);
    this.group.setControlsVisibility(noStretch);

    // Now we start calculate the coordinates of the corners of the bay.

    let lengthScaled = ladderlen / scale;
    let rungWidthScaled = ladderRungWidth / scale;
    let numberOfRungs = Math.floor(ladderlen / ladderRungWidth - 1);
    let remainderAtEnd = (ladderlen - numberOfRungs * ladderRungWidth) / 2;
    let remainderScaled = remainderAtEnd / scale;
    let baseCorner = new PointTrans(xpos, ypos);
    let memberCorner = baseCorner.translate(0, lengthScaled, this.orient);
    let rungCorner = baseCorner.translate(rungWidthScaled, 0, this.orient);
    let farCorner = baseCorner.translate(
      rungWidthScaled,
      lengthScaled,
      this.orient
    );
    this.drawladderline(baseCorner, memberCorner);
    this.drawladderline(rungCorner, farCorner);

    // Let us add the rungs.

    let firstRungA, firstRungB;
    for (let i = 0; i <= numberOfRungs; i++) {
      firstRungA = baseCorner.translate(
        0,
        remainderScaled + i * rungWidthScaled,
        this.orient
      );
      firstRungB = rungCorner.translate(
        0,
        remainderScaled + i * rungWidthScaled,
        this.orient
      );
      this.drawladderline(firstRungA, firstRungB);
    }
  }

  /**
   * Draws a line representing part of a ladder on canvas.
   * @param {PointTrans} first the coordinates for the start of the line
   * @param {PointTrans} second the coordinates for the end of the line
   * @return {fabric.Line} a Fabric.Js object representing the line.
   */

  drawladderline(first, second) {
    let ourLadderLine = new fabric.Line(
      [first.xpos, first.ypos, second.xpos, second.ypos],
      {
        fill: maincolour,
        stroke: maincolour,
        strokeWidth: (ladderLineWidth * defaultScale) / this.scale,
        originX: "center",
        originY: "center",
      }
    );
    this.group.addWithUpdate(ourLadderLine);
    return ourLadderLine;
  }
}

/* For showing grids behind the screen. */

class Grid extends ScaffoldingBase {
  /**
   * @param {canvas} canv The canvas to draw upon.
   * @param {number} scale The scale of the drawing.
   * @param {number} xpos The x-position coordinate on the left.
   * @param {number} ypos The y-position coordinate on the top.
   * @param {number} width The width of the grid.
   * @param {number} height The height of the grid.
   * @param {number} griddist The size of the grid (in metres).
   * @param {number} boundwidth The width of the boundary line (in px).
   * @param {string} linecolour The colour to show lines.
   * @param {number} linewidth The width of the grid lines (in px.)
   */
  constructor(
    canv,
    scale,
    xpos,
    ypos,
    width,
    height,
    griddist,
    boundwidth,
    linecolour = maincolour,
    linewidth = 1
  ) {
    super(canv, scale, xpos, ypos);
    this.width = width;
    this.height = height;
    this.griddist = griddist;
    let gridScaled = this.griddist / this.scale;
    let maxGridsInHeight = Math.floor(this.height / gridScaled);
    let maxGridsInWidth = Math.floor(this.width / gridScaled);
    let heightRemainder = (this.height - maxGridsInHeight * gridScaled) / 2;
    let widthRemainder = (this.width - maxGridsInWidth * gridScaled) / 2;

    // You can stretch, as long as proportionality is kept.

    let theGroupElems = [];
    let theRect = new fabric.Rect({
      left: xpos,
      top: ypos,
      fill: invisible,
      width: width,
      height: height,
      strokeWidth: boundwidth,
      stroke: linecolour,
    });
    theGroupElems.push(theRect);

    // Now we can do the vertical lines.

    for (let i = 0; i < maxGridsInWidth + 1; i++) {
      let vertLine = new fabric.Line(
        [
          this.xpos + widthRemainder + i * gridScaled,
          this.ypos,
          this.xpos + widthRemainder + i * gridScaled,
          this.ypos + this.height,
        ],
        {
          fill: linecolour,
          stroke: linecolour,
          strokeWidth: linewidth,
          originX: "center",
          originY: "center",
        }
      );
      theGroupElems.push(vertLine);
    }

    // Now we can do the horizontal lines.

    for (let i = 0; i < maxGridsInHeight + 1; i++) {
      let hozLine = new fabric.Line(
        [
          this.xpos,
          this.ypos + heightRemainder + i * gridScaled,
          this.xpos + this.width,
          this.ypos + heightRemainder + i * gridScaled,
        ],
        {
          fill: linecolour,
          stroke: linecolour,
          strokeWidth: linewidth,
          originX: "center",
          originY: "center",
        }
      );
      theGroupElems.push(hozLine);
    }
    let existingGroup = this.group;
    this.canv.remove(existingGroup);
    this.group = new fabric.StoreGroup(theGroupElems, {
      objlength: 0,
      subtype: "GRID",
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      lockUniScaling: true,
      lockRotation: true,
      selectable: false,
      labelid: null,
      noattachments: 0,
    });
    this.canv.add(this.group);
    this.canv.sendToBack(this.group);
    this.group.setControlsVisibility(invisibleControls);
    this.canv.renderAll();
  }

  // Turns a Grid visible (or invisible)

  /**
   * @param {fabric.Group} group The group.
   * @param {boolean} bTrueForVisible Set true for visible; false for invisible.
   */

  static setVisibleOrInvisible(canv, group, bTrueForVisible) {
    let colour = invisible;
    if (bTrueForVisible) {
      colour = maincolour;
    }
    for (let i = 0; i < group._objects.length; i++) {
      group.item(i).set({ stroke: colour });
      if (group.item(i).type === "line") {
        group.set({ fill: colour });
      }
    }
    canv.renderAll();
  }
}

export {
  PointTrans,
  ScaffoldingBase,
  OrientScaffoldingBase,
  StairScaffoldingBase,
  BayRowItem,
  BayRow,
  LoadingBay,
  StretcherStair,
  ConstStair,
  ButtBay,
  LadderBay,
  ElevPlan,
  ElevPlanTie,
  ScafText,
  Ladder,
  Tube,
  Jack,
  Lap,
  Grid,
  LedgerTransom,
  ObliqueJack,
  tiecolour,
  facebracecolour,
  scaffoldties,
  scaffoldorient,
  scaffoldbrand,
  invisible,
  indivselectcolour,
  indivselectfill,
  stoppingtransomcolour,
  heightindbgnd,
  heightindborder,
  defaultGap,
  faceBraceGapBScale,
  lineIntersectsRectangle,
  defaultBoardWidth,
  intersects,
  heightgroupsubtype,
  maincolour,
  extrabaydir,
  addleftcornerboardlabel,
  addrightcornerboardlabel,
  ElevPlanLedgerBrace,
  defaultScale,
  altLineIntersectRectangle,
  maxanglemagdiff,
  betteredgedetection,
  islessInvPoleDetection,
  isnoninvasiveSidePoleDetection,
};
