import React from "react";

class YesNoModal extends React.Component {
  render() {
    return (
          <div
            className="modal fade"
            id={this.props.modalId}
            tabIndex="-1"
            role="dialog"
            aria-labelledby={this.props.modalLabelId}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id={this.props.modalLabelId}>
                    {this.props.modalTitle}
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.props.question}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={this.props.callonYes}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
    );
  }
}

export default YesNoModal;
