import React from "react";
import PropTypes from "prop-types";
import logo from "../scafflowlogocyan.png";
//import NavNotifyBadge from './NavNotifyBadge.js'
import { connect } from "react-redux";
import logoSvg from "../logo.png";
import { externalDomain } from "../reduxandotherstuff/env";
import { getCurrentUserId } from "../reduxandotherstuff/commonfabric";
import * as env from "../reduxandotherstuff/env";

class NavBarItem extends React.Component {
  render() {
    let activeState = "";
    if (this.props.isActive) {
      activeState = " active";
    }
    const classDef = "nav-item" + activeState;
    return (
      <li className={classDef}>
        <a className="nav-link" href={this.props.href}>
          {this.props.navText}
        </a>
      </li>
    );
  }
}

NavBarItem.propTypes = {
  isActive: PropTypes.bool,
  href: PropTypes.string,
  navText: PropTypes.string,
  theid: PropTypes.string,
  clickfunc: PropTypes.func,
};

class DropDownNavBarItem extends React.Component {
  render() {
    if (this.props.datatarget) {
      return (
        <a
          className="dropdown-item"
          href={this.props.href}
          data-toggle="modal"
          data-target={this.props.datatarget}
          aria-labelledby="navbarDropdown"
        >
          {this.props.navText}
        </a>
      );
    } else {
      return (
        <a
          className="dropdown-item"
          href={this.props.href}
          onClick={this.props.clickfunc}
        >
          {this.props.navText}
        </a>
      );
    }
  }
}

DropDownNavBarItem.propTypes = {
  clickfunc: PropTypes.func,
  navText: PropTypes.string,
  datatarget: PropTypes.string,
  href: PropTypes.string,
};

class NavBar extends React.Component {
  render() {
    const navbarItems = this.props.navbarItems.map((item, index) => (
      <NavBarItem
        isActive={item.isActive}
        href={item.href}
        navText={item.navText}
        key={index}
        id={item.theid}
        onClick={item.clickfunc}
      />
    ));

    let logoutbutton;

    let avatarSrc = logoSvg;
    if (
      this.props.getuserinfo.item &&
      this.props.getuserinfo.item.extraprofile.avatar
    ) {
      avatarSrc =
        externalDomain + this.props.getuserinfo.item.extraprofile.avatar;
    }

    if (getCurrentUserId()) {
      logoutbutton = (
        <ul className="navbar-nav navbar-right">
          <li className="nav-item">
            <a href="/myprofile" className="nav-link">
              <div className="inset">
                <img src={avatarSrc} alt="My profile" />
              </div>
            </a>
          </li>
          <li className="nav-item">
            <a href="/logout" className="nav-link">
              Log Out
            </a>
          </li>
        </ul>
      );
    } else {
      logoutbutton = <ul className="navbar-nav navbar-right" />;
    }

    let showLoadSaveMenu = false;
    if (
      this.props.saveImage ||
      this.props.savePDF ||
      this.props.saveDB ||
      this.props.loadJSON ||
      this.props.saveJSON ||
      this.props.fileChange
    ) {
      showLoadSaveMenu = true;
    }
    let numbers,
      addnavbaritems,
      viewnavbaritems,
      addmorenavbaritems,
      addevenmorenavbaritems,
      editnavbaritems,
      extraloadandsaveitems;

    if (this.props.addElemItems) {
      numbers = Array.from(
        { length: this.props.addElemItems.length },
        (v, k) => k + 1
      );
      addnavbaritems = numbers.map((number) =>
        this.props.addElemItems[number - 1].datatarget ? (
          <DropDownNavBarItem
            key={number.toString()}
            datatarget={this.props.addElemItems[number - 1].datatarget}
            navText={this.props.addElemItems[number - 1].navText}
          />
        ) : (
          <DropDownNavBarItem
            key={number.toString()}
            navText={this.props.addElemItems[number - 1].navText}
            clickfunc={this.props.addElemItems[number - 1].clickfunc}
          />
        )
      );
    }

    if (this.props.viewElemItems) {
      numbers = Array.from(
        { length: this.props.viewElemItems.length },
        (v, k) => k + 1
      );
      viewnavbaritems = numbers.map((number) =>
        this.props.viewElemItems[number - 1].datatarget ? (
          <DropDownNavBarItem
            key={number.toString()}
            datatarget={this.props.viewElemItems[number - 1].datatarget}
            navText={this.props.viewElemItems[number - 1].navText}
          />
        ) : (
          <DropDownNavBarItem
            key={number.toString()}
            navText={this.props.viewElemItems[number - 1].navText}
            clickfunc={this.props.viewElemItems[number - 1].clickfunc}
          />
        )
      );
    }

    if (this.props.addMoreElemItems) {
      numbers = Array.from(
        { length: this.props.addMoreElemItems.length },
        (v, k) => k + 1
      );
      addmorenavbaritems = numbers.map((number) =>
        this.props.addMoreElemItems[number - 1].datatarget ? (
          <DropDownNavBarItem
            key={number.toString()}
            datatarget={this.props.addMoreElemItems[number - 1].datatarget}
            navText={this.props.addMoreElemItems[number - 1].navText}
          />
        ) : (
          <DropDownNavBarItem
            key={number.toString()}
            navText={this.props.addMoreElemItems[number - 1].navText}
            clickfunc={this.props.addMoreElemItems[number - 1].clickfunc}
          />
        )
      );
    }

    if (this.props.addEvenMoreElemItems) {
      numbers = Array.from(
        { length: this.props.addEvenMoreElemItems.length },
        (v, k) => k + 1
      );
      addevenmorenavbaritems = numbers.map((number) =>
        this.props.addEvenMoreElemItems[number - 1].datatarget ? (
          <DropDownNavBarItem
            key={number.toString()}
            datatarget={this.props.addEvenMoreElemItems[number - 1].datatarget}
            navText={this.props.addEvenMoreElemItems[number - 1].navText}
          />
        ) : (
          <DropDownNavBarItem
            key={number.toString()}
            navText={this.props.addEvenMoreElemItems[number - 1].navText}
            clickfunc={this.props.addEvenMoreElemItems[number - 1].clickfunc}
          />
        )
      );
    }

    if (this.props.editElemItems) {
      numbers = Array.from(
        { length: this.props.editElemItems.length },
        (v, k) => k + 1
      );
      editnavbaritems = numbers.map((number) =>
        this.props.editElemItems[number - 1].datatarget ? (
          <DropDownNavBarItem
            key={number.toString()}
            datatarget={this.props.editElemItems[number - 1].datatarget}
            navText={this.props.editElemItems[number - 1].navText}
          />
        ) : (
          <DropDownNavBarItem
            key={number.toString()}
            navText={this.props.editElemItems[number - 1].navText}
            clickfunc={this.props.editElemItems[number - 1].clickfunc}
          />
        )
      );
    }

    if (this.props.extraLoadandSaveItems) {
      numbers = Array.from(
        { length: this.props.extraLoadandSaveItems.length },
        (v, k) => k + 1
      );
      extraloadandsaveitems = numbers.map((number) =>
        this.props.extraLoadandSaveItems[number - 1].datatarget ? (
          <DropDownNavBarItem
            key={number.toString()}
            datatarget={this.props.extraLoadandSaveItems[number - 1].datatarget}
            navText={this.props.extraLoadandSaveItems[number - 1].navText}
          />
        ) : (
          <DropDownNavBarItem
            key={number.toString()}
            navText={this.props.extraLoadandSaveItems[number - 1].navText}
            clickfunc={this.props.extraLoadandSaveItems[number - 1].clickfunc}
          />
        )
      );
    }

    return (
      <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <a className="navbar-brand" href=".">
          <img src={logo} alt="Scafflow" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExampleDefault"
          aria-controls="navbarsExampleDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarsExampleDefault">
          <ul className="navbar-nav mr-auto">
            {navbarItems}{" "}
            {showLoadSaveMenu && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="."
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Load and Save
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {this.props.loadJSON && env.debugMode && (
                    <a
                      className="dropdown-item"
                      id="loadjsonabbrev"
                      href="/loadjson"
                      onClick={this.props.loadJSON}
                    >
                      Load from File
                    </a>
                  )}
                  {this.props.saveJSON && env.debugMode && (
                    <a
                      className="dropdown-item"
                      id="downloadjsonabbrev"
                      href="/savejson"
                      onClick={this.props.saveJSON}
                    >
                      Save to File
                    </a>
                  )}
                  {this.props.saveDB && (
                    <a
                      className="dropdown-item"
                      id="savedb"
                      href="/savedb"
                      onClick={this.props.saveDB}
                    >
                      Save to Database
                    </a>
                  )}
                  {this.props.saveImage && (
                    <a
                      className="dropdown-item"
                      id="downloadimg"
                      href="/img"
                      onClick={this.props.saveImage}
                    >
                      Save as Image
                    </a>
                  )}
                  {this.props.savePDF && (
                    <a
                      className="dropdown-item"
                      id="downloadpdf"
                      href="/pdf"
                      onClick={this.props.savePDF}
                    >
                      Save as PDF
                    </a>
                  )}
                  {this.props.extraLoadandSaveItems && (
                    <>{extraloadandsaveitems}</>
                  )}
                  {this.props.fileChange && (
                    <input
                      type="file"
                      id="loadjsonabbrevelem"
                      onChange={this.props.fileChange}
                    />
                  )}
                </div>
              </li>
            )}
            {this.props.editElemItems && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="."
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Edit
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {editnavbaritems}
                </div>
              </li>
            )}
            {this.props.viewElemItems && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="."
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  View
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {viewnavbaritems}
                </div>
              </li>
            )}
            {this.props.addElemItems && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="."
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.props.addElemItemLabel}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {addnavbaritems}
                </div>
              </li>
            )}
            {this.props.addMoreElemItems && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="."
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.props.addMoreElemItemLabel}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {addmorenavbaritems}
                </div>
              </li>
            )}
            {this.props.addEvenMoreElemItems && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="."
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.props.addEvenMoreElemItemLabel}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {addevenmorenavbaritems}
                </div>
              </li>
            )}
          </ul>
        </div>
        {logoutbutton}
      </nav>
    );
  }
}

NavBar.propTypes = {
  navbarItems: PropTypes.arrayOf(PropTypes.object),
  saveImage: PropTypes.func,
  saveDB: PropTypes.func,
  savePDF: PropTypes.func,
  loadJSON: PropTypes.func,
  saveJSON: PropTypes.func,
  fileChange: PropTypes.func,
  addElemItems: PropTypes.arrayOf(PropTypes.object),
  viewElemItems: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps(state) {
  return {
    getuserinfo: state.getuserinfo,
  };
}

export default connect(mapStateToProps)(NavBar);
