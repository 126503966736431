import * as constants from "./constants";
import { combineReducers } from "redux";

/* There are common patterns reducers appear to satisfy, so let's give some
constants for initialisation. */

/* For getting individual items. */

const initialGETState = {
  item: null,
  loading: false,
  error: null
};

/* For listing items and posting. */

const initialLISTandPOSTState = {
  items: [],
  loading: false,
  error: null
};

/* For loading objects like logos. */

const initialLOAD = {
  status: 0,
  error: null
};

/* For putting and deleting. */

const initialPUTandDELETEState = {
  item: [],
  loading: false,
  error: null
};

/* For reducers for listing items. */

function defaultLISTReducer(
  state,
  action,
  actBeginType,
  actSuccessType,
  actFailureType,
  actClearType
) {
  switch (action.type) {
    case actBeginType:
      return {
        items: [],
        loading: true,
        error: null
      };

    case actSuccessType:
      return {
        items: action.payload,
        loading: false,
        error: null
      };

    case actFailureType:
      return {
        items: [],
        loading: false,
        error: action.payload
      };

    case actClearType:
      return {
        items: [],
        loading: false,
        error: null
      };

    default:
      return state;
  }
}

/* For reducers for getting items. */

function defaultGETReducer(
  state,
  action,
  actBeginType,
  actSuccessType,
  actFailureType
) {
  switch (action.type) {
    case actBeginType:
      return {
        item: null,
        loading: true,
        error: null
      };

    case actSuccessType:
      return {
        item: action.payload,
        loading: false,
        error: null
      };

    case actFailureType:
      return {
        item: null,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}

/* For reducers for posting items. */

function defaultPOSTReducer(
  state,
  action,
  actBeginType,
  actSuccessType,
  actFailureType
) {
  switch (action.type) {
    case actBeginType:
      return {
        items: [],
        loading: true,
        error: null
      };

    case actSuccessType:
      return {
        items: action.payload,
        loading: false,
        error: null
      };

    case actFailureType:
      return {
        items: [],
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}

/* For reducers for putting items. */

function defaultPUTReducer(
  state,
  action,
  actBeginType,
  actSuccessType,
  actFailureType
) {
  switch (action.type) {
    case actBeginType:
      return {
        item: [],
        loading: true,
        error: null
      };

    case actSuccessType:
      return {
        item: action.payload,
        loading: false,
        error: null
      };

    case actFailureType:
      return {
        item: [],
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}

/* For reducers for deleting items. */

function defaultDELETEReducer(
  state,
  action,
  actBeginType,
  actSuccessType,
  actFailureType
) {
  switch (action.type) {
    case actBeginType:
      return {
        item: [],
        loading: true,
        error: null
      };

    case actSuccessType:
      return {
        item: action.payload,
        loading: false,
        error: null
      };

    case actFailureType:
      return {
        item: [],
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}

/* For reducers for loading items (images); not normally for REST APIs. */

function defaultLOADReducer(
  state,
  action,
  actBeginType,
  actSuccessType,
  actFailureType
) {
  switch (action.type) {
    case actBeginType:
      return {
        status: 1,
        error: null
      };

    case actSuccessType:
      return {
        status: 0,
        error: null
      };

    case actFailureType:
      return {
        status: 2,
        error: action.payload
      };

    default:
      return state;
  }
}

export function dummyinfo(state = {}, action) {
  switch (action.type) {
    case constants.DUMMY_INFO:
      return {};
    default:
      return state;
  }
}

export function getlogininfo(state = initialLISTandPOSTState, action) {
  return defaultLISTReducer(
    state,
    action,
    constants.GET_LOGIN_BEGIN,
    constants.GET_LOGIN_SUCCESS,
    constants.GET_LOGIN_FAILURE,
    constants.CLEAR_LOGIN
  );
}

export function getuseridinfo(state = initialLISTandPOSTState, action) {
  return defaultLISTReducer(
    state,
    action,
    constants.GET_USERID_BEGIN,
    constants.GET_USERID_SUCCESS,
    constants.GET_USERID_FAILURE,
    constants.CLEAR_USERID
  );
}

export function getusersinfo(state = initialLISTandPOSTState, action) {
  return defaultLISTReducer(
    state,
    action,
    constants.GET_USERS_BEGIN,
    constants.GET_USERS_SUCCESS,
    constants.GET_USERS_FAILURE,
    constants.CLEAR_USERS
  );
}

export function ufilersinfo(state = initialLISTandPOSTState, action) {
  return defaultLISTReducer(
    state,
    action,
    constants.GET_UFILERS_BEGIN,
    constants.GET_UFILERS_SUCCESS,
    constants.GET_UFILERS_FAILURE,
    constants.CLEAR_UFILERS
  );
}

export function plansinfo(state = initialLISTandPOSTState, action) {
  return defaultLISTReducer(
    state,
    action,
    constants.GET_PLANS_BEGIN,
    constants.GET_PLANS_SUCCESS,
    constants.GET_PLANS_FAILURE,
    constants.CLEAR_PLANS
  );
}

export function planinfo(state = initialGETState, action) {
  return defaultGETReducer(
    state,
    action,
    constants.GET_PLAN_BEGIN,
    constants.GET_PLAN_SUCCESS,
    constants.GET_PLAN_FAILURE
  );
}

export function postplaninfo(state = initialLISTandPOSTState, action) {
  return defaultPOSTReducer(
    state,
    action,
    constants.POST_PLAN_BEGIN,
    constants.POST_PLAN_SUCCESS,
    constants.POST_PLAN_FAILURE
  );
}

export function putplaninfo(state = initialPUTandDELETEState, action) {
  return defaultPUTReducer(
    state,
    action,
    constants.PUT_PLAN_BEGIN,
    constants.PUT_PLAN_SUCCESS,
    constants.PUT_PLAN_FAILURE
  );
}

export function deleteplaninfo(state = initialPUTandDELETEState, action) {
  return defaultDELETEReducer(
    state,
    action,
    constants.DELETE_PLAN_BEGIN,
    constants.DELETE_PLAN_SUCCESS,
    constants.DELETE_PLAN_FAILURE
  );
}

export function getpagesinfo(state = initialLISTandPOSTState, action) {
  return defaultLISTReducer(
    state,
    action,
    constants.GET_PAGES_BEGIN,
    constants.GET_PAGES_SUCCESS,
    constants.GET_PAGES_FAILURE,
    constants.CLEAR_PAGES
  );
}

export function getpageinfo(state = initialGETState, action) {
  return defaultGETReducer(
    state,
    action,
    constants.GET_PAGE_BEGIN,
    constants.GET_PAGE_SUCCESS,
    constants.GET_PAGE_FAILURE
  );
}

export function putpageinfo(state = initialPUTandDELETEState, action) {
  return defaultPUTReducer(
    state,
    action,
    constants.PUT_PAGE_BEGIN,
    constants.PUT_PAGE_SUCCESS,
    constants.PUT_PAGE_FAILURE
  );
}

export function loadimageinfo(state = initialLOAD, action) {
  return defaultLOADReducer(
    state,
    action,
    constants.LOADIMAGE_BEGIN,
    constants.LOADIMAGE_SUCCESS,
    constants.LOADIMAGE_FAILURE
  );
}

export function loadboundinfo(state = initialLOAD, action) {
  return defaultLOADReducer(
    state,
    action,
    constants.LOADBOUND_BEGIN,
    constants.LOADBOUND_SUCCESS,
    constants.LOADBOUND_FAILURE
  );
}

export function loadlogoinfo(state = initialLOAD, action) {
  return defaultLOADReducer(
    state,
    action,
    constants.LOADLOGO_BEGIN,
    constants.LOADLOGO_SUCCESS,
    constants.LOADLOGO_FAILURE
  );
}

export function getgennotesinfo(state = initialLISTandPOSTState, action) {
  return defaultLISTReducer(
    state,
    action,
    constants.GET_GENNOTES_BEGIN,
    constants.GET_GENNOTES_SUCCESS,
    constants.GET_GENNOTES_FAILURE,
    constants.CLEAR_GENNOTES
  );
}

export function getgennoteinfo(state = initialGETState, action) {
  return defaultGETReducer(
    state,
    action,
    constants.GET_GENNOTE_BEGIN,
    constants.GET_GENNOTE_SUCCESS,
    constants.GET_GENNOTE_FAILURE
  );
}

export function postgennoteinfo(state = initialLISTandPOSTState, action) {
  return defaultPOSTReducer(
    state,
    action,
    constants.POST_GENNOTE_BEGIN,
    constants.POST_GENNOTE_SUCCESS,
    constants.POST_GENNOTE_FAILURE
  );
}

export function putgennoteinfo(state = initialPUTandDELETEState, action) {
  return defaultPUTReducer(
    state,
    action,
    constants.PUT_GENNOTE_BEGIN,
    constants.PUT_GENNOTE_SUCCESS,
    constants.PUT_GENNOTE_FAILURE
  );
}

export function deletegennoteinfo(state = initialPUTandDELETEState, action) {
  return defaultDELETEReducer(
    state,
    action,
    constants.DELETE_GENNOTE_BEGIN,
    constants.DELETE_GENNOTE_SUCCESS,
    constants.DELETE_GENNOTE_FAILURE
  );
}

export function getimagedatasinfo(state = initialLISTandPOSTState, action) {
  return defaultLISTReducer(
    state,
    action,
    constants.GET_IMAGEDATAS_BEGIN,
    constants.GET_IMAGEDATAS_SUCCESS,
    constants.GET_IMAGEDATAS_FAILURE,
    constants.CLEAR_IMAGEDATAS
  );
}

export function getimagedatainfo(state = initialGETState, action) {
  return defaultGETReducer(
    state,
    action,
    constants.GET_IMAGEDATA_BEGIN,
    constants.GET_IMAGEDATA_SUCCESS,
    constants.GET_IMAGEDATA_FAILURE
  );
}

export function postimagedatainfo(state = initialLISTandPOSTState, action) {
  return defaultPOSTReducer(
    state,
    action,
    constants.POST_IMAGEDATA_BEGIN,
    constants.POST_IMAGEDATA_SUCCESS,
    constants.POST_IMAGEDATA_FAILURE
  );
}

export function putimagedatainfo(state = initialPUTandDELETEState, action) {
  return defaultPUTReducer(
    state,
    action,
    constants.PUT_IMAGEDATA_BEGIN,
    constants.PUT_IMAGEDATA_SUCCESS,
    constants.PUT_IMAGEDATA_FAILURE
  );
}

export function deleteimagedatainfo(state = initialPUTandDELETEState, action) {
  return defaultDELETEReducer(
    state,
    action,
    constants.DELETE_IMAGEDATA_BEGIN,
    constants.DELETE_IMAGEDATA_SUCCESS,
    constants.DELETE_IMAGEDATA_FAILURE
  );
}

export function getfileresultsinfo(state = initialLISTandPOSTState, action) {
  return defaultLISTReducer(
    state,
    action,
    constants.GET_FILERESULTS_BEGIN,
    constants.GET_FILERESULTS_SUCCESS,
    constants.GET_FILERESULTS_FAILURE,
    constants.CLEAR_FILERESULTS
  );
}

export function getfileresultinfo(state = initialGETState, action) {
  return defaultGETReducer(
    state,
    action,
    constants.GET_FILERESULT_BEGIN,
    constants.GET_FILERESULT_SUCCESS,
    constants.GET_FILERESULT_FAILURE
  );
}

export function postfileresultinfo(state = initialLISTandPOSTState, action) {
  return defaultPOSTReducer(
    state,
    action,
    constants.POST_FILERESULT_BEGIN,
    constants.POST_FILERESULT_SUCCESS,
    constants.POST_FILERESULT_FAILURE
  );
}

export function putfileresultinfo(state = initialPUTandDELETEState, action) {
  return defaultPUTReducer(
    state,
    action,
    constants.PUT_FILERESULT_BEGIN,
    constants.PUT_FILERESULT_SUCCESS,
    constants.PUT_FILERESULT_FAILURE
  );
}

export function deletefileresultinfo(state = initialPUTandDELETEState, action) {
  return defaultDELETEReducer(
    state,
    action,
    constants.DELETE_FILERESULT_BEGIN,
    constants.DELETE_FILERESULT_SUCCESS,
    constants.DELETE_FILERESULT_FAILURE
  );
}

export function uscafitemsheetsinfo(state = initialLISTandPOSTState, action) {
  return defaultLISTReducer(
    state,
    action,
    constants.GET_USCAFITEMSHEETS_BEGIN,
    constants.GET_USCAFITEMSHEETS_SUCCESS,
    constants.GET_USCAFITEMSHEETS_FAILURE,
    constants.CLEAR_USCAFITEMSHEETS
  );
}

export function getscafitemsheetsinfo(state = initialLISTandPOSTState, action) {
  return defaultLISTReducer(
    state,
    action,
    constants.GET_SCAFITEMSHEETS_BEGIN,
    constants.GET_SCAFITEMSHEETS_SUCCESS,
    constants.GET_SCAFITEMSHEETS_FAILURE,
    constants.CLEAR_SCAFITEMSHEETS
  );
}

export function getscafitemsheetinfo(state = initialGETState, action) {
  return defaultGETReducer(
    state,
    action,
    constants.GET_SCAFITEMSHEET_BEGIN,
    constants.GET_SCAFITEMSHEET_SUCCESS,
    constants.GET_SCAFITEMSHEET_FAILURE
  );
}

export function postscafitemsheetinfo(state = initialLISTandPOSTState, action) {
  return defaultPOSTReducer(
    state,
    action,
    constants.POST_SCAFITEMSHEET_BEGIN,
    constants.POST_SCAFITEMSHEET_SUCCESS,
    constants.POST_SCAFITEMSHEET_FAILURE
  );
}

export function putscafitemsheetinfo(state = initialPUTandDELETEState, action) {
  return defaultPUTReducer(
    state,
    action,
    constants.PUT_SCAFITEMSHEET_BEGIN,
    constants.PUT_SCAFITEMSHEET_SUCCESS,
    constants.PUT_SCAFITEMSHEET_FAILURE
  );
}

export function deletescafitemsheetinfo(
  state = initialPUTandDELETEState,
  action
) {
  return defaultDELETEReducer(
    state,
    action,
    constants.DELETE_SCAFITEMSHEET_BEGIN,
    constants.DELETE_SCAFITEMSHEET_SUCCESS,
    constants.DELETE_SCAFITEMSHEET_FAILURE
  );
}

export function getuserinfo(state = initialGETState, action) {
  return defaultGETReducer(
    state,
    action,
    constants.GET_USER_BEGIN,
    constants.GET_USER_SUCCESS,
    constants.GET_USER_FAILURE
  );
}

export function postuserinfo(state = initialLISTandPOSTState, action) {
  return defaultPOSTReducer(
    state,
    action,
    constants.POST_USER_BEGIN,
    constants.POST_USER_SUCCESS,
    constants.POST_USER_FAILURE
  );
}

export function putuserinfo(state = initialPUTandDELETEState, action) {
  return defaultPUTReducer(
    state,
    action,
    constants.PUT_USER_BEGIN,
    constants.PUT_USER_SUCCESS,
    constants.PUT_USER_FAILURE
  );
}

export function activateuserinfo(state = initialLISTandPOSTState, action) {
  return defaultPOSTReducer(
    state,
    action,
    constants.ACTIVATE_USER_BEGIN,
    constants.ACTIVATE_USER_SUCCESS,
    constants.ACTIVATE_USER_FAILURE
  );
}

export function resenduserinfo(state = initialLISTandPOSTState, action) {
  return defaultPOSTReducer(
    state,
    action,
    constants.RESEND_USER_BEGIN,
    constants.RESEND_USER_SUCCESS,
    constants.RESEND_USER_FAILURE
  );
}

export function genimginfo(state = initialLOAD, action) {
  return defaultLOADReducer(
    state,
    action,
    constants.GENIMG_BEGIN,
    constants.GENIMG_SUCCESS,
    constants.GENIMG_FAILURE
  );
}

export function genpdfinfo(state = initialLOAD, action) {
  return defaultLOADReducer(
    state,
    action,
    constants.GENPDF_BEGIN,
    constants.GENPDF_SUCCESS,
    constants.GENPDF_FAILURE
  );
}

export function postpasswordinfo(state = initialLISTandPOSTState, action) {
  return defaultPOSTReducer(
    state,
    action,
    constants.POST_PASSWORD_BEGIN,
    constants.POST_PASSWORD_SUCCESS,
    constants.POST_PASSWORD_FAILURE
  );
}

export function resetpasswordinfo(state = initialLISTandPOSTState, action) {
  return defaultPOSTReducer(
    state,
    action,
    constants.RESET_PASSWORD_BEGIN,
    constants.RESET_PASSWORD_SUCCESS,
    constants.RESET_PASSWORD_FAILURE
  );
}

export function confirmpasswordinfo(state = initialLISTandPOSTState, action) {
  return defaultPOSTReducer(
    state,
    action,
    constants.CONFIRM_PASSWORD_BEGIN,
    constants.CONFIRM_PASSWORD_SUCCESS,
    constants.CONFIRM_PASSWORD_FAILURE
  );
}

const rootReducer = combineReducers({
  dummyinfo,
  getlogininfo,
  getuseridinfo,
  getusersinfo,
  ufilersinfo,
  plansinfo,
  planinfo,
  postplaninfo,
  putplaninfo,
  deleteplaninfo,
  getpagesinfo,
  putpageinfo,
  getpageinfo,
  loadboundinfo,
  loadimageinfo,
  getgennotesinfo,
  getgennoteinfo,
  postgennoteinfo,
  putgennoteinfo,
  deletegennoteinfo,
  getimagedatasinfo,
  getimagedatainfo,
  postimagedatainfo,
  putimagedatainfo,
  deleteimagedatainfo,
  getfileresultsinfo,
  getfileresultinfo,
  postfileresultinfo,
  putfileresultinfo,
  deletefileresultinfo,
  getscafitemsheetsinfo,
  getscafitemsheetinfo,
  postscafitemsheetinfo,
  putscafitemsheetinfo,
  deletescafitemsheetinfo,
  uscafitemsheetsinfo,
  loadlogoinfo,
  getuserinfo,
  postuserinfo,
  putuserinfo,
  activateuserinfo,
  resenduserinfo,
  genimginfo,
  genpdfinfo,
  postpasswordinfo,
  resetpasswordinfo,
  confirmpasswordinfo
});

export default rootReducer;
