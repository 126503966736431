import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootReducer from "./reducers";

// Just to prevent our storage blowing out (and causing DomExceptions on Firefox),
// we limit our whitelist to:
// (i) Anything user related.
// (ii) The list of plans for the logged in user.
// We need to test this naturally.

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    "dummyinfo",
    "getlogininfo",
    "getuseridinfo",
    "getusersinfo",
    "getuserinfo",
    "plansinfo",
    "postuserinfo",
    "putuserinfo",
    "activateuserinfo",
    "resenduserinfo",
    "postpasswordinfo",
    "resetpasswordinfo",
    "confirmpasswordinfo",
  ],
};
const pReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  pReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunkMiddleware))
);

const persistor = persistStore(store);
export { persistor, store };
