import React from "react";
import { connect } from "react-redux";
import {
  getLoginActions,
  getUserIdActions,
  getPlansActions
} from "../reduxandotherstuff/actions";

class LogoutPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(getLoginActions.logout());
    this.props.dispatch(getUserIdActions.clearUserId());
    this.props.dispatch(getPlansActions.clearPlans());
  }

  render() {
    return (<div/>);
  }
}

function mapStateToProps(state) {
  return {
    getlogininfo: state.getlogininfo
  };
}

export default connect(mapStateToProps)(LogoutPage);
