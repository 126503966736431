import React from "react";
import SideMenuItem from "./SideMenuItem.js";

class SideMenu extends React.Component {
  render() {
    let numbers = Array.from(
      { length: this.props.entries.length },
      (v, k) => k + 1
    );

    let sidemenuitems = numbers.map(number =>
      this.props.entries[number - 1] ? (
        <SideMenuItem
          key={number.toString()}
          icon={this.props.entries[number - 1].icon}
          text={this.props.entries[number - 1].text}
          enabling={this.props.entries[number - 1].enabling}
          hrefval={this.props.entries[number - 1].hrefval}
          circlearound={this.props.entries[number - 1].circlearound}
          datatarget={this.props.entries[number - 1].datatarget}
        />
      ) : (
        <hr key={number.toString()} />
      )
    );

    return <>{sidemenuitems}</>;
  }
}

export default SideMenu;
