import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import "./App.css";
import { history } from "./reduxandotherstuff/constants";
import { dummyActions } from "./reduxandotherstuff/actions";
import { Helmet } from "react-helmet";
import asyncComponent from "./components/AsyncComponent";
import LoginPage from "./components/LoginPage";
import PrivatePage from "./components/PrivatePage";
import LogoutPage from "./components/LogoutPage";

// We are going to use the trick from "Code Splitting in Create React App"
// https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
// To load all the pages asynchronously. That will hopefully prevent the page
// load get too big for any individual page.

// However, we are going to make exceptions for 3 pages: Home, Login and Private.
// Sometimes we get infinite recursion when trying to access plans pages with
// old tokens, so we are going to make those pages normally imported.

//const AsyncHomePage = asyncComponent(() => import("./components/HomePage"));
//const AsyncLoginPage = asyncComponent(() => import("./components/LoginPage"));
//const AsyncPrivatePage = asyncComponent(() => import("./components/PrivatePage"));
const AsyncPlanDetailPage = asyncComponent(() =>
  import("./components/PlanDetailPage")
);
const AsyncGennotesPage = asyncComponent(() =>
  import("./components/GennotesPage")
);
const AsyncImageDataPage = asyncComponent(() =>
  import("./components/ImageDataPage")
);
const AsyncNoMatchPage = asyncComponent(() =>
  import("./components/NoMatchPage")
);
const AsyncMyProfilePage = asyncComponent(() =>
  import("./components/MyProfilePage")
);
const AsyncPlanCatPage = asyncComponent(() =>
  import("./components/PlanCatPage")
);
const AsyncPlanDetailSelectedPage = asyncComponent(() =>
  import("./components/PlanDetailSelectedPage")
);
const AsyncPlanDetailSavedPDFsPage = asyncComponent(() =>
  import("./components/PlanDetailSavedPDFsPage")
);
const AsyncRecentPage = asyncComponent(() => import("./components/RecentPage"));
const AsyncLatestScafSheetPage = asyncComponent(() =>
  import("./components/LatestScafSheetPage")
);
const AsyncPlanDetailScafSheetPage = asyncComponent(() =>
  import("./components/PlanDetailScafSheetPage")
);

const AsyncUsersListPage = asyncComponent(() =>
  import("./components/UsersListPage")
);

const AsyncActivatePage = asyncComponent(() =>
  import("./components/ActivatePage")
);

const PrivateRoute = ({
  redirector: Redirector,
  component: Component,
  settings: Settings,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("usertoken") ? (
        <Component {...props} settings={Settings} />
      ) : (
        <Redirect to={Redirector} />
      )
    }
  />
);

const NeverPrivateRoute = ({
  redirector: Redirector,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !localStorage.getItem("usertoken") ? (
        <Component {...props} />
      ) : (
        <Redirect to={Redirector} />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
      dispatch(dummyActions.dummy());
    });
  }

  render() {
    return (
      <div className="App">
        <Helmet>
          <meta
            name="keywords"
            content="Scaffold, transom, standard, ledger, hopup, tie, Brisbane"
          />
          <meta
            name="description"
            content="Scafflow: for all your IT scaffolding solutions."
          />
          <title>Scafflow</title>
        </Helmet>
        <Router history={history}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/logout" component={LogoutPage} />
            <NeverPrivateRoute
              path="/login"
              redirector="/plans"
              component={LoginPage}
            />
            <Route
              path="/activate/:uid/:token/"
              component={AsyncActivatePage}
            />
            <PrivateRoute
              path="/myprofile"
              redirector="/login"
              component={AsyncMyProfilePage}
              settings={false}
            />
            <PrivateRoute
              path="/savedpdfs"
              redirector="/login"
              component={AsyncRecentPage}
              settings={false}
            />
            <PrivateRoute
              path="/savedscafsheets"
              redirector="/login"
              component={AsyncLatestScafSheetPage}
              settings={false}
            />

            <PrivateRoute
              path="/plans/:id/pages/:pageno"
              redirector="/login"
              component={AsyncImageDataPage}
              settings={false}
            />
            <PrivateRoute
              path="/plans/:id/gennotes/:number/:size/:resolution"
              redirector="/login"
              component={AsyncGennotesPage}
            />
            <PrivateRoute
              path="/plans/:id/selectedpages"
              redirector="/login"
              component={AsyncPlanDetailSelectedPage}
            />
            <PrivateRoute
              path="/plans/:id/savedpdfs"
              redirector="/login"
              component={AsyncPlanDetailSavedPDFsPage}
            />

            <PrivateRoute
              path="/userslist"
              redirector="/login"
              component={AsyncUsersListPage}
            />

            <PrivateRoute
              path="/plans/:id/savedscafsheets"
              redirector="/login"
              component={AsyncPlanDetailScafSheetPage}
            />
            <PrivateRoute
              path="/plans/:id"
              redirector="/login"
              component={AsyncPlanDetailPage}
            />
            <PrivateRoute
              path="/plancat/:cat"
              redirector="/login"
              component={AsyncPlanCatPage}
            />
            <PrivateRoute
              path="/plans"
              redirector="/login"
              component={PrivatePage}
            />
            <Route component={AsyncNoMatchPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { dummyinfo } = state;
  return {
    dummyinfo,
  };
}

export default connect(mapStateToProps)(App);
