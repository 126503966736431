import { createBrowserHistory } from "history";
import { wrapandbreak } from "./commonfabric";

// Need this action/reducer to keep store persistantly hydrated.

export const DUMMY_INFO = "DUMMY_INFO";
export const GET_LOGIN_BEGIN = "GET_LOGIN_BEGIN";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAILURE = "GET_LOGIN_FAILURE";
export const CLEAR_LOGIN = "CLEAR_LOGIN";
export const GET_USERID_BEGIN = "GET_USERID_BEGIN";
export const GET_USERID_SUCCESS = "GET_USERID_SUCCESS";
export const GET_USERID_FAILURE = "GET_USERID_FAILURE";
export const CLEAR_USERID = "CLEAR_USERID";
export const GET_USERS_BEGIN = "GET_USERS_BEGIN";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const CLEAR_USERS = "CLEAR_USERS";
export const GET_PLANS_BEGIN = "GET_PLANS_BEGIN";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAILURE = "GET_PLANS_FAILURE";
export const CLEAR_PLANS = "CLEAR_PLANS";
export const GET_UFILERS_BEGIN = "GET_UFILERS_BEGIN";
export const GET_UFILERS_SUCCESS = "GET_UFILERS_SUCCESS";
export const GET_UFILERS_FAILURE = "GET_UFILERS_FAILURE";
export const CLEAR_UFILERS = "CLEAR_UFILERS";
export const GET_USCAFITEMSHEETS_BEGIN = "GET_USCAFITEMSHEETS_BEGIN";
export const GET_USCAFITEMSHEETS_SUCCESS = "GET_USCAFITEMSHEETS_SUCCESS";
export const GET_USCAFITEMSHEETS_FAILURE = "GET_USCAFITEMSHEETS_FAILURE";
export const CLEAR_USCAFITEMSHEETS = "CLEAR_USCAFITEMSHEETS";
export const GET_PLAN_BEGIN = "GET_PLAN_BEGIN";
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS";
export const GET_PLAN_FAILURE = "GET_PLAN_FAILURE";
export const POST_PLAN_BEGIN = "POST_PLAN_BEGIN";
export const POST_PLAN_SUCCESS = "POST_PLAN_SUCCESS";
export const POST_PLAN_FAILURE = "POST_PLAN_FAILURE";
export const PUT_PLAN_BEGIN = "PUT_PLAN_BEGIN";
export const PUT_PLAN_SUCCESS = "PUT_PLAN_SUCCESS";
export const PUT_PLAN_FAILURE = "PUT_PLAN_FAILURE";
export const DELETE_PLAN_BEGIN = "DELETE_PLAN_BEGIN";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAILURE = "DELETE_PLAN_FAILURE";
export const GET_PAGES_BEGIN = "GET_PAGES_BEGIN";
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS";
export const GET_PAGES_FAILURE = "GET_PAGES_FAILURE";
export const CLEAR_PAGES = "CLEAR_PAGES";
export const GET_PAGE_BEGIN = "GET_PAGE_BEGIN";
export const GET_PAGE_SUCCESS = "GET_PAGE_SUCCESS";
export const GET_PAGE_FAILURE = "GET_PAGE_FAILURE";
export const PUT_PAGE_BEGIN = "PUT_PAGE_BEGIN";
export const PUT_PAGE_SUCCESS = "PUT_PAGE_SUCCESS";
export const PUT_PAGE_FAILURE = "PUT_PAGE_FAILURE";
export const LOADIMAGE_BEGIN = "LOADIMAGE_BEGIN";
export const LOADIMAGE_SUCCESS = "LOADIMAGE_SUCCESS";
export const LOADIMAGE_FAILURE = "LOADIMAGE_FAILURE";
export const LOADBOUND_BEGIN = "LOADBOUND_BEGIN";
export const LOADBOUND_SUCCESS = "LOADBOUND_SUCCESS";
export const LOADBOUND_FAILURE = "LOADBOUND_FAILURE";
export const GET_GENNOTES_BEGIN = "GET_GENNOTES_BEGIN";
export const GET_GENNOTES_SUCCESS = "GET_GENNOTES_SUCCESS";
export const GET_GENNOTES_FAILURE = "GET_GENNOTES_FAILURE";
export const CLEAR_GENNOTES = "CLEAR_GENNOTES";
export const GET_GENNOTE_BEGIN = "GET_GENNOTE_BEGIN";
export const GET_GENNOTE_SUCCESS = "GET_GENNOTE_SUCCESS";
export const GET_GENNOTE_FAILURE = "GET_GENNOTE_FAILURE";
export const POST_GENNOTE_BEGIN = "POST_GENNOTE_BEGIN";
export const POST_GENNOTE_SUCCESS = "POST_GENNOTE_SUCCESS";
export const POST_GENNOTE_FAILURE = "POST_GENNOTE_FAILURE";
export const PUT_GENNOTE_BEGIN = "PUT_GENNOTE_BEGIN";
export const PUT_GENNOTE_SUCCESS = "PUT_GENNOTE_SUCCESS";
export const PUT_GENNOTE_FAILURE = "PUT_GENNOTE_FAILURE";
export const DELETE_GENNOTE_BEGIN = "DELETE_GENNOTE_BEGIN";
export const DELETE_GENNOTE_SUCCESS = "DELETE_GENNOTE_SUCCESS";
export const DELETE_GENNOTE_FAILURE = "DELETE_GENNOTE_FAILURE";
export const GET_IMAGEDATAS_BEGIN = "GET_IMAGEDATAS_BEGIN";
export const GET_IMAGEDATAS_SUCCESS = "GET_IMAGEDATAS_SUCCESS";
export const GET_IMAGEDATAS_FAILURE = "GET_IMAGEDATAS_FAILURE";
export const CLEAR_IMAGEDATAS = "CLEAR_IMAGEDATAS";
export const GET_IMAGEDATA_BEGIN = "GET_IMAGEDATA_BEGIN";
export const GET_IMAGEDATA_SUCCESS = "GET_IMAGEDATA_SUCCESS";
export const GET_IMAGEDATA_FAILURE = "GET_IMAGEDATA_FAILURE";
export const POST_IMAGEDATA_BEGIN = "POST_IMAGEDATA_BEGIN";
export const POST_IMAGEDATA_SUCCESS = "POST_IMAGEDATA_SUCCESS";
export const POST_IMAGEDATA_FAILURE = "POST_IMAGEDATA_FAILURE";
export const PUT_IMAGEDATA_BEGIN = "PUT_IMAGEDATA_BEGIN";
export const PUT_IMAGEDATA_SUCCESS = "PUT_IMAGEDATA_SUCCESS";
export const PUT_IMAGEDATA_FAILURE = "PUT_IMAGEDATA_FAILURE";
export const DELETE_IMAGEDATA_BEGIN = "DELETE_IMAGEDATA_BEGIN";
export const DELETE_IMAGEDATA_SUCCESS = "DELETE_IMAGEDATA_SUCCESS";
export const DELETE_IMAGEDATA_FAILURE = "DELETE_IMAGEDATA_FAILURE";
export const GET_FILERESULTS_BEGIN = "GET_FILERESULTS_BEGIN";
export const GET_FILERESULTS_SUCCESS = "GET_FILERESULTS_SUCCESS";
export const GET_FILERESULTS_FAILURE = "GET_FILERESULTS_FAILURE";
export const CLEAR_FILERESULTS = "CLEAR_FILERESULTS";
export const GET_FILERESULT_BEGIN = "GET_FILERESULT_BEGIN";
export const GET_FILERESULT_SUCCESS = "GET_FILERESULT_SUCCESS";
export const GET_FILERESULT_FAILURE = "GET_FILERESULT_FAILURE";
export const POST_FILERESULT_BEGIN = "POST_FILERESULT_BEGIN";
export const POST_FILERESULT_SUCCESS = "POST_FILERESULT_SUCCESS";
export const POST_FILERESULT_FAILURE = "POST_FILERESULT_FAILURE";
export const PUT_FILERESULT_BEGIN = "PUT_FILERESULT_BEGIN";
export const PUT_FILERESULT_SUCCESS = "PUT_FILERESULT_SUCCESS";
export const PUT_FILERESULT_FAILURE = "PUT_FILERESULT_FAILURE";
export const DELETE_FILERESULT_BEGIN = "DELETE_FILERESULT_BEGIN";
export const DELETE_FILERESULT_SUCCESS = "DELETE_FILERESULT_SUCCESS";
export const DELETE_FILERESULT_FAILURE = "DELETE_FILERESULT_FAILURE";
export const GET_SCAFITEMSHEETS_BEGIN = "GET_SCAFITEMSHEETS_BEGIN";
export const GET_SCAFITEMSHEETS_SUCCESS = "GET_SCAFITEMSHEETS_SUCCESS";
export const GET_SCAFITEMSHEETS_FAILURE = "GET_SCAFITEMSHEETS_FAILURE";
export const CLEAR_SCAFITEMSHEETS = "CLEAR_SCAFITEMSHEETS";
export const GET_SCAFITEMSHEET_BEGIN = "GET_SCAFITEMSHEET_BEGIN";
export const GET_SCAFITEMSHEET_SUCCESS = "GET_SCAFITEMSHEET_SUCCESS";
export const GET_SCAFITEMSHEET_FAILURE = "GET_SCAFITEMSHEET_FAILURE";
export const POST_SCAFITEMSHEET_BEGIN = "POST_SCAFITEMSHEET_BEGIN";
export const POST_SCAFITEMSHEET_SUCCESS = "POST_SCAFITEMSHEET_SUCCESS";
export const POST_SCAFITEMSHEET_FAILURE = "POST_SCAFITEMSHEET_FAILURE";
export const PUT_SCAFITEMSHEET_BEGIN = "PUT_SCAFITEMSHEET_BEGIN";
export const PUT_SCAFITEMSHEET_SUCCESS = "PUT_SCAFITEMSHEET_SUCCESS";
export const PUT_SCAFITEMSHEET_FAILURE = "PUT_SCAFITEMSHEET_FAILURE";
export const DELETE_SCAFITEMSHEET_BEGIN = "DELETE_SCAFITEMSHEET_BEGIN";
export const DELETE_SCAFITEMSHEET_SUCCESS = "DELETE_SCAFITEMSHEET_SUCCESS";
export const DELETE_SCAFITEMSHEET_FAILURE = "DELETE_SCAFITEMSHEET_FAILURE";
export const LOADLOGO_BEGIN = "LOADLOGO_BEGIN";
export const LOADLOGO_SUCCESS = "LOADLOGO_SUCCESS";
export const LOADLOGO_FAILURE = "LOADLOGO_FAILURE";
export const GET_USER_BEGIN = "GET_USER_BEGIN";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const POST_USER_BEGIN = "POST_USER_BEGIN";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_FAILURE = "POST_USER_FAILURE";
export const PUT_USER_BEGIN = "PUT_USER_BEGIN";
export const PUT_USER_SUCCESS = "PUT_USER_SUCCESS";
export const PUT_USER_FAILURE = "PUT_USER_FAILURE";
export const ACTIVATE_USER_BEGIN = "ACTIVATE_USER_BEGIN";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAILURE = "ACTIVATE_USER_FAILURE";
export const RESEND_USER_BEGIN = "RESEND_USER_BEGIN";
export const RESEND_USER_SUCCESS = "RESEND_USER_SUCCESS";
export const RESEND_USER_FAILURE = "RESEND_USER_FAILURE";
export const GENIMG_BEGIN = "GENIMG_BEGIN";
export const GENIMG_SUCCESS = "GENIMG_SUCCESS";
export const GENIMG_FAILURE = "GENIMG_FAILURE";
export const GENPDF_BEGIN = "GENPDF_BEGIN";
export const GENPDF_SUCCESS = "GENPDF_SUCCESS";
export const GENPDF_FAILURE = "GENPDF_FAILURE";
export const POST_PASSWORD_BEGIN = "POST_PASSWORD_BEGIN";
export const POST_PASSWORD_SUCCESS = "POST_PASSWORD_SUCCESS";
export const POST_PASSWORD_FAILURE = "POST_PASSWORD_FAILURE";
export const RESET_PASSWORD_BEGIN = "RESET_PASSWORD_BEGIN";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const CONFIRM_PASSWORD_BEGIN = "CONFIRM_PASSWORD_BEGIN";
export const CONFIRM_PASSWORD_SUCCESS = "CONFIRM_PASSWORD_SUCCESS";
export const CONFIRM_PASSWORD_FAILURE = "CONFIRM_PASSWORD_FAILURE";

export const PAGEORIENT = {
  L: "Landscape",
  P: "Portrait",
  S: "Square"
};

// For Landscape: height and width in mm.

export const PAGESIZES = {
  A0: [841, 1189],
  A1: [594, 841],
  A2: [420, 594],
  A3: [297, 420],
  A4: [210, 297],
  A5: [148, 210],
  B0: [1000, 1414],
  B1: [707, 1000],
  B2: [500, 707],
  B3: [353, 500],
  B4: [250, 353],
  B5: [176, 250],
  C0: [917, 1297],
  C1: [648, 917],
  C2: [458, 648],
  C3: [324, 458],
  C4: [229, 324],
  C5: [162, 229],
  "US Letter": [216, 279],
  "US Legal": [216, 356]
};

export const PAPERSIZE_PREC = 0.02;

// Useful for scaling pictures in general notes pages.

export const GENNOTES_PCSCALES = {
  A0: 2,
  A1: 1189 / 841,
  A2: 1,
  A3: 841 / 1189,
  A4: 1 / 2,
  A5: 841 / 2378
};

export const DPI_TOO_LARGE =
  "There is an error in reading the PDF on your computer. Perhaps you need to make the DPI smaller?";

export const INFO_PDF_EXTRACT = "Extracting images from PDF";

export const INFO_ZIP_IMAGES = "Zipping up images to load to server";

export const LOGIN_ERROR_MESSAGE =
  "You were not able to log in with the user name and password provided.";

export const PLAN_STATUSES = {
  QT: "Quoted",
  AC: "Active",
  PA: "Archived"
};

export const BOUNDTEXT_PLACEHOLDER = "[Edit Here]";

export const history = createBrowserHistory();
export const mmpinch = 25.4;
export const dpmm = 300 / 25.4;
export const pointtomm = 25.4 / 72;
export const defaultBWidth = 4;
export const ledgerBodyDivideWidth = 4.5;
export const DPI300Scaler = 1 / 72;
export const defaultResolution = 300;
export const defaultEncoding = "png";
export const defaultEncoderOpt = 0.92; // Only for JPG; unused in practice.

// For the logo

export const defaultLogoWidth = 238;
export const defaultLogoHeight = 136;
export const defaultLHDividerScale = 165.4;
export const defaultLedgerHeightDivider = 1.1;

export const genNotesStartOld =
  "G1.  IF IN DOUBT, PLEASE ASK.\n" +
  "G2.  DO NOT OBTAIN DIMENSIONS BY SCALING FROM THESE DRAWINGS.\n" +
  "G3.  VERIFY ON SITE ALL SET OUT DIMENSIONS SHOWN ON THESE DRAWINGS.\n" +
  "G4.  ALL DIMENSIONS ARE IN MILLIMETRES UNLESS NOTED OTHERWISE ON THESE DRAWINGS.\n" +
  "G5.  NO ALTERATIONS TO THE DESIGN SHOWN ON THESE DRAWINGS ARE PERMITTED WITHOUT THE PRIOR APPROVAL OF THE DESIGNER.";

export const scafNotesStartNewPart1To4 =
  "S1.  THE SCAFFOLD SHOWN IN THESE PRELIMINARY DRAWINGS HAS BEEN DRAWN BASED ON RELEVANT SECTIONS OF AUSTRALIAN STANDARDS AS/NZ1576.1 AND AS/NZ4576 EXCEPT WIND ACTION. THIS PROPOSAL HAS BEEN BASED ON THE USE OF {company_name} & SCAFFOLDING EQUIPMENT ONLY. OTHER SUPPLIER'S EQUIPMENT MAY BE OF DIFFERENT DIMENSIONS AND HAVE A DIFFERENT WORKING LOAD LIMIT AND THUS MANY NOT BE SUITABLE AND ADEQUATE IN THE APPLICATION SHOWN.\n" +
  "S2   ALL ERECTION WORK IS TO BE CARRIED OUT IN A SAFE TRADES-PERSON-LIKE MANNER AND BE IN ACCORDANCE WITH {company_name} GUIDELINES, AS1576, AS4576, LOCAL AUTHORITY REQUIREMENTS AND OCCUPATIONAL HEALTH AND SAFETY REGULATIONS.\n" +
  "S3.  THE CLIENT MUST ENSURE THAT:\n" +
  "     A) THE SUPPORTING FOUNDATION/STRUCTURE IS STABLE AND HAS ADEQUATE CAPACITY TO SUPPORT THE LOADS IMPOSED BY THE SCAFFOLD.\n" +
  "     B) THE SUPPORTING FOUNDATION/STRUCTURE IS ADEQUATELY PREPARED TO AVOID OR LIMIT ANY DIFFERENTIAL SETTLEMENT TO NOT MORE THAN 5MM UNDER SCAFFOLD LEGS.\n" +
  "     C) THE PERMANENT STRUCTURE HAS ADEQUATE STRENGTH TO SUPPORT THE IMPOSED LOADS FROM THE SCAFFOLD ASSEMBLY & SCAFFOLD TIES.\n" +
  "S4.  THIS PROPOSAL HAS BEEN PREPARED USING THE FOLLOWING PARAMETERS:\n" +
  "     BAY LOADING                                = {bayload}\n";

export const gennotesNumericKeys = [
  "maxscaffold",
  "nodecked",
  "noworking",
  "platformbrack",
  "extadjust",
  "maxlegload",
  "destieload",
  "scaffoldnosheet",
  "min30poros",
  "designwind"
];

export const scafNotesStartNewNumParameters = {
  maxscaffold:
    "     MAX. SCAFFOLD HEIGHT TO TOP DECK           = {maxscaffold} m\n",
  nodecked: "     NO. OF DECKED LEVELS                       = {nodecked} \n",
  noworking: "     NO. OF WORKING LEVELS                      = {noworking} \n",
  platformbrack:
    "     NO. OF PLATFORM BRACKET LEVELS             = {platformbrack} \n",
  extadjust:
    "     MAX. EXTENSION OF ADJUSTABLE BASES         = {extadjust} mm\n",
  maxlegload:
    "     MAX. LEG LOAD                              = {maxlegload} kN\n",
  destieload:
    "     MAX. DESIGN TIE LOAD (COMP/TENSION)        = {destieload} kN\n" +
    "     PRESCRIBED CONTAINMENT SHEETING (I.E. SHADECLOTH) HAVING MINIMUM 30%\n" +
    "     POROSITY HAS NOT BEEN ALLOWED FOR.\n",
  scaffoldnosheet:
    "     MAX. SCAFFOLD HEIGHT ABOUT TOP TIE WITH NO PRESCRIBED\n" +
    "     SHEETING                                   = {scaffoldnosheet} m\n",
  min30poros:
    "     MAX. SCAFFOLDING HEIGHT ABOVE TOP TIE WITH PRESCRIBED SHEETING HAVING\n" +
    "     MINIMUM 30% POROSITY                       = {min30poros} m\n",
  designwind:
    "     MAX. DESIGN WIND SPEED                     = {designwind} km/h\n" +
    "     ADDITIONAL TIES MUST BE INSTALLED OR SHADE CLOTH REMOVED IF WIND SPEED IS TO EXCEED {designwind} km/h. REFER TO {company_name} OR CONSULT SUITABLY EXPERIENCED & QUALIFIED ENGINEERS.\n"
};

export const scafNotesStartNewPart5To9 =
  "S5.  THE SCAFFOLD IS TO BE TIED TO THE BUILDING/STRUCTURE WITH A MINIMUM TYING REQUIREMENT AS FOLLOWS:\n" +
  "     * HORIZONTALLY: EVERY 3rd STANDARD\n" +
  "     * VERTICALLY: EVERY 4.0m MAXIMUM\n" +
  /*"     THE BELOW FIGURES DETAIL THE REQUIREMENT SPACING OF TIES. THE DETAILS GIVEN ARE FOR TWO DIFFERENT CONTAINMENT METHODS. CLIENT TO ADHERE TO THE METHOD SUITABLE BASED ON THE TYPE OF CONTAINMENT / LINING USED ON THE SCAFFOLD. IF IN DOUBT ASK ENGINEERING.\n"+
"     TIE PATTERN OPTION 1: TIE PATTERN FOR SCAFFOLD WHEN AN OPEN STYLE 25X25 CHAIN WIRE OR GRID STYLE CONTAINMENT WITH MAXIMUM IMPERMEABLE SOLID ARE OF 30% WITHOUT A SHADE CLOTH LINING IS USED.\n"+
"     TIE PATTERN OPTION 2: THE PATTERN FOR SCAFFOLD WHEN CONTAINMENT SHEETING IS USED E.G., DYNAMESH WITH A SHADE CLOTH LINING WITH IMPERMEABLE SOLID AREA GREATER THAN 30%.\n"+
"     AS THE BUILDING PROGRESSES, THE SCAFFOLD UNDER THE TWP MUST BE TIED/RAKER EVERY LEAD AS INDICATED. ONCE THE SLAB HAS BEEN CAST THE RAKER CAN BE REPLACED WITH A SLAB EDGE TIE EVER 4.9M AND THE RAKER TIES LIFTED TO THE NEXT LIFT OF SCAFFOLD ON EVERY LEG T.\n"+*/
  "S6.  TIES OR OTHER COMPONENTS ARE NOT TO BE MOVED OR TAMPERED WITH IN ANY WAY EXCEPT BY {company_name} OR OUR NOMINATED SUB-CONTRACTORS.\n" +
  "S7.  UNLESS NOTED OTHERWISE ON DRAWINGS:\n" +
  "     * THE DISTANCE BETWEEN FACE BRACING PANELS IS NOT TO EXCEED THREE BAYS.\n" +
  "     * ALL RETURNS OR BREAKS IN SCAFFOLDS ARE TO BE TUBE TIED, COMPLETE WITH PLANKING, GUARDRAILS, MIDRAILS AND KICKBOARD OR MESH INFILL AS REQUIRED ON ADJACENT SCAFFOLDS.\n" +
  "     * SCAFFOLDS TO BE A MAXIMUM OF 225mm FROM WORKING FACE.\n" +
  "     * PLATFORM BRACKETS (“HOP UPS”) ARE NOT TO BE LOADED WITH MATERIALS. THEY ARE TO BE USED FOR ACCESS BY PERSONNEL ONLY.\n" +
  "     * JOINTS IN THE STANDARDS ABOVE THE TOP WORKING PLATFORMS ARE NOT PERMITTED.\n" +
  "S8.  IT SHALL REMAIN THE CLIENTS RESPONSIBILITY TO ENSURE THAT ALL REASONABLE PRECAUTIONS ARE TAKEN TO AVOID IMPACT LOADS ON THE SCAFFOLD DURING USE, CRANEAGE & VEHICULAR OPERATIONS.\n" +
  "S9.  IRRESPECTIVE OF ANY RECOMMENDATIONS SHOWN ON THE DRAWINGS, THE USERS ARE RESPONSIBLE FOR THE STRUCTURAL ADEQUACY OF MATERIAL OF THEIR OWN SUPPLY AND FOR ENSURING THAT THE APPROVED WORKING LOAD LIMITS OF {company_name} AND SCAFFOLDING EQUIPMENT ARE NOT EXCEEDED.\n";

export const scafNotesStartNewPart10 =
  "S10. THIS PROPOSAL HAS BEEN BASED ON: {basedon}";

export const foundationSupportOld =
  "F1.  THE CLIENT MUST ENSURE THAT THE SUPPORTING FOUNDATION HAS ADEQUATE BEARING " +
  "CAPACITY TO SUPPORT THE LOAD IMPOSED BY THE SCAFFOLD. THIS SHOULD BE " +
  "SUPPLIED TO THE CLIENT/SCAFFOLD DESIGNED BY THE PRINCIPAL CONTRACTOR BY " +
  "MEANS OF A REPORT BY AN ENGINEER COMPETENT IN GEOTECHNICAL ENGINEERING OR " +
  "WRITTEN CONFIRMATION THAT THE SUPPORTING GROUND OR STRUCTURE IS ADEQUATE " +
  "FOR THE LOADS APPLIED AS SHOWN IN THE DESIGN.\n" +
  "F2.  THE PRINCIPAL CONTRACTOR MUST ENSURE CLIENT IN PROVIDED WITH A LEVEL AND " +
  "STABLE BEARING SURFACE FOR ALL SOLE BOARD LOCATIONS. THE PRINCIPAL " +
  "CONTRACTOR MUST ENSURE THAT THE PERMANENT STRUCTURE HAS ADEQUATE STRENGTH " +
  "TO WITHSTAND THE IMPOSED LOADS FROM THE SCAFFOLD ASSEMBLY.\n" +
  "F3.  THE PRINCIPAL CONTRACTOR MUST ENSURE THAT THE SUPPORTING FOUNDATION IS " +
  "ADEQUATELY PREPARED TO AVOID DIFFERENTIAL SETTELMENT TO NOT MORE THAN 5mm " +
  "UNDER SCAFFOLD LEGS.\n\n" +
  "     SOLE BOARDS TO BE HARDWOOD TIMBER\n" +
  "     SCAFFOLD APPLICATIONS U.N.O. 225 wide x 38 thick x 500 long\n" +
  "     FORMWORK APPLICATIONS U.N.O. 225 wide x 50 thick x 500 long\n\n" +
  "F4.  GROUND BEARNING CAPACITY REQUIREMENTS AS NOTED BELOW ARE FOR THE WORST " +
  "CASE SCAFFOLD LEG LOADS ON THE PROJECT AS A WHOLE (EXCLUDING LOADING BAY AND " +
  "STAIR LEG LOADS; REFER SPECIFIC DETAILS IN THOSE LOCATIONS). CLIENT MAY " +
  "DETERMINE THE MINIMUM GROUND BEARING CAPACITY IF REQUIRED USING THE " +
  "CALCULATION METHOD BELOW. IF IN DOUBT, CONSULT ENGINEERING.\n\n" +
  "     STEP 1: DETERMINE SOLE BOARD AREA\n" +
  "         SINGLE SOLE BOARD: 0.5mx0.225m=0.1125m²\n" +
  "         TRIPLE SOLE BOARD: 0.5mx0.45m=0.225m²\n" +
  "     STEP 2: DETERMINE SCAFFOLD LEG LOAD. REFER TO NOTES.\n" +
  "     STEP 3: DIVIDE SCAFFOLD LEG LOAD BY SOLE BOARD AREA.\n\n" +
  "EXAMPLE 1: SCAFFOLD ON SINGLE SOLE BOARD WITH A LEG LOAD OF 15kN:\n" +
  "15kN / 0.1125m² = 133kPa MINIMUM GROUND BEARING CAPACITY REQUIRED\n\n" +
  "EXAMPLE 2: SCAFFOLD ON TRIPLE SOLE BOARD WITH A LEG LOAD OF 15kN:\n" +
  "15kN / 0.225² = 67kPa MINIMUM GROUND BEARING CAPACITY REQUIRED";

export const certSupportNew =
  "C1.  UNLESS SPECIFIED IN THE CONTRACT, CLIENT IS RESPONSIBLE TO ADVISE IF " +
  "THEY REQUIRE {company_name} TO CERTIFY THE DESIGN AS PER THE REQUIREMENTS " +
  "OF AS1576, QLD FORMWORK CODE OF PRACTICE AND OTHERE RELEVANT REQUIREMENTS " +
  "OD THEY WILL ARRANGE THIS THEMSELVES. UNLESS DRAWINGS HAVE BEEN CERTIFIED BY " +
  "FORMS OF STAMPED DRAWINGS OR AN ACCOMPANYING LETTER, IT IS TO BE ASSUME THE " +
  "DRAWINGS ARE NOT CERTIFIED AND CONSTRUCTION WORK SHOULD NOT PROCEEED.";

export const theTiesNew =
  "T1.  THE PRINCIPAL CONTRACTOR IS RESPONSIBLE FOR ENSURING ALL SCAFFOLD " +
  "STRUCTURES REMAIN ADEQUATELY TIED AND BRACED  TO CARRY THE TIE FORCED AND " +
  "ENSURE STABILITY. TIES OR HOP-UPS ARE NOT PERMITTED TO BE MOVED BY OTHER " +
  "TRADES REGARDLESS IF THEY HOLD A VALID SCAFFOLD TICKET WITHOUT CONSULTING " +
  "{company_name}.\n" +
  "*    CONSULT {company_name} FOR SCAFFOLD TIE AND BRACING REQUIREMENTS FOR " +
  "SCAFFOLD EXCEEDING 30m TO THE TWP.\n" +
  "*    NO JOINTS IN STANDARDS ABOVE TOP TIE IS ALLOWED UNLESS PINNED OR " +
  "SPLICED AS PER THE TYPICAL DETAIL\n" +
  "*    PRESCRIBED CONTAINMENT/LINING NOT TO EXTEND MORE THAN 2m PAST LAST TIE. " +
  "SCAFFOLD MUST BE BOXED OUT WITH TOP TRANSOM INSTALLED.\n" +
  "*    TIES ARE TO BE INSTALLED ARE PER TYPICAL TIE DETAILS SHOWN IN THESE " +
  "DRAWINGS. ALL TIE LOCATIONS MAY UTILISE ANY OF THESE DETAILS TO SUIT THE " +
  "PARTICULAR TIE POINT.\n" +
  "*    TIES MUST BE INSTALLED WITHIN 300mm OF A TRANSOM/NODE OR SCAFFOLD LEG " +
  "TO BE DOUBLED AS PER TYPICAL SINGLE LEG TIE DIETAIL IN MIDDLE OF STANDARD.\n" +
  "*    TUBE IN SCAFFOLD COUPLERS TO EXTEND AT LEAST 10mm PAST END OF CHECK " +
  "COUPLER. IT MAY ALSO BE PRUDENT TO WRAP END OF TIES IF THE SCAFFOLD TUBE " +
  "ENTENDS INTO THE WALKWAY AREA.\n" +
  "*    SWIVEL COUPLERS MAY BE USED FOR RAKER TIES ALTHOUGH AS1576 RESTRICTS " +
  "SWIVEL COUPLERS TO NON-LOAD BEARING APPLICATIONS. THE RAKER TIE IS SUBJECT " +
  "TO TRANSIENT WIND LOADS AND THEREFORE NOT A CONSTANT LOAD. THUS, " +
  "{company_name} DEEM THAT THE USE OF SWIVER COUPLERS IS SUITABLE IN RAKER TIE " +
  "APPLICATIONS. PREFERENCE SHOULD HOWEVER BE GIVERN TO STANDARD COUPLERS " +
  "WHERE REASONABLY POSSIBLE.\n" +
  "*    IF WIND SPEEDS ARE LIKELY TO REACH OR EXCEED 100km/hr, PRESCRIBED " +
  "CONTAINMENT SHEETING TO BE REMOVED AND SCAFFOLD BOARDS TO BE EITHER REMOVED " +
  "OR STRAPPED DOWN.\n" +
  "*    FOR HIGHT WIND/CYCLONE OR COASTAL AREAS, ADDITIONAL TIE REQUIREMENTS " +
  "AND SECURING OF BOARDS ARE REQUIRED, REFER SPECIFIC NOTES OR DRAWINGS IN " +
  "THESE CASES AND CONSULT {company_name}.\n" +
  "*    ALL ANCHORS TO BE SET AS PER MANUFACTURERS SPECIFICATION CENTRAL INTO " +
  "MIN. 200mm THICK SLAB @ 25 MPa MIN.\n" +
  "*    ALL DEMOLITION SCAFFOLD TO BE TIED EVERY LEG AND EVERY 4m VERTICALLY. " +
  "ALL BOARDS TO BE STRAPPED DOWN AND STANDARDS PINNED OR SPLICED.\n" +
  "*    SCAFFOLD TIES TO BE INSTALLED ON THE LAST STANDARD OF EVERY RUN " +
  "REGARDLESS OF HORIZONTAL SPACING. A RETURN BAY WITH LINKING HANDRAILS WOULD " +
  "CONTINUE A TIED STANDARD.\n\n" +
  "THE BELOW FIGURES DETAIL THE REQUIRED SPACING OF TIES. TIE DETAILS GIVEN ARE " +
  "FOR TWO DIFFERENT CONTAINMENT METHODS. CLIENT TO ADHERE TO THE METHOD " +
  "SUITABLE BASED ON THE TYPE OF CONTAINMENT/LINING USED ON THE SCAFFOLD. IF IN " +
  "DOUBT ASK {company_name}.";

export const genNotesInterpObj = {
  bayload: "Light Duty (225kg)",
  maxscaffold: "0",
  nodecked: "0",
  noworking: "0",
  platformbrack: "0",
  extadjust: "0",
  maxlegload: "0",
  destieload: "0",
  scaffoldnosheet: "0",
  min30poros: "0",
  designwind: "0",
  basedon: ""
};

export const genNotesStart = wrapandbreak(genNotesStartOld, 80, 5);
export const foundationSupportStart = wrapandbreak(foundationSupportOld, 80, 5);

// Sets attributes on objects for saving.

export const controlSettingsForSave = [
  "lockMovementX",
  "lockMovementY",
  "lockRotation",
  "lockScalingX",
  "lockScalingY",
  "lockUniScaling",
  "selectable",
  "_controlsVisibility"
];

// A base width for when users draw free standing lines. When drawn, it is scaled
// to be the same width as ledgers and transoms.

export const lineSizeBase = 37.5;

// For setting scale message:

export const setScaleMessage =
  "Please enter the distance (in mm) between two points on the plan. After " +
  "clicking OK, please click on those points.";

export const setTextSizeMessage =
  "Please enter the size (in pixels) for this piece of text.";

export const setLineSizeMessage =
  "Please enter the size (in pixels) for the width of this line.";

export const setScaleRatioMessage =
  "Please enter a number n which will determine the scale '1:n' of the " +
  "diagram between how it is laid out between a PDF printout and the objects " +
  "it represents. For example, if you wish to set a scale of '1:100', please " +
  "enter '100'.";

export const setScaleRatioValidMessage =
  "The scale ratio must be greater than zero.";

export const excessiveResolutionMessage =
  "Error: this browser on this computer cannot work with plans of this page " +
  "size and resolution. Perhaps try using a smaller resolution when " +
  "uploading this plan.";

// Useful for situations when the status is loading | success | failure.

export const WaitStatusEnum = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
};
