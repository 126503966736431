import {
  getUfilersActions,
  getPlansActions,
  getPlanActions,
  getUserIdActions,
  getUsersActions,
  getLoginActions,
  deletePlanActions,
  putPlanActions,
  postPlanActions,
  getPagesActions,
  getPageActions,
  putPageActions,
  getGenNotesActions,
  getGenNoteActions,
  postGenNoteActions,
  putGenNoteActions,
  deleteGenNoteActions,
  getImageDatasActions,
  getImageDataActions,
  postImageDataActions,
  putImageDataActions,
  deleteImageDataActions,
  getFileResultsActions,
  getFileResultActions,
  postFileResultActions,
  deleteFileResultActions,
  getScafItemSheetsActions,
  getScafItemSheetActions,
  postScafItemSheetActions,
  deleteScafItemSheetActions,
  getUscafitemsheetsActions,
  getUserActions,
  postUserActions,
  putUserActions,
  activateUserActions,
  resendUserActions,
  postPasswordActions,
  resetPasswordActions,
  confirmPasswordActions
} from "./actions";
import { store } from "./store";
import * as constants from "./constants";
import { externalDomain } from "./env";
const serializeError = require("serialize-error");

//const domainStart = "http://127.0.0.1:8000";
const domainStart = externalDomain;

export const userService = {
  domainStart,
  logout,
  getLogin,
  getUserId,
  getUsers,
  getUfilers,
  getPlans,
  getPlan,
  postPlan,
  putPlan,
  deletePlan,
  getPages,
  getPage,
  putPage,
  getGenNotes,
  getGenNote,
  postGenNote,
  putGenNote,
  deleteGenNote,
  getImageDatas,
  getImageData,
  postImageData,
  putImageData,
  deleteImageData,
  getFileResults,
  getFileResult,
  postFileResult,
  deleteFileResult,
  getUscafitemsheets,
  getScafItemSheets,
  getScafItemSheet,
  postScafItemSheet,
  deleteScafItemSheet,
  getUser,
  postUser,
  resendUser,
  putUser,
  activateUser,
  postPassword
};

/**
 * Generates the header and body for requests.
 * @param {string} method - The method - "GET", "POST", or so on - used.
 * @param {object} [body=null] - the request body as key value pair, if needed.
 * @param {string} [token=null] - the JWT token to pass on, if needed.
 * @return {object} An object of header and body ready to be passed to fetch.
 */
function genReqOptions(method, body = null, token = null) {
  let ourReqOptions = {};
  ourReqOptions.method = method;
  if (body) {
    ourReqOptions.body = JSON.stringify(body);
  }
  ourReqOptions["headers"] = { "Content-Type": "application/json" };
  if (token) {
    ourReqOptions["headers"]["Authorization"] = "JWT " + token;
  }
  return ourReqOptions;
}

/**
 * Generates the header and body for form data requests.
 * @param {string} method - The method - "GET", "POST", or so on - used.
 * @param {object} body - the request body as key value pair.
 * @return {object} An object of header and body ready to be passed to fetch.
 */
function formdataReqOptions(method, body) {
  let formData = new FormData();
  for (var key in body) {
    if (body.hasOwnProperty(key)) {
      // For multiple file submission. Assume keys are in "fieldname[]" form.

      if (key.includes("[]")) {
        let newkey = key.slice(0, -2);
        body[key].forEach(element => {
          formData.append(newkey, element);
        });
      } else {
        formData.append(key, body[key]);
      }
    }
  }
  return {
    method: method,
    body: formData,
    headers: { Authorization: "JWT " + localStorage.getItem("usertoken") }
  };
}

// Code to be called when 401 errors arise (generally when JWT tokens time out.)

function redirecttologin() {
  localStorage.removeItem("usertoken");
  sessionStorage.removeItem("clipboard");
  sessionStorage.removeItem("scleft");
  sessionStorage.removeItem("sctop");
  sessionStorage.removeItem("scfocus");
  sessionStorage.removeItem("buildsc");
  localStorage.removeItem("userid");
  store.dispatch(getLoginActions.logout());
  store.dispatch(getUserIdActions.clearUserId());
  store.dispatch(getPlansActions.clearPlans());
  constants.history.push("/login");
}

// Nice to overwrite the Error class to pass on status codes.

class HTTPError extends Error {
  constructor(message, status) {
    super(message);
    this.name = "HTTPError";
    this.message = message;
    this.status = status;
  }
}

// This seems to be the best practice for handling errors while fetching responses
// from a server and putting the results into Redux. It is designed to work with
// code that fits the following pattern:
//
// function someRequest(someParameters) {
//   return dispatch => {
//     dispatch(someAction.someActionBegin());
//     return fetch(someURL, someParameters)
//       .then(handleErrorsBest)
//       .then(res => {
//         dispatch(someAction.someActionSuccess(res));
//         return res;
//       })
//       .catch(error => dispatch(someAction.someActionFailure(serializeError(error))));
//   };
// }
// (a) If successful getting something from a server, expect JSON, convert it as
// such, and return it back to the caller.
// (b) If you have a 401 error, redirect to the login window.
// (c) Any other situation, throw an error, preferably with the original
// response text. If the server is unavailable, then an Error will be thrown as
// well. If the response can't be converted to JSON, you get an error as well!
//
// This is based on Jason Watmore's handleResponse function here:
// https://jasonwatmore.com/post/2017/09/16/react-redux-user-registration-and-login-tutorial-example
// However, I tweaked it so that meaningful data would be passed back on success and failure.
// Not only that, but the meaningful data would be available in the Redux store AND the Redux Dev
// Tools add-on for Firefox. (The serializeError method assists with the later.)

function handleErrorsBest(response) {
  return response.text().then(text => {
    if (!response.ok) {
      if (response.status === 401) {
        redirecttologin();
      }
      throw new HTTPError(text, response.status);
    }

    // Some response texts are empty, such as posting password change or deleting resources. We need
    // to handle those as well.

    if (text.length === 0) {
      return {};
    } else {
      return JSON.parse(text);
    }
  });
}

function getUserId() {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getUserIdActions.getUserIdBegin());
    return fetch(`${domainStart}/v0/me`, requestOptions)
      .then(handleErrorsBest)
      .then(json => {
        localStorage.setItem("userid", json.id.toString());
        dispatch(getUserIdActions.getUserIdSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getUserIdActions.getUserIdFailure(serializeError(error)))
      );
  };
}

function getLogin(usernamepassword) {
  const requestOptions = genReqOptions("POST", usernamepassword);
  return dispatch => {
    dispatch(getLoginActions.getLoginBegin());
    return fetch(`${domainStart}/v0/jwt/create`, requestOptions)
      .then(handleErrorsBest)
      .then(json => {
        localStorage.setItem("usertoken", json.access);
        sessionStorage.setItem("clipboard", null);
        sessionStorage.setItem("scleft", null);
        sessionStorage.setItem("sctop", null);
        sessionStorage.setItem("scfocus", null);
        sessionStorage.setItem("buildsc", null);
        dispatch(getLoginActions.getLoginSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getLoginActions.getLoginFailure(serializeError(error)))
      );
  };
}

function logout() {
  localStorage.removeItem("usertoken");
  sessionStorage.removeItem("clipboard");
  sessionStorage.removeItem("scleft");
  sessionStorage.removeItem("sctop");
  sessionStorage.removeItem("scfocus");
  sessionStorage.removeItem("buildsc");
  localStorage.removeItem("userid");
}

function getUser(userid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getUserActions.getUserBegin());
    return fetch(`${domainStart}/v0/scafapp/users/${userid}/`, requestOptions)
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getUserActions.getUserSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getUserActions.getUserFailure(serializeError(error)))
      );
  };
}

function postUser(userdata) {
  let requestOptions = formdataReqOptions("POST", userdata);
  return dispatch => {
    dispatch(postUserActions.postUserBegin());
    return fetch(`${domainStart}/v0/users/create/`, requestOptions)
      .then(handleErrorsBest)
      .then(json => {
        dispatch(postUserActions.postUserSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(postUserActions.postUserFailure(serializeError(error)))
      );
  };
}

function activateUser(userdata) {
  let requestOptions = formdataReqOptions("POST", userdata);
  return dispatch => {
    dispatch(activateUserActions.activateUserBegin());
    return fetch(`${domainStart}/v0/users/activation/`, requestOptions)
      .then(handleErrorsBest)
      .then(json => {
        dispatch(activateUserActions.activateUserSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(activateUserActions.activateUserFailure(serializeError(error)))
      );
  };
}

function resendUser(userdata) {
  let requestOptions = formdataReqOptions("POST", userdata);
  return dispatch => {
    dispatch(resendUserActions.resendUserBegin());
    return fetch(`${domainStart}/v0/users/resend/`, requestOptions)
      .then(handleErrorsBest)
      .then(json => {
        dispatch(resendUserActions.resendUserSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(resendUserActions.resendUserFailure(serializeError(error)))
      );
  };
}

function putUser(userid, userdata) {
  let requestOptions = formdataReqOptions("PUT", userdata);
  return dispatch => {
    dispatch(putUserActions.putUserBegin());
    return fetch(`${domainStart}/v0/scafapp/users/${userid}/`, requestOptions)
      .then(handleErrorsBest)
      .then(res => {
        dispatch(putUserActions.putUserSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(putUserActions.putUserFailure(serializeError(error)))
      );
  };
}

function getUsers() {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getUsersActions.getUsersBegin());
    return fetch(`${domainStart}/v0/scafapp/users/`, requestOptions)
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getUsersActions.getUsersSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getUsersActions.getUsersFailure(serializeError(error)))
      );
  };
}

function getPlans(userid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getPlansActions.getPlansBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getPlansActions.getPlansSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getPlansActions.getPlansFailure(serializeError(error)))
      );
  };
}

function getUfilers(userid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getUfilersActions.getUfilersBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/fileresult/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getUfilersActions.getUfilersSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getUfilersActions.getUfilersFailure(serializeError(error)))
      );
  };
}

function getUscafitemsheets(userid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getUscafitemsheetsActions.getUscafitemsheetsBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/scafitemsheet/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getUscafitemsheetsActions.getUscafitemsheetsSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(
          getUscafitemsheetsActions.getUscafitemsheetsFailure(
            serializeError(error)
          )
        )
      );
  };
}

function getPlan(userid, planid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getPlanActions.getPlanBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getPlanActions.getPlanSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getPlanActions.getPlanFailure(serializeError(error)))
      );
  };
}

function getPages(userid, planid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getPagesActions.getPagesBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/pages/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getPagesActions.getPagesSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getPagesActions.getPagesFailure(serializeError(error)))
      );
  };
}

function getPage(userid, planid, pageno) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getPageActions.getPageBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/pages/${pageno}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getPageActions.getPageSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getPageActions.getPageFailure(serializeError(error)))
      );
  };
}

function postPlan(userid, plandata) {
  let requestOptions = formdataReqOptions("POST", plandata);
  return dispatch => {
    dispatch(postPlanActions.postPlanBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(postPlanActions.postPlanSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(postPlanActions.postPlanFailure(serializeError(error)))
      );
  };
}

function putPlan(userid, planid, plandata) {
  let requestOptions = formdataReqOptions("PUT", plandata);
  return dispatch => {
    dispatch(putPlanActions.putPlanBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(putPlanActions.putPlanSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(putPlanActions.putPlanFailure(serializeError(error)))
      );
  };
}

function putPage(userid, planid, pageno) {
  let requestOptions = genReqOptions(
    "PUT",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(putPageActions.putPageBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/pages/${pageno}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(putPageActions.putPageSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(putPageActions.putPageFailure(serializeError(error)))
      );
  };
}

function deletePlan(userid, planid) {
  const requestOptions = genReqOptions(
    "DELETE",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(deletePlanActions.deletePlanBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(deletePlanActions.deletePlanSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(deletePlanActions.deletePlanFailure(serializeError(error)))
      );
  };
}

function getGenNotes(userid, planid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getGenNotesActions.getGenNotesBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/gennotes/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getGenNotesActions.getGenNotesSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getGenNotesActions.getGenNotesFailure(serializeError(error)))
      );
  };
}

function getGenNote(userid, planid, pageno) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getGenNoteActions.getGenNoteBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/gennotes/${pageno}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getGenNoteActions.getGenNoteSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getGenNoteActions.getGenNoteFailure(serializeError(error)))
      );
  };
}

function postGenNote(userid, planid, gennotesdata) {
  let requestOptions = formdataReqOptions("POST", gennotesdata);
  return dispatch => {
    dispatch(postGenNoteActions.postGenNoteBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/gennotes/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(postGenNoteActions.postGenNoteSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(postGenNoteActions.postGenNoteFailure(serializeError(error)))
      );
  };
}

function putGenNote(userid, planid, pageno, gennotesdata) {
  let requestOptions = formdataReqOptions("PUT", gennotesdata);
  return dispatch => {
    dispatch(putGenNoteActions.putGenNoteBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/gennotes/${pageno}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(putGenNoteActions.putGenNoteSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(putGenNoteActions.putGenNoteFailure(serializeError(error)))
      );
  };
}

function deleteGenNote(userid, planid, pageno) {
  const requestOptions = genReqOptions(
    "DELETE",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(deleteGenNoteActions.deleteGenNoteBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/gennotes/${pageno}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(deleteGenNoteActions.deleteGenNoteSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(
          deleteGenNoteActions.deleteGenNoteFailure(serializeError(error))
        )
      );
  };
}

function getImageDatas(userid, planid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getImageDatasActions.getImageDatasBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/imagedata/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getImageDatasActions.getImageDatasSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(
          getImageDatasActions.getImageDatasFailure(serializeError(error))
        )
      );
  };
}

function getImageData(userid, planid, pageno) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getImageDataActions.getImageDataBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/imagedata/${pageno}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getImageDataActions.getImageDataSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(getImageDataActions.getImageDataFailure(serializeError(error)))
      );
  };
}

function postImageData(userid, planid, imagedata) {
  let requestOptions = formdataReqOptions("POST", imagedata);
  return dispatch => {
    dispatch(postImageDataActions.postImageDataBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/imagedata/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(postImageDataActions.postImageDataSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(
          postImageDataActions.postImageDataFailure(serializeError(error))
        )
      );
  };
}

function putImageData(userid, planid, pageno, imagedata) {
  let requestOptions = formdataReqOptions("PUT", imagedata);
  return dispatch => {
    dispatch(putImageDataActions.putImageDataBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/imagedata/${pageno}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(putImageDataActions.putImageDataSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(putImageDataActions.putImageDataFailure(serializeError(error)))
      );
  };
}

function deleteImageData(userid, planid, pageno) {
  const requestOptions = genReqOptions(
    "DELETE",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(deleteImageDataActions.deleteImageDataBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/imagedata/${pageno}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(deleteImageDataActions.deleteImageDataSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(
          deleteImageDataActions.deleteImageDataFailure(serializeError(error))
        )
      );
  };
}

function getFileResults(userid, planid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getFileResultsActions.getFileResultsBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/fileresult/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getFileResultsActions.getFileResultsSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(
          getFileResultsActions.getFileResultsFailure(serializeError(error))
        )
      );
  };
}

function getFileResult(userid, planid, fileresultid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getFileResultActions.getFileResultBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/fileresult/${fileresultid}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getFileResultActions.getFileResultSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(
          getFileResultActions.getFileResultFailure(serializeError(error))
        )
      );
  };
}

function postFileResult(userid, planid, fileresultdata) {
  let requestOptions = formdataReqOptions("POST", fileresultdata);
  return dispatch => {
    dispatch(postFileResultActions.postFileResultBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/fileresult/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(postFileResultActions.postFileResultSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(
          postFileResultActions.postFileResultFailure(serializeError(error))
        )
      );
  };
}

function deleteFileResult(userid, planid, fileresultid) {
  const requestOptions = genReqOptions(
    "DELETE",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(deleteFileResultActions.deleteFileResultBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/fileresult/${fileresultid}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(deleteFileResultActions.deleteFileResultSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(
          deleteFileResultActions.deleteFileResultFailure(serializeError(error))
        )
      );
  };
}

function getScafItemSheets(userid, planid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getScafItemSheetsActions.getScafItemSheetsBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/scafitemsheet/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getScafItemSheetsActions.getScafItemSheetsSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(
          getScafItemSheetsActions.getScafItemSheetsFailure(
            serializeError(error)
          )
        )
      );
  };
}

function getScafItemSheet(userid, planid, scafitemsheetid) {
  const requestOptions = genReqOptions(
    "GET",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(getScafItemSheetActions.getScafItemSheetBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/scafitemsheet/${scafitemsheetid}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(json => {
        dispatch(getScafItemSheetActions.getScafItemSheetSuccess(json));
        return json;
      })
      .catch(error =>
        dispatch(
          getScafItemSheetActions.getScafItemSheetFailure(serializeError(error))
        )
      );
  };
}

function postScafItemSheet(userid, planid, scafitemsheetdata) {
  let requestOptions = formdataReqOptions("POST", scafitemsheetdata);
  return dispatch => {
    dispatch(postScafItemSheetActions.postScafItemSheetBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/scafitemsheet/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(postScafItemSheetActions.postScafItemSheetSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(
          postScafItemSheetActions.postScafItemSheetFailure(
            serializeError(error)
          )
        )
      );
  };
}

function deleteScafItemSheet(userid, planid, scafitemsheetid) {
  const requestOptions = genReqOptions(
    "DELETE",
    null,
    localStorage.getItem("usertoken")
  );
  return dispatch => {
    dispatch(deleteScafItemSheetActions.deleteScafItemSheetBegin());
    return fetch(
      `${domainStart}/v0/scafapp/users/${userid}/plans/${planid}/scafitemsheet/${scafitemsheetid}/`,
      requestOptions
    )
      .then(handleErrorsBest)
      .then(res => {
        dispatch(deleteScafItemSheetActions.deleteScafItemSheetSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(
          deleteScafItemSheetActions.deleteScafItemSheetFailure(
            serializeError(error)
          )
        )
      );
  };
}

function postPassword(passworddata) {
  let requestOptions = formdataReqOptions("POST", passworddata);
  return dispatch => {
    dispatch(postPasswordActions.postPasswordBegin());
    return fetch(`${domainStart}/v0/password/`, requestOptions)
      .then(handleErrorsBest)
      .then(res => {
        dispatch(postPasswordActions.postPasswordSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(postPasswordActions.postPasswordFailure(serializeError(error)))
      );
  };
}

function resetPassword(passworddata) {
  let requestOptions = formdataReqOptions("POST", passworddata);
  return dispatch => {
    dispatch(resetPasswordActions.resetPasswordBegin());
    return fetch(`${domainStart}/v0/password/reset/`, requestOptions)
      .then(handleErrorsBest)
      .then(res => {
        dispatch(resetPasswordActions.resetPasswordSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(
          resetPasswordActions.resetPasswordFailure(serializeError(error))
        )
      );
  };
}

function confirmPassword(passworddata) {
  let requestOptions = formdataReqOptions("POST", passworddata);
  return dispatch => {
    dispatch(confirmPasswordActions.confirmPasswordBegin());
    return fetch(`${domainStart}/v0/password/reset/confirm/`, requestOptions)
      .then(handleErrorsBest)
      .then(res => {
        dispatch(confirmPasswordActions.confirmPasswordSuccess(res));
        return res;
      })
      .catch(error =>
        dispatch(
          confirmPasswordActions.confirmPasswordFailure(serializeError(error))
        )
      );
  };
}
