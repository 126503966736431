import React from "react";
import NavBar from "./NavBar.js";
import { connect } from "react-redux";
import { getLoginActions } from "../reduxandotherstuff/actions";
import BootFooter from "./BootFooter.js";
import { Helmet } from "react-helmet";
import * as constants from "../reduxandotherstuff/constants";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      submitted: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // If the user has a 401 redirection from an open Bootstrap modal, things will appear greyed out.
  // So this removes the greyed out elements.

  componentDidMount() {
    let modalbackdrop = document.getElementsByClassName("modal-backdrop");
    for (var i = modalbackdrop.length - 1; i >= 0; i--) {
      modalbackdrop[0].parentNode.removeChild(modalbackdrop[0]);
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      dispatch(
        getLoginActions.login({ username: username, password: password })
      );
    }
  }

  render() {
    const propSupply = [
      { isActive: false, href: "/", navText: "Home" },
      { isActive: true, href: "/login", navText: "Login" },
      { isActive: false, href: "/plans", navText: "Plan Board" }
    ];
    const { username, password, submitted } = this.state;

    let usernameClass = "form-control";
    if (submitted && !username) {
      usernameClass = "form-control is-invalid";
    }
    let passwordClass = "form-control";
    if (submitted && !password) {
      passwordClass = "form-control is-invalid";
    }

    return (
      <div>
        <Helmet>
          <title>Scafflow | Login</title>
        </Helmet>
        <NavBar navbarItems={propSupply} />
        <main className="container" role="main">
          <div className="jumbotron row" id="loginjumbo">
            <div className="col-md-6 mx-auto" id="loginjumboleft">
              <h1>Welcome back.</h1>
              <p id="loginjumboleftpara">
                <img
                  alt="Scaffold silhouette"
                  src="/images/silhouettecyan.png"
                />
              </p>
            </div>
            <div className="col-md-6 mx-auto" id="loginjumboright">
              <h2>Sign in</h2>

              <p>
                {/*               Don't have an account? <a href=".">Sign up</a> */}
              </p>

              {this.props.getlogininfo.error && (
                <div className="alert alert-danger">
                  {constants.LOGIN_ERROR_MESSAGE}
                </div>
              )}
              <form className="login-form" onSubmit={this.handleSubmit}>
                <div className="form-group row">
                  <label htmlFor="username">User Name</label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className={usernameClass}
                    value={username}
                    onChange={this.handleChange}
                    placeholder="User Name"
                  />
                  <div className="invalid-feedback">
                    Please provide a valid user name.
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className={passwordClass}
                    value={password}
                    onChange={this.handleChange}
                    placeholder="Password"
                  />
                  <div className="invalid-feedback">
                    Please provide a valid password.
                  </div>
                </div>
                                
                <div className="row">
           {/*       <div className="form-check col-md-6">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                    />
                    <label className="form-check-label" htmlFor="gridCheck">
                      Remember me?
                    </label>
              </div> */}
            {/*      <div className="col-md-12">  Was col-md-6, right aligned. 
                    {" "}
                    <p className="text-left">
                      {" "}
                      <a href=".">Forget password?</a>
                    </p>
                  </div> */}
                </div>

                <button
                  type="submit"
                  className="btn btn-login float-right btn-primary"
                  id="btnSignIn"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </main>
        <BootFooter />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    getlogininfo: state.getlogininfo
  };
}

export default connect(mapStateToProps)(LoginPage);
